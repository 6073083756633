import React, { Component } from "reactn";
import FountainPenTip from "mdi-material-ui/FountainPenTip";
import { Icon, IconButton, Tooltip } from "@material-ui/core";
import { ThemeProvider as MuiThemeProvider } from "@material-ui/core/styles";

import {
    AuthoringBlockType,
    TextStyleType,
    VerticalAlignType,
    HorizontalAlignType,
    AuthoringShapeType,
    AuthoringElementType,
    ListStyleType, ChartType
} from "../../../../../../common/constants";
import { _ } from "../../../../../vendor";
import { AssetType } from "../../../../../../common/constants";
import * as geom from "../../../../../core/utilities/geom";
import { ShowDialog } from "../../../../../react/components/Dialogs/BaseDialog";
import { AddAssetsContainer } from "../../../../../react/views/AddAssets/index";
import { UIDraggable, UIMenuThumbnail, UIMenuThumbnailGrid } from "../../../../../editor/selection/components/StyledComponents";
import { Gap20 } from "../../../../../react/components/Gap";
import { FlexBox } from "../../../../../react/components/LayoutGrid";
import { dialogTheme } from "../../../../../react/materialThemeOverrides";
import { trackActivity, uuid } from "../../../../../core/utilities/utilities";
import FlipToFront from "mdi-material-ui/FlipToFront";
import FlipToBack from "mdi-material-ui/FlipToBack";
import AlignHorizontalLeft from "mdi-material-ui/AlignHorizontalLeft";
import AlignHorizontalCenter from "mdi-material-ui/AlignHorizontalCenter";
import Group from "mdi-material-ui/Group";
import Ungroup from "mdi-material-ui/Ungroup";
import AlignHorizontalRight from "mdi-material-ui/AlignHorizontalRight";
import AlignVerticalTop from "mdi-material-ui/AlignVerticalTop";
import AlignVerticalCenter from "mdi-material-ui/AlignVerticalCenter";
import AlignVerticalBottom from "mdi-material-ui/AlignVerticalBottom";
import DistributeHorizontalCenter from "mdi-material-ui/DistributeHorizontalCenter";
import DistributeVerticalCenter from "mdi-material-ui/DistributeVerticalCenter";
import { PopupMenu } from "../../../../../react/components/PopupMenu";

import { AuthoringCanvasControlBar, ControlBarGroup } from "./AuthoringCanvasControlBar";
import { ConvertScreenToSelectionLayer } from "./Editors/AuthoringHelpers";
import AppController from "../../../../../core/AppController";
import { InfographicElementTypes } from "../../../elements/elements/Dashboard/InfographicManager";
import { ChangeChartType } from "../../ElementPropertyPanels/ChartEditor/ChartUI";

export class AuthoringControlBar extends Component {
    constructor(props) {
        super(props);

        this.controlBarRef = React.createRef();
    }

    get bounds() {
        if (this.controlBarRef.current) {
            return this.controlBarRef.current.gridBounds;
        }

        return null;
    }

    getDefaultModel = (type, style, chartType) => {
        switch (type) {
            case AuthoringElementType.TEXT:
                let defaultBlock;
                if (style == AuthoringBlockType.EQUATION || style == AuthoringBlockType.CODE) {
                    defaultBlock = {
                        id: uuid(),
                        type: style
                    };
                } else {
                    defaultBlock = {
                        id: uuid(),
                        type: AuthoringBlockType.TEXT,
                        textStyle: style,
                        html: ""
                    };

                    if (style == TextStyleType.BULLET_LIST) {
                        defaultBlock.listStyle = ListStyleType.BULLET;
                    }
                }

                return {
                    type: AuthoringElementType.SHAPE,
                    width: 400,
                    height: 150,
                    shape: AuthoringShapeType.RECT,
                    fill: "none",
                    stroke: "none",
                    textAlign: HorizontalAlignType.LEFT,
                    verticalAlign: VerticalAlignType.TOP,
                    fitToText: true,
                    text: {
                        blocks: [defaultBlock]
                    }
                };
            case AuthoringElementType.SHAPE:
                return {
                    type,
                    shape: style,
                    width: 150,
                    height: 150
                };
            case AuthoringElementType.COMPONENT:
                return {
                    type,
                    componentType: style,
                    // Give components bigger default size
                    width: 400,
                    height: 200,
                    // Will force the component to use this object
                    // for its model
                    element: {}
                };
            case AuthoringElementType.INFOGRAPHIC:
                if (style === InfographicElementTypes.CHART && (chartType === ChartType.PIE || chartType === ChartType.DONUT)) {
                    return {
                        type: AuthoringElementType.COMPONENT,
                        componentType: "LayoutContainerItem",
                        width: 400,
                        height: 300,
                        element: {
                            type: "PieChart",
                            componentType: "PieChart",
                            data: [{
                                label: { text: "Category 1" },
                                value: 50,
                                color: null,
                                offset: 0
                            }, {
                                label: { text: "Category 2" },
                                value: 30,
                                color: null,
                                offset: 0
                            }],
                            isDonut: chartType === ChartType.DONUT,
                            collectionColor: "theme"
                        }
                    };
                }
                return {
                    type: AuthoringElementType.COMPONENT,
                    componentType: "LayoutContainerItem",
                    width: 400,
                    height: 300,
                    element: {
                        componentType: style
                    }
                };
            case AuthoringElementType.CALLOUT:
                // The callout type doesn't have a default background color
                return {
                    type,
                    calloutType: style,
                    width: 200,
                    height: 50
                };
            default:
                return {
                    type,
                    width: 130,
                    height: 130
                };
        }
    }

    createElement = async (type, style, chartType = null) => {
        const {
            setDragCreateModel,
            containerElement,
            refreshCanvasAndSaveChanges,
            selectElement,
        } = this.props;

        const model = this.getDefaultModel(type, style, chartType);

        switch (model.type) {
            case AuthoringElementType.CONTENT:
                ShowDialog(AddAssetsContainer, {
                    assetType: AssetType.IMAGE,
                    workspaceId: AppController.workspaceId,
                    callback: assetModel => {
                        const {
                            content_type,
                            content_value,
                            content_url,
                            assetProps,
                            assetProps: {
                                originalSize,
                            },
                            previewUrl
                        } = assetModel;

                        let defaultSize;
                        if (content_type === AssetType.ICON) {
                            defaultSize = new geom.Size(100, 100);
                        } else {
                            const baseAspectRatio = 1280 / 720;
                            const aspectRatio = originalSize.width / originalSize.height;

                            // Assume width is the limiting dimension
                            let width = Math.min(originalSize.width, 1280);
                            let height = originalSize.height * (width / originalSize.width);
                            // Recalculate if height is the limiting factor
                            if (aspectRatio < baseAspectRatio) {
                                height = Math.min(originalSize.height, 720);
                                width = originalSize.width * (height / originalSize.height);
                            }

                            defaultSize = new geom.Size(width, height);
                        }

                        const model = {
                            type: AuthoringElementType.CONTENT,
                            // Placing in the center of canvas
                            x: containerElement.bounds.width / 2 - defaultSize.width / 2,
                            y: containerElement.bounds.height / 2 - defaultSize.height / 2,
                            width: defaultSize.width,
                            height: defaultSize.height,
                            element: {
                                content_value,
                                content_type,
                                content_url,
                                assetProps,
                                previewUrl
                            }
                        };

                        if (content_type === AssetType.ICON) {
                            model.element.iconColor = "primary";
                        }

                        const { id } = containerElement.addItem(model);

                        refreshCanvasAndSaveChanges()
                            .then(() => {
                                selectElement(containerElement.getChild(id));
                            });
                    }
                });
                break;
            default:
                setDragCreateModel(model, chartType);
        }

        trackActivity("Slide", "AuthoringElementAdded", null, null, { type });
    }

    handleDragStart = () => {
        const { clearSelection } = this.props;
        clearSelection();
    }

    handleDragDrop = async (event, type, style, chartType = null) => {
        const { containerElement, selectElement, refreshCanvasAndSaveChanges } = this.props;

        const model = this.getDefaultModel(type, style, chartType);

        const dropPoint = ConvertScreenToSelectionLayer(event.pageX, event.pageY).multiply(1 / containerElement.canvas.getScale());

        // Fitting in canvas
        const elementBounds = new geom.Rect(dropPoint.x - model.width / 2, dropPoint.y - model.height / 2, model.width, model.height)
            .fitInRect(containerElement.bounds.zeroOffset());
        model.x = elementBounds.left;
        model.y = elementBounds.top;

        const { id } = containerElement.addItem(model);

        refreshCanvasAndSaveChanges()
            .then(async () => {
                if (chartType) {
                    await ChangeChartType(containerElement.getChild(id), chartType);
                    refreshCanvasAndSaveChanges()
                        .then(() => selectElement(containerElement.getChild(id)));
                } else {
                    selectElement(containerElement.getChild(id));
                }
            });
    }

    handleAction = (event, action) => {
        event.stopPropagation();
        this.props.onAction(action);
    }

    getElementsSelectionBounds = elements => {
        let selectionBounds;
        for (const element of elements) {
            let elementBounds = new geom.Rect(element.model.x, element.model.y, element.model.width, element.model.height);
            if (element.referencePoint) {
                elementBounds = elementBounds.offset(-element.referencePoint.x, -element.referencePoint.y);
            }
            if (!selectionBounds) {
                selectionBounds = elementBounds;
            } else {
                selectionBounds = selectionBounds.union(elementBounds);
            }
        }
        return selectionBounds;
    }

    renderIconButton(tooltipTitle, action, IconComponent) {
        return (
            <Tooltip title={tooltipTitle} arrow>
                <IconButton onClick={event => this.handleAction(event, action)}>
                    <IconComponent />
                </IconButton>
            </Tooltip>
        );
    }

    renderAlignmentControls() {
        return (
            <>
                {this.renderIconButton("Align Left", "align-left", AlignHorizontalLeft)}
                {this.renderIconButton("Align Center", "align-center", AlignHorizontalCenter)}
                {this.renderIconButton("Align Right", "align-right", AlignHorizontalRight)}
                {this.renderIconButton("Align Top", "align-top", AlignVerticalTop)}
                {this.renderIconButton("Align Middle", "align-middle", AlignVerticalCenter)}
                {this.renderIconButton("Align Bottom", "align-bottom", AlignVerticalBottom)}
                {this.props.selection.length > 2 && (
                    <>
                        {this.renderIconButton("Distribute Horizontally", "distribute-horizontal", DistributeHorizontalCenter)}
                        {this.renderIconButton("Distribute Vertically", "distribute-vertical", DistributeVerticalCenter)}
                    </>
                )}
            </>
        );
    }

    renderGroupControls() {
        const { canGroup, canUngroup } = this.props;
        return (
            <>
                {canGroup && this.renderIconButton("Group Layers", "group", Group)}
                {canUngroup && this.renderIconButton("Ungroup Layers", "ungroup", Ungroup)}
            </>
        );
    }

    render() {
        const { dragCreate, canGroup, canUngroup, canvasBounds, selection, minimized, containerElement } = this.props;

        const shapeTypes = [AuthoringShapeType.RECT, AuthoringShapeType.ELLIPSE, AuthoringShapeType.ARROW, AuthoringShapeType.STAR, AuthoringShapeType.CHEVRON_START, AuthoringShapeType.CHEVRON, AuthoringShapeType.POLYGON, AuthoringShapeType.DIAMOND];
        const textStyles = [TextStyleType.HEADING, TextStyleType.TITLE, TextStyleType.BODY, TextStyleType.BULLET_LIST, AuthoringBlockType.CODE, AuthoringBlockType.EQUATION];
        const chartTypes = [ChartType.LINE, ChartType.COLUMN, ChartType.BAR, ChartType.AREA, ChartType.SPLINE, ChartType.AREASPLINE, ChartType.PIE, ChartType.DONUT, ChartType.WATERFALL];
        let left = canvasBounds.centerH;
        let top = canvasBounds.bottom + 30;

        if (dragCreate) {
            let dragType = dragCreate.type;
            if (dragCreate.type == AuthoringElementType.SHAPE && dragCreate.blocks?.length > 0) {
                dragType = "Text Box";
            } else if (dragCreate.type == AuthoringElementType.COMPONENT) {
                switch (dragCreate.componentType) {
                    case "TableFrame":
                        dragType = "Table";
                        break;
                    case "Dashboard":
                        dragType = "Chart";
                        break;
                }
            }
            return (
                <MuiThemeProvider theme={dialogTheme}>
                    <AuthoringCanvasControlBar ref={this.controlBarRef} left={left} top={top}>
                        <ControlBarGroup style={{ padding: 0 }}>
                            <FlexBox fillHeight style={{ background: "orangered" }}>
                                <Gap20 />
                                <label>Drag to create {dragType}</label>
                                <Gap20 />
                            </FlexBox>
                        </ControlBarGroup>
                    </AuthoringCanvasControlBar>
                </MuiThemeProvider>
            );
        }

        const AlignmentControls = this.renderAlignmentControls();

        if (minimized) {
            if (selection.length < 2) {
                return null;
            }

            let selectionBounds = this.getElementsSelectionBounds(selection).multiply(containerElement.canvas.getScale());
            left = selectionBounds.centerH;
            top = selectionBounds.top - 30;
            return (
                // <MuiThemeProvider theme={dialogTheme}>
                <AuthoringCanvasControlBar id="authoring-control-bar" ref={this.controlBarRef} left={left} top={top}>
                    <ControlBarGroup>
                        {selection.length > 0 && (canGroup || canUngroup) &&
                            <>
                                {canGroup && this.renderIconButton("Group Layers", "group", Group)}
                                {canUngroup && this.renderIconButton("Ungroup Layers", "ungroup", Ungroup)}
                            </>}
                        {AlignmentControls}
                    </ControlBarGroup>
                </AuthoringCanvasControlBar>
                // </MuiThemeProvider>
            );
        }

        return (
            <MuiThemeProvider theme={dialogTheme}>
                <AuthoringCanvasControlBar id="authoring-control-bar" ref={this.controlBarRef} left={left} top={top}>
                    <ControlBarGroup>
                        <PopupMenu icon="text_fields" tooltip="Add Text">
                            <UIMenuThumbnailGrid>
                                {textStyles.map((style, index) => (
                                    <UIDraggable key={index} onDragStart={this.handleDragStart} onDragComplete={event => this.handleDragDrop(event, AuthoringElementType.TEXT, style)}>
                                        <UIMenuThumbnail
                                            key={style}
                                            value={style}
                                            label={style}
                                            path={`/images/ui/contentblocktypes/${style}.svg`}
                                            onClick={() => this.createElement(AuthoringElementType.TEXT, style)}
                                        />
                                    </UIDraggable>
                                ))}
                            </UIMenuThumbnailGrid>
                        </PopupMenu>
                        <PopupMenu icon="crop_landscape" tooltip="Add Shape">
                            <UIMenuThumbnailGrid columns={8} size={75}>
                                {shapeTypes.map((style, index) => (
                                    <UIDraggable key={index} onDragComplete={event => this.handleDragDrop(event, AuthoringElementType.SHAPE, style)}>
                                        <UIMenuThumbnail
                                            key={style}
                                            value={style}
                                            path={`/images/authoring/${style}.svg`}
                                            onClick={() => this.createElement(AuthoringElementType.SHAPE, style)}
                                        />
                                    </UIDraggable>
                                ))}
                            </UIMenuThumbnailGrid>
                        </PopupMenu>
                        <Tooltip title="Add Image" arrow>
                            <IconButton onClick={() => this.createElement(AuthoringElementType.CONTENT)}>
                                <Icon>photo_camera</Icon>
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Draw Path" arrow>
                            <IconButton onClick={() => this.createElement(AuthoringElementType.PATH)}>
                                <FountainPenTip />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Add Table" arrow>
                            <IconButton onClick={() => this.createElement(AuthoringElementType.INFOGRAPHIC, InfographicElementTypes.TABLE)}>
                                <Icon>grid_on</Icon>
                            </IconButton>
                        </Tooltip>
                        <PopupMenu icon="bar_chart" tooltip="Add Chart">
                            <UIMenuThumbnailGrid columns={9} size={75}>
                                {chartTypes.map(chartType => (
                                    <UIDraggable key={chartType} onDragComplete={event => this.handleDragDrop(event, AuthoringElementType.INFOGRAPHIC, InfographicElementTypes.CHART, chartType)}>
                                        <UIMenuThumbnail
                                            key={chartType}
                                            value={chartType}
                                            label={chartType}
                                            path={chartType === "areaspline" ? `/images/infographics/chart-area-spline.svg` : `/images/infographics/chart-${chartType}.svg`}
                                            onClick={() => this.createElement(AuthoringElementType.INFOGRAPHIC, InfographicElementTypes.CHART, chartType)}
                                        />
                                    </UIDraggable>
                                ))}
                            </UIMenuThumbnailGrid>
                        </PopupMenu>
                    </ControlBarGroup>

                    {selection.length > 0 && (canGroup || canUngroup || !selection.some(selection => selection.childElement?.isCallout)) &&
                        <ControlBarGroup light>
                            {canGroup && this.renderIconButton("Group Layers", "group", Group)}
                            {canUngroup && this.renderIconButton("Ungroup Layers", "ungroup", Ungroup)}

                            {/* keep this functionality for the authoring elements but not the callouts */}
                            {!selection.some(selection => selection.childElement?.isCallout) && (
                                <>
                                    <Tooltip title="Bring to Front" arrow>
                                        <IconButton onClick={event => this.handleAction(event, "bringToFront")}>
                                            <FlipToFront />
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title="Send to Back" arrow>
                                        <IconButton onClick={event => this.handleAction(event, "sendToBack")}>
                                            <FlipToBack />
                                        </IconButton>
                                    </Tooltip>
                                </>)}
                            {selection.length > 1 && AlignmentControls}
                        </ControlBarGroup>
                    }
                </AuthoringCanvasControlBar>
            </MuiThemeProvider>
        );
    }
}
