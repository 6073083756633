import {
    AssetType,
    BlockStructureType,
    DecorationStyle,
    HorizontalAlignType,
    PositionType,
    TextStyleType,
    VerticalAlignType
} from "common/constants";
import * as geom from "js/core/utilities/geom";
import { _ } from "js/vendor";

import { AuthoringBlockType } from "common/constants";

import {
    PresentationTitleLogosControlBar,
    PresentationTitleLogosPropertyPanel
} from "../../Editor/ElementPropertyPanels/PresentationTitleLogosUI";
import {
    PresentationTitlePresentersControlBar,
    PresentationTitlePresentersItemSelection,
    PresentationTitlePresentersPropertyPanel,
    PresentationTitlePresentersSelection
} from "../../Editor/ElementPropertyPanels/PresentationTitlePresentersUI";
import {
    PresentationTitleImageSelection,
    PresentationTitlePropertyPanel,
    PresentationTitleTextSelection
} from "../../Editor/ElementPropertyPanels/PresentationTitleUI";
import { BaseElement } from "../base/BaseElement";
import { CollectionElement, CollectionItemElement } from "../base/CollectionElement";
import { AutoSizeContentElement, ContentElement } from "../base/ContentElement";
import { TextElement } from "../base/Text/TextElement";
import { TextFrame } from "../base/Text/TextFrame";
import { layoutHelper } from "../layouts/LayoutHelper";
import { FramedMediaElement } from "../base/MediaElements/FramedMediaElement";

class PresentationTitle extends BaseElement {
    static get schema() {
        return {
            showFooter: false
        };
    }

    get name() {
        return "Title Slide";
    }

    getElementPropertyPanel() {
        return PresentationTitlePropertyPanel;
    }

    getCanvasMargins() {
        return { left: 0, right: 0, top: 0, bottom: 0 };
    }

    get titleDecoration() {
        if (this.model.decoration) {
            return this.model.decoration;
        } else {
            // use theme decoration to get default title decoration
            switch (this.canvas.getTheme().get("styleDecoration")) {
                case "bar_left":
                    return "left_border";
                case "bar_top_center":
                case "bar_top_left":
                    return "top_border";
                case "border":
                    return "frame";
                case "block":
                case "block_center":
                case "block_left":
                    return "block";
                case "underline_left":
                case "underline_center":
                    return "underline";
                default:
                    return "none";
            }
        }
    }

    get _canRollover() {
        return true;
    }

    get showPresenters() {
        return (this.model.showPresenters && this.model.presenters && this.model.presenters.items && (this.model.presenters.items.length > 0)) ?? false;
    }

    get showFooter() {
        return this.model.showFooter;
    }

    get showLogos() {
        return this.model.logos?.items?.length > 0;
    }

    get showImage() {
        return this.model.image != null && !_.isEmpty(this.model.image.position);
    }

    get canStyleText() {
        return this.showImage && this.imagePosition === "background";
    }

    get imagePosition() {
        return this.model.image?.position || "left";
    }

    get imageTraySize() {
        return this.model.image?.traySize || 400;
    }

    set imageTraySize(value) {
        if (!this.model.image) {
            this.model.image = {};
        }
        this.model.image.traySize = value;
    }

    get textPosition() {
        switch (this.model.textAlign ?? HorizontalAlignType.CENTER) {
            case HorizontalAlignType.LEFT:
                switch (this.model.verticalAlign) {
                    case VerticalAlignType.TOP:
                        return PositionType.TOP_LEFT;
                    case VerticalAlignType.BOTTOM:
                        return PositionType.BOTTOM_LEFT;
                    default:
                        return PositionType.LEFT;
                }
            case HorizontalAlignType.RIGHT:
                switch (this.model.verticalAlign) {
                    case VerticalAlignType.TOP:
                        return PositionType.TOP_RIGHT;
                    case VerticalAlignType.BOTTOM:
                        return PositionType.BOTTOM_RIGHT;
                    default:
                        return PositionType.RIGHT;
                }
            case HorizontalAlignType.CENTER:
            default:
                switch (this.model.verticalAlign) {
                    case VerticalAlignType.TOP:
                        return PositionType.TOP;
                    case VerticalAlignType.BOTTOM:
                        return PositionType.BOTTOM;
                    default:
                        return PositionType.CENTER;
                }
        }
    }

    get hasTopBar() {
        return this.showLogos && (this.logos.position == PositionType.TOP_LEFT || this.logos.position() == PositionType.TOP_RIGHT);
    }

    get hasBottomBar() {
        return (this.showLogos && (this.logos.position == PositionType.BOTTOM_LEFT || this.logos.position() == PositionType.BOTTOM_RIGHT)) || this.showPresenters;
    }

    get disableAllAnimationsByDefault() {
        return true;
    }

    _build() {
        if (!this.model.accentColor) {
            this.model.accentColor = this.canvas.model.layout.slideColor ?? this.canvas.getTheme().get("defaultSlideColor") ?? "theme";
        }

        if (this.showImage) {
            this.image = this.addElement("image", () => PresentationTitleImage, { model: this.model.image, overflow: "hidden" });
            this.image.layer = this.imagePosition == "background" ? -1 : 1;
        }

        let textOverImage = this.showImage && this.imagePosition == "background" && this.titleDecoration != "block";
        this.textFrame = this.addElement("textFrame", () => PresentationTitleTextFrame, {
            autoHeight: true,
            fillFrameWidth: true,
            textPosition: this.textPosition,
            backgroundElement: textOverImage ? this.image : null,
            textOptions: {
                accentColor: this.accentColor,
                allowAlignment: false,
                canAddBlocks: false,
                allowedBlockTypes: [TextStyleType.HEADING, TextStyleType.TITLE, TextStyleType.BODY, TextStyleType.LABEL],
                defaultBlockTextStyle: TextStyleType.HEADING,
                elementSelection: PresentationTitleTextSelection
            },
        });

        if (this.showPresenters) {
            this.presenters = this.addElement("presenters", () => PresentationTitlePresenters, { model: this.model.presenters });
        }
        if (this.showFooter) {
            this.footer = this.addElement("footer", () => PresentationTitleFooter);
        }
        if (this.showLogos) {
            this.logos = this.addElement("logos", () => PresentationLogos, { model: this.model.logos });
            this.logos.layer = 1;
        }
    }

    get decorationStyle() {
        return DecorationStyle.FILLED;
    }

    _loadStyles(styles) {
        if (this.titleDecoration && this.titleDecoration !== "none") {
            let decoration = this.titleDecoration;

            if (decoration === "underline") {
                if (this.model.backdropStyle && this.model.backdropStyle != "none") {
                    return; // underline decoration is not supported with backdrop
                }
                switch (this.textPosition) {
                    case "left":
                    case "top_left":
                    case "bottom_left":
                        decoration = "underline_left";
                        break;
                    case "right":
                    case "top_right":
                    case "bottom_right":
                        decoration = "underline_right";
                        break;
                    default:
                        decoration = "underline_center";
                }
            }

            styles.applyStyles(styles.decorations[decoration]);
        }
    }

    _calcProps(props, options) {
        let { size } = props;

        let availableBounds = new geom.Rect(0, 0, size);

        switch (this.titleDecoration) {
            case "top_border":
                availableBounds = availableBounds.deflate({ top: this.canvas.styleSheet.$decorationStrokeWidth * 3 });
                break;
            case "left_border":
                availableBounds = availableBounds.deflate({ left: this.canvas.styleSheet.$decorationStrokeWidth * 3 });
                break;
            case "frame":
                availableBounds = availableBounds.deflate(this.canvas.styleSheet.$decorationStrokeWidth * 4);
                break;
        }

        if (this.model.showFooter) {
            let footerProps = this.footer.calcProps(size);
            footerProps.bounds = new geom.Rect(0, size.height - footerProps.size.height, size.width, footerProps.size.height);
            availableBounds = availableBounds.deflate({ bottom: footerProps.size.height });
        }

        let logosProps;
        if (this.showLogos) {
            logosProps = this.logos.calcProps(size);
            switch (this.logos.position) {
                case PositionType.CENTER:
                    // handled below after text layout
                    break;
                case PositionType.TOP_RIGHT:
                    logosProps.bounds = new geom.Rect(availableBounds.right - logosProps.size.width, availableBounds.top, logosProps.size);
                    break;
                case PositionType.TOP_LEFT:
                default:
                    logosProps.bounds = new geom.Rect(availableBounds.left, availableBounds.top, logosProps.size);
            }
        }

        if (this.showImage) {
            let imageProps;
            switch (this.imagePosition) {
                case "left":
                    imageProps = this.image.calcProps(new geom.Size(this.imageTraySize, availableBounds.height));
                    imageProps.bounds = new geom.Rect(availableBounds.left, availableBounds.top, imageProps.size);
                    availableBounds = availableBounds.deflate({ left: imageProps.size.width });
                    break;
                case "right":
                    imageProps = this.image.calcProps(new geom.Size(this.imageTraySize, availableBounds.height));
                    imageProps.bounds = new geom.Rect(availableBounds.right - imageProps.size.width, availableBounds.top, imageProps.size);
                    availableBounds = availableBounds.deflate({ right: imageProps.size.width });
                    break;
                case "top":
                    imageProps = this.image.calcProps(new geom.Size(availableBounds.width, this.imageTraySize));
                    imageProps.bounds = new geom.Rect(availableBounds.left, availableBounds.top, imageProps.size);
                    availableBounds = availableBounds.deflate({ top: imageProps.size.height });
                    break;
                case "bottom":
                    imageProps = this.image.calcProps(new geom.Size(availableBounds.width, this.imageTraySize));
                    imageProps.bounds = new geom.Rect(availableBounds.left, availableBounds.bottom - imageProps.size.height, imageProps.size);
                    availableBounds = availableBounds.deflate({ bottom: imageProps.size.height });
                    break;
                case "background":
                    imageProps = this.image.calcProps(availableBounds.size);
                    imageProps.bounds = new geom.Rect(availableBounds.left, availableBounds.top, imageProps.size);
                    break;
            }
        }

        let presenterProps;
        if (this.showPresenters) {
            if (this.textPosition.contains("left")) {
                this.presenters.styles.paddingLeft += this.textFrame.styles.paddingLeft;
            }

            presenterProps = this.presenters.calcProps(availableBounds.size);
            presenterProps.bounds = new geom.Rect(availableBounds.left, availableBounds.bottom - presenterProps.size.height, availableBounds.width, presenterProps.size.height);
        }

        let textAreaBounds = availableBounds.clone();
        if (this.showLogos && !(this.showImage && this.imagePosition == "top") && this.textPosition.contains("top")) {
            textAreaBounds = textAreaBounds.deflate({ top: logosProps.size.height });
        }

        if (this.showPresenters && (this.textPosition.contains("bottom") || (this.showImage && this.imagePosition == "top"))) {
            textAreaBounds = textAreaBounds.deflate({ bottom: presenterProps.size.height });
        }

        let textAlign;
        switch (this.textPosition) {
            case "left":
            case "top_left":
            case "bottom_left":
                textAlign = HorizontalAlignType.LEFT;
                // if (props.styles.textFrame.textStyle.contains("box")){
                //     textAreaBounds = textAreaBounds.deflate({left: props.styles.textFrame.styles.backdrop.paddingLeft});
                // }
                break;
            case "top":
            case "center":
            case "bottom":
                textAlign = HorizontalAlignType.CENTER;
                break;
            case "top_right":
            case "right":
            case "bottom_right":
                textAlign = HorizontalAlignType.RIGHT;
                break;
        }

        let textProps = this.textFrame.calcProps(textAreaBounds.size, { textAlign });
        textProps.bounds = new geom.Rect(textAreaBounds.left, textAreaBounds.centerV - textProps.size.height / 2, textProps.size);

        switch (this.textPosition) {
            case "left":
            case "right":
            case "center":
                textProps.bounds.top = textAreaBounds.centerV - textProps.size.height / 2;
                break;
            case "top_left":
            case "top_right":
            case "top":
                textProps.bounds.top = textAreaBounds.top;//+ this.textFrame.styles.backdrop.paddingTop;
                break;
            case "bottom_left":
            case "bottom_right":
            case "bottom":
                textProps.bounds.top = textAreaBounds.bottom - textProps.size.height;// - this.textFrame.styles.backdrop.paddingBottom;
                break;
        }

        if (this.showLogos && this.logos.position == PositionType.CENTER) {
            let left;
            switch (this.textPosition) {
                case "left":
                case "top_left":
                case "bottom_left":
                    left = availableBounds.left;
                    break;
                case "top_right":
                case "bottom_right":
                case "right":
                    left = availableBounds.right - logosProps.size.width;
                    break;
                case "bottom":
                case "center":
                case "top":
                default:
                    left = availableBounds.left + layoutHelper.getHorizontalAlignOffset(logosProps.size.width, availableBounds.width, HorizontalAlignType.CENTER);
            }
            logosProps.bounds = new geom.Rect(left, textAreaBounds.top + this.textFrame.textFrameBox.bounds.top - logosProps.size.height, logosProps.size);
            if (this.titleDecoration == "block") {
                logosProps.bounds.top -= 40;
            }
        }

        return { size };
    }

    _applyColors() {
        if (this.showImage && this.imagePosition == "background") {
            this.colorSet.backgroundColor = this.palette.getColor("black");
        }
        this.colorSet.decorationColor = this.palette.getColor(this.model.accentColor ?? "theme", this.getBackgroundColor());

        if (this.titleDecoration == "block") {
            // block ignores background color
            this.textFrame.text.decoration.colorSet.fillColor = this.colorSet.decorationColor;
            this.textFrame.text.decoration.colorSet.backgroundColor = this.getBackgroundColor();
            this.textFrame.text.colorSet.backgroundColor = this.colorSet.decorationColor;
        }
        if (this.titleDecoration == "frame") {
            // this is a hack to make the fill color of the frame decoration the same as the background color
            this.decoration.colorSet.fillColor = this.getBackgroundColor();
        }
    }

    _migrate_10() {
        delete this.model.body; // old legacy model property that is no longer in use
        if (!this.model.showLogos) {
            delete this.model.showLogos;
            delete this.model.logos;
        }
    }

    _migrate_10_02() {
        switch (this.model.textPosition) {
            case PositionType.TOP_LEFT:
                this.model.textAlign = HorizontalAlignType.LEFT;
                this.model.verticalAlign = VerticalAlignType.TOP;
                break;
            case PositionType.TOP_RIGHT:
                this.model.textAlign = HorizontalAlignType.RIGHT;
                this.model.verticalAlign = VerticalAlignType.TOP;
                break;
            case PositionType.BOTTOM_LEFT:
                this.model.textAlign = HorizontalAlignType.LEFT;
                this.model.verticalAlign = VerticalAlignType.BOTTOM;
                break;
            case PositionType.BOTTOM_RIGHT:
                this.model.textAlign = HorizontalAlignType.RIGHT;
                this.model.verticalAlign = VerticalAlignType.BOTTOM;
                break;
            case PositionType.LEFT:
                this.model.textAlign = HorizontalAlignType.LEFT;
                this.model.verticalAlign = VerticalAlignType.CENTER;
                break;
            case PositionType.RIGHT:
                this.model.textAlign = HorizontalAlignType.RIGHT;
                this.model.verticalAlign = VerticalAlignType.CENTER;
                break;
            case PositionType.CENTER:
                this.model.textAlign = HorizontalAlignType.CENTER;
                this.model.verticalAlign = VerticalAlignType.CENTER;
                break;
            case PositionType.TOP:
                this.model.textAlign = HorizontalAlignType.CENTER;
                this.model.verticalAlign = VerticalAlignType.TOP;
                break;
            case PositionType.BOTTOM:
                this.model.textAlign = HorizontalAlignType.CENTER;
                this.model.verticalAlign = VerticalAlignType.BOTTOM;
                break;
            default:
                if (this.canvas.getTheme().get("styleDecoration").contains("center")) {
                    this.model.textAlign = HorizontalAlignType.CENTER;
                    this.model.verticalAlign = VerticalAlignType.CENTER;
                } else {
                    this.model.textAlign = HorizontalAlignType.LEFT;
                    this.model.verticalAlign = VerticalAlignType.CENTER;
                }
        }
        delete this.model.textPosition;
    }

    _exportToSharedModel() {
        const textContent = this.textFrame.text._exportToSharedModel(true).textContent;
        if (this.showPresenters) {
            textContent.push(...this.presenters.itemElements.reduce((acc, item) => [
                ...acc, ...item.text._exportToSharedModel().textContent
            ], []));
        }

        const assets = this.showImage ? this.image._exportToSharedModel().assets : [];
        if (this.showLogos) {
            assets.push(...this.logos.itemElements.reduce((acc, item) => [
                ...acc, ...item.logo._exportToSharedModel().assets
            ], []));
        }

        return { textContent, assets };
    }

    _importFromSharedModel(model) {
        const { textContent, assets } = model;
        if (!textContent?.length && !assets?.length) {
            return;
        }

        const elementModel = {};

        if (textContent?.length) {
            elementModel.text = {
                blocks: textContent.reduce((acc, { mainText, secondaryTexts }) => ([...acc, ...[
                    {
                        html: mainText.text,
                        textStyle: mainText.textStyle || TextStyleType.TITLE,
                        type: AuthoringBlockType.TEXT,
                    },
                    ...secondaryTexts.map(({ text, textStyle }) => ({
                        html: text,
                        textStyle: textStyle || TextStyleType.BODY,
                        type: AuthoringBlockType.TEXT,
                    }))
                ]]), [])
            };
        }

        if (assets?.length) {
            const [image, ...logos] = assets;

            if (image) {
                elementModel.showImage = true;
                elementModel.image = {
                    content_type: image.type,
                    content_value: image.value,
                    assetName: image.name,
                    assetProps: image.configProps,
                    position: "background"
                };
            }

            if (logos.length) {
                elementModel.showLogos = true;
                elementModel.logos = {
                    items: logos.map(logo => ({
                        logo: {
                            content_type: logo.type,
                            content_value: logo.value,
                            assetName: logo.name,
                            assetProps: logo.configProps,
                        }
                    }))
                };
            }
        }

        return elementModel;
    }
}

class PresentationTitleTextFrame extends TextFrame {
    static get schema() {
        return {
            backdropPadding: 40
        };
    }

    get _canSelect() {
        return false;
    }

    get canDragPosition() {
        return false;
    }

    get canDelete() {
        return false;
    }
}

class PresentationTitleImage extends ContentElement {
    get _canSelect() {
        return true;
    }

    get showInlineAddMediaButton() {
        return false;
    }

    get animationElementName() {
        return "Image";
    }

    get animateChildren() {
        return false;
    }

    getElementSelection() {
        return PresentationTitleImageSelection;
    }

    _loadStyles(styles) {
        styles.overflow = "hidden"; // this is necessary to clip the content when adjusting scale and from ken burns animations
    }

    _calcProps(props, options = {}) {
        this.createDecoration({ type: "frame", shape: "rect" });
        return super._calcProps(props, options);
    }

    _applyColors() {
        this.decoration.colorSet.fillColor = this.palette.getColor(this.model.backgroundColor ?? "transparent");
        super._applyColors();
    }

    _getAnimations() {
        return [{
            name: "Fade in",
            prepare: () => this.animationState.fadeInProgress = 0,
            onBeforeAnimationFrame: progress => {
                this.animationState.fadeInProgress = progress;
            }
        }];
    }
}

class PresentationLogos extends CollectionElement {
    static get schema() {
        return {
            scale: 0.75
        };
    }

    get name() {
        return "Logos";
    }

    getElementControlBar() {
        return PresentationTitleLogosControlBar;
    }

    getElementPropertyPanel() {
        return PresentationTitleLogosPropertyPanel;
    }

    get _canSelect() {
        return true;
    }

    getChildItemType() {
        return PresentationLogoItem;
    }

    get position() {
        return this.model.position ?? PositionType.TOP_LEFT;
    }

    get minItemCount() {
        return 0;
    }

    get selectionPadding() {
        return 0;
    }

    get scale() {
        return this.model.scale ?? 1;
    }

    _calcProps(props, options) {
        let { size } = props;
        if (this.position == "center") {
            this.updateStyles(this.styles.center);
        }

        let layouter = this.getLayouter(props, this.itemElements, new geom.Size(size.width, this.styles.logoHeight * this.scale));
        layouter.distributeHorizontally({
            gap: this.styles.hGap * this.scale,
            verticalAlign: VerticalAlignType.MIDDLE,
            useCalculatedSizes: false,
            itemOptions: {
                scale: this.scale
            }
        });

        return { size: layouter.size };  // center position returns actual width of logos and not full width like the other positions
    }

    get animationElementName() {
        return `Logo${this.itemElements.length > 1 ? "s" : ""}`;
    }

    get animateChildren() {
        return false;
    }

    _getAnimations() {
        return [{
            name: "Fade in",
            prepare: () => this.animationState.fadeInProgress = 0,
            onBeforeAnimationFrame: progress => {
                this.animationState.fadeInProgress = progress;
            }
        }];
    }
}

class PresentationLogoItem extends CollectionItemElement {
    get _canSelect() {
        return true;
    }

    get _requireParentSelection() {
        return false;
    }

    get name() {
        return "Logo";
    }

    get selectionPadding() {
        return 0;
    }

    _build() {
        this.logo = this.addElement("logo", () => AutoSizeContentElement, {
            defaultAssetType: AssetType.LOGO,
            allowBackdrop: false,
            fitHeight: true,
            canSelect: false,
        });
    }

    _calcProps(props, options) {
        let { size } = props;

        let logoProps = this.logo.calcProps(size, { fitHeight: true });
        logoProps.bounds = new geom.Rect(0, 0, logoProps.size);

        return { size: logoProps.size };
    }
}

class PresentationTitlePresenters extends CollectionElement {
    get name() {
        return "Presenters";
    }

    get _canSelect() {
        return true;
    }

    get _passThroughSelection() {
        return false;
    }

    getChildItemType() {
        return PresentationTitlePresenterItem;
    }

    getElementPropertyPanel() {
        return PresentationTitlePresentersPropertyPanel;
    }

    getElementSelection() {
        return PresentationTitlePresentersSelection;
    }

    getElementControlBar() {
        return PresentationTitlePresentersControlBar;
    }

    get minItemCount() {
        return 0;
    }

    get layer() {
        return 2;
    }

    get showRollover() {
        return true;
    }

    get horizontalAlign() {
        return this.model.horizontalAlign || HorizontalAlignType.LEFT;
    }

    get isOverImage() {
        return this.parentElement.showImage && this.parentElement.imagePosition === "background";
    }

    get textStyle() {
        if (this.isOverImage) {
            return this.model.textStyle || "white_text";
        } else {
            return null;
        }
    }

    _loadStyles(styles) {
        switch (this.textStyle) {
            case "transparent_light_box":
            case "white_box":
            case "transparent_dark_box":
                styles.decoration = {
                    type: "frame",
                    shape: "rect",
                    fillColor: "white",
                    fillOpacity: 0.5
                };
                break;
            case "white_text_with_shadow":
                styles.PresentationTitlePresenterItem.text.title.filter = "textShadow";
                styles.PresentationTitlePresenterItem.text.body.filter = "textShadow";
                break;
        }
    }

    getHTMLFilter() {
        if (this.textStyle == "white_text_with_shadow") {
            return "drop-shadow(2px 4px 6px rgba(0,0,0,.25))";
        }
    }

    _calcProps(props, options) {
        let { size } = props;
        if (this.canvas.showBrandingWatermark() && this.horizontalAlign == HorizontalAlignType.RIGHT && !this.parentElement.showFooter) {
            size.width -= 150;
        }

        let layouter = this.getLayouter(props, this.itemElements, size);
        layouter.distributeHorizontally({ gap: this.styles.hGap }).alignHorizontally(this.horizontalAlign);
        props.isFit = layouter.isFit;

        return { size: new geom.Size(size.width, layouter.size.height) };
    }

    _applyColors() {
        switch (this.textStyle) {
            case "white_box":
                this.colorSet.decorationColor = this.palette.getColor("white");
                this.colorSet.backgroundColor = this.colorSet.decorationColor;
                break;
            case "transparent_light_box":
                this.colorSet.decorationColor = this.palette.getColor("white").setAlpha(0.5);
                this.colorSet.backgroundColor = this.palette.getColor("black");
                break;
            case "transparent_dark_box":
                this.colorSet.decorationColor = this.palette.getColor("black").setAlpha(0.3);
                this.colorSet.backgroundColor = this.palette.getColor("black");
                break;
            case "white_text":
            case "white_text_with_shadow":
                this.colorSet.backgroundColor = this.palette.getColor("black");
                break;
            case "dark_text":
                this.colorSet.backgroundColor = this.palette.getColor("white");
                break;
        }
    }

    get animationElementName() {
        return `Presenter${this.itemElements.length > 1 ? "s" : ""}`;
    }

    get animateChildren() {
        return false;
    }

    _getAnimations() {
        return [{
            name: "Fade in",
            prepare: () => this.animationState.fadeInProgress = 0,
            onBeforeAnimationFrame: progress => {
                this.animationState.fadeInProgress = progress;
            }
        }];
    }
}

class PresentationTitlePresenterItem extends CollectionItemElement {
    get name() {
        return "Presenter";
    }

    getElementSelection() {
        return PresentationTitlePresentersItemSelection;
    }

    get selectionPadding() {
        return 10;
    }

    _build() {
        this.text = this.addElement("text", () => PresentationTitlePresenterText, {
            blockStructure: BlockStructureType.TITLE_AND_BODY,
            allowedBlockTypes: [TextStyleType.TITLE, TextStyleType.BODY],
            model: this.model,
            title: {
                placeholder: "Add Presenter"
            },
            body: {
                placeholder: "Add Title"
            },
            autoWidth: true,
            autoHeight: true,
            allowAlignment: true,
            passThroughSelection: true, // enable Presenter collection control bar to be shown when selecting Presenter text
        });
    }

    _calcProps(props, options) {
        let { size } = props;

        let textProps = this.text.calcProps(size);

        return { size: textProps.size };
    }
}

class PresentationTitlePresenterText extends TextElement {

}

class PresentationTitleFooter extends BaseElement {
    _build() {
        this.info = this.addElement("info", () => TextElement, {
            singleLine: true,
            canStyle: true,
            autoHeight: true,
            textAlign: HorizontalAlignType.LEFT
        });

        this.disclaimer = this.addElement("disclaimer", () => TextElement, {
            singleLine: true,
            canStyle: true,
            autoHeight: true,
            textAlign: HorizontalAlignType.RIGHT
        });
    }

    _calcProps(props, options) {
        let { size } = props;

        let infoProps = this.info.calcProps(new geom.Size(size.width / 2, size.height));
        infoProps.bounds = new geom.Rect(0, 0, size.width / 2, infoProps.size.height);

        let disclaimerWidth = size.width / 2;
        if (this.canvas.showBrandingWatermark()) {
            disclaimerWidth -= 150;
        }
        let disclaimerProps = this.disclaimer.calcProps(new geom.Size(disclaimerWidth, size.height));
        disclaimerProps.bounds = new geom.Rect(size.width / 2, 0, disclaimerProps.size);

        return { size: new geom.Size(size.width, Math.max(infoProps.size.height, disclaimerProps.size.height)) };
    }

    get animationElementName() {
        return "Footer";
    }

    get animateChildren() {
        return false;
    }

    _getAnimations() {
        return [{
            name: "Fade in",
            prepare: () => this.animationState.fadeInProgress = 0,
            onBeforeAnimationFrame: progress => {
                this.animationState.fadeInProgress = progress;
            }
        }];
    }
}

export { PresentationTitle };

export const elements = {
    PresentationTitle
};
