import React from "react";

import { AuthoringBlockType, BlockStructureType, FormatType, HorizontalAlignType } from "common/constants";
import { formatter } from "js/core/utilities/formatter";
import * as geom from "js/core/utilities/geom";
import { getSVGStyleProps, getTransformProps, SVGGroup } from "js/core/utilities/svgHelpers";
import { _ } from "js/vendor";

import { detectCompareContent } from "js/core/services/sharedModelManager";
import {
    PictorialChartItemControlBar,
    PictorialChartPropertyPanel
} from "../../Editor/ElementPropertyPanels/PictorialChartUI";
import { ValuePictorialChartLabelSelection } from "../../Editor/ElementSelections/ValueLabelSelection";
import { BaseElement } from "../base/BaseElement";
import { CollectionElement, CollectionItemElement } from "../base/CollectionElement";
import { Icon } from "../base/MediaElements/IconElement";
import { TextElement } from "../base/Text/TextElement";
import { layoutHelper } from "../layouts/LayoutHelper";

class PictorialCharts extends CollectionElement {
    static get schema() {
        return {
            showDescription: true,
            fitIcons: false,
        };
    }

    getElementPropertyPanel() {
        return PictorialChartPropertyPanel;
    }

    getChildItemType() {
        return PictorialChartItem;
    }

    get defaultItemData() {
        let defaultShape = "male";
        if (this.model.items.length > 0) {
            defaultShape = _.last(this.model.items).shape;
        }
        return {
            value: 75,
            shape: defaultShape,
            format: _.last(this.model.items)?.format ?? FormatType.PERCENT,
            formatOptions: _.last(this.model.items)?.formatOptions ?? formatter.getDefaultFormatOptions()
        };
    }

    get maxItemCount() {
        return 6;
    }

    get maxValue() {
        // Will ensure max value is never less than 100
        return _.max([...this.itemCollection.map(model => model.value), 100]);
    }

    _calcProps(props, options) {
        const { size } = props;

        let vGap = this.styles.vGap;

        const multipleRows = this.itemCount > 1;

        const availableItemSize = new geom.Size(Math.min(size.width, this.styles.maxItemWidth), size.height);

        if (!this.itemElements.some(element => element.isAnimating)) {
            let unscaledWidth = 0;
            let unscaledHeight = 0;
            for (const item of this.itemElements) {
                const itemSize = item.calcProps(availableItemSize, { scale: 1, multipleRows }).size;
                unscaledWidth = Math.max(unscaledWidth, itemSize.width);
                unscaledHeight += itemSize.height + vGap;
            }
            unscaledHeight -= vGap;

            this.scale = Math.min(1, size.height / unscaledHeight, size.width / unscaledWidth);
        }

        vGap *= this.scale;

        let y = 0;
        for (const item of this.itemElements) {
            const itemProps = item.calcProps(availableItemSize, { scale: this.scale, multipleRows });
            itemProps.bounds = new geom.Rect(0, y, itemProps.size);
            y += itemProps.size.height + vGap;
        }

        const offsetY = size.height / 2 - layoutHelper.getTotalBoundsOfItems(this.itemElements).size.height / 2;
        const maxWidth = _.maxBy(this.itemElements, el => el.calculatedProps.bounds.width).calculatedProps.bounds.width;

        for (const item of this.itemElements) {
            item.calculatedProps.bounds.left = size.width / 2 + maxWidth / 2 - item.calculatedProps.bounds.width;
            item.calculatedProps.bounds.top += offsetY;
        }

        return { size };
    }

    _migrate_10_02() {
        this.model.showDescription = this.itemCollection.some(item => item.description);
    }

    _exportToSharedModel() {
        const textContent = this.itemElements.map(itemElement => ({
            mainText: {
                text: itemElement.description.blocks[0].textContent,
                textStyle: itemElement.description.blocks[0].textStyle
            },
            secondaryTexts: []
        }));

        const compareContent = this.itemElements.map((item, i) => ({
            value: item.model.value, text: textContent[i],
            format: this.model.format,
            emphasized: !!item.model.hilited
        }));

        return { compareContent, textContent, collectionColor: this.collectionColor };
    }

    _importFromSharedModel(model) {
        const compareContent = detectCompareContent(model);
        if (!compareContent?.length) return;

        const items = compareContent.map(({ text, value }) => ({
            value: value ?? Math.round(Math.random() * (80 - 40) + 40),
            description: {
                blocks: [{
                    html: text.mainText.text,
                    type: AuthoringBlockType.TEXT,
                    textStyle: text.textStyle
                }]
            },
            hilited: !!text.emphasized
        }));

        items.splice(this.maxItemCount);
        return {
            items,
            format: compareContent[0].format ?? FormatType.PERCENT,
            collectionColor: model.collectionColor
        };
    }
}

class PictorialChartItem extends CollectionItemElement {
    static get schema() {
        return {
            format: FormatType.PERCENT,
            value: 50,
            description: {
                blocks: [{
                    html: "",
                    textStyle: "body",
                    type: "text",
                }]
            },
            formatOptions: formatter.getDefaultFormatOptions()
        };
    }

    getElementControlBar() {
        return PictorialChartItemControlBar;
    }

    get format() {
        return this.model.format || FormatType.PERCENT;
    }

    get _formatOptions() {
        return this.model.formatOptions;
    }

    get currentValue() {
        let value = this.model.labelValue ?? this.model.value;

        if (this.format === FormatType.PERCENT) {
            value = this.model.value;
        }

        return this.isAnimating ? value * this.animationState.value : value;
    }

    formatValue(value) {
        let displayValue = value;
        if (this.format === FormatType.PERCENT) {
            displayValue = value / 100;
        } else {
            displayValue = value;
        }

        return formatter.formatValue(displayValue, this.format, this.formatOptions);
    }

    get showDescription() {
        return this.parentElement.model.showDescription;
    }

    get rolloverPadding() {
        return 0;
    }

    get labelText() {
        let text = this.formatValue(this.currentValue);

        if (this.isAnimating) {
            text = text.slice(0, this.labelTextLength);
            if (text.endsWith(".")) {
                text = text.slice(0, text.length - 1);
            }
        }

        return text;
    }

    _build() {
        this.icons = this.addElement("icons", () => PictorialChartItemIcons);

        this.label = this.addElement("label", () => PictorialChartItemLabel, {
            html: this.labelText,
            autoWidth: true,
            autoHeight: true,
            textAlign: HorizontalAlignType.RIGHT,
            canEdit: false
        });

        if (this.showDescription) {
            this.description = this.addElement("description", () => TextElement, {
                selection: "PictorialChartDescriptionSelection",
                autoHeight: true,
                blockStructure: BlockStructureType.SINGLE_BLOCK,
                disableEvenBreak: true,
                syncFontSizeWithSiblings: true
            });
        }
    }

    _calcProps(props, options) {
        const { size } = props;
        const { scale, multipleRows } = options;

        if (this.isAnimating) {
            this.label.options.html = this.labelText;
        }

        let labelProps = this.label.calcProps(size, { stylesScale: scale });
        if (this.isAnimating) {
            labelProps.bounds = new geom.Rect(this.labelSize.width - labelProps.size.width, 0, this.labelSize);
        } else {
            labelProps.bounds = new geom.Rect(0, 0, labelProps.size);
            this.labelSize = labelProps.bounds.size;
            this.labelTextLength = this.labelText.length;
        }

        const iconsSize = new geom.Size(size.width - this.labelSize.width, this.labelSize.height);
        const iconsProps = this.icons.calcProps(iconsSize, { maxValue: this.parentElement.maxValue, multipleRows: multipleRows });
        iconsProps.bounds = new geom.Rect(this.labelSize.width, 0, iconsProps.size);

        let totalWidth = this.labelSize.width + iconsProps.size.width;
        let totalHeight = iconsProps.size.height;

        if (this.showDescription) {
            let descriptionWidth = totalWidth;
            let descriptionLeft = 0;
            if (this.parentElement.model.fitIcons) {
                descriptionWidth = totalWidth - this.labelSize.width;
                descriptionLeft = this.labelSize.width;
            }

            const descriptionProps = this.description.calcProps(new geom.Size(descriptionWidth, size.height), { stylesScale: scale });
            descriptionProps.bounds = new geom.Rect(descriptionLeft, this.label.bounds.bottom, descriptionProps.size);
            descriptionProps.layer = 100;
            totalHeight += descriptionProps.size.height;
        }

        return { size: new geom.Size(totalWidth, totalHeight) };
    }

    get animationElementName() {
        return `Icons #${this.itemIndex + 1}`;
    }

    _getAnimations() {
        return [{
            name: "Fill in",
            prepare: () => {
                this.animationState.fadeInProgress = 0;
                this.animationState.value = this.icons.animationState.value = 0;
                if (this.showDescription) {
                    this.description.animationState.fadeInProgress = 0;
                }
            },
            onBeforeAnimationFrame: progress => {
                this.animationState.fadeInProgress = Math.min(1, progress * 3);
                this.animationState.value = this.icons.animationState.value = progress;
                if (this.showDescription) {
                    this.description.animationState.fadeInProgress = Math.clamp((progress - 0.7) / 0.3, 0, 1);
                }
                return this.parentElement;
            }
        }];
    }
}

class PictorialChartItemLabel extends TextElement {
    get hasCustomEditor() {
        return true;
    }

    get _passThroughSelection() {
        return false;
    }

    getElementSelection() {
        return ValuePictorialChartLabelSelection;
    }

    get _canSelect() {
        return true;
    }
}

class PictorialChartItemIcons extends BaseElement {
    // TODO: figure out why powerpoint is not rendering svg correctly
    get exportAsImage() {
        return true;
    }

    get iconId() {
        return this.model.iconId || "figure-man";
    }

    get currentValue() {
        if (this.parentElement.isAnimating) {
            return this.model.value * this.animationState.value;
        }

        return this.model.value;
    }

    _build() {
        this.icon = this.addElement("icon", () => Icon, {
            icon: this.iconId,
            preventExport: true,
            preventRender: true,
            canSelect: false
        });
    }

    _calcProps(props, options) {
        const { size } = props;

        const womanIconWidth = 45.265594482421875;
        const womanIconHeight = 87.8494644165039;

        let iconWidth = options.multipleRows ? womanIconWidth : this.icon.innerViewbox.width;
        let iconHeight = options.multipleRows ? womanIconHeight : this.icon.innerViewbox.height;

        const iconScale = 128 / iconHeight;
        const outerScale = size.height / 128;

        let scale = iconScale * outerScale;

        iconWidth *= scale;

        if (options.multipleRows && this.iconId != "figure-woman" && this.iconId != "figure-man") {
            scale *= 0.75;
        }
        let outerIconSize = this.icon.viewbox.multiply(scale);

        // calculate the icon props
        const iconProps = this.icon.calcProps(this.icon.viewbox.size, { iconScale: 1 }); // width doesn't matter because we are just getting aspect ratio to fit to the height

        let minGap = 10;

        let iconCount, iconGap;
        if (this.getRootElement().model.fitIcons) {
            iconCount = 10;
            iconGap = this.styles.hGap ?? 0;
        } else {
            iconCount = Math.floor(size.width / (iconWidth + minGap));
            iconGap = (size.width - (iconWidth * iconCount)) / iconCount;
        }

        let iconsBounds = [];
        let x = iconWidth / 2 - outerIconSize.width / 2;
        for (let i = 0; i < iconCount; i++) {
            iconsBounds.push({ transform: `translateX(${x}px) translateY(${size.height / 2 - outerIconSize.height / 2}px) scale(${scale}) ` });
            x += iconWidth + iconGap;
        }

        let totalWidth = iconCount * (iconWidth + iconGap) - iconGap;

        const maskBounds = new geom.Rect(-10, -10, totalWidth * (this.currentValue / Math.max(options.maxValue, 100)) + 10, size.height + 20);

        return { size: new geom.Size(totalWidth, size.height), maskBounds, iconsBounds, iconProps };
    }

    _applyColors() {
        let backgroundColor = this.getRootElement().getBackgroundColor();
        if (backgroundColor.isColor) {
            // force icons to white on color background to be compatible with previous versions
            this.icon.colorSet.iconColor = this.palette.getColor("white");
        }
        this.colorSet.backgroundColor = backgroundColor;
    }

    renderChildren(transition) {
        const props = this.calculatedProps;
        const { iconsBounds, maskBounds } = props;

        // special case because we are calling icon renderchildren instead of normal render pipelie
        return (
            <SVGGroup key={this.id}>
                <g className="backgroundIcons" {...getSVGStyleProps(this.styles.unhilited_icons)}>
                    {iconsBounds.map((transform, idx) => {
                        return <g key={idx} style={transform}>{this.icon.renderChildren(false)}</g>;
                    })}
                </g>
                <clipPath id={`${this.uniqueId}-clip-path`}>
                    <rect {...getTransformProps(maskBounds)} />
                </clipPath>
                <g className="foregroundIcons" clipPath={`url(#${this.uniqueId}-clip-path`} {...getSVGStyleProps(this.styles.hilited_icons)}>
                    {iconsBounds.map((transform, idx) => {
                        return <g key={idx} style={transform}>{this.icon.renderChildren(false)}</g>;
                    })}
                </g>
            </SVGGroup>
        );
    }
}

export const elements = {
    PictorialCharts,
    PictorialChartItem
};
