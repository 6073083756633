import React from "react";
import { v4 as uuid } from "uuid";

import { StaticImage } from "js/Components/StaticImage";
import { getStaticUrl } from "js/config";
import * as geom from "js/core/utilities/geom";
import { DivGroup } from "js/core/utilities/svgHelpers";
import { _ } from "js/vendor";
import { Path, Shape } from "js/core/utilities/shapes";

import { DeviceFrames } from "../DeviceFrames";

export function calcElementFrameProps(forElement, frameStyle, size) {
    let frameId = frameStyle.frameType || "iphone_x";

    if (frameId == "theme") {
        frameId = "iphone_x";
    }

    let frame = _.find(DeviceFrames, { id: frameId });

    let FrameComponent;
    let clipPath;
    let clipBounds;
    let frameType;

    const componentId = `${forElement.uniqueId}-frame`;

    switch (frame.category) {
        case "Shape": {
            frameType = "shape";

            ({ clipBounds, clipPath } = calcShapeMask(frame, size.scale(frameStyle.scale)), forElement.decorationStyle);

            clipBounds = clipBounds.centerInRect(new geom.Rect(0, 0, size));
            break;
        }
        case "Masks":
            frameType = "mask";
            clipPath = calcFrameMask(frame, size);
            break;
        default: {
            frameType = "image";
            let frameSize = new geom.Size(frame.width, frame.height);
            let scale = geom.fitImageToRect(frameSize, size) * frameStyle.scale;

            let scaledFrameSize = frameSize.scale(scale);
            let frameClip = new geom.Rect(frame.clip).multiply(scale);

            let frameBoundsInContainer = new geom.Rect(0, 0, scaledFrameSize).centerInRect(new geom.Rect(0, 0, size));
            clipBounds = frameClip.offset(frameBoundsInContainer.position);

            FrameComponent = (
                <DivGroup className="frame" bounds={frameBoundsInContainer} key={componentId}>
                    <StaticImage src={`/images/frames/${frame.file}`} />
                </DivGroup>
            );

            let cornerRadius = frame.cornerRadius * scale;
            clipPath = <rect x={0} y={0} width={clipBounds.width} height={clipBounds.height} rx={cornerRadius} ry={cornerRadius} fill="white" />;
        }
    }
    let transform;
    if (frameStyle.flipX || frameStyle.flipY) {
        transform = {
            transform: `scaleX(${frameStyle.flipX ? -1 : 1}) scaleY(${frameStyle.flipY ? -1 : 1})`,
            transformOrigin: `${size.width / 2}px ${size.height / 2}px`
        };
    }

    let ClipComponent;
    if (clipPath) {
        ClipComponent = (
            <svg key={componentId}>
                <defs>
                    <clipPath id={componentId} style={transform}>
                        {clipPath}
                    </clipPath>
                </defs>
            </svg>
        );
    }

    return {
        frameType,
        FrameComponent,
        ClipComponent,
        clipComponentId: componentId,
        clipBounds,
    };
}

function calcShapeMask(frame, size, strokeWidth = 0) {
    let clipBounds, clipPath;
    switch (frame.id) {
        case "rect":
            clipBounds = new geom.Rect(0, 0, size.width, size.height);
            clipPath = <rect x={0} y={0} width={clipBounds.width} height={clipBounds.height} fill="white" />;
            break;
        case "circle":
            clipBounds = new geom.Rect(0, 0, size.square());
            clipBounds = clipBounds.offset(size.width / 2 - clipBounds.width / 2, size.height / 2 - clipBounds.height / 2);
            clipPath =
                (<circle cx={clipBounds.width / 2} cy={clipBounds.height / 2} r={clipBounds.width / 2}
                    fill="white" />);
            break;
        case "rounded_rect":
            clipBounds = new geom.Rect(0, 0, size.width, size.height);
            clipPath =
                <rect x={0} y={0} width={clipBounds.width} height={clipBounds.height} rx={20} ry={20} fill="white" />;
            break;
        case "octagon":
            clipBounds = new geom.Rect(0, 0, size.square());
            clipBounds = clipBounds.offset(size.width / 2 - clipBounds.width / 2, size.height / 2 - clipBounds.height / 2);
            const octagon = Shape.drawOctagon(clipBounds.size);
            clipPath = <polygon points={octagon.toPolylineData()} fill="white" />;
            break;
    }
    return { clipBounds, clipPath };
}

function calcFrameMask(frame, size) {
    let w = size.width;
    let h = size.height;

    let imageMask;
    let paths = [];

    switch (frame.id) {
        case "triangle1":
            paths = [`M0 0 L${w} 0 L${w - 90} ${h} L0 ${h} Z`];
            break;
        case "triangle2":
            paths = [`M${.75 * w} 0H0V${h}H${.75 * w - 125}L${1 * w} ${.25 * h}Z`];
            break;

        case "parallelogram": {
            let size = w * 0.5;
            let offset = w * 0.5;
            let path = new Path();
            path.moveTo(w - size - offset, h);
            path.lineTo(w - size, 0);
            path.lineTo(w, 0);
            path.lineTo(w - offset, h);
            path.close();
            paths.push(path.toPathData());
            break;
        }

        case "stacked-capsules": {
            let capsuleCount = 4;
            let offset = 30;
            let gap = 10;

            let r = (h - (gap * (capsuleCount + 1))) / capsuleCount / 2;

            let y = gap;

            for (let i = 0; i < capsuleCount; i++) {
                let right = w - offset * i;

                let path = new Path();
                path.moveTo(0, y);
                path.lineTo(right - r, y);
                path.arc(r, r, 0, 0, 1, right - r, y + r * 2);
                path.lineTo(0, y + r * 2);
                path.close();
                paths.push(path.toPathData());

                y += gap + r * 2;
            }

            break;
        }

        case "arrows": {
            let capsuleCount = 5;
            let offset = 50;
            let gap = 5;

            let r = ((h - (gap + 1) * capsuleCount) / capsuleCount) / 2;

            let y = gap;

            for (let i = 0; i < capsuleCount; i++) {
                let right = w - offset * i;

                let path = new Path();
                path.moveTo(0, y);
                path.lineTo(right - r, y);
                path.lineTo(right, y + r);
                path.lineTo(right - r, y + r * 2);
                path.lineTo(0, y + r * 2);
                path.close();
                paths.push(path.toPathData());

                y += gap + r * 2;
            }
            break;
        }

        case "boxes": {
            let rows = 6;
            let gap = 10;

            let size = (h - (gap + 1) * rows) / rows;

            let cols = Math.floor(w / size);

            let x = gap;
            let y = gap;

            for (let row = 0; row < rows; row++) {
                for (let col = 0; col < cols; col++) {
                    let path = new Path();
                    path.moveTo(x, y);
                    path.lineTo(x + size, y);
                    path.lineTo(x + size, y + size);
                    path.lineTo(x, y + size);
                    path.close();
                    paths.push(path.toPathData());
                    x += size + gap;
                }
                x = gap;
                y += size + gap;
            }

            break;
        }

        case "paint": {
            imageMask = `url("${getStaticUrl("/themes/backgrounds/paintmask3.png")}")`;
            break;
        }
    }

    if (imageMask) {
        return {
            type: "mask",
            maskImage: imageMask
        };
    } else {
        return paths.map(path => <path fillRule="evenodd" clipRule="evenodd" d={path} fill="white" />);
    }
}
