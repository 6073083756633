import { AuthoringBlockType, PositionType, TextStyleType } from "common/constants";
import { getValueOrDefault } from "js/core/utilities/extensions";
import * as geom from "js/core/utilities/geom";
import { _ } from "js/vendor";

import { TextFrame } from "../base/Text/TextFrame";
import { PhotoCollage, PhotoCollageItem } from "./PhotoCollage";
import { HeadlinePropertyPanel, HeadlineSelection } from "../../Editor/ElementPropertyPanels/HeadlineUI";
import { BaseElement } from "../base/BaseElement";
import { LayoutEngineType } from "./LayoutContainers/GridLayoutContainer";

const HeadlinePositionType = {
    TOP: "top",
    BOTTOM: "bottom",
    LEFT: "left",
    RIGHT: "right",
    CENTER: "center"
};

class Headline extends BaseElement {
    getElementPropertyPanel() {
        return HeadlinePropertyPanel;
    }

    getElementSelection() {
        return HeadlineSelection;
    }

    get _canSelect() {
        return true;
    }

    get _canRollover() {
        return this.showImage && this.headlinePosition != HeadlinePositionType.CENTER;
    }

    get minWidth() {
        return 400;
    }

    get minHeight() {
        return 200;
    }

    get dividerMin() {
        return this.headlinePosition === HeadlinePositionType.TOP || this.headlinePosition === HeadlinePositionType.BOTTOM ? 0.1 : 0.33;
    }

    get dividerMax() {
        return 0.75;
    }

    get dividerSize() {
        return Math.clamp(this.model.dividerSize || 0.5, this.dividerMin, this.dividerMax);
    }

    get showImage() {
        if (this.model.items) {
            return this.model.items.length > 0 && Object.keys(this.model.items[0]).length > 0;
        } else {
            return false;
        }
    }

    get fullBleed() {
        if (this.model.aspectRatio == "fit") {
            return false;
        } else {
            return this.model.fullBleed;
        }
    }

    get reserveFooterSpace() {
        if (this.fullBleed && (!this.showImage || this.images.showGutter)) {
            return true;
        } else {
            return false;
        }
    }

    get headlinePosition() {
        if (this.showImage) {
            return this.model.headlinePosition || HeadlinePositionType.TOP;
        } else {
            return HeadlinePositionType.CENTER;
        }
    }

    get textPosition() {
        return getValueOrDefault(this.model.textPosition, PositionType.CENTER);
    }

    _build() {
        if (!this.model.text?.blocks || this.model.text.blocks.length == 0) {
            this.model.text = {
                blocks: [{
                    textStyle: "headline",
                    type: AuthoringBlockType.TEXT,
                }]
            };
        }

        if (this.showImage) {
            // it is important that the HeadlineImages element be built after the HeadlineTextFrame so that it is after HeadlineTextFrame in the headlines's elements collection
            // this will allow selection to work when clicking on text over a HeadlineImage because the selectionLayer findElementsAtPoint code iterates through the elements collection
            // the layer property is used to position the HeadlineImages before the HeadlineTextFrame in the DOM
            this.images = this.addElement("images", () => HeadlineImages);
            this.images.layer = -1;
        }

        this.textFrame = this.addElement("textFrame", () => HeadlineTextFrame, {
            backgroundElement: (this.headlinePosition == HeadlinePositionType.CENTER && this.showImage) ? this.images.itemElements[0].childElement.content : null
        });
    }

    _calcProps(props, options) {
        let { size } = props;

        let textFrameBounds = new geom.Rect(0, 0, size);

        if (this.headlinePosition == HeadlinePositionType.CENTER) {
            let textFrameProps = this.textFrame.calcProps(size);
            textFrameProps.bounds = new geom.Rect(0, 0, textFrameProps.size);
        } else {
            switch (this.headlinePosition) {
                case HeadlinePositionType.LEFT:
                    textFrameBounds = new geom.Rect(0, 0, size.width * this.dividerSize - this.styles.horizontalGap / 2, size.height);
                    break;
                case HeadlinePositionType.RIGHT:
                    textFrameBounds = new geom.Rect(size.width - size.width * this.dividerSize + this.styles.horizontalGap / 2, 0, size.width * this.dividerSize - this.styles.horizontalGap / 2, size.height);
                    break;
                case HeadlinePositionType.TOP:
                    this.textFrame.styles.paddingBottom = 20;
                    textFrameBounds = new geom.Rect(0, 0, size.width, size.height * this.dividerSize - this.styles.blockSpacing / 2);
                    break;
                case HeadlinePositionType.BOTTOM:
                    this.textFrame.styles.paddingTop = 20;
                    textFrameBounds = new geom.Rect(0, size.height - size.height * this.dividerSize + this.styles.blockSpacing / 2, size.width, size.height * this.dividerSize - this.styles.blockSpacing / 2);
                    break;
            }

            this.textFrame.styles.paddingLeft = this.textFrame.styles.paddingRight = this.textFrame.styles.paddingTop = this.textFrame.styles.paddingBottom = 0;

            let textFrameProps = this.textFrame.calcProps(textFrameBounds.size);
            textFrameProps.bounds = new geom.Rect(0, 0, textFrameProps.size).centerInRect(textFrameBounds);
        }

        let imageGridBounds = new geom.Rect(0, 0, size);
        if (this.showImage) {
            let canvasMargins = this.getCanvasMargins();
            let gutterSize = this.images.showGutter ? this.images.gutterSize : 0;
            switch (this.headlinePosition) {
                case HeadlinePositionType.CENTER:
                    if (this.fullBleed) {
                        if (this.canvas.model.layout.showElementAttribution || this.canvas.model.layout.elementTextBlockPosition == "inline") {
                            imageGridBounds = imageGridBounds.inflate({
                                top: canvasMargins.top,
                                left: canvasMargins.left,
                                right: canvasMargins.right,
                                bottom: 0
                            }).deflate(gutterSize);
                        } else {
                            imageGridBounds = imageGridBounds.inflate(canvasMargins).deflate(gutterSize);
                        }
                    }
                    break;
                case HeadlinePositionType.LEFT:
                    imageGridBounds = imageGridBounds.deflate({ left: textFrameBounds.width + this.styles.horizontalGap / 2 });
                    if (this.fullBleed) {
                        imageGridBounds = imageGridBounds.inflate({
                            top: canvasMargins.top - gutterSize,
                            right: canvasMargins.right - gutterSize,
                            bottom: (this.canvas.model.layout.showElementAttribution || this.canvas.model.layout.elementTextBlockPosition == "inline")
                                ? 0 : canvasMargins.bottom - gutterSize
                        });
                    }
                    break;
                case HeadlinePositionType.RIGHT:
                    imageGridBounds = imageGridBounds.deflate({ right: textFrameBounds.width + this.styles.horizontalGap / 2 });
                    if (this.fullBleed) {
                        imageGridBounds = imageGridBounds.inflate({
                            top: canvasMargins.top - gutterSize,
                            left: canvasMargins.left - gutterSize,
                            bottom: (this.canvas.model.layout.showElementAttribution || this.canvas.model.layout.elementTextBlockPosition == "inline")
                                ? 0 : canvasMargins.bottom - gutterSize
                        });
                    }
                    break;
                case HeadlinePositionType.TOP:
                    imageGridBounds = imageGridBounds.deflate({ top: textFrameBounds.height + this.styles.blockSpacing / 2 });
                    if (this.fullBleed) {
                        imageGridBounds = imageGridBounds.inflate({
                            left: canvasMargins.left - gutterSize,
                            right: canvasMargins.right - gutterSize,
                            bottom: canvasMargins.bottom - gutterSize
                        });
                    }
                    break;
                case HeadlinePositionType.BOTTOM:
                    imageGridBounds = imageGridBounds.deflate({ bottom: textFrameBounds.height + this.styles.blockSpacing / 2 });
                    if (this.fullBleed) {
                        imageGridBounds = imageGridBounds.inflate({
                            top: canvasMargins.top - gutterSize,
                            right: canvasMargins.right - gutterSize,
                            left: canvasMargins.left - gutterSize
                        });
                    }
                    break;
            }

            let imagesProps = this.images.calcProps(imageGridBounds.size);
            imagesProps.bounds = imageGridBounds;
            imagesProps.layer = -1;
        }

        return { size };
    }

    getAnimations() {
        return Object.values(this.elements)
            // Sorting in order to put image animations in the end
            .sort((a, b) => a.type.localeCompare(b.type))
            .reduce((animations, element) => ([...animations, ...element.getAnimations()]), []);
    }

    _exportToSharedModel() {
        const assets = this.images?._exportToSharedModel().assets || [];
        const textContent = this.textFrame.text._exportToSharedModel(true).textContent;

        return { assets, textContent };
    }

    _importFromSharedModel(model) {
        const { assets, textContent } = model;
        if (!assets?.length && !textContent?.length) return;

        const items = [];
        if (assets?.length) {
            items.push({
                assetName: assets[0].name,
                assetProps: assets[0].props,
                content_type: assets[0].type,
                content_value: assets[0].value,
            });
        }

        let blocks = [];
        if (textContent?.length) {
            blocks = textContent.map(text => ([
                {
                    html: text.mainText.text,
                    textStyle: TextStyleType.HEADLINE,
                    type: AuthoringBlockType.TEXT,
                },
                ...text.secondaryTexts.map(secondaryText => ({
                    html: secondaryText.text,
                    textStyle: secondaryText.textStyle || TextStyleType.BODY,
                    type: AuthoringBlockType.TEXT,
                }))
            ])).flat();
        }

        return { items, headlinePosition: "center", text: { blocks } };
    }

    _migrate_10() {
        if (this.model.userFontScale) {
            Object.entries(this.model.userFontScale).forEach(([key, value]) => {
                if (key.includes("ContentBlockFrame/ContentBlockCollection/ContentBlockItem")) {
                    this.model.userFontScale[key.replace("ContentBlockFrame/ContentBlockCollection/ContentBlockItem", "HTMLContentBlockFrame")] = value;
                }
            });
        }
    }

    _migrate_10_02() {
        delete this.model.blocks;

        if (this.model.text?.blocks) {
            for (let block of this.model.text.blocks) {
                if (block.type === "media" && block.color == "auto" && block.elementModel) {
                    block.elementModel.color = this.canvas.getSlideColor();
                } else if (block.type == "text") {
                    block.accentColor = this.canvas.getSlideColor();
                }
            }
        }
    }
}

class HeadlineTextFrame extends TextFrame {
    get minItemCount() {
        return 0;
    }

    get canDelete() {
        return false;
    }

    get canDragPosition() {
        return this.parentElement.showImage && this.parentElement.headlinePosition == HeadlinePositionType.CENTER;
    }

    get canDragResize() {
        return this.parentElement.showImage && this.parentElement.headlinePosition == HeadlinePositionType.CENTER;
    }

    get isOverImage() {
        return (this.parentElement.headlinePosition == HeadlinePositionType.CENTER && this.parentElement.showImage);
    }

    get showAddBlockUI() {
        return true;
    }

    _migrate_10_02() {
        if (this.model.text?.blocks) {
            for (let block of this.model.text?.blocks) {
                block.accentColor = this.canvas.getSlideColor();
            }
        }

        super._migrate_10_02();
    }
}

class HeadlineImages extends PhotoCollage {
    get canSelect() {
        return true;
    }

    get canRollover() {
        return false;
    }

    getElementControlBar() {
        return null;
    }

    get passThroughSelection() {
        return true;
    }

    getChildItemType() {
        return HeadlinePhotoItem;
    }

    getChildOptions(model, index) {
        return {
            allowText: false,
            elementSelection: this.layoutEngine.getCellSelection(),
            elementPropertyPanel: this.options.itemPropertyPanel,
            placeholderPropertyPanel: this.options.placeholderPropertyPanel,
            placeholderDefaultOverlay: this.options.placeholderDefaultOverlay,
            canPasteImage: this.options.canPasteImage,
            canEdit: this.options.canEdit,
            hideControlBarWhenTextSelected: this.hideControlBarWhenTextSelected
        };
    }

    get minItemCount() {
        return 0;
    }

    get gutterSize() {
        return 10;
    }

    _migrate_10_02() {
        // Force-retain text
        const text = _.cloneDeep(this.model.text);
        delete this.model.text;

        super._migrate_10_02();

        this.model.text = text;
    }
}

class HeadlinePhotoItem extends PhotoCollageItem {
    get passThroughSelection() {
        return true;
    }
}

export { Headline, HeadlinePositionType };

export const elements = {
    Headline
};
