import { ForeColorType, HiliteType, PaletteColorType, ShadeColorsType } from "common/constants";
import { _ } from "js/vendor";

import { v4 as uuid } from "uuid";

import { CollectionElementControlBar } from "../../Editor/ElementControlBars/CollectionElementControlBar";
import { CollectionItemElementSelection } from "../../Editor/ElementSelections/CollectionItemElementSelection";
import { BaseElement } from "./BaseElement";

class CollectionElement extends BaseElement {
    static get schema() {
        return {
            collectionColor: "theme"
        };
    }

    get collectionPropertyName() {
        return "items";
    }

    get itemCollection() {
        if (!this.model[this.collectionPropertyName]) {
            this.model[this.collectionPropertyName] = [];
        }

        return this.model[this.collectionPropertyName];
    }

    set itemCollection(value) {
        this.model[this.collectionPropertyName] = value;
    }

    get itemElements() {
        return _.map(this.model[this.collectionPropertyName], itemModel => {
            return this.elements[itemModel.id];
        }).filter(Boolean);
    }

    getElementControlBar() {
        return this.options.elementControlBar ?? CollectionElementControlBar;
    }

    getItemElementById(id) {
        return _.find(this.itemElements, { id: id });
    }

    get _canSelect() {
        return true;
    }

    get clipboardElement() {
        return this;
    }

    get canPasteNewElement() {
        return true;
    }

    get collectionColor() {
        return this.model.collectionColor ?? "theme";
    }

    get shadeColorsDefault() {
        return false;
    }

    get shadeColors() {
        if (_.some(this.itemCollection, item => item.hilited)) {
            // don't shade if any of the items are hilighted
            return ShadeColorsType.NONE;
        } else {
            return (this.model.shadeColors ?? this.shadeColorsDefault) ? ShadeColorsType.LIGHT : ShadeColorsType.NONE;
        }
    }

    getItemColor(index) {
        if (this.collectionColor == ForeColorType.COLORFUL) {
            // get a colorful color from the palette based on the item index
            return this.canvas.getTheme().palette.getColorfulColor(index);
        } else if (this.shading) {
            // get a shaded color based on the slideColor, itemIndex and itemCount
            return this.canvas.getTheme().palette.getShadedColor(this.canvas.getTheme().palette.getColor(this.collectionColor), index, this.itemCount, this.shading == "dark");
        } else {
            // just return the slideColor
            return this.canvas.getTheme().palette.getColor(this.collectionColor);
        }
    }

    getChildValues(propertyName, selectedElements) {
        let elements = selectedElements ?? this.itemElements;
        let values = _.uniq(elements.filter(Boolean).map(item => item.model[propertyName] ?? null));
        if (values.length == 1) {
            return values[0];
        } else if (values.length == 0) {
            return null;
        } else {
            return "mixed";
        }
    }

    // This should be used for transient updates that should not save in slide history (e.g. mouseMove updates)
    async refreshChildModels(props, options = {}) {
        for (let item of (options.selectedElements ?? this.itemElements)) {
            Object.assign(item.model, props);
        }
        await this.canvas.refreshCanvas();
    }

    // This should be used when we want to save a new version in slide history
    async updateChildModels(props, options = {}) {
        const { transition = false, refreshStyles = false } = options;
        for (let item of (options.selectedElements ?? this.itemElements)) {
            Object.assign(item.model, props);
        }
        await this.saveModel(transition, refreshStyles);
    }

    async invokeChildMethods(methodName, options = {}) {
        const { transition = false, refreshStyles = false } = options;
        for (let item of (options.selectedElements ?? this.itemElements)) {
            let fn = item?.childElement[methodName];
            if (_.isFunction(fn)) {
                fn.call(item);
            }
        }
        await this.saveModel(transition, refreshStyles);
    }

    handlePasteModel(model) {
        if (this.canPasteNewElement && this.addItem) {
            if (this.itemCount >= this.maxItemCount) {
                throw "This slide already contains the maximum number of allowed items";
            }

            try {
                let newItemModel = this.addItem(model);
                this.canvas.updateCanvasModel(false);
            } catch (err) {
                throw `An error occurred while attempting to add the data on the clipboard to the collection: ${err}`;
            }
        }
    }

    _build() {
        this.model.collectionColor ||= "theme"; // ensure collectionColor is set (in case someone overrides schema)
        this.buildItems();
    }

    buildItems() {
        if (this.itemCollection.length == 0) {
            // let itemCount = this.minItemCount == 0 ? 1 : this.minItemCount;  mjg: working on connector group and i'm not sure why this is here since it prevents minItemCount from being zero
            let itemCount = this.minItemCount;
            for (let i = 0; i < itemCount; i++) {
                this.itemCollection.push(this.defaultItemData);
            }
        }

        // check for any null items
        for (let i = 0; i < this.itemCollection.length; i++) {
            if (this.itemCollection[i] == null) {
                this.itemCollection[i] = {};
            }
        }

        for (let itemModel of this.itemCollection) {
            if (!itemModel.id) {
                // This might create inconsistent states in collaboration. So far this is harmless,
                // but if we expect the id to never change once it has been set, it can break in collaboration.
                itemModel.id = uuid();
            }
            this.addElement(itemModel.id, () => this.getChildItemType(itemModel), Object.assign({ model: itemModel }, this.getChildOptions(itemModel, this.itemCollection.indexOf(itemModel))));
        }
    }

    _applyColors() {
        let backgroundColor = this.getBackgroundColor();

        for (let item of this.itemElements) {
            // use the collectionColor to calculate this item's decoration color based on the item index (if it's colorful)
            let decorationColor = this.palette.getColor(item.decorationColor ?? this.collectionColor, backgroundColor, { itemIndex: item.itemIndex, forceWhiteForPrimaryOnColor: true });

            // if shading colors, modify the decoration color
            if (this.shadeColors != ShadeColorsType.NONE && this.collectionColor != "colorful" && item.model.color == null) {
                if (backgroundColor.isColor) {
                    decorationColor.setAlpha(.3 + (.7 * item.itemIndex / this.itemCount));
                } else {
                    if (this.shadeColors == ShadeColorsType.LIGHT) {
                        decorationColor = decorationColor.clone().brighten(30 * (item.itemIndex / this.itemCount));
                    } else {
                        decorationColor = decorationColor.clone().darken(30 * (item.itemIndex / this.itemCount));
                    }
                }
            }

            item.colorSet = {
                decorationColor
            };
        }
    }

    getChildOptions(model, index) {
        return {};
    }

    getChildItemType(itemModel) {
        // override to return the class type of an item for this container element
    }

    get minItemCount() {
        return 1;
    }

    get maxItemCount() {
        return 10;
    }

    get itemCount() {
        return this.itemCollection.length;
    }

    get supportsAddHotKey() {
        return true;
    }

    addItem(props, index) {
        props = _.extend({ id: _.uniqueId() + new Date().getTime() }, this.defaultItemData, props || {});
        if (index != undefined) {
            this.itemCollection.splice(index, 0, props);
        } else {
            this.itemCollection.push(props);
        }
        return props;
    }

    deleteItem(itemId) {
        let item = _.find(this.itemCollection, { id: itemId });
        let itemElement = _.find(this.itemElements, { id: itemId });
        if (itemElement) {
            itemElement.toBeDeleted = true;
        }
        if (item) {
            this.itemCollection.remove(item);
        }
    }

    getItemIndex(itemId) {
        return _.findIndex(this.itemCollection, { id: itemId });
    }

    get defaultItemData() {
        return {};
    }

    get allowDragDropElements() {
        return true;
    }

    getAnimations() {
        const animations = this._getAnimations();
        animations.forEach(animation => animation.element = this);

        if (this.animateChildren) {
            Object.values(this.elements)
                // Will preserve the correct order of the children
                .sort((elementA, elementB) => elementA.itemIndex - elementB.itemIndex)
                .forEach(element => {
                    animations.push(...element.getAnimations());
                });
        }

        if (this.disableAnimationsByDefault) {
            animations.forEach(animation => animation.disabledByDefault = true);
        }

        return animations;
    }

    _migrate_10_02() {
        // migrate slide color to collection color
        this.model.collectionColor = this.canvas.getSlideColor();
        if (this.model.collectionColor == "primary") {
            //  ive disabled this migration because some places require primary color to be set (like textboxgrid with bold theme)
            // this.model.collectionColor = PaletteColorType.BACKGROUND_LIGHT;
        }
    }
}

class CollectionItemElement extends BaseElement {
    get name() {
        return this.options.name ?? "Item";
    }

    get isCollectionItem() {
        return true;
    }

    get collectionItemIndex() {
        return this.itemIndex >= 0 ? this.itemIndex : null;
    }

    get itemIndex() {
        return this.options.itemIndex ?? this.parentElement.itemCollection?.indexOf(this.model) ?? 0;
    }

    get itemCount() {
        return this.parentElement.itemCount;
    }

    get isLastItem() {
        return this.itemIndex === this.itemCount - 1;
    }

    get isFirstItem() {
        return this.itemIndex === 0;
    }

    get extendLastItemDropBounds() {
        return this.options.extendLastItemDropBounds ?? true;
    }

    get _canSelect() {
        return true;
    }

    get _canRollover() {
        return this.canDrag;
    }

    getElementSelection() {
        return this.options.elementSelection ?? CollectionItemElementSelection;
    }

    get selectionPadding() {
        return 20;
    }

    get clipboardElement() {
        return this;
    }

    get canDelete() {
        return true;
    }

    get decorationColor() {
        return this.model.color == "auto" ? null : this.model.color;
    }

    get shadeColors() {
        return this.parentElement.shadeColors;
    }

    _loadStyles(styles) {
        super._loadStyles(styles);
        this.loadEmphasizedStyles(styles, this.getHiliteType());
    }

    // recursively navigate through a style object's properties and merge the appropriate emphasized and deemphasized styles into it
    loadEmphasizedStyles(styles, hiliteType) {
        // if element is emphasized or de-emphasized, merge the root styles with the appropriate state styles
        switch (hiliteType) {
            case HiliteType.DEEMPHASIZED:
                if (styles.deemphasized) {
                    _.merge(styles, styles.deemphasized);
                }
                break;
            case HiliteType.EMPHASIZED:
                if (styles.emphasized) {
                    _.merge(styles, styles.emphasized);
                }
                break;
            case HiliteType.NONE:
                break;
        }

        for (let key in styles) {
            if (typeof (styles[key]) == "object" && styles[key] != null) {
                this.loadEmphasizedStyles(styles[key], hiliteType);
            }
        }
    }

    getHiliteType() {
        // check if any sibling elements are spotlighted
        const hasHilitedSibling = this.parentElement?.itemCollection?.filter(m => m.hilited).length > 0;

        if (this.model.hilited) {
            return HiliteType.EMPHASIZED;
        } else if (hasHilitedSibling) {
            return HiliteType.DEEMPHASIZED;
        } else {
            return HiliteType.NONE;
        }
    }

    get animationElementName() {
        return `${this.name} #${this.itemIndex + 1}`;
    }

    get animateChildren() {
        return false;
    }

    _getAnimations() {
        return [{
            name: "Fade in",
            prepare: () => this.animationState.fadeInProgress = 0,
            onBeforeAnimationFrame: progress => {
                this.animationState.fadeInProgress = progress;
            }
        }];
    }

    _migrate_10_02() {
        if (this.model.color == "auto") {
            this.model.color = null;
        }
    }
}

export { CollectionElement, CollectionItemElement };
