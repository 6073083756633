import React, { Component } from "react";
import styled from "styled-components";

import { AssetType, AuthoringElementType, ControlBarPosition, PositionType } from "common/constants";
import { _ } from "js/vendor";
import { stopPropagation } from "js/core/utilities/stopPropagation";
import { Slider } from "js/Components/Slider";
import { AnchorType, Convert } from "js/core/utilities/geom";
import * as geom from "js/core/utilities/geom";
import { themeColors } from "js/react/sharedStyles";

import { GetValueFromSelection } from "./AuthoringHelpers";
import { ControlBar } from "../../../ElementControlBars/Components/ControlBar";
import { MediaPopup } from "../../../EditorComponents/MediaPopup";
import { ImageFramePopupMenu } from "../../../EditorComponents/ImageFrameMenu";
import { getAnchorPointsForUI } from "../../../ElementPropertyPanels/ConnectorItemUI";

const WidgetContainer = styled.div`
    position: absolute;
    bottom: -20px;
    right: -20px;
    transform: translate(-50%, -50%);
    z-index: 100;
    pointer-events: all;

    position: absolute;
    pointer-events: auto;
    background: white;
    border: solid 1px #50bbe6;
    width: 18px;
    height: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    cursor: pointer;

    ::after {
        content: "Drag to connect";
        color: white;
        background: #23aae0;
        padding: 4px 2px;
        border-radius: 10px;
        top: -25px;
        width: 110px;
        font-size: 10px;
        text-transform: uppercase;
        position: absolute;
        text-align: center;
        font-weight: 600;
        opacity: 0;
        transition: opacity 400ms;
        pointer-events: none;
    }

    :hover {
        background: #50bbe6;

        i {
            color: white;
        }
    }

    :hover:after {
        opacity: 1;
    }
`;

const ConnectionWidget = styled.div`
    position: absolute;
    pointer-events: auto;
    width: 10px;
    height: 10px;
    background: white;
    border: solid 1px ${themeColors.ui_blue};
    border-radius: 50%;
    transform: translate(-50%, -50%);

    &:hover {
        background: ${themeColors.ui_blue};
    }
`;

const AnchorPointWidget = styled.div.attrs(({ active, square = true }) => ({
    style: {
        background: active ? themeColors.ui_blue : "white",
        borderRadius: square ? 0 : "50%"
    }
}))`
    position: absolute;
    pointer-events: auto;
    width: 12px;
    height: 12px;
    background: white;
    border: solid 1px ${themeColors.ui_blue};
    transform: translate(-50%, -50%);

    //&:hover {
    //    background: orangered;
    //}
`;

const AnchorLabel = styled.div`
    background: ${themeColors.ui_blue};
    color: white;
    position: absolute;
    font-size: 10px;
    text-transform: uppercase;
    padding: 4px 6px;
    border-radius: 2px;
    transform: translate(0, -50%);
    r
`;

const Icon = styled.i`
    color: #50bbe6;
    font-size: 12px;
`;

export class AuthoringCalloutEditor extends Component {
    constructor() {
        super();
        this.state = {
            anchorPoints: []
        };
    }

    onDragConnector = async event => {
        const { selection, containerElement, refreshCanvas, selectionLayerController } = this.props;

        event.persist();

        // Create new connector model and add it
        const connectorModel = containerElement.getDefaultConnectorModel(selection[0].id);
        connectorModel.targetPoint = Convert.ScreenToElementCoordinates(containerElement.canvas, containerElement, event.pageX, event.pageY);
        containerElement.connectors.model.items.push(connectorModel);

        // Refresh so the connector gets rendered
        await refreshCanvas();

        // Select the connector
        const addedConnectorItem = _.last(containerElement.connectors.itemElements);
        await selectionLayerController.setSelectedElements([addedConnectorItem]);

        // Start dragging on the connector selection
        addedConnectorItem.uiRefs.selectionRef.current.handleConnectorEndDragStart(true, event);
    };

    onDragNewNode = async (event, anchor) => {
        const { selection, selectionElement, containerElement, refreshCanvas, selectionLayerController } = this.props;

        // create a new node
        const newNodeModel = containerElement.addItem({
            ...containerElement.getDefaultModel(AuthoringElementType.CALLOUT, { calloutType: selectionElement.model.calloutType }),
            color: selection[0].model.color,
            decorationStyle: selection[0].model.decorationStyle
        });

        switch (anchor) {
            case AnchorType.TOP:
                newNodeModel.x = selection[0].model.x;
                newNodeModel.y = selection[0].model.y - selection[0].model.height / 2 - 100;
                break;
            case AnchorType.BOTTOM:
                newNodeModel.x = selection[0].model.x;
                newNodeModel.y = selection[0].model.y + selection[0].model.height / 2 + 100;
                break;
            case AnchorType.LEFT:
                newNodeModel.x = selection[0].model.x - selection[0].model.width / 2 - 150;
                newNodeModel.y = selection[0].model.y;
                break;
            case AnchorType.RIGHT:
                newNodeModel.x = selection[0].model.x + selection[0].model.width / 2 + 150;
                newNodeModel.y = selection[0].model.y;
                break;
        }

        // create a new connector between the source node and the new node
        const connectorModel = containerElement.getDefaultConnectorModel(selection[0].id);
        connectorModel.target = newNodeModel.id;
        containerElement.connectors.model.items.push(connectorModel);

        // Refresh so the new node and connector get rendered
        await refreshCanvas();

        // select the new node
        const newNode = containerElement.getChild(newNodeModel.id);
        newNode.isDragCreating = true;

        // Start dragging on the new node
        this.sourceNode = selection[0];
        this.connector = _.last(containerElement.connectors.itemElements);
        this.dragCreateNode = newNode;

        this.setState({ isDraggingConnector: true });

        window.addEventListener("mousemove", this.onInitialMouseMove);
        window.addEventListener("mouseup", this.onMouseUpCreateConnector);
    };

    // This switch indicates that the initial drag threshold has been met,
    // and the application should now handle the drag operation differently,
    // by rerendering the canvas and showing the dragCreateNode
    onInitialMouseMove = event => {
        const { dragStartPoint } = this.state;
        const currentPoint = { x: event.pageX, y: event.pageY };

        if (!dragStartPoint) {
            this.setState({ dragStartPoint: { x: event.pageX, y: event.pageY } });
            return;
        }

        const dragDistance = Math.sqrt(Math.pow(currentPoint.x - dragStartPoint.x, 2) + Math.pow(currentPoint.y - dragStartPoint.y, 2));

        if (dragDistance >= 20) {
            window.removeEventListener("mousemove", this.onInitialMouseMove);
            window.addEventListener("mousemove", this.onMouseMoveCreateConnector);
        }
    };

    onMouseMoveCreateConnector = event => {
        const { containerElement, refreshCanvas } = this.props;
        const canvas = containerElement.canvas;

        const currentPoint = Convert.ScreenToElementCoordinates(containerElement.canvas, containerElement, event.pageX, event.pageY);

        const anchorPoints = [];
        const overElement = containerElement.itemElements.find(el => el.id !== this.dragCreateNode.id && el !== this.sourceNode && el.bounds.inflate(20).contains(currentPoint));
        if (overElement) {
            // Canvas bounds relative to the sourceNode (which is still the active selected element) selection bounds to let the editor know where the canvas is
            const canvasBounds = new geom.Rect(0, 0, canvas.CANVAS_WIDTH, canvas.CANVAS_HEIGHT)
                .setPosition(-this.sourceNode.selectionBounds.left, -this.sourceNode.selectionBounds.top)
                .spaceScale(canvas.getScale());

            // get the anchor points for the overElement
            anchorPoints.push(...getAnchorPointsForUI(overElement, canvasBounds));

            // delete the dragCreateNode
            containerElement.itemCollection.remove(this.dragCreateNode.model);

            // and point the connector to the overElement's closest anchor
            let activeAnchor = _.minBy(anchorPoints, anchorPoint => anchorPoint.sensitiveBounds.getPoint(PositionType.CENTER).distance(currentPoint));
            this.connector.model.target = overElement?.id;
            this.connector.model.endAnchor = activeAnchor.anchor;
            activeAnchor.active = true;
        } else {
            // show the dragCreateNode and point the connector back to it
            if (!containerElement.itemCollection.contains(this.dragCreateNode.model)) {
                containerElement.itemCollection.push(this.dragCreateNode.model);
            }
            this.dragCreateNode.isHidden = false;
            this.connector.model.target = this.dragCreateNode.id;
            this.connector.model.endAnchor = AnchorType.FREE;
            this.dragCreateNode.model.x = currentPoint.x;
            this.dragCreateNode.model.y = currentPoint.y;
        }

        refreshCanvas();

        this.setState({ anchorPoints });
    }

    onMouseUpCreateConnector = event => {
        const { containerElement, selectionLayerController } = this.props;

        window.removeEventListener("mousemove", this.onInitialMouseMove);
        window.removeEventListener("mousemove", this.onMouseMoveCreateConnector);
        window.removeEventListener("mouseup", this.onMouseUpCreateConnector);

        this.setState({ anchorPoints: [], isDraggingConnector: false });

        containerElement.saveModel();

        if (containerElement.itemCollection.contains(this.dragCreateNode.model)) {
            selectionLayerController.setSelectedElements([this.dragCreateNode]);
        }
    }

    render() {
        const { selection, selectionElement, showSelectionBox } = this.props;
        const { anchorPoints, isDraggingConnector } = this.state;

        if (selectionElement.isOnAuthoringCanvas) {
            // we don't support connectors on callouts that are on the authoring canvas yet
            return null;
        }

        const isMixed = GetValueFromSelection(selection, "childElement") === "mixed";
        const showConnectionWidget = selectionElement.options.allowConnectors ?? true;
        const isNodeDiagram = selectionElement.findClosestOfType("NodeDiagram") !== null;

        if (showConnectionWidget && !isMixed && showSelectionBox) {
            if (isNodeDiagram) {
                return (
                    <>
                        {!isDraggingConnector && (
                            <>
                                <ConnectionWidget style={{ top: -20, left: "50%" }}
                                    onMouseDown={event => this.onDragNewNode(event, AnchorType.TOP)}
                                />
                                <ConnectionWidget style={{ bottom: -30, left: "50%" }}
                                    onMouseDown={event => this.onDragNewNode(event, AnchorType.BOTTOM)}
                                />
                                <ConnectionWidget style={{ top: "50%", left: -20 }}
                                    onMouseDown={event => this.onDragNewNode(event, AnchorType.LEFT)}
                                />
                                <ConnectionWidget style={{ top: "50%", right: -30 }}
                                    onMouseDown={event => this.onDragNewNode(event, AnchorType.RIGHT)}
                                />
                            </>
                        )}

                        {anchorPoints.map(({ anchor, position, active }, index) => (
                            <>
                                <AnchorPointWidget key={index} square={anchor == AnchorType.FREE} active={active} style={{ top: position.y, left: position.x }} />
                                {anchor == AnchorType.FREE && active && (
                                    <AnchorLabel style={{ top: position.y, left: position.x + 10 }}>Auto</AnchorLabel>
                                )}
                            </>
                        ))}
                    </>
                );
            } else {
                return (
                    <WidgetContainer onMouseDown={stopPropagation(this.onDragConnector)}>
                        <Icon className="micon">my_location</Icon>
                    </WidgetContainer>
                );
            }
        } else {
            return null;
        }
    }
}

export class AuthoringCalloutMediaControlBar extends Component {
    render() {
        const { element } = this.props;

        if ([AssetType.IMAGE, AssetType.ICON, AssetType.VIDEO, AssetType.STOCK_VIDEO].includes(element.assetType)) {
            const elementHeight = element.calculatedProps?.bounds?.height ?? Number.POSITIVE_INFINITY;
            const controlBarPosition = elementHeight < 150 ? ControlBarPosition.BELOW : ControlBarPosition.INNER_BOTTOM;

            const parentElement = element.parentElement;

            return (
                <ControlBar position={controlBarPosition}>
                    <MediaPopup element={element} />
                    <ImageFramePopupMenu
                        frameType={element.model.frameType}
                        allowedCategories={["shape", "device", "decorative"]}
                        onChange={frameType => element.updateModel({ frameType }, { refreshStyles: true })}
                    />
                    {parentElement.canScale && (
                        <Slider value={parentElement.getMarkerSize()}
                            min={parentElement.minMarkerSize} max={parentElement.maxMarkerSize}
                            onChange={value => element.updateModel({ [parentElement.widthKey]: value })}
                            onCommit={() => element.saveModel()}
                        />
                    )}
                </ControlBar>
            );
        }

        return null;
    }
}
