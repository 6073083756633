export type GradientConfigStop = {
    color: string,
    position?: number,
    offset?: number,
}

export type GradientConfig = {
    gradientId: string,
    type: "linear" | "radial"
    padding: number,
    stops: GradientConfigStop[],
}

export const exportGradientConfig = (
    width: number,
    height: number,
    gradientConfig: GradientConfig,
) => {
    const {
        type,
        padding = 0,
        stops,
    } = gradientConfig;

    const midWidth = width / 2;
    const midHeight = height / 2;
    let scaleY = 1;

    const canvas = document.createElement("canvas");
    canvas.width = width;
    canvas.height = height;
    const ctx = canvas.getContext("2d");

    let gradient: CanvasGradient;
    if (type === "linear") {
        gradient = ctx.createLinearGradient(
            0,
            -(midHeight + padding),
            0,
            midHeight + padding,
        );
    } else {
        gradient = ctx.createRadialGradient(
            // Inner circle
            0,
            0,
            0,
            // Outer circle
            0,
            0,
            midWidth + padding,
        );

        // Apply a scale so the radial gradient becomes an elliptical gradient
        scaleY = (height + padding * 2) / (width + padding * 2);
    }
    stops.forEach(stop => {
        gradient.addColorStop(stop.offset ? stop.offset : stop.position / 100, stop.color);
    });

    ctx.translate(midWidth, midHeight);

    ctx.fillStyle = gradient;
    ctx.scale(1, scaleY);
    ctx.fillRect(
        -midWidth,
        -midHeight / scaleY,
        width,
        height / scaleY,
    );

    let result = canvas.toDataURL();
    return result;
};
