import React, { Component } from "react";
import styled from "styled-components";

import { ImageOption, ImageOptionList } from "js/Components/ImageOptionList";
import { PopupContainer } from "js/Components/Popup";
import { AuthoringBlockType, HorizontalAlignType, VerticalAlignType } from "common/constants";
import { addTextToLayoutContainerItem, setCellToTextAndImage } from "./TextAndImageUI";

const StyledPopupContainer = styled(PopupContainer)`
    background: #f1f1f1;
`;

export class AddTextPopupMenu extends Component {
    handleSetIdea = idea => {
        const { element, closePopup } = this.props;

        if (idea.text) {
            addTextToLayoutContainerItem({
                element,
                ...idea.text,
                autoSelect: false
            });
        } else if (idea.id == "cell-caption") {
            setCellToTextAndImage(element);
            element.model.showTitle = "below";
            element.saveModel();
        } else if (idea.id == "cell-title") {
            setCellToTextAndImage(element);
            element.model.showTitle = "above";
            element.saveModel();
        }

        if (closePopup) {
            closePopup();
        }
    }

    render() {
        const { element } = this.props;

        let availableIdeas = element.parentElement.getAvailableTextIdeas();
        let ideas = TextIdeas.filter(idea => availableIdeas.includes(idea.id));

        return (
            <StyledPopupContainer >
                <ImageOptionList size={70} cols={ideas.length} gap={20} onChange={this.handleSetIdea}>
                    {ideas.map((idea, index) => <ImageOption key={index} value={idea} label={idea.label} url={idea.url} />)}
                </ImageOptionList>
            </StyledPopupContainer>
        );
    }
}

export const TextIdeas = [
    {
        id: "headline",
        label: "Headline",
        url: "/images/add-content/text-headline.svg",
        text: {
            blocks: [{
                type: "text",
                textStyle: "heading",
                html: "Lorem ipsum dolor sit amet"
            }]
        }
    },
    {
        id: "title",
        label: "Title",
        url: "/images/add-content/text-headline.svg",
        text: {
            blocks: [{
                type: "text",
                textStyle: "title",
                html: "Lorem ipsum dolor sit amet"
            }]
        }
    },
    {
        id: "bullets",
        label: "Bullets",
        url: "/images/add-content/text-bullet.svg",
        text: {
            blocks: [{
                type: "text",
                textStyle: "bulleted",
                listStyle: "bullets",
                indent: 0,
                html: ""
            }]
        }
    },
    {
        id: "body",
        label: "Body Text",
        url: "/images/add-content/text-details.svg",
        text: {
            verticalAlign: VerticalAlignType.TOP,
            textAlign: HorizontalAlignType.LEFT,
            blocks: [{
                type: "text",
                textStyle: "body",
                html: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed nisl tellus, sodales eget dui eu, molestie gravida lacus. Etiam diam nulla, luctus quis interdum et, vulputate et leo."
            }]
        }
    },
    {
        id: "icontext",
        label: "Icon + Text",
        url: "/images/add-content/text-icon.svg",
        text: {
            verticalAlign: VerticalAlignType.MIDDLE,
            textAlign: HorizontalAlignType.CENTER,
            blocks: [{
                type: AuthoringBlockType.MEDIA,
                elementModel: {
                    elementType: "MediaBlock",
                    content_type: "icon",
                    content_value: "star"
                },
            }, {
                type: "text",
                textStyle: "heading",
                html: "Lorem ipsum dolor sit amet, consectetur adipiscing elit."
            }]
        }
    },
    {
        id: "quote",
        label: "Quote",
        url: "/images/add-content/text-quote.svg",
        text: {
            blocks: [{
                type: "text",
                textStyle: "heading",
                html: `"<font class='emphasized'>Design</font> is not just what it looks like and feels like. <font class=\'emphasized\'>Design</font> is how it works."`
            }, {
                type: "divider",
                dividerWidth: "short"
            }, {
                type: "text",
                textStyle: "caption",
                html: "Steve Jobs"
            }]
        }
    },
    {
        id: "stat",
        label: "Stat",
        url: "/images/add-content/text-stat.svg",
        text: {
            blockFontScales: { headline: 1.75 },
            blocks: [{
                type: "text",
                textStyle: "headline",
                html: "$100m"
            }, {
                type: "text",
                textStyle: "title",
                html: "Annual Recurring Revenue"
            }]
        }
    },
    {
        id: "cell-caption",
        label: "Cell Caption",
        url: "/images/add-content/dashboard-caption.svg",
        showTitle: "below"
    },
    {
        id: "cell-title",
        label: "Cell Title",
        url: "/images/add-content/dashboard-title.svg",
        showTitle: "above"
    }
];

