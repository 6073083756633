import React from "react";
import styled from "styled-components";

import { isRenderer } from "js/config";
import * as geom from "js/core/utilities/geom";
import { getTransformProps } from "js/core/utilities/svgHelpers";
import { Box } from "js/Components/Box";
import { sanitizeHtml } from "js/core/utilities/dompurify";

import { BaseElement } from "../base/BaseElement";
import { WebViewPropertyPanel } from "../../Editor/ElementPropertyPanels/WebViewUI";
import { ImageFrame } from "../base/Frame";
import { ElementTextBlockPositionType } from "common/constants";

const baiUrl = "https://www.beautiful.ai/?";

const WebViewIFrame = styled.iframe.attrs(({ scale }) => ({
    style: {
        transform: `scale(${scale})`,
        width: `${100 / scale}%`,
        height: `${100 / scale}%`
    }
}))`
    position: absolute;
    pointer-events: auto;
    border: solid 1px #aaa;
    transform-origin: 0 0;
`;

export class WebView extends BaseElement {
    isCurrentCanvas = false;

    static get schema() {
        return {
            scale: 1,
            url: baiUrl,
            fullBleed: false
        };
    }

    getElementPropertyPanel() {
        return WebViewPropertyPanel;
    }

    get scale() {
        return this.model.scale ?? 1;
    }

    getCanvasMargins() {
        const fullBleed = this.model.fullBleed;
        if (fullBleed) {
            if (this.canvas.getCanvasElement().showFooter ||
            this.canvas.getCanvasElement().elementTextBlockPosition === ElementTextBlockPositionType.INLINE) {
                return { left: 0, top: 0, bottom: 10, right: 0 };
            } else {
                return { left: 0, top: 0, bottom: 0, right: 0 };
            }
        } else {
            return { left: 50, top: 50, bottom: 50, right: 50 };
        }
    }

    get frameType() {
        return this.model.frameType ?? "none";
    }

    _build() {
        if (this.frameType != "none") {
            this.frame = this.addElement("frame", () => ImageFrame, {
                model: {
                    frame: this.frameType,
                }
            });
        }
    }

    _calcProps(props, options) {
        const { size } = props;

        let webViewBounds = new geom.Rect(0, 0, size);

        if (this.frameType != "none") {
            const frameProps = this.frame.calcProps(size);

            if (options.autoWidth) {
                size.width = frameProps.size.width;
            } else if (options.autoHeight) {
                size.height = frameProps.size.height;
            }

            frameProps.bounds = new geom.Rect(size.width / 2 - frameProps.size.width / 2, size.height / 2 - frameProps.size.height / 2, frameProps.size);
            frameProps.layer = 1;

            webViewBounds = new geom.Rect(0, 0, frameProps.clipBounds.size).offset(frameProps.bounds.left, frameProps.bounds.top).offset(frameProps.clipBounds.left, frameProps.clipBounds.top);
        }

        return { size, webViewBounds };
    }

    _prepareToShowElement() {
        this.isCurrentCanvas = true;
        this.refreshRender();
    }

    _stopElement() {
        this.isCurrentCanvas = false;
        this.refreshRender();
    }

    getSanitizedUrl() {
        return this.model.url.startsWith("javascript:") ? baiUrl : sanitizeHtml(this.model.url);
    }

    renderChildren(transition) {
        if (isRenderer) {
            return null;
        }

        const children = super.renderChildren(transition);
        /**
         * this is a hack to force the iframe to reload
         * when the url changes this will fix the issue if
         * there was a video/audio playing */
        if (this.isCurrentCanvas) {
            children.push(
                <Box {...getTransformProps(this.calculatedProps.webViewBounds)}>
                    <WebViewIFrame
                        sandbox="allow-forms allow-scripts allow-same-origin allow-presentation allow-orientation-lock	allow-pointer-lock"
                        // bounds={this.calculatedProps.webViewBounds}
                        src={this.getSanitizedUrl()}
                        scale={this.scale}
                    />
                </Box>
            );
        }

        return children;
    }
}

export const elements = {
    WebView
};

