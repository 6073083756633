import { _ } from "js/vendor";
import { BlockStructureType, AuthoringBlockType, TextStyleType } from "common/constants";
import { detectTextContent } from "js/core/services/sharedModelManager";

import { CollectionElement, CollectionItemElement } from "../base/CollectionElement";
import { TextElement } from "../base/Text/TextElement";
import { TextBoxGridItemControlBar, TextBoxGridPropertyPanel } from "../../Editor/ElementPropertyPanels/TextBoxGridUI";

class TextBoxGrid extends CollectionElement {
    get name() {
        return "Text Boxes";
    }

    getElementPropertyPanel() {
        return TextBoxGridPropertyPanel;
    }

    getChildItemType() {
        return TextBoxGridItem;
    }

    get maxItemCount() {
        return 6;
    }

    _calcProps(props, options) {
        const { size } = props;

        const layouter = this.getLayouter(props, this.itemElements, size);
        layouter.calcTextBoxGridLayout({
            flipOrientation: this.model.flipOrientation,
            fitToText: this.model.fitToText
        });

        return { size };
    }

    _migrate_10() {
        this.model.fitToText = true;
    }

    _exportToSharedModel() {
        const textContent = this.itemElements.reduce(
            (textContent, itemElement) => ([
                ...textContent, ...itemElement.text._exportToSharedModel().textContent
            ]), []
        );

        return { textContent, collectionColor: this.collectionColor };
    }

    _importFromSharedModel(model) {
        const textContent = detectTextContent(model);
        if (!textContent) return;

        return {
            items: textContent.map(({ mainText, secondaryTexts }) => ({
                text: {
                    blocks: [
                        {
                            type: AuthoringBlockType.TEXT,
                            textStyle: TextStyleType.TITLE,
                            html: mainText.text
                        },
                        ...secondaryTexts.map(secondaryText => ({
                            type: AuthoringBlockType.TEXT,
                            textStyle: TextStyleType.BODY,
                            html: secondaryText.text
                        }))
                    ]
                }
            })).slice(0, this.maxItemCount),
            collectionColor: model.collectionColor
        };
    }
}

class TextBoxGridItem extends CollectionItemElement {
    getElementControlBar() {
        return TextBoxGridItemControlBar;
    }

    get selectionPadding() {
        return 0;
    }

    _build() {
        this.text = this.addElement("text", () => TextElement, {
            blockStructure: BlockStructureType.TITLE_AND_BODY,
            scaleTextToFit: true,
            syncFontSizeWithSiblings: true,
            backgroundElement: this.decoration
        });
    }

    _calcProps(props, options) {
        const { size } = props;

        const textProps = this.text.calcProps(size, options);

        return { size: textProps.size, textScale: textProps.textScale };
    }
}

export const elements = {
    TextBoxGrid,
};
