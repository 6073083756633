import React, { Component } from "react";
import { _ } from "js/vendor";

import { WithLabel } from "js/Components/WithLabel";
import { ToggleSwitch } from "js/Components/ToggleSwitch";
import { Dropdown } from "js/Components/Dropdown";
import { CHART_DATE_FORMATS, FormatType, PositionType } from "common/constants";
import { NumericStepper } from "js/Components/NumericStepper";
import PresentationEditorController from "js/editor/PresentationEditor/PresentationEditorController";
import { FlexSpacer, Gap20 } from "js/react/components/Gap";
import { getValidChartDataFromCsv } from "js/core/utilities/xlsx";
import { PropertyPanelContainer, PropertySection, PropertySectionHeader } from "js/EditorComponents/PropertyPanel";
import { Button } from "js/Components/Button";
import { FlexBox } from "js/react/components/LayoutGrid";
import { Input } from "js/Components/Input";
import { Popup, PopupContainer, PopupContent } from "js/Components/Popup";
import { ImagePopup } from "js/Components/ImagePopup";
import { ImageOption, ImageOptionList } from "js/Components/ImageOptionList";
import { StaticImage } from "js/Components/StaticImage";
import { ShowConfirmationDialog } from "js/react/components/Dialogs/BaseDialog";
import { ToggleButton, ToggleButtons } from "js/Components/ToggleButtons";
import { formatter } from "js/core/utilities/formatter";
import { ToolTipBadge } from "js/Components/ToolTip";
import { MenuItem } from "js/Components/Menu";
import { Box } from "js/Components/Box";

import { FormattingDropdown } from "../../EditorComponents/FormattingOptions";
import { BaseElementSelection } from "../../ElementSelections/BaseElementSelection";
import { AxisWidgets } from "../../ElementSelections/ChartSelection/AxisWidgets";
import { SeriesWidgets } from "../../ElementSelections/ChartSelection/SeriesWidgets";

export async function ChangeChartType(element, type) {
    const containerElement = element.parentElement;
    const chartData = getChartData(element);

    switch (type) {
        case "pie":
        case "donut":
            await generatePieChartModel(element, type === "donut");
            break;
        case "waterfall":
            await generateWaterfallChartModel(element);
            break;
        default:
            if (containerElement.model.componentType === "Table") {
                const tableData = getTableData(element);

                const { categories, series } = getValidChartDataFromCsv(tableData, false);

                Object.assign(element.model, element.canvas.chartUtils.getDefaultChartModel(type));

                chartData.xAxis.categories = categories;
                chartData.series[0].data = series[0].data.map(value => ({ y: value, pt: true }));
                chartData.series[0].colorName = element.model.color;
            } else if (containerElement.model.componentType === "Chart" || containerElement.model.elements[0].element.componentType) {
                // convert all the series in the existing chart to the new type
                for (const series of chartData.series) {
                    series.type = type;
                    series.colorName = series.colorName ?? chartData?.series[0].colorName ?? containerElement.childElement.model.collectionColor ?? "theme";
                }
            } else {
                // convert the pie chart to a chart
                const pieData = element.model.data;

                const color = containerElement.childElement.model.collectionColor ?? "theme";

                // clear out the old chart data
                for (const key of Object.keys(element.model)) {
                    delete element.model[key];
                }

                Object.assign(element.model, element.canvas.chartUtils.getDefaultChartModel(type));
                element.model.chartData.xAxis.categories = pieData.map(d => d.label.blocks.map(block => block.html).join(" "));
                element.model.chartData.series[0].data = pieData.map(d => ({ y: d.value, pt: true }));
                element.model.chartData.series[0].colorName = color;
            }
            containerElement.model.componentType = "Chart";
    }

    // This is a special case fix to deal with a highcharts
    // bug when changing legend to/from proximate
    element.canvas.layouter.clear();
    // Again, highcharts bug requires relayout, so we have to tell undoManager to do the same
    await element.canvas.updateCanvasModel(false, false, { undoOptions: { clearLayout: true } });
}

const generatePieChartModel = async (element, isDonut) => {
    const containerElement = element.parentElement;

    let data;
    let categories;
    let moreThanOneSeries = false;

    let color = containerElement.model?.childElement?.color;
    switch (containerElement.model.componentType) {
        case "Table":
            const tableData = getTableData(element);

            const { categories: tableCategories, series } = getValidChartDataFromCsv(tableData, true);
            categories = tableCategories;
            moreThanOneSeries = series.length > 1;
            data = series[0].data.map(value => ({ y: value, pt: true }));

            break;
        case "Chart":
            // convert the chart to a pie chart
            const chartData = getChartData(element);

            categories = chartData.xAxis.categories;
            moreThanOneSeries = chartData.series.length > 1;
            data = chartData.series[0].data;
            color = chartData.series[0].colorName;
            break;
        default:
            element.model.isDonut = isDonut;
            return;
    }

    if (moreThanOneSeries) {
        const hasConfirmed = await ShowConfirmationDialog({
            title: "Convert to Pie Chart",
            message: "Converting to a pie chart will only convert the first series in your chart. The additional series data will be discarded. Are you sure you want to continue?"
        });
        if (!hasConfirmed) {
            return;
        }
    }

    const pieData = [];
    for (let i = 0; i < categories.length; i++) {
        const value = data[i]?.y;
        if (typeof value !== "number") {
            continue;
        }
        if (value < 0) {
            continue;
        }

        pieData.push({
            label: {
                text: categories[i]
            },
            value,
            color: null,
            offset: 0
        });
    }

    // clear out the old chart data
    for (const key of Object.keys(element.model)) {
        delete element.model[key];
    }

    if (pieData.length === 0) {
        element.model.data = element.canvas.chartUtils.getDefaultPieChartModel().data;
    } else {
        element.model.data = pieData;
    }

    element.model.collectionColor = color ?? "theme";
    element.model.isDonut = isDonut;
    containerElement.model.componentType = "PieChart";
};

const generateWaterfallChartModel = async element => {
    const containerElement = element.parentElement;
    const chartData = getChartData(element);

    let moreThanOneSeries = false;

    let categories;
    let data;
    let series;

    const componentType = containerElement.model.componentType || containerElement.model.elements[0].element.componentType;

    switch (componentType) {
        case "Table":
            const tableData = getTableData(element);

            const { series: tableSeries, categories: tableCategories } = getValidChartDataFromCsv(tableData, false, true);

            moreThanOneSeries = tableSeries.length > 1;
            categories = tableCategories;
            data = tableSeries[0].data.map(value => ({ y: value, pt: true }));

            break;
        case "Chart":
            moreThanOneSeries = chartData.series.length > 1;
            series = chartData.series.slice(0, 1);
            break;
        case "PieChart":
            const { tabularData } = element.exportToSharedModel();

            data = tabularData[0].data[1].map(value => ({ y: value, pt: true }));
            categories = tabularData[0].data[0];
            break;
        default:
            Object.assign(element.model, element.canvas.chartUtils.getDefaultWaterfallChartModel());
            containerElement.model.componentType = "Chart";
            return;
    }

    if (moreThanOneSeries) {
        if (!await ShowConfirmationDialog({
            title: "Convert to Waterfall Chart",
            message: "Converting to a waterfall chart will only convert the first series in your chart. The additional series data will be discarded."
        })) {
            return;
        }
    }

    if (series) {
        chartData.series = chartData.series.slice(0, 1);
    } else {
        Object.assign(element.model, element.canvas.chartUtils.getDefaultWaterfallChartModel());
        chartData.series[0].data = data;
        chartData.xAxis.categories = categories;
        containerElement.model.componentType = "Chart";
    }

    chartData.series[0].type = "waterfall";
    chartData.series[0].colorName = null;
    chartData.series[0].zones = null;
    chartData.negativeBarColor = "negative";
    chartData.positiveBarColor = "positive";
    chartData.sumBarColor = "chart1";
};

const getTableData = element => {
    const { cells, rows, cols } = element.table.model;

    const data = new Array(rows.length).fill().map(() => new Array(cols.length).fill(""));
    for (const cell of cells) {
        data[cell.row][cell.col] = cell.cellText?.text || "";
    }

    return data;
};

const getChartData = element => {
    return element.model.chartData || element.model.element?.chartData;
};

export class ChartTypePicker extends Component {
    render() {
        const { element, value } = this.props;
        return (
            <ImagePopup value={value}
                border
                previewSize={25}
                cols={4}
                size={40}
                onChange={type => ChangeChartType(element, type)}
            >
                <ImageOption value="line"
                    label="Line"
                >
                    <StaticImage src="/images/infographics/chart-line.svg" />
                </ImageOption>
                <ImageOption value="column"
                    label="Column"
                >
                    <StaticImage src="/images/infographics/chart-column.svg" />
                </ImageOption>
                <ImageOption value="bar"
                    label="Bar"
                >
                    <StaticImage src="/images/infographics/chart-bar.svg" />
                </ImageOption>
                <ImageOption value="area"
                    label="Area"
                >
                    <StaticImage src="/images/infographics/chart-area.svg" />
                </ImageOption>
                <ImageOption value="spline"
                    label="Spline"
                >
                    <StaticImage src="/images/infographics/chart-spline.svg" />
                </ImageOption>
                <ImageOption value="areaspline"
                    label="Area Spline"
                >
                    <StaticImage src="/images/infographics/chart-area-spline.svg" />
                </ImageOption>
                <ImageOption value="pie"
                    label="Pie"
                >
                    <StaticImage src="/images/infographics/chart-pie.svg" />
                </ImageOption>
                <ImageOption value="donut"
                    label="Donut"
                >
                    <StaticImage src="/images/infographics/chart-donut.svg" />
                </ImageOption>
                <ImageOption value="waterfall"
                    label="Waterfall"
                >
                    <StaticImage src="/images/infographics/chart-waterfall.svg" />
                </ImageOption>
            </ImagePopup>
        );
    }
}

export class ChartPropertyPanel extends Component {
    state = {
        selectedTab: "xAxis"
    }

    handleEditChartData = async () => {
        const { element } = this.props;
        await element.canvas.selectionLayerController.setSelectedElements([element]);
        PresentationEditorController.showElementPanel(element);
    }

    handleCopyStyles = () => {
        const { element } = this.props;

        const container = element.findClosestOfType("GridLayoutContainer");

        const elementChartModel = _.cloneDeep(element.chartModel);
        delete elementChartModel.xAxis.categories;
        delete elementChartModel.xAxis.categoryType;
        delete elementChartModel.xAxis.labels;
        delete elementChartModel.xAxis.labelInterval;
        delete elementChartModel.xAxis.autoType;
        delete elementChartModel.xAxis.axisTitleText;
        delete elementChartModel.xAxis.dateFormatting;
        delete elementChartModel.xAxis.type;
        elementChartModel.series.forEach(s => {
            delete s.id;
            delete s.data;
            delete s.name;
            delete s.type;
        });

        for (const chartItem of container
            .itemElements
            .filter(e => e.model.componentType === "Chart" && e.parentElement.id !== element.parentElement.id)
        ) {
            const chart = chartItem.childElement;

            chart.chartModel.xAxis = {
                ...chart.chartModel.xAxis,
                ...elementChartModel.xAxis,
            };

            chart.chartModel.series = chart.chartModel.series.map((series, index) => ({
                ...series,
                ...elementChartModel.series[index] || {},
            }));
            chart.chartModel.yAxis = _.cloneDeep(elementChartModel.yAxis);
            chart.chartModel.yAxis2 = _.cloneDeep(elementChartModel.yAxis2);
            chart.model.legendPosition = element.model.legendPosition;
            chart.chartModel.legendReverse = elementChartModel.legendReverse;
            chart.chartModel.columnPadding = elementChartModel.columnPadding;
        }

        element.canvas.updateCanvasModel(false);
    }

    renderYAxisMenu(yAxis) {
        const { element } = this.props;

        return (
            <Popup icon="more_vert">
                <PopupContent>
                    {closePopup => (
                        <Box width={280}>
                            <PropertyPanelContainer>
                                <PropertySection>

                                    <WithLabel label="Show Axis Labels">
                                        <ToggleSwitch value={yAxis.labels.enabled}
                                            onChange={value => {
                                                yAxis.labels.enabled = value;
                                                element.canvas.updateCanvasModel(true);
                                            }}
                                        />
                                    </WithLabel>
                                    {yAxis.labels.enabled && (
                                        <WithLabel label="Label Size">
                                            <NumericStepper value={yAxis.labels.fontSize ?? 15}
                                                min={5}
                                                max={30}
                                                step={1}
                                                onChange={value => {
                                                    yAxis.labels.fontSize = value;
                                                    element.canvas.updateCanvasModel(false);
                                                }}
                                            />
                                        </WithLabel>
                                    )}
                                </PropertySection>
                                <PropertySection>
                                    <WithLabel label="Axis Type">
                                        <ToggleButtons value={yAxis.axisType ?? "linear"}
                                            onChange={value => {
                                                yAxis.axisType = value;
                                                element.canvas.updateCanvasModel(true);
                                            }}
                                        >
                                            <ToggleButton small
                                                value="linear"
                                            >
                                                Linear
                                            </ToggleButton>
                                            <ToggleButton small
                                                value="logarithmic"
                                            >
                                                Logarithmic
                                            </ToggleButton>
                                        </ToggleButtons>
                                    </WithLabel>
                                    <WithLabel label="Scale Values"
                                        toolTip={(
                                            <>
                                                <p>Scales how y-axis labels and data labels are shown.</p>
                                                <p>You can use this feature when the values in your chart data are abbreviated, ie. 10 instead of 10,000, but would like to show labels as the actual scaled value.</p>
                                            </>
                                        )}
                                    >
                                        <Dropdown value={yAxis.labelFormat}
                                            onChange={value => {
                                                yAxis.labelFormat = value;
                                                element.canvas.updateCanvasModel(true);
                                            }}
                                        >
                                            <MenuItem value="ones">None</MenuItem>
                                            <MenuItem value="thousands">Thousands</MenuItem>
                                            <MenuItem value="millions">Millions</MenuItem>
                                            <MenuItem value="billions">Billions</MenuItem>
                                            <MenuItem value="trillions">Trillions</MenuItem>
                                            <MenuItem value="percent">Percent</MenuItem>
                                        </Dropdown>
                                    </WithLabel>
                                </PropertySection>
                                <PropertySection>
                                    <WithLabel label="Axis Steps"
                                        left
                                        gap={5}
                                        toolTip={(
                                            <>
                                                <p>Sets how many ticks to display on the axis.</p>
                                                <p>Deleting the value will reset to the recommened value.</p>
                                            </>
                                        )}
                                    >
                                        <NumericStepper value={yAxis.tickAmount}
                                            width={70}
                                            min={2}
                                            max={100}
                                            step={1}
                                            useAutoWhenBlank
                                            onChange={value => {
                                                yAxis.tickAmount = value;
                                                element.canvas.updateCanvasModel(false);
                                            }}
                                        />
                                    </WithLabel>
                                    <WithLabel label="Axis Range"
                                        left
                                        toolTip={(
                                            <>
                                                <p>Sets the minimum and maximum values of the axis.</p>
                                                <p>Setting the axis range to less than series data will clip the data.</p>
                                                <p>Deleting the value will reset to auto detect the recommended min/max.</p>
                                            </>
                                        )}
                                    >

                                        <NumericStepper value={yAxis.min}
                                            width={70}
                                            min={0}
                                            max={1000000}
                                            step={1}
                                            useAutoWhenBlank
                                            onChange={value => {
                                                // Ensure the value is a number and that it only has one decimal point
                                                value = parseFloat(value);
                                                value = isNaN(value) ? undefined : Math.round(value * 10) / 10;
                                                // Ensure min is less than max
                                                if (value > yAxis.max) {
                                                    yAxis.min = yAxis.max;
                                                    yAxis.max = value;
                                                } else {
                                                    yAxis.min = value;
                                                }
                                                element.canvas.updateCanvasModel(false);
                                            }}
                                        />
                                        <NumericStepper value={yAxis.max}
                                            width={70}
                                            min={1}
                                            max={1000000}
                                            step={1}
                                            useAutoWhenBlank
                                            onChange={value => {
                                                // Ensure the value is a number and that it only has one decimal point
                                                value = parseFloat(value);
                                                value = isNaN(value) ? undefined : Math.round(value * 10) / 10;
                                                // Ensure max is greater than min
                                                if (value < yAxis.min) {
                                                    yAxis.max = yAxis.min;
                                                    yAxis.min = value;
                                                } else {
                                                    yAxis.max = value;
                                                }
                                                element.canvas.updateCanvasModel(false);
                                            }}
                                        />
                                    </WithLabel>
                                    <WithLabel label="Show Minimum Value"
                                        toolTip="Sets whether to show the minimum value on the y-axis."
                                    >
                                        <ToggleSwitch value={yAxis.showFirstLabel}
                                            onChange={value => {
                                                yAxis.showFirstLabel = value;
                                                element.canvas.updateCanvasModel(false);
                                            }}
                                        />
                                    </WithLabel>
                                </PropertySection>
                                <PropertySection>
                                    <WithLabel above
                                        label="Custom Labels"
                                        toolTip="Allows you to prepend or extend axis labels with custom text."
                                    >
                                        <FlexBox>
                                            <WithLabel label="Prefix"
                                                tight
                                            >
                                                <Input type="text"
                                                    value={yAxis.prefix}
                                                    onChange={e => {
                                                        yAxis.prefix = e.target.value;
                                                        element.canvas.updateCanvasModel(false);
                                                    }}
                                                />
                                            </WithLabel>
                                            <Gap20 />
                                            <WithLabel label="Suffix"
                                                tight
                                            >
                                                <Input type="text"
                                                    value={yAxis.suffix}
                                                    onChange={e => {
                                                        yAxis.suffix = e.target.value;
                                                        element.canvas.updateCanvasModel(false);
                                                    }}
                                                />
                                            </WithLabel>
                                        </FlexBox>
                                    </WithLabel>
                                </PropertySection>
                            </PropertyPanelContainer>
                        </Box>
                    )}
                </PopupContent>
            </Popup>
        );
    }

    renderXAxisMenu(xAxis) {
        const { element } = this.props;

        return (
            <Popup icon="more_vert">
                <PopupContent>
                    {closePopup => (
                        <Box width={225}>
                            <PropertyPanelContainer>
                                <PropertySection>

                                    <WithLabel label="Show Labels">
                                        <ToggleSwitch value={xAxis.labels.enabled}
                                            onChange={value => {
                                                xAxis.labels.enabled = value;
                                                element.canvas.updateCanvasModel(true);
                                            }}
                                        />
                                    </WithLabel>
                                </PropertySection>
                                {xAxis.labels.enabled && (
                                    <PropertySection>
                                        <WithLabel label="Label Rotate">
                                            <Dropdown value={xAxis.labels.rotation ?? "auto"}
                                                onChange={value => {
                                                    xAxis.labels.rotation = (value == "auto") ? null : value;
                                                    element.canvas.updateCanvasModel(true);
                                                }}
                                            >
                                                <MenuItem value="auto">
                                                    Auto
                                                    <ToolTipBadge>Auto setting will only rotate labels if needed to prevent overlapping.</ToolTipBadge>
                                                </MenuItem>
                                                <MenuItem value={0}>None</MenuItem>
                                                <MenuItem value={-45}>45 degrees</MenuItem>
                                            </Dropdown>
                                        </WithLabel>
                                        <WithLabel label="Label Size">
                                            <NumericStepper value={xAxis.labels.fontSize ?? 15}
                                                min={5}
                                                max={30}
                                                step={1}
                                                onChange={value => {
                                                    xAxis.labels.fontSize = value;
                                                    element.canvas.updateCanvasModel(false);
                                                }}
                                            />
                                        </WithLabel>
                                    </PropertySection>
                                )}
                            </PropertyPanelContainer>
                        </Box>
                    )}
                </PopupContent>
            </Popup>
        );
    }

    renderLegendMenu() {
        const { element } = this.props;
        return (
            <Popup contained
                showArrow
                label={(element.model.legendPosition ?? "None").toTitleCase()}
            >
                <PopupContent>
                    {closePopup => (
                        <PopupContainer width={275}>
                            <PropertyPanelContainer>
                                <PropertySection>
                                    <WithLabel label="Chart Legend"
                                        toolTip={(
                                            <>
                                                <p>Controls whether and where to show the chart legend.</p>
                                                <p>
                                                    The
                                                    <strong>With series</strong>
                                                    {" "}
                                                    option will attempt to position the legend proximate with the end of each series value.
                                                </p>
                                            </>
                                        )}
                                    >
                                        <Dropdown value={element.model.legendPosition ?? PositionType.OFF}
                                            onChange={value => {
                                                element.model.legendPosition = value;
                                                element.lastBounds = null;

                                                element.canvas.layouter.clear(); // this is a special case fix to deal with a highcharts bug when changing legend to/from proximate
                                                element.canvas.updateCanvasModel(false);
                                            }}
                                        >
                                            <MenuItem value={PositionType.OFF}>None</MenuItem>
                                            <MenuItem value={PositionType.BOTTOM}>Bottom</MenuItem>
                                            <MenuItem value={PositionType.TOP}>Top</MenuItem>
                                            <MenuItem value={PositionType.RIGHT}>Right</MenuItem>
                                            <MenuItem value={PositionType.LEFT}>Left</MenuItem>
                                            <MenuItem value="proximate"
                                                disabled={element.chartModel.series.some(s => s.type == "bar")}
                                            >
                                                With
                                                Series
                                            </MenuItem>
                                        </Dropdown>
                                    </WithLabel>
                                    <WithLabel label="Reverse Legend Order">
                                        <ToggleSwitch value={element.model.legendReverse}
                                            onChange={value => element.updateModel({ legendReverse: value })}
                                        />
                                    </WithLabel>
                                </PropertySection>
                            </PropertyPanelContainer>
                        </PopupContainer>
                    )}
                </PopupContent>
            </Popup>
        );
    }

    renderChartStylesMenu() {
        const { element } = this.props;
        const isBarChart = element.chartModel.series.some(s => s.type === "bar");
        const xAxis = isBarChart ? element.chartModel.yAxis : element.chartModel.xAxis;
        const yAxis = isBarChart ? element.chartModel.xAxis : element.chartModel.yAxis;

        // xAxis2 only exists in UI code - model only has yAxis2
        const xAxis2 = isBarChart && element.chartModel.yAxis2 && element.chartModel.yAxis2.visible ? element.chartModel.yAxis2 : null;
        const yAxis2 = !isBarChart && element.chartModel.yAxis2 && element.chartModel.yAxis2.visible ? element.chartModel.yAxis2 : null;

        return (
            <Popup icon="palette"
                showArrow
            >
                <PopupContent>
                    {closePopup => (
                        <PropertyPanelContainer>
                            <PropertySection>
                                <FlexBox left
                                    gap={30}
                                >
                                    <WithLabel label="Axis Lines"
                                        above
                                        left
                                    >
                                        <ImageOptionList size={30}>
                                            <ImageOption label="X-Axis"
                                                url="/images/ui/charts/x-axis.svg"
                                                selected={xAxis.showAxisLine}
                                                onClick={() => {
                                                    xAxis.showAxisLine = !xAxis.showAxisLine;
                                                    element.canvas.updateCanvasModel(false);
                                                }}
                                            />
                                            {xAxis2 && (
                                                <ImageOption label="X-Axis 2"
                                                    url="/images/ui/charts/x-axis.svg"
                                                    selected={xAxis2.showAxisLine}
                                                    onClick={() => {
                                                        xAxis2.showAxisLine = !xAxis2.showAxisLine;
                                                        element.canvas.updateCanvasModel(false);
                                                    }}
                                                />
                                            )}
                                            <ImageOption label="Left Axis"
                                                url="/images/ui/charts/y-axis.svg"
                                                selected={yAxis.showAxisLine}
                                                onClick={() => {
                                                    yAxis.showAxisLine = !yAxis.showAxisLine;
                                                    element.canvas.updateCanvasModel(false);
                                                }}
                                            />
                                            {yAxis2 && (
                                                <ImageOption label="Right Axis"
                                                    url="/images/ui/charts/y-axis.svg"
                                                    selected={yAxis2.showAxisLine}
                                                    onClick={() => {
                                                        yAxis2.showAxisLine = !yAxis2.showAxisLine;
                                                        element.canvas.updateCanvasModel(false);
                                                    }}
                                                />
                                            )}
                                        </ImageOptionList>
                                    </WithLabel>

                                    <WithLabel label="Grid Lines"
                                        above
                                        left
                                    >
                                        <ImageOptionList size={30}>
                                            <ImageOption label="X-Axis"
                                                url="/images/ui/charts/grid-vertical.svg"
                                                selected={xAxis.showGridLines}
                                                onClick={() => {
                                                    xAxis.showGridLines = !xAxis.showGridLines;
                                                    element.canvas.updateCanvasModel(false);
                                                }}
                                            />
                                            {xAxis2 && (
                                                <ImageOption label="X-Axis 2"
                                                    url="/images/ui/charts/grid-vertical.svg"
                                                    selected={xAxis2.showGridLines}
                                                    onClick={() => {
                                                        xAxis2.showGridLines = !xAxis2.showGridLines;
                                                        element.canvas.updateCanvasModel(false);
                                                    }}
                                                />
                                            )}

                                            <ImageOption label="Left Axis"
                                                url="/images/ui/charts/grid-horizontal.svg"
                                                selected={yAxis.showGridLines}
                                                onClick={() => {
                                                    yAxis.showGridLines = !yAxis.showGridLines;
                                                    element.canvas.updateCanvasModel(false);
                                                }}
                                            />

                                            {yAxis2 && (
                                                <ImageOption label="Right Axis"
                                                    url="/images/ui/charts/grid-horizontal.svg"
                                                    selected={yAxis2.showGridLines}
                                                    onClick={() => {
                                                        yAxis2.showGridLines = !yAxis2.showGridLines;
                                                        element.canvas.updateCanvasModel(false);
                                                    }}
                                                />
                                            )}
                                        </ImageOptionList>
                                    </WithLabel>
                                    <WithLabel label="Tickmarks"
                                        above
                                        left
                                    >
                                        <ImageOptionList size={30}>
                                            <ImageOption label="X-Axis"
                                                url="/images/ui/charts/ticks-x-axis.svg"
                                                selected={xAxis.showMajorTicks}
                                                onClick={() => {
                                                    xAxis.showMajorTicks = !xAxis.showMajorTicks;
                                                    element.canvas.updateCanvasModel(false);
                                                }}
                                            />
                                            {xAxis2 && (
                                                <ImageOption label="X-Axis 2"
                                                    url="/images/ui/charts/ticks-x-axis.svg"
                                                    selected={xAxis2.showMajorTicks}
                                                    onClick={() => {
                                                        xAxis2.showMajorTicks = !xAxis2.showMajorTicks;
                                                        element.canvas.updateCanvasModel(false);
                                                    }}
                                                />
                                            )}
                                            <ImageOption label="Left Axis"
                                                url="/images/ui/charts/ticks-y-axis.svg"
                                                selected={yAxis.showMajorTicks}
                                                onClick={() => {
                                                    yAxis.showMajorTicks = !yAxis.showMajorTicks;
                                                    element.canvas.updateCanvasModel(false);
                                                }}
                                            />
                                            {yAxis2 && (
                                                <ImageOption label="Right Axis"
                                                    url="/images/ui/charts/ticks-y-axis.svg"
                                                    selected={yAxis2.showMajorTicks}
                                                    onClick={() => {
                                                        yAxis2.showMajorTicks = !yAxis2.showMajorTicks;
                                                        element.canvas.updateCanvasModel(false);
                                                    }}
                                                />
                                            )}
                                        </ImageOptionList>
                                    </WithLabel>
                                </FlexBox>
                            </PropertySection>
                            <PropertySection>
                                <WithLabel label="X-Axis Padding"
                                    toolTip="Adds padding between the y-axis line and the data series. Not available for column or bar charts."
                                >
                                    <ToggleSwitch value={!xAxis.zeroAxisPadding}
                                        onChange={value => {
                                            xAxis.zeroAxisPadding = !value;
                                            element.canvas.updateCanvasModel(true);
                                        }}
                                        disabled={element.chartModel.series.some(s => s.type == "column" || s.type == "waterfall" || s.type == "bar")}
                                    />
                                </WithLabel>

                            </PropertySection>
                            <PropertySection>
                                <WithLabel label="Chart Stacking"
                                    toolTip="Controls whether multiple series in a chart should be stacked to better show how different series contribute to a total value."
                                >
                                    <Dropdown value={element.chartModel.series[0].stacking || "none"}
                                        onChange={
                                            newStacking => {
                                                // Update yAxis format if we're switching to/from "percent" stacking
                                                const oldStacking = element.chartModel.series[0].stacking;

                                                if (oldStacking !== "percent" && newStacking == "percent"
                                                ) {
                                                    element.chartModel.yAxis.format = "percent";
                                                    element.chartModel.yAxis.labelFormat = "percent";
                                                } else if (oldStacking == "percent" && newStacking !== "percent") {
                                                    element.chartModel.yAxis.format = "number";
                                                    element.chartModel.yAxis.labelFormat = "ones";
                                                }

                                                element.chartModel.series.forEach(series => {
                                                    series.stacking = newStacking !== "none" ? newStacking : null;
                                                });

                                                if (newStacking == "none") {
                                                    element.chartModel.columnPadding = Math.max(0, (element.chartModel.columnPadding ?? 0.1) - 0.1);
                                                    element.chartModel.groupPadding = 0.1;
                                                }

                                                element.canvas.updateCanvasModel(true);
                                            }
                                        }
                                    >
                                        <MenuItem value="none">None</MenuItem>
                                        <MenuItem value="normal">Stacked</MenuItem>
                                        <MenuItem value="percent">100% Stacked</MenuItem>
                                    </Dropdown>
                                </WithLabel>
                            </PropertySection>
                            {
                                element.chartModel.series.filter(s => s.type == "bar" || s.type == "column").length > 0 && (
                                    <PropertySection>
                                        <WithLabel label="Column/Bar Padding"
                                            toolTip="Controls the padding between individual columns of data."
                                        >
                                            <NumericStepper value={Math.round((element.chartModel.columnPadding ?? 0.1) * 100)}
                                                min={0}
                                                max={50}
                                                step={5}
                                                onChange={value => {
                                                    element.chartModel.columnPadding = value / 100;
                                                    element.markStylesAsDirty();
                                                    element.canvas.updateCanvasModel(false);
                                                }}
                                            />
                                        </WithLabel>
                                        <WithLabel label="Group Padding"
                                            toolTip="Controls the padding between grouped columns of data. Not applicable when using stacked charts."
                                        >
                                            <NumericStepper value={Math.round((element.chartModel.groupPadding ?? 0.1) * 100)}
                                                min={0}
                                                max={50}
                                                step={5}
                                                onChange={value => {
                                                    element.chartModel.groupPadding = value / 100;
                                                    element.markStylesAsDirty();
                                                    element.canvas.updateCanvasModel(false);
                                                }}
                                            />
                                        </WithLabel>
                                    </PropertySection>
                                )
                            }
                        </PropertyPanelContainer>
                        // </Box>
                    )}
                </PopupContent>
            </Popup>
        );
    }

    render() {
        const { element } = this.props;

        if (!element.chartModel) {
            return null;
        }

        const xAxis = element.chartModel.xAxis;
        const yAxis = element.chartModel.yAxis;
        const yAxis2 = element.chartModel.yAxis2 && element.chartModel.yAxis2.visible ? element.chartModel.yAxis2 : null;

        xAxis.labels = xAxis.labels || { enabled: false };

        let seriesType;
        let values = _.uniq(element.chartModel.series.map(series => series.type));
        if (values.length == 1) {
            seriesType = values[0];
        } else {
            seriesType = "mixed";
        }

        // Check if all series are on the same right axis
        const allSeriesSetToRightAxis = element.chartModel.series.every(({ yAxis }) => yAxis === 1);

        return (
            <>
                <PropertySection>
                    {/*<WithLabel label="Chart Type">*/}
                    {/*    <ChartTypePicker value={seriesType} element={element} />*/}
                    {/*</WithLabel>*/}
                    <WithLabel label="Chart Styles">
                        {this.renderChartStylesMenu()}
                    </WithLabel>

                </PropertySection>
                <PropertySection>
                    <PropertySectionHeader label={element.chartModel.series.some(series => series.type == "bar") ? "Y-Axis (left)" : "X-Axis"}>
                        {this.renderXAxisMenu(xAxis)}
                    </PropertySectionHeader>

                    <WithLabel label="Title">
                        <ToggleButtons value={xAxis.axisTitle ?? false}
                            onChange={value => {
                                xAxis.axisTitle = value;
                                element.canvas.updateCanvasModel(true);
                            }}
                        >
                            <ToggleButton small
                                value={false}
                            >
                                None
                            </ToggleButton>
                            <ToggleButton small
                                value={true}
                            >
                                On axis
                            </ToggleButton>
                        </ToggleButtons>
                    </WithLabel>

                    <WithLabel label="Date Format">
                        <Dropdown value={xAxis.dateFormatting ?? "none"}
                            onChange={value => {
                                xAxis.dateFormatting = value;
                                element.canvas.updateCanvasModel(true);
                            }}
                        >
                            {CHART_DATE_FORMATS.map(format => (
                                <MenuItem key={format}
                                    value={format}
                                >
                                    {format == "none" ? "None" : formatter.formatValue(Date.now(), FormatType.DATE, { dateFormat: format })}
                                </MenuItem>
                            ))}
                        </Dropdown>
                    </WithLabel>
                </PropertySection>
                {!allSeriesSetToRightAxis && (
                    <PropertySection>
                        <PropertySectionHeader label={element.chartModel.series.some(series => series.type == "bar") ? "X-Axis" : "Y-Axis (left)"}>
                            {this.renderYAxisMenu(yAxis)}
                        </PropertySectionHeader>

                        <WithLabel label="Title">
                            <ToggleButtons value={yAxis.axisTitle}
                                onChange={value => {
                                    yAxis.axisTitle = value;
                                    element.canvas.updateCanvasModel(true);
                                }}
                            >
                                <ToggleButton small
                                    value="none"
                                >
                                    None
                                </ToggleButton>
                                <ToggleButton small
                                    value="edge"
                                >
                                    On Axis
                                </ToggleButton>
                                {!element.isHorizontalChart && (
                                    <ToggleButton small
                                        value="top"
                                    >
                                        Top
                                    </ToggleButton>
                                )}

                            </ToggleButtons>
                        </WithLabel>
                        <WithLabel label="Value Format"
                            toolTip={(
                                <>
                                    <p>Sets how to format values in the y-axis.</p>
                                    <p>This setting applies to y-axis labels and any visible data labels or annotations within a series.</p>
                                </>
                            )}
                        >
                            <FormattingDropdown format={yAxis.format}
                                formatOptions={yAxis.formatOptions}
                                allowedFormats={[FormatType.NUMBER, FormatType.CURRENCY, FormatType.PERCENT]}
                                onChangeFormat={value => {
                                    yAxis.format = value;
                                    element.canvas.updateCanvasModel(false);
                                }}
                                onChangeFormatOptions={value => {
                                    if (!yAxis.format) {
                                        yAxis.format = "number";
                                    }

                                    yAxis.formatOptions = value;
                                    element.canvas.updateCanvasModel(false);
                                }}
                            />
                        </WithLabel>
                    </PropertySection>
                )}

                {yAxis2 && (
                    <PropertySection>
                        <PropertySectionHeader label={element.chartModel.series.some(series => series.type == "bar") ? "X-Axis 2 (Right)" : "Y-Axis (right)"}>
                            {this.renderYAxisMenu(yAxis2)}
                        </PropertySectionHeader>

                        <WithLabel label="Title">
                            <ToggleButtons value={yAxis2.axisTitle}
                                onChange={value => {
                                    yAxis2.axisTitle = value;
                                    element.canvas.updateCanvasModel(true);
                                }}
                            >
                                <ToggleButton small
                                    value="none"
                                >
                                    None
                                </ToggleButton>
                                <ToggleButton small
                                    value="edge"
                                >
                                    On Axis
                                </ToggleButton>
                                {!element.isHorizontalChart && (
                                    <ToggleButton small
                                        value="top"
                                    >
                                        Top
                                    </ToggleButton>
                                )}

                            </ToggleButtons>
                        </WithLabel>
                        <WithLabel label="Value Format"
                            toolTip={(
                                <>
                                    <p>Sets how to format values in the y-axis.</p>
                                    <p>This setting applies to y-axis labels and any visible data labels or annotations within a series.</p>
                                </>
                            )}
                        >
                            <FormattingDropdown format={yAxis2.format}
                                formatOptions={yAxis2.formatOptions}
                                allowedFormats={[FormatType.NUMBER, FormatType.CURRENCY, FormatType.PERCENT]}
                                onChangeFormat={value => {
                                    yAxis2.format = value;
                                    element.canvas.updateCanvasModel(false);
                                }}
                                onChangeFormatOptions={value => {
                                    yAxis2.formatOptions = value;
                                    element.canvas.updateCanvasModel(false);
                                }}
                            />
                        </WithLabel>
                    </PropertySection>
                )}

                {/*
                    If the chart is from a waterfall chart, we don't want to show the legend options
                    because the chart data name cannot be changed, and it will always be "data"
                 */}
                {!element.chartModel.series.some(s => s.type === "waterfall") && (
                    <PropertySection>
                        <WithLabel label="Legend"
                            toolTip={(
                                <>
                                    <p>Controls whether and where to show the chart legend.</p>
                                    <p>
                                        The
                                        <strong>With series</strong>
                                        {" "}
                                        option will attempt to position the legend proximate with the end of each series value.
                                    </p>
                                </>
                            )}
                        >
                            {this.renderLegendMenu()}
                        </WithLabel>
                    </PropertySection>
                )}
                <FlexSpacer />
                {
                    element.findClosestOfType("CollectionElement").itemElements.filter(e => e?.model?.componentType == "Chart").length > 1 && (
                        <PropertySection>
                            <Button small
                                onClick={this.handleCopyStyles}
                            >
                                Apply Styles to All Charts
                            </Button>
                        </PropertySection>
                    )
                }
            </>
        );
    }
}

export class ChartSelection extends BaseElementSelection {
    get showSelectionBorder() {
        // only show selection border if the element is on the authoring canvas
        // otherwise, the selection is shown on the LayoutContainerItem
        return this.props.element.isOnAuthoringCanvas;
    }

    componentDidMount() {
        PresentationEditorController.showElementPanel(this.props.element);
    }

    shouldComponentUpdate(nextProps, nextState) {
        const { element } = this.props;

        if (!element.chart) {
            // Prevent refresh when there's no chart rendered
            return false;
        }

        return true;
    }

    renderCustomChildren() {
        const { element } = this.props;
        if (!element.chart) {
            return null;
        }

        return (
            <>
                {element.chart.axes.filter(({ visible }) => visible).map((axis, index) => (
                    <AxisWidgets key={index}
                        {...this.props}
                        axis={axis}
                        axisIndex={index}
                    />
                ))}
                {element.chart.series.map((series, index) => (
                    <SeriesWidgets key={index}
                        {...this.props}
                        series={series}
                    />
                ))}
            </>
        );
    }
}
