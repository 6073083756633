import { AssetType, DecorationStyle } from "../../../../../../common/constants";
import React, { Component } from "react";
import { ColorPicker } from "./ColorPicker";

export class CollectionColorPicker extends Component {
    render() {
        let { element, size = 25, allowColorOnColor, ignoreMediaElement = false, backgroundColor, onChange, onChangeDecorationStyle, showPrimary, showChartColors, showThemeColors, showBackgroundColors = true, showNone, propertyName = "collectionColor", showDecorationStyles, position } = this.props;

        const canvas = element.canvas;
        let collectionColor;
        if (element.isInstanceOf("CollectionElement")) {
            collectionColor = element.getChildValues(propertyName) ?? element.model[propertyName];
        } else {
            collectionColor = element.model[propertyName];
        }
        const slideAccentColor = canvas.getSlideColor();

        if (!ignoreMediaElement && element.itemElements?.some(el => el.mediaElement != null && el.mediaElement.assetType == AssetType.ICON)) {
            // check for any media background color to use as the background color
            let hasShapeFrame = element.itemElements.some(el => el.mediaElement?.hasShapeFrame);
            let hasDeviceFrame = element.itemElements.some(el => el.mediaElement?.hasDeviceFrame);
            let mediaBackgroundColors = element.itemElements.map(el => el.mediaElement?.getBackgroundColor());

            if (hasDeviceFrame || mediaBackgroundColors.some(color => element.palette.doesBackgroundColorAllowColorOnTop(color))) {
                // force the background color to white so the colorpicker will allow coloring of icons
                backgroundColor = element.palette.getColor("white");
            }

            if (showDecorationStyles) {
                // don't show DecorationStyles for device frames
                showDecorationStyles = hasShapeFrame;
            }
        }

        return (
            <ColorPicker value={collectionColor ?? slideAccentColor}
                allowColorOnColor={allowColorOnColor}
                backgroundColor={backgroundColor} canvas={canvas}
                showPrimary={showPrimary}
                showColorful showBackgroundColors={showBackgroundColors}
                showThemeColors={showThemeColors} showChartColors={showChartColors}
                showNone={showNone} showDecorationStyles={showDecorationStyles}
                position={position} size={size}
                onChange={color => {
                    if (onChange) {
                        onChange(color);
                    } else {
                        if (element.itemElements) {
                            // reset any overridden item colors
                            for (let item of element.itemElements) {
                                item.model.color = null;
                            }
                        }
                        element.updateModel({ [propertyName]: color });
                        if (this.props.onAfterChange) {
                            this.props.onAfterChange(color);
                        }
                    }
                }}
                onChangeDecorationStyle={decorationStyle => {
                    if (onChangeDecorationStyle) {
                        onChangeDecorationStyle(decorationStyle);
                    } else {
                        if (element.itemElements) {
                            for (let item of element.itemElements) {
                                item.model.decorationStyle = null;
                            }
                            element.updateModel({ decorationStyle });
                            if (this.props.onAfterChangeDecorationStyles) {
                                this.props.onAfterChangeDecorationStyles(decorationStyle);
                            }
                        }
                    }
                }}
            />
        );
    }
}
