import React, { Component } from "react";
import { PropertyPanelContainer, PropertySection } from "../../../../EditorComponents/PropertyPanel";
import { WithLabel } from "../../../../Components/WithLabel";
import { ToggleSwitch } from "../../../../Components/ToggleSwitch";
import { MenuItem } from "@material-ui/core";
import { Dropdown } from "../../../../Components/Dropdown";
import { Slider } from "../../../../Components/Slider";
import { ImagePopup } from "../../../../Components/ImagePopup";
import { AuthoringElementType, CalloutType } from "../../../../../common/constants";
import { ControlBar } from "../ElementControlBars/Components/ControlBar";
import { ImageOption } from "../../../../Components/ImageOptionList";
import { StaticImage } from "../../../../Components/StaticImage";
import { Icon } from "../../../../Components/Icon";
import { ContentItemControlBar } from "./ContentItemUI";
import { Button } from "../../../../Components/Button";
import { AuthoringCanvasSelection } from "../ElementSelections/AuthoringCanvasSelection";
import { CALLOUTS } from "../../../../editor/ElementEditor/CalloutsPane";
import { CollectionColorPicker } from "../EditorComponents/ColorPickers/CollectionColorPicker";

export class XYGraphPropertyPanel extends Component {
    render() {
        const { element } = this.props;

        let selectedStyle;
        if (element.model.showAxis && !element.model.showQuadrants && element.model.axisPosition == "left") {
            selectedStyle = "axis";
        } else if (element.model.showAxis && element.model.showQuadrants) {
            selectedStyle = "axis-with-quadrants";
        } else if (element.model.showAxis && element.model.axisPosition == "center") {
            selectedStyle = "center-axis";
        } else {
            selectedStyle = "no-axis";
        }

        const graphCanvas = element.graphCanvas;

        const allowedCalloutTypes = graphCanvas.getAllowedCalloutTypes().map(type => CALLOUTS.find(c => c.value === type));

        return (
            <PropertyPanelContainer>
                <PropertySection>
                    <WithLabel label="Item Style">
                        <ImagePopup value={graphCanvas.getChildValues("calloutType")}
                            onChange={value => graphCanvas.updateChildModels({ calloutType: value })}
                            size={70}
                            scale={1}
                            previewScale={1.5}
                        >
                            {allowedCalloutTypes.map(callout => (
                                <ImageOption key={callout.value} value={callout.value} label={callout.label}>
                                    <StaticImage src={callout.image} />
                                </ImageOption>
                            ))}
                        </ImagePopup>
                    </WithLabel>
                    <WithLabel label="Item Color">
                        <CollectionColorPicker element={graphCanvas} showDecorationStyles/>
                    </WithLabel>
                </PropertySection>
                <PropertySection>
                    <WithLabel label="Axis Style">
                        <Dropdown value={selectedStyle}
                            onChange={value => {
                                switch (value) {
                                    case "axis":
                                        element.model.showAxis = true;
                                        element.model.showQuadrants = false;
                                        element.model.axisPosition = "left";
                                        break;
                                    case "axis-with-quadrants":
                                        element.model.showAxis = true;
                                        element.model.showQuadrants = true;
                                        element.model.axisPosition = "left";
                                        break;
                                    case "center-axis":
                                        element.model.showAxis = true;
                                        element.model.showQuadrants = false;
                                        element.model.axisPosition = "center";
                                        break;
                                    case "no-axis":
                                        element.model.showAxis = false;
                                        element.model.showQuadrants = false;
                                        break;
                                }
                                element.canvas.updateCanvasModel(true);
                            }}>
                            <MenuItem value="axis">Left Axis</MenuItem>
                            <MenuItem value="axis-with-quadrants">Quadrant Axis</MenuItem>
                            <MenuItem value="center-axis">Center Axis</MenuItem>
                            <MenuItem value="no-axis">No Axis</MenuItem>
                        </Dropdown>
                    </WithLabel>
                </PropertySection>
                <PropertySection>
                    <WithLabel label="Show Axis Labels">
                        <ToggleSwitch value={element.model.showAxisLabels}
                            disabled={!element.model.showAxis}
                            onChange={value => element.updateModel({ showAxisLabels: value })} />
                    </WithLabel>
                    <WithLabel label="Show Arrowheads">
                        <ToggleSwitch value={element.model.showArrowHeads}
                            disabled={!element.model.showAxis}
                            onChange={value => element.updateModel({ showArrowHeads: value })} />
                    </WithLabel>
                    {selectedStyle === "axis-with-quadrants" && (
                        <WithLabel label="Show Quadrant Labels">
                            <ToggleSwitch value={element.model.showQuadrantLabels}
                                onChange={value => {
                                    element.updateModel({
                                        showQuadrantLabels: value,
                                        showAxis: true,
                                        showQuadrants: true,
                                        axisPosition: "left"
                                    });
                                }} />
                        </WithLabel>
                    )}
                </PropertySection>
                <PropertySection>
                    <WithLabel label="Show Grid">
                        <ToggleSwitch value={element.model.showGrid}
                            onChange={value => element.updateModel({ showGrid: value })} />
                    </WithLabel>
                    {/*{element.model.showGrid && (*/}
                    {/*    <WithLabel label="Snap to Grid">*/}
                    {/*        <ToggleSwitch value={element.model.snapToGrid}*/}
                    {/*            onChange={value => element.updateModel({ snapToGrid: value })} />*/}
                    {/*    </WithLabel>*/}
                    {/*)}*/}
                    {element.model.showGrid && (
                        <WithLabel label="Grid Size">
                            <Slider value={element.model.gridSize}
                                min={16}
                                max={50}
                                step={2}
                                disabled={!element.model.showGrid}
                                onChange={value => element.refreshModel({ gridSize: value })}
                                onCommit={() => element.saveModel()}
                            />
                        </WithLabel>
                    )}
                </PropertySection>
            </PropertyPanelContainer>
        );
    }
}

export class XYGraphSelection extends AuthoringCanvasSelection {
    get allowAreaSelect() {
        return false;
    }

    get authoringLayerProps() {
        return {
            minimizedControlBar: true
        };
    }
}

export class XYGraphControlBar extends Component {
    handleAddItem = () => {
        const { element } = this.props;

        const graphCanvas = element.graphCanvas;
        let calloutType = graphCanvas.getChildValues("calloutType");
        if (calloutType == "mixed") {
            calloutType = graphCanvas.getAllowedCalloutTypes()[0];
        } else if (!calloutType) {
            calloutType = CalloutType.FLEX_CIRCLE;
        }

        const calloutModel = graphCanvas.getDefaultModel(AuthoringElementType.CALLOUT, { calloutType });
        graphCanvas.addItem({
            ...calloutModel,
            x: Math.round(Math.random() * (element.bounds.width - 100)), y: Math.round(Math.random() * (element.bounds.height - 100))
        });
        graphCanvas.saveModel();
    }

    render() {
        return (
            <ControlBar>
                <Button blue onClick={this.handleAddItem}>
                    <Icon>add_circle</Icon>
                    Add Item
                </Button>
            </ControlBar>
        );
    }
}
