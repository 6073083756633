import React, { Component } from "react";
import { createPortal } from "react-dom";
import _ from "lodash";

import { ds } from "js/core/models/dataService";
import { Button } from "js/Components/Button";
import { Icon } from "js/Components/Icon";
import { ContentItemSelection } from "../ElementSelections/ContentItemSelection";
import { ControlBar, ControlBarGroup } from "../ElementControlBars/Components/ControlBar";
import { VideoRecorder } from "../../elements/VideoOverlay/VideoOverlayRecorder";

export class VideoOverlaySelection extends ContentItemSelection {
    get canResize() {
        return false;
    }

    get canDelete() {
        return true;
    }

    get canDrag() {
        return true;
    }

    get dragPositionProps() {
        const { element, canvas } = this.props;
        let dragStartProps;

        return {
            ...super.dragPositionProps,
            onDragStart: async () => {
                const parentSize = element.parentElement.calculatedProps.size.clone();
                dragStartProps = {
                    x: element.model.x,
                    y: element.model.y,
                    parentSize,
                    elementSize: element.bounds
                };
            },
            onDrag: async ({ dragOffset }) => {
                const { x, y, parentSize, elementSize } = dragStartProps;

                // Calculate the maximum allowed position considering element size
                const maxX = 1 - (elementSize.width / parentSize.width);
                const maxY = 1 - (elementSize.height / parentSize.height);

                const horizontalShift = dragOffset.x / parentSize.width;
                const verticalShift = dragOffset.y / parentSize.height;

                // Clamp the position between 0 and max values
                element.model.x = Math.clamp(x + horizontalShift, 0, maxX);
                element.model.y = Math.clamp(y + verticalShift, 0, maxY);
                element.model.hasBeenDragged = true;

                if (element.model.snapOptions) {
                    delete element.model.snapOptions;
                }

                if (element.parentElement.canRefreshElement) {
                    element.parentElement.refreshElement();
                } else {
                    await canvas.refreshCanvas();
                }
            }
        };
    }

    handleDeleteElement = async () => {
        const { element } = this.props;

        element.canvas.getCanvasElement().model.elements.videoOverlay = null;
        await element.canvas.getCanvasElement().saveModel();
    };
}

export class VideoOverlayControlBar extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showRecorder: false,
            currentRecordingDuration: null
        };

        this.recorderContainerElement = null;
    }

    componentDidMount() {
        this.recorderContainerElement = document.createElement("div");
        document.body.appendChild(this.recorderContainerElement);

        this.getCurrentRecordingDuration();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        // Check if new recording is added and update duration
        const prevVideoOverlay = prevProps.canvas.model.elements.videoOverlay;
        const currentVideoOverlay = this.props.canvas.model.elements.videoOverlay;
        if (prevVideoOverlay?.videoAssetId !== currentVideoOverlay?.videoAssetId) {
            this.getCurrentRecordingDuration();
            return;
        }

        // Check if recorder is opened or closed and update duration
        if (prevState.showRecorder !== this.state.showRecorder) {
            this.getCurrentRecordingDuration();
            return;
        }
    }

    componentWillUnmount() {
        this.recorderContainerElement.remove();
    }

    getCurrentRecordingDuration = () => {
        const videoOverlay = this.props.canvas.model.elements.videoOverlay;
        if (videoOverlay?.videoAssetId) {
            const currentRecording = ds.assets.get(videoOverlay.videoAssetId);
            const currentRecordingDuration = currentRecording.get("duration");

            this.setState({ currentRecordingDuration: this.formatDuration(currentRecordingDuration) });
        } else {
            this.setState({ currentRecordingDuration: null });
        }
    }

    handleOpenRecorder = () => {
        this.setState({ showRecorder: true });
    }

    handleCloseRecorder = () => {
        this.setState({ showRecorder: false });
    }

    formatDuration(durationSeconds) {
        let seconds = durationSeconds;
        const hours = Math.floor(seconds / (60 * 60));
        seconds -= hours * 60 * 60;
        const minutes = Math.floor(seconds / 60);
        seconds -= minutes * 60;
        return `${_.padStart(minutes, 2, "0")}:${_.padStart(seconds.toFixed(3), 6, "0")}`;
    }

    render() {
        const { showRecorder, currentRecordingDuration } = this.state;

        return (<>
            <ControlBar>
                {!showRecorder && (
                    <ControlBarGroup>
                        <Button blue small onClick={this.handleOpenRecorder}>
                            <Icon>video_camera_front</Icon>
                            Add Recording
                        </Button>
                    </ControlBarGroup>
                )}
                {!showRecorder && !!currentRecordingDuration && (
                    <ControlBarGroup color="#333">
                        <span style={{ padding: "0 10px" }}>
                            {currentRecordingDuration}
                        </span>
                    </ControlBarGroup>
                )}

                {showRecorder && (
                    createPortal(
                        <VideoRecorder
                            {...this.props}
                            onClose={this.handleCloseRecorder}
                        />,
                        this.recorderContainerElement
                    )
                )}
            </ControlBar>
        </>);
    }
}
