import { v4 as uuid } from "uuid";

import { AssetType, DecorationStyle, PositionType, TextStyleType } from "common/constants";
import * as geom from "js/core/utilities/geom";
import { _, tinycolor } from "js/vendor";
import { getStaticUrl } from "js/config";
import { blendColors } from "js/core/utilities/utilities";

import { GridPlaceholderDefaultOverlay } from "../../Editor/ElementPropertyPanels/GridContainer/Infographics/GridPlaceholderUI";
import {
    TextAndImagePropertyPanel,
    addTextToLayoutContainerItem
} from "../../Editor/ElementPropertyPanels/GridContainer/Infographics/TextAndImageUI";
import { BaseElement } from "../base/BaseElement";
import { ContentElement } from "../base/ContentElement";
import { TextElement } from "../base/Text/TextElement";
import { TextFrame } from "../base/Text/TextFrame";
import { LayoutEngineType } from "./LayoutContainers/GridLayoutContainer";
import { DeviceFrames } from "../DeviceFrames";

export class TextAndImage extends BaseElement {
    get name() {
        if (this.showImage && this.showText) {
            return `${this.content?.assetType} + Text`;
        } else if (this.showImage) {
            return this.content?.assetType;
        } else if (this.showText) {
            return "Text";
        } else {
            return "Empty";
        }
    }

    getElementPropertyPanel() {
        return TextAndImagePropertyPanel;
    }

    get selectionPadding() {
        return 0;
    }

    get selectionBounds() {
        // special casing this to always return the parent containers bounds because when there is a frame, the selection bounds should not be the same as the content bounds
        return this.parentElement.selectionBounds;
    }

    get minWidth() {
        if (this.showText) {
            return 20;
        } else {
            return 0;
        }
    }

    get minHeight() {
        if (this.showText) {
            return 20;
        } else {
            return 0;
        }
    }

    get canDropImage() {
        return true;
    }

    get canPasteImage() {
        return true;
    }

    get _canSelect() {
        return false;
    }

    // get doubleClickToSelect() {
    //     if (this._showDefaultOverlay) {
    //         return false;
    //     } else {
    //         return super.doubleClickToSelect;
    //     }
    // }

    get _showDefaultOverlay() {
        return !this.showImage && !this.showText;
    }

    getElementDefaultOverlay() {
        return GridPlaceholderDefaultOverlay;
    }

    get frameType() {
        if (this.model.frameType === "theme") {
            return this.canvas.getTheme().get("styleShape") ?? "none";
        }
        return this.model.frameType ?? "none";
    }

    get showImage() {
        return this.model.content_value != null;
    }

    get allowText() {
        return this.options.allowText ?? true;
    }

    get showText() {
        if (this.allowText) {
            return this.model.blocks?.length > 0 || this.model.text?.blocks?.length > 0;
        } else {
            return false;
        }
    }

    get textPosition() {
        return this.model.textPosition ?? PositionType.CENTER;
    }

    get listStyle() {
        return this.model.listStyle || "bullets";
    }

    get showFrame() {
        return false;
    }

    get showShadow() {
        return false;
    }

    get showCaption() {
        return this.showImage && this.model.caption != null;
    }

    get showAttribution() {
        return this.showImage && this.model.attribution != null;
    }

    get contentSize() {
        let contentSize;
        if (this.showImage) {
            contentSize = this.content.getContentSize();
        } else {
            contentSize = new geom.Size(100, 100);
        }
        return contentSize;
    }

    get allowBackgroundColor() {
        if (this.model.frameType && this.model.frameType != "none") {
            return true;
        }

        return !this.content ||
            this.content.assetType === AssetType.ICON ||
            this.content.assetElement?.isScaledBelowFill ||
            this.content.hasSolidBackground ||
            this.content.assetElement?.hasAlpha ||
            this.showCaption;
    }

    get imageBackgroundColor() {
        if (this.content?.hasSolidBackground) {
            return tinycolor(this.content.asset.get("imageBackgroundColor")).toRgbString();
        }

        return null;
    }

    get assetId() {
        const asset = this.showImage ? this.content?.asset : null;
        return asset?.id;
    }

    get frameURL() {
        return getStaticUrl("/images/frames/" + this.frameDef.file);
    }

    get frameDef() {
        return DeviceFrames.findById(this.frameType);
    }

    resetUserColors() {
        this.model.cellColor = null;
    }

    _loadStyles(styles) {
        // if (DeviceFrames.findById(this.frameType).category != "Shape") {
        if (this.frameType && this.frameType != "none") {
            // create frame styles for the frame (used by BaseElement to create the frame)
            // NOTE: this is different from using a FramedMediaElement and supports non-contentElement framing (like a TextFrame)
            styles.frame = {
                frameType: this.frameType,
                scale: this.model.frameScale ?? 1,
            };

            let decorationShape = "rect";

            if (this.frameDef.category == "Shape") {
                decorationShape = this.frameType;
            }

            // create a decoration for the backdrop behind the element within the frame
            styles.decoration = {
                type: "frame",
                shape: decorationShape,
            };
        }

        styles.overflow = "hidden"; // this is necessary to clip the content when adjusting scale and from ken burns animations
    }

    get decorationStyle() {
        if (this.model.decorationStyle && this.frameType && DeviceFrames.findById(this.frameType).category == "Shape") {
            // for shape frames, use the decoration style from the model if it exists
            return this.model.decorationStyle;
        } else {
            // for all other frames (ie image frames), use the filled decoration style
            return DecorationStyle.FILLED;
        }
    }

    get mediaElement() {
        return this.content;
    }

    _build() {
        const gridLayoutContainer = this.findClosestOfType("GridLayoutContainer");

        if (this.showImage) {
            this.content = this.addElement("contentElement", () => ContentElement, {
                allowImageScaling: gridLayoutContainer.layoutEngineType != LayoutEngineType.PRESERVE_ASPECT,
                allowBackdrop: false,
                preserveAspectRatioForUnframed: false,
                // canSelect: true,
                requireParentSelection: true,
                parentCellColor: this.options.parentCellColor,
                overflow: "hidden",
                allowColorOnColor: true,
            });
            this.content.layer = -1;
        } else {
            this.content = null;
        }

        if (this.showText) {
            let backgroundElement;
            if (this.showImage) {
                backgroundElement = this.content;
            } else if (this.frameType != "none") {
                backgroundElement = this.decoration;
            } else {
                // the cell background is in the parent LayoutContainerItem
                backgroundElement = this.parentElement.decoration;
            }

            let hasIconAndText = this.showImage && this.showText && this.content.assetType === AssetType.ICON;

            let blockDefaults;
            if (this.getRootElement().isInstanceOf("BottomTray")) {
                // this is a special case to handle legacy bottom tray titles which overrode the blockDefaults for title blocks
                blockDefaults = {
                    [TextStyleType.TITLE]: {
                        evenBreak: true
                    }
                };
            }

            this.textFrame = this.addElement("textFrame", () => TextFrame, {
                // autoHeight: true,
                autoWidth: this.model.textWidth == null,
                scaleTextToFit: true,
                selectionPadding: 0,
                canDragPosition: this.showImage,
                canDragResize: this.showImage,
                canDelete: true,
                fillFrame: !this.showImage || hasIconAndText,
                backgroundElement,
                textOptions: {
                    syncFontSizeWithSiblings: gridLayoutContainer.itemCount > 1,
                    syncFontSizeWithSiblingsDefaultValue: this.model.text.syncFontSizeWithSiblings ?? false,
                    canDeleteLastBlock: true,
                    allowVerticalAlignment: !this.showImage,
                    hideControlBarWhenTextSelected: this.options.hideControlBarWhenTextSelected,
                    blockDefaults
                },
            });
        }

        if (this.showAttribution) {
            this.attribution = this.addElement("attribution", () => PhotoCollageAttribution, {
                autoWidth: true,
                autoHeight: true,
                placeholder: "Type attribution",
                canEdit: true,
                canSelect: true
            });
        }
    }

    _calcProps(props, options) {
        const { size } = props;

        let contentBounds = new geom.Rect(0, 0, size);

        let hasIconAndText = this.showImage && this.showText && this.content.assetType === AssetType.ICON;

        if (hasIconAndText) {
            let padding = this.model.backdropPadding;
            switch (this.model.iconPosition ?? PositionType.LEFT) {
                case PositionType.LEFT: {
                    let iconWidth = Math.min(size.height, size.width / 3);
                    const contentProps = this.content.calcProps(new geom.Size(iconWidth, size.height));
                    contentProps.bounds = new geom.Rect(0, 0, contentProps.size);

                    const textFrameProps = this.textFrame.calcProps(new geom.Size(size.width - iconWidth, size.height), {
                        ...options,
                        backdropPadding: { left: 0, top: padding, right: padding, bottom: padding }
                    });
                    textFrameProps.bounds = new geom.Rect(contentProps.size.width, 0, textFrameProps.size);

                    break;
                }
                case PositionType.RIGHT: {
                    let iconWidth = Math.min(size.height, size.width / 3);
                    const contentProps = this.content.calcProps(new geom.Size(iconWidth, size.height));
                    contentProps.bounds = new geom.Rect(size.width - iconWidth, 0, contentProps.size);

                    const textFrameProps = this.textFrame.calcProps(new geom.Size(size.width - iconWidth, size.height), {
                        ...options,
                        backdropPadding: { left: padding, top: padding, right: 0, bottom: padding }
                    });
                    textFrameProps.bounds = new geom.Rect(0, 0, textFrameProps.size);
                    break;
                }
                case PositionType.BACKGROUND: {
                    let iconWidth = Math.min(size.height, size.width / 3);
                    const contentProps = this.content.calcProps(size);
                    contentProps.bounds = new geom.Rect(0, 0, contentProps.size);

                    const textFrameProps = this.textFrame.calcProps(size, options);
                    textFrameProps.bounds = new geom.Rect(0, 0, textFrameProps.size);
                    break;
                }
            }
        } else {
            // calcProps on image
            if (this.showImage) {
                const contentProps = this.content.calcProps(contentBounds.size, { ...options });
                contentProps.bounds = contentBounds;
            }

            if (this.showAttribution) {
                const attributionProps = this.attribution.calcProps(contentBounds.size);
                attributionProps.bounds = new geom.Rect(contentBounds.right - attributionProps.size.width, contentBounds.bottom - attributionProps.size.height, attributionProps.size);
            }

            // calcProps on text
            if (this.showText) {
                const textFrameProps = this.textFrame.calcProps(contentBounds.size, options);
                textFrameProps.bounds = new geom.Rect(0, 0, textFrameProps.size);
            }
        }

        if (this.showText && options.autoSizeToTextHeight) {
            return { size: new geom.Size(size.width, this.textFrame.calculatedProps.size.height) };
        }
        return { size };
    }

    _applyColors() {
        // if (this.showImage && this.model.content_type === "image") {
        // if (this.showImage) {
        //     // force the background color to be treated as black when there is an image
        //     this.colorSet.backgroundColor = this.palette.getColor("black");
        // }

        if (this.decoration) {
            this.decoration.colorSet.decorationColor = this.palette.getColor(this.model.frameColor ?? "theme", this.getBackgroundColor(), { allowColorOnColor: true, allowForeColorToMatchBackgroundColor: true });
            if (this.decorationStyle == DecorationStyle.FILLED) {
                this.colorSet.backgroundColor = this.decoration.colorSet.decorationColor;
            } else if (this.decorationStyle == DecorationStyle.MUTED || this.decorationStyle == DecorationStyle.FILL_AND_STROKE) {
                this.colorSet.backgroundColor = blendColors(this.decoration.colorSet.decorationColor, this.getBackgroundColor(), 0.5);
            }
        }
    }

    get animationElementName() {
        return `Picture #${this.itemIndex + 1}`;
    }

    getAnimations() {
        return [];
    }

    _getCustomBlocksDropTargets(sourceElement, sourceBlocks) {
        if (this.showText) {
            return [];
        }

        const canvasBounds = this.canvasBounds;
        return [{
            canvasBounds: canvasBounds.deflate({ top: canvasBounds.height / 2 - 18, bottom: canvasBounds.height / 2 - 18, left: 40, right: 40 }),
            labelText: "Add Text",
            onDrop: blocks => addTextToLayoutContainerItem({ element: this.parentElement, text: { blocks: _.cloneDeep(blocks.map(({ model }) => ({ ...model, id: uuid() }))) } })
        }];
    }

    _exportToSharedModel() {
        const assets = this.content?._exportToSharedModel()?.assets || [];
        const textContent = this.textFrame?._exportToSharedModel()?.textContent || [];

        return { assets, textContent };
    }

    _migrate_10_02() {
        if (this.model.text) {
            this.model.text.syncFontSizeWithSiblings = this.model.text.syncFontSizeWithSiblings ?? true;
            if (this.model.text.blocks) {
                for (const block of this.model.text.blocks) {
                    if (block.type === "media" && block.color == "auto" && block.elementModel) {
                        block.elementModel.color = this.canvas.getSlideColor();
                    } else if (block.type == "text") {
                        if (this.canvas.getSlideColor().contains("background")) {
                            block.accentColor = "primary";
                        } else {
                            block.accentColor = this.canvas.getSlideColor();
                        }
                    }
                }
            }
        }
    }
}

class PhotoCollageCaption extends TextElement {
}

class PhotoCollageAttribution extends TextElement {
    _applyColors() {
        this.decoration.colorSet.fillColor = this.palette.getColor("white");
        super._applyColors();
    }
}

export const elements = {
    TextAndImage
};
