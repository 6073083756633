import { AuthoringShapeType, BlockStructureType, CalloutType, DirectionType, HorizontalAlignType, TextStyleType, VerticalAlignType } from "../../../../../../common/constants";
import * as geom from "../../../../../core/utilities/geom";

import { TextElement } from "../../base/Text/TextElement";
import { SVGCircleElement } from "../../base/SVGElement";
import { CalloutElement } from "./CalloutElement";
import { MarkerCallout } from "./MarkerCallout";
import { AuthoringShapeElement } from "../authoring/AuthoringShape";
import { ShapeCallout } from "./ShapeCallout";

export class FlexCircleCallout extends MarkerCallout {
    static get schema() {
        return {
            markerSize: 100,
            textDirection: "auto",
        };
    }

    get authoringElementType() {
        return CalloutType.FLEX_CIRCLE;
    }

    get selectionPadding() {
        return { left: 0, top: 0, right: 0, bottom: 0 };
    }

    get minWidth() {
        return 10;
    }

    get minMarkerSize() {
        return 10;
    }

    get maxMarkerSize() {
        return 400;
    }

    get isTextAndShape() {
        return true;
    }

    get referencePoint() {
        return this.marker.offsetBounds.center;
    }

    get referencePointAnchor() {
        switch (this.textDirection) {
            case DirectionType.TOP:
                return geom.AnchorType.BOTTOM;
            case DirectionType.BOTTOM:
                return geom.AnchorType.TOP;
            case DirectionType.LEFT:
                return geom.AnchorType.RIGHT;
            case DirectionType.RIGHT:
            case DirectionType.AUTO:
                return geom.AnchorType.LEFT;
        }
    }

    get textDirection() {
        return this.model.textDirection ?? "right";
    }

    get resizeDirections() {
        return {
            left: !this.calculatedProps?.textIsInCircle, right: !this.calculatedProps?.textIsInCircle,
            top: false, bottom: false
        };
    }

    get fitToContents() {
        return {
            height: true,
            width: true// this.calculatedProps?.textIsInCircle
        };
    }

    getMarkerSize() {
        let size = Math.clamp(this.model.markerSize ?? 50, this.minMarkerSize, this.maxMarkerSize);
        return new geom.Size(size, size);
    }

    get connectionShape() {
        return {
            bounds: this.marker.offsetBounds,
            type: "circle"
        };
    }

    get anchorBounds() {
        return this.marker.offsetBounds.offset(-this.styles.paddingLeft || 0, -this.styles.paddingTop || 0);
    }

    get textWidth() {
        return this.model.textWidth ?? 200;
    }

    buildMarker() {
        return this.addElement("marker", () => SVGCircleElement);
    }

    _build() {
        this.marker = this.addElement("marker", () => ShapeCallout, {
            model: {
                calloutType: CalloutType.CIRCLE,
                color: this.model.color,
                decorationStyle: this.decorationStyle,
            },
            hasText: false
        });

        this.marker = this.addElement("marker", () => SVGCircleElement, {
            // model: {
            //     calloutType: CalloutType.CIRCLE,
            //     color: this.model.color,
            //     decorationStyle: this.decorationStyle,
            // },
            // hasText: false
        });
        this.text = this.addElement("text", () => TextElement, {
            blockStructure: BlockStructureType.HEADER,
            defaultBlockTextStyle: TextStyleType.TITLE,
            showFontSize: false,
            allowedBlockTypes: this.options.allowedTextBlockTypes ?? null,
            autoHeight: true,
            isTextFit: true,
            autoWidth: false,
            canAddBlocks: this.canAddBlocks,
            syncFontSizeWithSiblings: this.syncFontSizeWithSiblings,
            canSelect: false,
            doubleClickToSelect: true
        });
    }

    _calcProps(props) {
        if (this.textDirection === DirectionType.AUTO) {
            const markerProps = this.marker.calcProps(this.getMarkerSize());

            const textInCircleSize = geom.fitRectInCircle(markerProps.size.width, 1, 1);

            const textProps = this.text.calcProps(textInCircleSize, {
                textAlign: HorizontalAlignType.CENTER,
                scaleTextToFit: false,
            });

            if (textProps.isTextFit) {
                textProps.bounds = new geom.Rect(markerProps.size.width / 2 - textProps.size.width / 2, markerProps.size.height / 2 - textProps.size.height / 2, textProps.size);
                markerProps.bounds = new geom.Rect(0, 0, markerProps.size);

                this.model.width = this.model.height = markerProps.size.width;

                return { size: markerProps.size, textIsInCircle: true };
            } else {
                // text doesn't fit in bubble...
                return super._calcProps(props);
            }
        } else {
            return super._calcProps(props);
        }
    }

    _applyColors() {
        this.marker.colorSet.decorationColor = this.palette.getColor(this.model.color ?? this.parentElement.collectionColor, this.getBackgroundColor(), { itemIndex: this.itemIndex });
        this.text.options.backgroundElement = this.calculatedProps.textIsInCircle ? this.marker : null;
    }
}
