import { AuthoringBlockType, AuthoringElementType, CalloutType, ConnectorType, ContentBlockType, DirectionType, ForeColorType, NodeType, PaletteColorType } from "common/constants";
import * as geom from "js/core/utilities/geom";
import { _ } from "js/vendor";
import { v4 as uuid } from "uuid";

import { detectGraphData } from "js/core/services/sharedModelManager";
import { FlowChartControlBar, FlowChartItemSelection, FlowChartPropertyPanel, FlowChartSelection } from "../../Editor/ElementPropertyPanels/FlowChartUI";
import { GridComponent } from "../components/Grid";
import { CalloutsCanvas } from "./Callouts/CalloutsCanvas";

class NodeDiagram extends CalloutsCanvas {
    get name() {
        return "Flow Chart";
    }

    getAllowedCalloutTypes() {
        return [
            CalloutType.TEXT,
            CalloutType.BOX,
            CalloutType.CIRCLE,
            CalloutType.DIAMOND,
            CalloutType.CAPSULE,
            CalloutType.BULLET_TEXT,
            CalloutType.CONTENT_AND_TEXT,
            CalloutType.NUMBERED_TEXT,
            CalloutType.CONTENT
        ];
    }

    getConnectorOptions(model) {
        return {
            canDragConnectors: false,
            allowStepConnector: true,
            convertToAuthoringAsSVG: true,
            canConnectToOtherNodes: true,
        };
    }

    get allowStepConnetors() {
        return true;
    }

    get allowSelectionContextMenu() {
        return true;
    }

    get allowContextMenu() {
        return true;
    }

    get minItemCount() {
        return 0;
    }

    get gridSize() {
        return 10;
    }

    get showGrid() {
        return this.model.showGrid;
    }

    getElementPropertyPanel() {
        return FlowChartPropertyPanel;
    }

    getElementSelection() {
        return FlowChartSelection;
    }

    getElementControlBar() {
        return FlowChartControlBar;
    }

    getChildOptions() {
        return {
            allowConnection: true,
            syncFontSizeWithSiblings: true,
            elementSelection: FlowChartItemSelection,
        };
    }

    get defaultConnectorColor() {
        return ForeColorType.SECONDARY;
    }

    get gridSpacing() {
        return this.gridSize;
    }

    get snapToGrid() {
        return this.model.snapToGrid ?? true;
    }

    get restrictElementsToBounds() {
        return true;
    }

    get restrictElementsToBoundsByRegistrationPoint() {
        return true;
    }

    get startPointsAreLocked() {
        return false;
    }

    getDefaultConnectorModel(source) {
        let model = {
            source,
            startAnchor: geom.AnchorType.CENTER,
            endAnchor: geom.AnchorType.FREE,
            target: null,
            targetPoint: null,
            endDecoration: "arrow",
            connectorType: ConnectorType.STEP
        };
        if (this.connectors?.model.defaultConnectorProps) {
            // merge default connector props if any
            Object.assign(model, this.connectors.model.defaultConnectorProps);
        }
        return model;
    }

    anchorStartType(element, mousePt) {
        const cornerAngle1 = element.bounds.getPoint("center").angleToPoint(element.bounds.getPoint("bottom-right"));
        const cornerAngle2 = element.bounds.getPoint("center").angleToPoint(element.bounds.getPoint("bottom-left"));
        const cornerAngle3 = element.bounds.getPoint("center").angleToPoint(element.bounds.getPoint("top-left"));
        const cornerAngle4 = element.bounds.getPoint("center").angleToPoint(element.bounds.getPoint("top-right"));

        const angle = element.bounds.getPoint("center").angleToPoint(mousePt);
        if (angle < cornerAngle1) {
            return geom.AnchorType.RIGHT;
        } else if (angle < cornerAngle2) {
            return geom.AnchorType.BOTTOM;
        } else if (angle < cornerAngle3) {
            return geom.AnchorType.LEFT;
        } else if (angle < cornerAngle4) {
            return geom.AnchorType.TOP;
        } else {
            return geom.AnchorType.RIGHT;
        }
    }

    // async onAfterConnectorCreated(connector) {
    //     const startElement = connector.startTarget;
    //
    //     if (!startElement) return;
    //
    //     const startElementModel = startElement.model;
    //     const connectorModel = connector.model;
    //
    //     if (startElement && !connectorModel.target) {
    //         const targetPoint = new geom.Point(connectorModel.targetPoint.x, connectorModel.targetPoint.y);
    //         connectorModel.startAnchor = this.anchorStartType(startElement, targetPoint);
    //
    //         const { height, width } = startElement.getRootElement().getDefaultModel(AuthoringElementType.CALLOUT, { calloutType: startElementModel.calloutType });
    //
    //         let textDirection;
    //         switch (connectorModel.startAnchor) {
    //             case geom.AnchorType.LEFT:
    //                 textDirection = DirectionType.LEFT;
    //                 break;
    //             default:
    //                 textDirection = DirectionType.AUTO;
    //         }
    //
    //         // if we ended the drag without connecting to another a node,  create a new node and connect to it
    //         const newNodeModel = this.addItem({
    //             stroke: startElementModel.stroke,
    //             strokeStyle: startElementModel.strokeStyle,
    //             decorationStyle: startElementModel.decorationStyle,
    //             fitToText: startElementModel.fitToText,
    //             fill: startElementModel.fill,
    //             calloutType: startElementModel.calloutType,
    //             type: startElementModel.type,
    //             width,
    //             textDirection,
    //             height,
    //             x: targetPoint.x,
    //             y: targetPoint.y
    //         });
    //
    //         connectorModel.target = newNodeModel.id;
    //         connectorModel.targetPoint = null;
    //
    //         await this.canvas.refreshCanvas();
    //
    //         const newNodeElement = this.getItemElementById(newNodeModel.id);
    //         newNodeElement.model.x -= newNodeElement.model.width / 2;
    //         newNodeElement.model.y -= newNodeElement.model.height / 2;
    //
    //         return newNodeElement;
    //     }
    //
    //     // delete connector if it's pointing at itself
    //     if (startElement && connectorModel.target === startElement.id && connectorModel.startAnchor === connectorModel.endAnchor) {
    //         this.connectors.model.items.remove(connectorModel);
    //         return;
    //     }
    //
    //     return connector;
    // }

    centerLayout() {
        let nodeBounds = this.itemElements.reduce((bounds, element) => bounds.union(element.bounds), this.itemElements[0].bounds);
        let centered = nodeBounds.centerInRect(this.elementBounds);

        let offsetX = centered.left - nodeBounds.left;
        let offsetY = centered.top - nodeBounds.top;

        for (let element of this.itemElements) {
            element.model.x += offsetX;
            element.model.y += offsetY;
        }
    }

    get showSnapLines() {
        return this.model.showSnapLines ?? true;
    }

    getCanvasMargins() {
        return {
            left: this.canvas.styleSheet.ElementMargin.marginLeft,
            top: this.canvas.styleSheet.ElementMargin.marginTop,
            right: this.canvas.styleSheet.ElementMargin.marginRight,
            bottom: this.canvas.styleSheet.ElementMargin.marginBottom
        };
    }

    _build() {
        super._build();

        if (this.showGrid) {
            this.grid = this.addElement("grid", () => GridComponent);
            this.grid.layer = -2;
        }
    }

    _calcProps(props, options) {
        const { size, connectorProps } = super._calcProps(props, options);

        // override trransparency for connectors
        connectorProps.opacity = 1;

        size.height = Math.round(size.height / this.gridSize) * this.gridSize;
        size.width = Math.round(size.width / this.gridSize) * this.gridSize;

        for (let item of this.itemElements) {
            if (!item.model.x && !item.model.y) {
                item.model.x = size.width / 2 - item.model.width / 2;
                item.model.y = size.height / 2 - item.model.height / 2;
            }
        }

        if (this.showGrid) {
            this.grid.calcProps(size, {
                gridSize: this.gridSize,
                layer: -2
            });
        }

        return { size };
    }

    _applyColors() {
        this.connectors.colorSet.connectorLineColor = this.palette.getColor(ForeColorType.SECONDARY, this.getBackgroundColor());
    }

    getInnerText(node, trimLength = null) {
        const text = this.model.title?.text;
        if (!text) {
            return null;
        }

        if (!trimLength) {
            return text;
        }

        return `${text.slice(0, 5)}${text.length > trimLength ? "..." : ""}`;
    }

    connectorsFromNode(node) {
        if (this.connectors) {
            return this.connectors.getConnectorsForItem(node.id, "source");
        }
        return [];
    }

    connectorsToNode(node) {
        if (this.connectors) {
            return this.connectors.getConnectorsForItem(node.id, "target");
        }
        return [];
    }

    childNodes(node) {
        // return all nodes that are connected to the given node, otherwise return an empty array
        return _.map(this.connectorsFromNode(node), c => this.itemElements.find(node => node.id === c.model.target)).filter(Boolean);
    }

    buildNodeTree(nodes) {
        if (!nodes) {
            let nodeTree = [];

            let rootNodes = this.itemElements.filter(node => this.connectorsToNode(node).length == 0);

            if (rootNodes.length) {
                nodeTree.push(rootNodes);
            } else {
                nodeTree.push([_.sortBy(this.itemElements, node => node.model.y)[0]]);
            }
            this.buildNodeTree(nodeTree);
            return nodeTree;
        } else {
            let childNodes = [];
            for (const node of _.last(nodes)) {
                node.treeLevel = nodes.length;
                for (const childNode of this.childNodes(node)) {
                    if (!nodes.flat().includes(childNode)) {
                        childNodes.push(childNode);
                    }
                }
            }
            nodes.push(childNodes);

            if (childNodes.length) {
                this.buildNodeTree(nodes);
            }
        }
    }

    getAnimations() {
        const animations = [];

        let nodeIdx = 1;
        const connectorsWithAnimations = [];
        const nodesWithAnimations = [];
        const nodeNames = {};
        this.nodeTree = this.buildNodeTree();
        this.nodeTree.forEach(nodeTreeLevel => {
            nodeTreeLevel.forEach(node => {
                const nodeText = this.getInnerText(node, 5);
                const nodeName = `Node ${nodeText ? `"${nodeText}"` : `#${nodeIdx}`}`;
                nodeNames[node.id] = nodeName;

                if (!nodesWithAnimations.includes(node)) {
                    nodeIdx++;

                    // Node animation (only if it's not already animated)
                    const nodeAnimations = node.getAnimations();
                    nodeAnimations.forEach(animation => animation.elementName = nodeName);
                    animations.push(...nodeAnimations);
                    nodesWithAnimations.push(node);
                }

                // Connectors to the node from already animated nodes or without start target
                node.connectorsToNode
                    .filter(connector => !connectorsWithAnimations.includes(connector))
                    .filter(connector => nodesWithAnimations.includes(connector.startTarget) || !connector.startTarget)
                    .forEach(connector => {
                        const connectorName = `→ ${nodeName}`;
                        const connectorAnimations = connector.getAnimations();
                        connectorAnimations.forEach(animation => animation.elementName = connectorName);
                        animations.push(...connectorAnimations);
                        connectorsWithAnimations.push(connector);
                    });

                // Connectors from the node without end target
                node.connectorsFromNode
                    .filter(connector => !connectorsWithAnimations.includes(connector))
                    .filter(connector => !connector.endTarget)
                    .forEach(connector => {
                        const connectorName = `${nodeName} →`;
                        const connectorAnimations = connector.getAnimations();
                        connectorAnimations.forEach(animation => animation.elementName = connectorName);
                        animations.push(...connectorAnimations);
                        connectorsWithAnimations.push(connector);
                    });

                nodesWithAnimations.push(node);
            });
        });

        // The rest of the connectors
        const connectorsWithoutAnimations = this.connectors.itemElements.filter(connector => !connectorsWithAnimations.includes(connector));
        connectorsWithoutAnimations.forEach((connector, idx) => {
            let connectorName = `Connector #${idx + 1}`;
            if (connector.endTarget) {
                const nodeName = nodeNames[connector.endTarget.id];
                connectorName = `→ ${nodeName}`;
            } else if (connector.startTarget) {
                const nodeName = nodeNames[connector.startTarget.id];
                connectorName = `${nodeName} →`;
            }
            const connectorAnimations = connector.getAnimations();
            connectorAnimations.forEach(animation => animation.elementName = connectorName);
            animations.push(...connectorAnimations);
            connectorsWithAnimations.push(connector);
        });

        return animations;
    }

    _exportToSharedModel() {
        const nodes = this.itemElements.map(
            itemElement => itemElement._exportToSharedModel().graphData[0].nodes[0]
        );

        const connectors = this.connectors.itemElements.map(connectorItem => ({
            source: connectorItem.model.source,
            target: connectorItem.model.target,
            type: connectorItem.model.connectorType,
            ...(connectorItem.labels?.length ? {
                label: connectorItem.labels[0]._exportToSharedModel().textContent[0]
            } : {}),
        }));

        return {
            graphData: [{ nodes, connectors }],
            textContent: nodes.map(node => node.textContent).filter(Boolean),
            assets: nodes.map(node => node.asset).filter(Boolean)
        };
    }

    _importFromSharedModel(model) {
        const { nodes, connectors = [] } = detectGraphData(model) || {};
        if (!nodes?.length) return;

        const nodeItems = nodes.map(node => ({
            ...(node.props || {}),
            id: node.id || uuid(),
            nodeType: node.type || (
                node.asset && node.textContent ? NodeType.CONTENT_AND_TEXT
                    : node.asset ? NodeType.CONTENT
                        : NodeType.TEXT
            ),
            ...(node.asset ? {
                content_type: node.asset.type,
                content_value: node.asset.value,
                assetProps: node.asset.props,
                assetName: node.asset.name
            } : {}),
            ...(node.textContent ? {
                text: {
                    blocks: [
                        {
                            html: node.textContent.mainText.text,
                            textStyle: node.textContent.mainText.textStyle,
                            type: AuthoringBlockType.TEXT,
                        },
                        ...node.textContent.secondaryTexts.map(secondaryText => ({
                            html: secondaryText.text,
                            textStyle: secondaryText.textStyle,
                            type: AuthoringBlockType.TEXT,
                        }))
                    ]
                }
            } : {}),
            x: node.x ?? Math.random(),
            y: node.y ?? Math.random(),
        }));

        const connectorItems = connectors.map(connector => ({
            ...(connector.props || {}),
            id: uuid(),
            source: connector.source,
            target: connector.target,
            connectorType: connector.type,
            ...(connector.label ? {
                labels: [
                    {
                        blocks: [
                            {
                                html: connector.label.mainText.text,
                                textStyle: ContentBlockType.TITLE,
                                type: AuthoringBlockType.TEXT,
                            }
                        ]
                    }
                ]
            } : {})
        }));

        return {
            items: nodeItems,
            connectors: { items: connectorItems },
            postProcessingFunction: canvas => canvas.getPrimaryElement().centerLayout(),
        };
    }

    _migrate_10_02() {
        super._migrate_10_02();

        this.model.connections = this.model.connectors;
        delete this.model.connectors;

        // // old connectors used their source nodes color by default
        // for (let connector of this.model.connections.items) {
        //     if (!connector.color) {
        //         connector.color = this.model.items.findById(connector.source)?.color ?? this.canvas.getSlideColor();
        //     }
        // }

        super._migrate_10_02();
    }
}

export const elements = {
    NodeDiagram
};

