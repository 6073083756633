import React, { Component } from "react";

import { ForeColorType } from "common/constants";
import {
    PropertyPanelContainer,
    PropertySection
} from "js/EditorComponents/PropertyPanel";
import { WithLabel } from "js/Components/WithLabel";
import { ImageOption, ImageOptionList } from "js/Components/ImageOptionList";
import { ToggleSwitch } from "js/Components/ToggleSwitch";
import { Slider } from "js/Components/Slider";
import { StaticImage } from "js/Components/StaticImage";

import { ControlBar } from "../ElementControlBars/Components/ControlBar";
import { CollectionColorPicker } from "../EditorComponents/ColorPickers/CollectionColorPicker";
import { EmphasizeToggle } from "../EditorComponents/EmphasizeToggle";
import { ItemColorPicker } from "../EditorComponents/ColorPickers/ItemColorPicker";

import { ColorPicker } from "../EditorComponents/ColorPickers/ColorPicker";

export class PyramidPropertyPanel extends Component {
    render() {
        const { element } = this.props;

        let allowShading = element.model.collectionColor != ForeColorType.COLORFUL && element.itemCollection.find(item => item.hilited) == null;

        return (
            <PropertyPanelContainer>
                <PropertySection>
                    <WithLabel label="Pyramid Style">
                        <ImageOptionList value={element.model.style}
                            onChange={value => element.updateModel({ style: value })}
                            cols={3} gap={10}
                        >
                            <ImageOption value="text" label="Text" icon="text_fields" />
                            <ImageOption value="sequence" label="Sequence" icon="pin" />
                            <ImageOption value="icon" label="Icon" icon="star" />
                        </ImageOptionList>
                    </WithLabel>
                </PropertySection>
                <PropertySection>
                    <WithLabel label="Section Colors">
                        <WithLabel small below center label="Content">
                            <ColorPicker value={element.model.sectionContentColor} canvas={element.canvas}
                                onChange={value => element.updateModel({ sectionContentColor: value })}
                                defaultColor="white"
                                showThemeColors={false} showWhite showBlack
                            />
                        </WithLabel>
                        <WithLabel small below center label="Sections">
                            <CollectionColorPicker element={element} showDecorationStyles ignoreMediaElement
                                onAfterChange={() => {
                                    // Reset section colors
                                    for (let section of element.itemElements) {
                                        section.model.color = null;
                                    }
                                    element.updateModel({ sectionContentColor: null });
                                }}
                            />
                        </WithLabel>
                    </WithLabel>
                    {allowShading && (
                        <WithLabel label="Shade Colors">
                            <ToggleSwitch value={element.model.shadeColors}
                                onChange={value => element.updateModel({ shadeColors: value })}
                            />
                        </WithLabel>
                    )}
                    <WithLabel label="Show Section Text">
                        <ToggleSwitch value={element.model.showDescription}
                            onChange={value => element.updateModel({ showDescription: value })}
                        />
                    </WithLabel>
                </PropertySection>

                <PropertySection>
                    <WithLabel label="Presets" above left>
                        <ImageOptionList cols={3} onChange={value => element.updateModel(value.primary, { transition: true })}>
                            {getVariations().map(variation => (
                                <ImageOption label={variation.label}
                                    value={variation.model}
                                >
                                    <StaticImage src={`/images/template-icons/${variation.icon}.svg`} />
                                </ImageOption>
                            ))}
                        </ImageOptionList>
                    </WithLabel>
                </PropertySection>

                <PropertySection>
                    <WithLabel label="Step">
                        <ToggleSwitch value={element.model.step}
                            onChange={value => element.updateModel({ step: value })}
                        />
                    </WithLabel>
                    <WithLabel label="Inverted">
                        <ToggleSwitch value={element.model.inverted}
                            onChange={value => element.updateModel({ inverted: value })}
                        />
                    </WithLabel>
                    <WithLabel label="Top Width">
                        <Slider value={element.model.capSize}
                            onChange={value => element.refreshModel({ capSize: value })}
                            onCommit={() => element.saveModel()}
                            min={0}
                            max={100}
                        />
                    </WithLabel>
                    <WithLabel label="Base Width">
                        <Slider value={element.model.baseSize}
                            onChange={value => element.refreshModel({ baseSize: value })}
                            onCommit={() => element.saveModel()}
                            min={0}
                            max={100}
                        />
                    </WithLabel>
                </PropertySection>

            </PropertyPanelContainer>
        );
    }
}

export class PyramidSectionControlBar extends Component {
    render() {
        const { element } = this.props;

        return (
            <ControlBar>
                <EmphasizeToggle element={element} showLabel />
                <ItemColorPicker element={element} ignoreMediaElement />
            </ControlBar>
        );
    }
}

function getVariations() {
    return [
        {
            label: "Pyramid",
            template: "pyramid",
            icon: "pyramid",
            model: {
                primary: {
                    capSize: 0,
                    baseSize: 60,
                    step: false,
                    inverted: false,
                    capAlign: "center"
                }
            }
        },
        {
            label: "Funnel",
            template: "pyramid",
            icon: "funnel",
            model: {
                primary: {
                    capSize: 65,
                    baseSize: 15,
                    step: false,
                    inverted: false,
                    capAlign: "center"
                }
            }
        },
        {
            label: "Wedge",
            template: "pyramid",
            icon: "pyramid_wedge",
            model: {
                primary: {
                    capSize: 35,
                    baseSize: 65,
                    step: false,
                    inverted: false,
                    capAlign: "center"
                }
            }
        },
        {
            label: "Volcano",
            template: "pyramid",
            icon: "pyramid_volcano",
            model: {
                primary: {
                    capSize: 7,
                    baseSize: 65,
                    step: false,
                    inverted: false,
                    capAlign: "center"
                }
            }
        },
        {
            label: "Aztec Pyramid",
            template: "pyramid",
            icon: "pyramid_step",
            model: {
                primary: {
                    capSize: 0,
                    baseSize: 60,
                    step: true,
                    inverted: false,
                    capAlign: "center"
                }
            }
        },
        {
            label: "Staircase",
            template: "pyramid",
            icon: "pyramid_staircase",
            model: {
                primary: {
                    capSize: 10,
                    baseSize: 40,
                    step: true,
                    inverted: false,
                    capAlign: "left"
                }
            }
        },
    ];
}
