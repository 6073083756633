import React, { Component } from "react";
import { ChromePicker } from "react-color";
import styled from "styled-components";

import { ForeColorType, PaletteColorType } from "common/constants";
import { ColorChit, ColorChitWithIcon, ThemeColorChit } from "js/Components/ColorChit";
import { Icon } from "js/Components/Icon";
import { MixedValue } from "js/Components/MixedValue";
import { Popup, PopupContainer, PopupContent, PopupPreview } from "js/Components/Popup";
import { ToolTipBadge } from "js/Components/ToolTip";
import { WithLabel } from "js/Components/WithLabel";
import { PropertyPanelContainer, PropertySection } from "js/EditorComponents/PropertyPanel";
import { AbsoluteBox, FlexBox, GridBox } from "js/react/components/LayoutGrid";
import { $, _, tinycolor } from "js/vendor";
import { DecorationStyles } from "./DecorationStyles";

const ColorSet = styled.div`
    display: flex;
    gap: 5px;
    margin: 0px 10px;

    &:first-child {
        margin-left: 0;
    }

    &:last-child {
        margin-right: 0;
    }
`;

const ColorWarning = styled.div`
    font-weight: 600;
    color: #333;
    font-size: 14px;
    text-transform: none;
    display: flex;
    gap: 10px;
`;

export class ColorPicker extends Component {
    constructor(props) {
        super(props);

        this.state = {
            anchorEl: null,
            customColor: null,
            color: null
        };

        this.onChangeDebounced = _.debounce(props.onChange ?? (() => {
        }), 500);
    }

    handleSelectRawColor = (color, event) => {
        const { saveOnClose = false } = this.props;

        const eventTargetId = event.target.id;
        // In react-color, there is a bug, where it does not deselect the input (if it's selected),
        // and if in a case a user would like to see the color reflected in the input we need to blur it.
        if (!eventTargetId.contains("rc-editable-input")) {
            $(".chrome-picker [id*=rc-editable-input]").trigger("blur");
        }

        event.preventDefault();
        event.stopPropagation();

        if (event.nativeEvent) {
            event.nativeEvent.preventDefault();
            event.nativeEvent.stopImmediatePropagation();
        }

        const customColor = `rgba(${color.rgb.r},${color.rgb.g},${color.rgb.b},${color.rgb.a})`;

        // In react-color there is a bug where the alpha value is not set correctly when the color is selected from the color picker.
        // Some explanation about it: https://github.com/casesandberg/react-color/issues/432
        // Basically when we open the picker, it is already set to be an rgba color, however when we select the text input of the picker,
        // it passes it as a rgba string which is not correct (This field accepts HEX values).
        // Here is the workaround for this issue:
        // If the alpha value is not 1, we assume it's an HEX.
        // If the alpha value is 1, we assume it's an RGBA.

        if (color.rgb.a !== 1) color = _.cloneDeep(color.rgb);
        else color = color.hex;

        this.setState({
            customColor,
            color
        });

        if (!saveOnClose) {
            this.onChangeDebounced(customColor);
        }
    };

    handleChange = color => {
        const { onChange, resolveColorFromPalette, canvas } = this.props;

        if (resolveColorFromPalette) {
            onChange(canvas.getTheme().palette.getColor(color));
        } else {
            onChange(color);
        }
    }

    render() {
        const { showThemeColors = true,
            showBackgroundColors,
            showColorful,
            showPositiveNegative,
            showPrimary,
            showSecondary,
            showWhite,
            showBlack,
            showNone,
            showColorPicker,
            showChartColors,
            imageBackgroundColor,
            canvas,
            backgroundColor,
            size = 25,
            defaultColor,
            showDecorationStyles,
            onChangeDecorationStyle,
            disableAlpha,
            allowColorOnColor,
            closeOnSelect = true,
            allowedDecorationStyles,
            topSection,
            bottomSection,
            customPopup } = this.props;

        let { value } = this.props;

        const { customColor } = this.state;

        if (!canvas) {
            throw new Error("Canvas is required");
        }

        const theme = canvas.getTheme();
        const themeColors = theme.palette.getSlideColors();

        let bgColor = backgroundColor || canvas.getBackgroundColor();
        let hasColorBackground = bgColor.isColor;

        if (value && typeof value === "string" && value.startsWith("rgb")) {
            // look for the color in the palette
            let paletteColorKey = _.findKey(canvas.getTheme().palette.getColors(), color => tinycolor.equals(value, color));
            if (paletteColorKey) {
                if (paletteColorKey.startsWith("primary")) {
                    value = ForeColorType.PRIMARY;
                } else if (paletteColorKey.startsWith("secondary")) {
                    value = ForeColorType.SECONDARY;
                } else {
                    value = paletteColorKey;
                }
            }
        }

        if (hasColorBackground && !allowColorOnColor && !customPopup) {
            return (
                <Popup>
                    <PopupPreview>
                        <ColorChit color="white" size={size} type="auto" />
                    </PopupPreview>
                    <PopupContent>
                        {closePopup => (
                            <PopupContainer>
                                <PropertyPanelContainer>
                                    <PropertySection>
                                        <ColorWarning>
                                            <Icon>invert_colors_off</Icon>Color is automatically selected when background is a color or image.
                                        </ColorWarning>
                                    </PropertySection>
                                    {showDecorationStyles &&
                                        <DecorationStyles allowedDecorationStyles={allowedDecorationStyles} color="#999" canvas={canvas}
                                            onChange={decorationStyle => {
                                                onChangeDecorationStyle && onChangeDecorationStyle(decorationStyle);
                                                closePopup();
                                            }}
                                        />
                                    }
                                </PropertyPanelContainer>
                            </PopupContainer>
                        )}
                    </PopupContent>
                </Popup>
            );
        } else {
            let colorPreview;
            if (customPopup) {
                colorPreview = customPopup;
            } else if (value == "mixed") {
                colorPreview = <MixedValue />;
            } else if (value == null) {
                colorPreview = <ThemeColorChit color={defaultColor} theme={theme} size={size} overlayLetter="A" />;
            } else {
                colorPreview = <ThemeColorChit color={value} theme={theme} size={size} />;
            }

            return (
                <Popup>
                    <PopupPreview>
                        {colorPreview}
                    </PopupPreview>
                    <PopupContent>
                        {closePopup => (
                            <PopupContainer>
                                <PropertyPanelContainer>
                                    {topSection}
                                    <PropertySection>
                                        <FlexBox gap={5} left>
                                            {showNone && (
                                                <ColorSet>
                                                    <WithLabel small below center label="None">
                                                        <ColorChit type="none" size={35}
                                                            onClick={() => {
                                                                this.handleChange("none");
                                                                closeOnSelect && closePopup();
                                                            }} />
                                                    </WithLabel>
                                                </ColorSet>
                                            )}

                                            {defaultColor && (
                                                <ColorSet>
                                                    <WithLabel small below center label="Auto">
                                                        <ThemeColorChit
                                                            color={defaultColor}
                                                            theme={theme}
                                                            type="auto"
                                                            size={35}
                                                            overlayLetter="A"
                                                            onClick={() => {
                                                                this.handleChange(null);
                                                                closeOnSelect && closePopup();
                                                            }} />
                                                    </WithLabel>
                                                </ColorSet>
                                            )}

                                            {imageBackgroundColor && (
                                                <ColorSet>
                                                    <WithLabel small below center label="Match Image">
                                                        <ColorChitWithIcon color={imageBackgroundColor} theme={theme} size={35}
                                                            onClick={() => {
                                                                this.handleChange("match-image");
                                                                closeOnSelect && closePopup();
                                                            }} >
                                                            <Icon>photo_camera</Icon>
                                                        </ColorChitWithIcon>
                                                    </WithLabel>
                                                </ColorSet>
                                            )}

                                            {showPrimary && (
                                                <WithLabel small below center label="Primary">
                                                    <ThemeColorChit color={ForeColorType.PRIMARY}
                                                        theme={theme}
                                                        type="primary"
                                                        size={35}
                                                        onClick={() => {
                                                            this.handleChange(ForeColorType.PRIMARY);
                                                            closeOnSelect && closePopup();
                                                        }} />
                                                    <AbsoluteBox right={0} top={0}>
                                                        <ToolTipBadge>
                                                            Automatically choose light or dark primary color to contrast with the background.
                                                        </ToolTipBadge>
                                                    </AbsoluteBox>
                                                </WithLabel>
                                            )}

                                            {showSecondary && (
                                                <WithLabel small below center label="Secondary">
                                                    <ThemeColorChit color={ForeColorType.SECONDARY}
                                                        theme={theme}
                                                        type="secondary"
                                                        size={35}
                                                        onClick={() => {
                                                            this.handleChange(ForeColorType.SECONDARY);
                                                            closeOnSelect && closePopup();
                                                        }} />
                                                </WithLabel>
                                            )}

                                            {showThemeColors && (
                                                <ColorSet>
                                                    <WithLabel small below left label="Theme Colors">
                                                        <GridBox columns={6} gap={5}>
                                                            {Object.keys(themeColors).map(color => (
                                                                <ThemeColorChit key={color}
                                                                    color={color}
                                                                    theme={theme}
                                                                    size={35}
                                                                    onClick={() => {
                                                                        this.handleChange(color);
                                                                        closeOnSelect && closePopup();
                                                                    }} />
                                                            ))}
                                                        </GridBox>
                                                    </WithLabel>
                                                </ColorSet>
                                            )}

                                            {showChartColors && (
                                                <ColorSet>
                                                    <WithLabel small below left label="Chart Colors">
                                                        <GridBox columns={6} gap={5}>
                                                            {Object.keys(theme.palette.getChartColors(false)).map(color => (
                                                                <ThemeColorChit key={color}
                                                                    color={color}
                                                                    theme={theme}
                                                                    size={35}
                                                                    onClick={() => {
                                                                        this.handleChange(color);
                                                                        closeOnSelect && closePopup();
                                                                    }} />
                                                            ))}
                                                        </GridBox>
                                                    </WithLabel>
                                                </ColorSet>
                                            )}

                                            {showColorful && (
                                                <ColorSet>
                                                    <WithLabel small below center label="Colorful">
                                                        <ThemeColorChit color={ForeColorType.COLORFUL} theme={theme} size={35}
                                                            onClick={() => {
                                                                this.handleChange(ForeColorType.COLORFUL);
                                                                closeOnSelect && closePopup();
                                                            }} />
                                                    </WithLabel>
                                                </ColorSet>
                                            )}

                                            {showPositiveNegative && (
                                                <ColorSet>
                                                    <WithLabel small below center label="Positive">
                                                        <ThemeColorChit color={PaletteColorType.POSITIVE} theme={theme} size={35} onClick={() => {
                                                            this.handleChange(PaletteColorType.POSITIVE);
                                                            closeOnSelect && closePopup();
                                                        }} />
                                                    </WithLabel>
                                                    <WithLabel small below center label="Negative">
                                                        <ThemeColorChit color={PaletteColorType.NEGATIVE} theme={theme} size={35} onClick={() => {
                                                            this.handleChange(PaletteColorType.NEGATIVE);
                                                            closeOnSelect && closePopup();
                                                        }} />
                                                    </WithLabel>
                                                </ColorSet>
                                            )}

                                            {showBackgroundColors && (
                                                <ColorSet>
                                                    {(bgColor.name != PaletteColorType.BACKGROUND_DARK || allowColorOnColor) &&
                                                        <WithLabel small below center label="Dark">
                                                            <ThemeColorChit color={PaletteColorType.BACKGROUND_DARK} theme={theme} size={35} onClick={() => {
                                                                this.handleChange(PaletteColorType.BACKGROUND_DARK);
                                                                closeOnSelect && closePopup();
                                                            }} />
                                                        </WithLabel>
                                                    }
                                                    {((bgColor.name != PaletteColorType.BACKGROUND_LIGHT && bgColor.name != "white") || allowColorOnColor) &&
                                                        <WithLabel small below center label="Light">
                                                            <ThemeColorChit color={PaletteColorType.BACKGROUND_LIGHT} theme={theme} size={35} onClick={() => {
                                                                this.handleChange(PaletteColorType.BACKGROUND_LIGHT);
                                                                closeOnSelect && closePopup();
                                                            }} />
                                                        </WithLabel>
                                                    }
                                                    {((bgColor.name != PaletteColorType.BACKGROUND_ACCENT && bgColor.name != "white") || allowColorOnColor) &&
                                                        <WithLabel small below center label="Accent">
                                                            <ThemeColorChit color={PaletteColorType.BACKGROUND_ACCENT} theme={theme} size={35} onClick={() => {
                                                                this.handleChange(PaletteColorType.BACKGROUND_ACCENT);
                                                                closeOnSelect && closePopup();
                                                            }} />
                                                        </WithLabel>
                                                    }
                                                </ColorSet>
                                            )}

                                            {showWhite && (
                                                <WithLabel small below center label="White">
                                                    <ThemeColorChit color="white" theme={theme} size={35} onClick={() => {
                                                        this.handleChange("white");
                                                        closeOnSelect && closePopup();
                                                    }} />
                                                </WithLabel>
                                            )}

                                            {showBlack && (
                                                <WithLabel small below center label="Black">
                                                    <ThemeColorChit color="black" theme={theme} size={35} onClick={() => {
                                                        this.handleChange("black");
                                                        closeOnSelect && closePopup();
                                                    }} />
                                                </WithLabel>
                                            )}

                                            {showColorPicker &&
                                                <ColorSet>
                                                    <WithLabel small below left label="Custom">
                                                        <Popup onClose={closePopup}>
                                                            <PopupPreview>
                                                                <ColorChit type="picker" size={35} />
                                                            </PopupPreview>
                                                            <PopupContent>
                                                                <ChromePicker
                                                                    disableAlpha={disableAlpha}
                                                                    color={customColor ?? value}
                                                                    style={{ boxShadow: "none" }}
                                                                    onChange={this.handleSelectRawColor}
                                                                />
                                                            </PopupContent>
                                                        </Popup>
                                                    </WithLabel>
                                                </ColorSet>
                                            }
                                        </FlexBox>
                                    </PropertySection>
                                    {showDecorationStyles &&
                                        <DecorationStyles color="#999" canvas={canvas}
                                            allowedDecorationStyles={allowedDecorationStyles}
                                            onChange={decorationStyle => {
                                                onChangeDecorationStyle && onChangeDecorationStyle(decorationStyle);
                                                closeOnSelect && closePopup();
                                            }}
                                        />
                                    }
                                    {bottomSection}
                                </PropertyPanelContainer>

                            </PopupContainer>
                        )}
                    </PopupContent>
                </Popup>
            );
        }
    }
}
