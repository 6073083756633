import React, { Component } from "react";

import { ElementTextBlockPositionType } from "common/constants";
import { ImageOption, ImageOptionList } from "js/Components/ImageOptionList";
import { StaticImage } from "js/Components/StaticImage";
import { WithLabel } from "js/Components/WithLabel";
import { PropertyPanelContainer, PropertySection, PropertySectionHeaderLabel } from "js/EditorComponents/PropertyPanel";

import { TextElementSelection } from "../ElementSelections/TextElementSelection";
import { BackgroundColorPicker } from "../EditorComponents/ColorPickers/BackgroundColorPicker";
import { PropertyPanelHeader } from "js/editor/ElementEditor/PropertyPanelHeader";

export class TextTrayPropertyPanel extends Component {
    render() {
        const { element } = this.props;

        const canvas = element.canvas;

        return (
            <PropertyPanelContainer>
                <PropertyPanelHeader>Lower Tray</PropertyPanelHeader>
                <PropertySection color="white">
                    <ImageOptionList value={canvas.model.layout.elementTextBlockPosition}
                        cols={2}
                        onChange={value => {
                            if (value == ElementTextBlockPositionType.TRAY) {
                                canvas.model.elements.elementTextBlock = {
                                    layoutEngineType: "bottom_tray",
                                    gridLayout: {
                                        cols: 12,
                                        rows: 12,
                                        cells: [{ x: 0, y: 0, width: 12, height: 12 }]
                                    },
                                    items: [{
                                        componentType: "TextAndImage",
                                        cellColor: "background_accent",
                                        childElement: {
                                            text: {
                                                blocks: canvas.model.elements.elementTextBlock?.elementTextBlock?.blocks ?? [{
                                                    type: "text",
                                                    textStyle: "title",
                                                    evenBreak: true,
                                                }]
                                            }
                                        }
                                    }]
                                };
                            }

                            canvas.model.layout.elementTextBlockPosition = value;

                            canvas.updateCanvasModel();
                        }}
                    >
                        <ImageOption label="Tray" value={ElementTextBlockPositionType.TRAY}>
                            <StaticImage src="/images/ui/trays/bottom_tray_text.png" />
                        </ImageOption>
                        <ImageOption label="Text" value={ElementTextBlockPositionType.INLINE}>
                            <StaticImage src="/images/ui/trays/bottom_inline_text.png" />
                        </ImageOption>
                    </ImageOptionList>
                </PropertySection>
                {canvas.model.layout.elementTextBlockPosition == ElementTextBlockPositionType.TRAY && (
                    <PropertySection>
                        <WithLabel label="Background Color">
                            <BackgroundColorPicker element={element} />
                        </WithLabel>
                    </PropertySection>
                )}
            </PropertyPanelContainer>
        );
    }
}

export class TextTraySelection extends TextElementSelection {
    get canDelete() {
        return true;
    }

    get showSelectionBorder() {
        return true;
    }

    handleDeleteElement = () => {
        const { element } = this.props;
        element.parentElement.model.layout.elementTextBlockPosition = ElementTextBlockPositionType.NONE;
        element.canvas.updateCanvasModel(true);
    }
}
