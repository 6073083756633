import React, { Component } from "react";

import { IconPicker, IconPickerPopup } from "../../../../Components/IconPicker";
import { ToggleSwitch } from "../../../../Components/ToggleSwitch";
import { WithLabel } from "../../../../Components/WithLabel";
import { Slider } from "../../../../Components/Slider";

import { ControlBar } from "../ElementControlBars/Components/ControlBar";
import { CollectionColorPicker } from "../EditorComponents/ColorPickers/CollectionColorPicker";
import { ItemColorPicker } from "../EditorComponents/ColorPickers/ItemColorPicker";
import {
    PropertyPanelContainer,
    PropertySection, PropertySectionHeader
} from "../../../../EditorComponents/PropertyPanel";
import { FormatType } from "common/constants";
import { FormattingDropdown } from "../EditorComponents/FormattingOptions";

const defaultIcons = ["figure-woman", "figure-man", "torso-female", "torso-male", "baby", "pregnant", "stroller", "people", "laborer", "business-man", "business-woman", "female", "male", "star", "full"];

export class PictorialChartPropertyPanel extends Component {
    render() {
        const { element } = this.props;

        return (
            <PropertyPanelContainer>
                <PropertySection>
                    <WithLabel label="Icon color">
                        <CollectionColorPicker element={element} />
                    </WithLabel>
                </PropertySection>
                <PropertySection>
                    <PropertySectionHeader label="Icon" />
                    <IconPicker
                        icons={defaultIcons}
                        canChooseIcon
                        value={element.getChildValues("iconId")}
                        onChange={iconId => element.updateChildModels({ iconId })}
                    />
                </PropertySection>

                <PropertySection>
                    <WithLabel label="Value Format"
                        toolTip="Configure how values in the chart are formatted when displayed."
                    >
                        <FormattingDropdown
                            format={element.getChildValues("format")}
                            formatOptions={element.getChildValues("formatOptions")}
                            onChangeFormat={format => element.updateChildModels({ format })}
                            onChangeFormatOptions={formatOptions => element.updateChildModels({ formatOptions })}
                            allowedFormats={[FormatType.NUMBER, FormatType.PERCENT]}
                        />
                    </WithLabel>
                </PropertySection>
                <PropertySection>
                    <WithLabel label="Show description text">
                        <ToggleSwitch value={element.model.showDescription}
                            onChange={value => element.updateModel({ showDescription: value })}
                        />
                    </WithLabel>
                    <WithLabel label="Fixed number of icons">
                        <ToggleSwitch value={element.model.fitIcons}
                            onChange={value => element.updateModel({ fitIcons: value })}
                        />
                    </WithLabel>
                </PropertySection>
            </PropertyPanelContainer>
        );
    }
}

export class PictorialChartItemControlBar extends Component {
    render() {
        const { element } = this.props;

        return (
            <ControlBar>
                <ItemColorPicker element={element} />
                <Slider value={element.model.value}
                    min={0}
                    max={100}
                    onChange={value => element.refreshModel({ value })}
                    onCommit={() => element.saveModel()}
                />
                <FormattingDropdown
                    contained={false}
                    label="Format"
                    format={element.format}
                    formatOptions={element.formatOptions}
                    onChangeFormat={format => element.updateModel({ format })}
                    onChangeFormatOptions={formatOptions => element.updateModel({ formatOptions })}
                    allowedFormats={[FormatType.NUMBER, FormatType.PERCENT]}
                />
                <IconPickerPopup value={element.model.iconId} icons={defaultIcons} canChooseIcon onChange={iconId => element.updateModel({ iconId })} />
            </ControlBar>
        );
    }
}
