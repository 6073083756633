import React, { Component, useRef } from "react";

import { WithLabel } from "../../../../Components/WithLabel";
import { PositionPicker } from "../../../../Components/PositionPicker";
import { FlexBox } from "../../../../react/components/LayoutGrid";
import { IconButton } from "../../../../Components/IconButton";
import { defaultDragPositionProps, defaultDragResizeProps } from "../../../../editor/PresentationEditor/DragElementManager";
import { AssetType, AuthoringBlockType, HorizontalAlignType, PaletteColorType, PositionType, ResizeDirection, VerticalAlignType } from "../../../../../common/constants";
import { HorizontalPropertyList, PropertyPanelContainer, PropertySection, PropertySectionHeader } from "../../../../EditorComponents/PropertyPanel";
import { BaseElementSelection } from "../ElementSelections/BaseElementSelection";
import { getShadowStyle, ShadowEditor } from "../../../../Components/legacy-components/AuthoringEditorComponents/ShadowEditor";
import { Popup, PopupContent, PopupPreview } from "../../../../Components/Popup";
import { ImageOption, ImageOptionList } from "../../../../Components/ImageOptionList";
import { ImagePopup } from "../../../../Components/ImagePopup";
import styled from "styled-components";
import { _ } from "../../../../vendor";
import CanvasPreview from "../../../../react/components/CanvasPreview";
import { NumericStepper } from "../../../../Components/NumericStepper";
import { ColorPicker } from "../EditorComponents/ColorPickers/ColorPicker";
import { getValueOrMixed } from "../../../../core/utilities/utilities";
import { DisabledStateToolTip } from "../../../../Components/ToolTip";
import { Dropdown } from "../../../../Components/Dropdown";
import { MenuItem } from "../../../../Components/Menu";
import { TextListProperties } from "./TextListUI";
import { FlexSpacer } from "../../../../react/components/Gap";
import { TextBlockPropertyPanel } from "./TextUI";

export class TextFrameBoxPropertyPanel extends Component {
    applyTextIdea = idea => {
        const { element } = this.props;

        element.model.textAlign = idea.text.textAlign ?? HorizontalAlignType.CENTER;
        element.model.verticalAlign = idea.text.verticalAlign ?? VerticalAlignType.MIDDLE;
        element.model.text.blockFontScales = idea.text.blockFontScales ?? {};

        element.model.text.blocks = [];

        idea.text.blocks.forEach((block, index) => {
            element.model.text.blocks[index] = {
                type: "text",
                ...block
            };
        });

        element.saveModel();
    }

    render() {
        const { element, showDeleteButton, showInsetWhenNotOverImage = true, title = "Text" } = this.props;
        const canvas = element.canvas;
        const textFrame = element.findClosestOfType("TextFrame");
        const fontColor = getValueOrMixed(element.model.text.blocks, "fontColor") ?? "primary";

        return (
            <>
                <PropertySection>
                    <PropertySectionHeader label={title}>
                        {showDeleteButton && (
                            <IconButton icon="delete"
                                tooltip="Remove Text"
                                onClick={() => {
                                    element.updateModel({ text: null });
                                }} />
                        )}
                    </PropertySectionHeader>

                    <HorizontalPropertyList gap={18}>
                        <WithLabel label="Position" below small center>
                            <PositionPicker value={textFrame.textPosition}
                                onChange={value => {
                                    let textAlign, verticalAlign;
                                    const innerBounds = textFrame.calculatedProps.innerBounds;
                                    switch (value) {
                                        case PositionType.TOP_LEFT:
                                            textAlign = HorizontalAlignType.LEFT;
                                            verticalAlign = VerticalAlignType.TOP;
                                            break;
                                        case PositionType.LEFT:
                                            textAlign = HorizontalAlignType.LEFT;
                                            verticalAlign = VerticalAlignType.MIDDLE;
                                            break;
                                        case PositionType.BOTTOM_LEFT:
                                            textAlign = HorizontalAlignType.LEFT;
                                            verticalAlign = VerticalAlignType.BOTTOM;
                                            break;
                                        case PositionType.TOP:
                                            textAlign = HorizontalAlignType.CENTER;
                                            verticalAlign = VerticalAlignType.TOP;
                                            break;
                                        case PositionType.CENTER:
                                            textAlign = HorizontalAlignType.CENTER;
                                            verticalAlign = VerticalAlignType.MIDDLE;
                                            break;
                                        case PositionType.BOTTOM:
                                            textAlign = HorizontalAlignType.CENTER;
                                            verticalAlign = VerticalAlignType.BOTTOM;
                                            break;
                                        case PositionType.TOP_RIGHT:
                                            textAlign = HorizontalAlignType.RIGHT;
                                            verticalAlign = VerticalAlignType.TOP;
                                            break;
                                        case PositionType.RIGHT:
                                            textAlign = HorizontalAlignType.RIGHT;
                                            verticalAlign = VerticalAlignType.MIDDLE;
                                            break;
                                        case PositionType.BOTTOM_RIGHT:
                                            textAlign = HorizontalAlignType.RIGHT;
                                            verticalAlign = VerticalAlignType.BOTTOM;
                                            break;
                                    }

                                    // Set text alignment for all blocks in order for the next blocks will be respected correctly
                                    // to make sure that it is using the parents' text alignment and not inner text alignment
                                    textFrame.model.text.blocks.forEach(block => {
                                        block.textAlign = undefined;
                                    });
                                    textFrame.updateModel({ textPosition: value, textAlign, verticalAlign, userPositionX: null, userPositionY: null, userWidth: null }, { transition: true });
                                }}
                            />
                        </WithLabel>
                        <WithLabel label="Text" below small center>
                            <ColorPicker value={fontColor} canvas={canvas} size={24}
                                showPrimary showSecondary showWhite showBlack allowColorOnColor
                                onChange={color => {
                                    for (const block of element.model.text.blocks) {
                                        block.fontColor = color == "neutral" ? null : color;
                                    }
                                    element.saveModel(false, true);
                                }}
                            />
                        </WithLabel>
                        {/*{element.hasBackdrop && (*/}
                        {/*    <WithLabel label="Inset" small below center>*/}
                        {/*        <NumericStepper value={textFrame.model.backdropPadding ?? 0}*/}
                        {/*                        onChange={value => textFrame.updateModel({ backdropPadding: value })}*/}
                        {/*                        min={0} max={100} step={1}*/}
                        {/*        />*/}
                        {/*    </WithLabel>*/}
                        {/*)}*/}
                        {element.isOverImage && (
                            <>
                                <WithLabel label="Backdrop" below small center>
                                    <Popup>
                                        <PopupPreview>
                                            {(!element.model.backdropStyle || element.model.backdropStyle == "none") && (
                                                <ImageOption url="/images/ui/backdrop/backdrop-none.svg" round size={26} />
                                            )}
                                            {element.model.backdropStyle == "white_box" && (
                                                <ImageOption url="/images/ui/backdrop/backdrop-white.svg" round size={26} />
                                            )}
                                            {element.model.backdropStyle == "transparent_light_box" && (
                                                <ImageOption url="/images/ui/backdrop/backdrop-transparent.svg" round size={26} />
                                            )}
                                            {element.model.backdropStyle == "transparent_dark_box" && (
                                                <ImageOption url="/images/ui/backdrop/backdrop-dark.svg" round size={26} />
                                            )}
                                            {element.model.backdropStyle == "frosted" && (
                                                <ImageOption url="/images/ui/backdrop/backdrop-frosted.svg" round size={26} />
                                            )}
                                        </PopupPreview>
                                        <PopupContent>
                                            <PropertyPanelContainer>
                                                <PropertySection>
                                                    <WithLabel label="Backdrop" above left>
                                                        <ImageOptionList value={element.model.backdropStyle ?? "none"} size={30} previewSize={24} border={false} showArrow={false}
                                                            onChange={value => {
                                                                const model = {
                                                                    backdropStyle: value,
                                                                    backdropColor: null,
                                                                    backdropOpacity: null,
                                                                    backdropPadding: null,
                                                                    backdropCornerRadius: null,
                                                                };

                                                                switch (value) {
                                                                    case "none":
                                                                        model.backdropStyle = "none";
                                                                        model.backdropColor = null;
                                                                        model.backdropOpacity = null;
                                                                        model.backdropPadding = null;
                                                                        model.backdropCornerRadius = null;
                                                                        break;
                                                                    case "white_box":
                                                                        model.backdropStyle = "white_box";
                                                                        model.backdropColor = PaletteColorType.BACKGROUND_LIGHT;
                                                                        model.backdropOpacity = 1;
                                                                        model.backdropPadding = element.model.backdropPadding ?? 30;
                                                                        model.backdropCornerRadius = 0;
                                                                        break;
                                                                    case "transparent_light_box":
                                                                        model.backdropStyle = "transparent_light_box";
                                                                        model.backdropColor = PaletteColorType.BACKGROUND_LIGHT;
                                                                        model.backdropOpacity = 0.5;
                                                                        model.backdropPadding = element.model.backdropPadding ?? 30;
                                                                        model.backdropCornerRadius = 0;
                                                                        break;
                                                                    case "transparent_dark_box":
                                                                        model.backdropStyle = "transparent_dark_box";
                                                                        model.backdropColor = PaletteColorType.BACKGROUND_DARK;
                                                                        model.backdropOpacity = 0.3;
                                                                        model.backdropPadding = element.model.backdropPadding ?? 30;
                                                                        model.backdropCornerRadius = 0;
                                                                        break;
                                                                    case "frosted":
                                                                        model.backdropStyle = "frosted";
                                                                        model.backdropColor = "frosted";
                                                                        model.backdropOpacity = 1;
                                                                        model.backdropPadding = element.model.backdropPadding ?? 30;
                                                                        model.backdropCornerRadius = 0;
                                                                        model.shadow = null;
                                                                        model.shadowStyle = "none";
                                                                        break;
                                                                    case "custom":
                                                                        model.backdropStyle = "custom";
                                                                        model.backdropColor = PaletteColorType.BACKGROUND_LIGHT;
                                                                        model.backdropOpacity = 1;
                                                                        model.backdropPadding = element.model.backdropPadding ?? 30;
                                                                        model.backdropCornerRadius = 0;
                                                                        break;
                                                                }

                                                                element.updateModel(model, { refreshStyles: true });
                                                            }}>
                                                            <ImageOption value="none" label="None" url="/images/ui/backdrop/backdrop-none.svg" round />
                                                            <ImageOption value="white_box" label="White" url="/images/ui/backdrop/backdrop-white.svg" round />
                                                            <ImageOption value="transparent_light_box" label="Transparent" url="/images/ui/backdrop/backdrop-transparent.svg" round />
                                                            <ImageOption value="transparent_dark_box" label="Dark" url="/images/ui/backdrop/backdrop-dark.svg" round />
                                                            <ImageOption value="frosted" label="Frosted" url="/images/ui/backdrop/backdrop-frosted.svg" round />
                                                        </ImageOptionList>
                                                    </WithLabel>
                                                </PropertySection>
                                                <PropertySection>
                                                    <HorizontalPropertyList>
                                                        <WithLabel label="Inset">
                                                            <NumericStepper value={textFrame.model.backdropPadding ?? 0}
                                                                onChange={value => textFrame.updateModel({ backdropPadding: value })}
                                                                min={0} max={100} step={1}
                                                            />
                                                        </WithLabel>

                                                        <WithLabel label="Shadow">
                                                            <DisabledStateToolTip title="Shadow is not available when backdrop is set to frosted" disabled={element.model.backdropStyle == "frosted"}>
                                                                <Popup disabled={element.model.backdropStyle == "frosted"}>
                                                                    <PopupPreview>
                                                                        <ImageOption value={getShadowStyle(element.model.shadow)} size={24} round
                                                                            url={`/images/ui/shadows/shadow-${getShadowStyle(element.model.shadow)}.svg`} />
                                                                    </PopupPreview>
                                                                    <PopupContent width={300}>
                                                                        {closePopup => (<PropertyPanelContainer>
                                                                            <ShadowEditor shadow={element.model.shadow}
                                                                                onChange={value => element.updateModel({ shadow: value }, { refreshStyles: true })}
                                                                                closePopup={closePopup} />
                                                                        </PropertyPanelContainer>
                                                                        )}
                                                                    </PopupContent>
                                                                </Popup>
                                                            </DisabledStateToolTip>
                                                        </WithLabel>

                                                    </HorizontalPropertyList>
                                                </PropertySection>

                                            </PropertyPanelContainer>
                                        </PopupContent>
                                    </Popup>
                                </WithLabel>

                            </>
                        )}
                        <FlexSpacer />
                        {/*<WithLabel label="Settings" below small center>*/}
                        {/*    <Popup icon="settings">*/}
                        {/*        <PopupContent width={300}>*/}
                        {/*            <TextBlockPropertyPanel element={element.text} />*/}
                        {/*        </PopupContent>*/}
                        {/*    </Popup>*/}
                        {/*</WithLabel>*/}
                    </HorizontalPropertyList>
                </PropertySection>
                <TextListProperties element={element.text} />
            </>
        );
    }
}

export class TextFrameBoxSelection extends BaseElementSelection {
    get canDrag() {
        return this.props.element.parentElement.canDragPosition;
    }

    get dragPositionProps() {
        const { element } = this.props;

        return {
            ...defaultDragPositionProps,
            dragOpacity: 0,
            onDragStart: async () => {
                element.model.userPositionX = element.model.userPositionX ?? (element.bounds.left / element.parentElement.calculatedProps.size.width);
                element.model.userPositionY = element.model.userPositionY ?? (element.bounds.top / element.parentElement.calculatedProps.size.height);
            },
            onDragEnd: async ({ dragOffset }) => {
                element.model.userPositionX = Math.clamp(element.model.userPositionX + dragOffset.x / element.parentElement.calculatedProps.size.width, 0, 1);
                element.model.userPositionY = Math.clamp(element.model.userPositionY + dragOffset.y / element.parentElement.calculatedProps.size.height, 0, 1);
                element.model.boxHAlign = null;
                element.model.verticalAlign = null;
            }
        };
    }

    get canResize() {
        return true;
    }

    get dragResizeProps() {
        const { element } = this.props;

        const textFrame = element.findClosestOfType("TextFrame");

        let resizeDirections;
        if (textFrame.textPosition?.equalsAnyOf(PositionType.RIGHT, PositionType.BOTTOM_RIGHT, PositionType.TOP_RIGHT)) {
            resizeDirections = [ResizeDirection.LEFT];
        } else {
            resizeDirections = [ResizeDirection.RIGHT];
        }

        let initialWidth;
        return {
            ...defaultDragResizeProps,
            resizeDirections,
            onDragStart: async () => {
                initialWidth = element.userWidth ?? element.bounds.width;
            },
            onDrag: async ({ dragOffset, resizeDirection }) => {
                const userWidth = initialWidth + dragOffset.x * (textFrame.textPosition?.equalsAnyOf(PositionType.CENTER, PositionType.TOP, PositionType.BOTTOM) ? 2 : 1) * (resizeDirection == ResizeDirection.RIGHT ? 1 : -1);
                element.model.userWidth = userWidth;
                element.refreshElement();
            }
        };
    }

    get canDelete() {
        return this.props.element.parentElement.canDelete;
    }

    handleDeleteElement = () => {
        const { element } = this.props;

        element.updateModel({ text: null });
        element.canvas.selectionLayerController.setSelectedElements([element.findClosestOfType("LayoutContainerItem")]);
    }
}

const AnimationThumbnail = styled.div`
    width: 210px;
    height: ${210 * .5625}px;
    //border: solid 1px #666;
    position: relative;
`;

const CanvasShield = styled.div`
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
`;

function AnimationPreview({ canvas, element, animationStyle }) {
    let previewRef = useRef(null);

    let handlePreview = onComplete => {
        previewRef.current.slideCanvas.getPrimaryElement().findChildById(element.id).previewAnimation();
    };

    let model = _.cloneDeep(canvas.dataModel.attributes);
    model.states[0].primary.animationStyle = animationStyle;

    let scale = 210 / 1280;

    return (
        <AnimationThumbnail>
            <CanvasPreview ref={previewRef}
                slideModel={model}
                scale={scale}
            />
            <CanvasShield onMouseEnter={handlePreview}
            />
        </AnimationThumbnail>
    );
}

const TextIdeas = [{
    label: "Headline",
    text: {
        blocks: [{
            textStyle: "heading",
            html: "Lorem ipsum dolor sit amet"
        }]
    }
}, {
    label: "Quote",
    text: {
        blocks: [{
            textStyle: "heading",
            html: `"<font class='emphasized'>Design</font> is not just what it looks like and feels like. <font class=\'emphasized\'>Design</font> is how it works."`
        }, {
            type: "divider",
            dividerWidth: "short"
        }, {
            textStyle: "caption",
            html: "Steve Jobs"
        }]
    }
}, {
    label: "Detailed Text",
    text: {
        verticalAlign: VerticalAlignType.TOP,
        textAlign: HorizontalAlignType.LEFT,
        blocks: [{
            textStyle: "body",
            html: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed nisl tellus, sodales eget dui eu, molestie gravida lacus. Etiam diam nulla, luctus quis interdum et, vulputate et leo."
        }]
    }
}, {
    label: "Statistic",
    text: {
        blockFontScales: { headline: 1.75 },
        blocks: [{
            textStyle: "headline",
            html: "$100m"
        }, {
            textStyle: "title",
            html: "Annual Recurring Revenue"
        }]
    }
}];

