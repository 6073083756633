import React, { Component } from "react";

import { FormatType, PositionType } from "../../../../../../common/constants";
import { Dropdown } from "../../../../../Components/Dropdown";
import { Slider } from "../../../../../Components/Slider";
import { ToggleSwitch } from "../../../../../Components/ToggleSwitch";
import { WithLabel } from "../../../../../Components/WithLabel";
import PresentationEditorController from "../../../../../editor/PresentationEditor/PresentationEditorController";

import { IconButton } from "../../../../../Components/IconButton";
import { MenuItem } from "../../../../../Components/Menu";
import { ToolTip, ToolTipBadge } from "../../../../../Components/ToolTip";
import { PropertySection } from "../../../../../EditorComponents/PropertyPanel";
import { CollectionColorPicker } from "../../EditorComponents/ColorPickers/CollectionColorPicker";
import { FormattingDropdown } from "../../EditorComponents/FormattingOptions";
import { BaseElementSelection } from "../../ElementSelections/BaseElementSelection";

export class PieChartPropertyPanel extends Component {
    handleEditChartData = () => {
        const { element } = this.props;
        PresentationEditorController.showElementPanel(element);
    }

    render() {
        const { element } = this.props;

        return (
            <>
                <PropertySection>
                    <WithLabel label="Pie Colors">
                        <CollectionColorPicker element={element} showThemeColors={false} showChartColors showBackgroundColors={false} />
                    </WithLabel>
                </PropertySection>
                <PropertySection>
                    <WithLabel label="Donut Chart">
                        <ToggleSwitch
                            value={!!element.model.isDonut}
                            onChange={async isDonut => {
                                await element.updateModel({ isDonut });
                                element.scaleChartToFit();
                            }}
                        />
                    </WithLabel>
                </PropertySection>
                <PropertySection>
                    <WithLabel label="Data Formatting">
                        <FormattingDropdown
                            format={element.format}
                            formatOptions={element.formatOptions}
                            onChangeFormat={format => element.updateModel({ format })}
                            onChangeFormatOptions={formatOptions => element.updateModel({ formatOptions })}
                            allowedFormats={[FormatType.NONE, FormatType.PERCENT, FormatType.NUMBER, FormatType.CURRENCY]}
                        />
                    </WithLabel>
                    <WithLabel label="Sort by Size">
                        <ToggleSwitch
                            value={!!element.model.sortValues}
                            onChange={sortValues => element.updateModel({ sortValues })}
                        />
                    </WithLabel>
                </PropertySection>
                <PropertySection>
                    <WithLabel label="Chart Legend">
                        <Dropdown
                            value={element.model.legendPosition ?? PositionType.OFF}
                            onChange={legendPosition => element.updateModel({ legendPosition })}
                        >
                            <MenuItem value={PositionType.OFF}>None</MenuItem>
                            <MenuItem value={PositionType.LEFT}>Left</MenuItem>
                            <MenuItem value={PositionType.RIGHT}>Right</MenuItem>
                        </Dropdown>
                    </WithLabel>

                </PropertySection>
                <PropertySection>

                    <WithLabel label="Scale">
                        <Slider
                            value={element.model.chartScale ?? 1}
                            min={0.3}
                            max={1}
                            step={0.01}
                            onChange={chartScale => element.refreshModel({ chartScale })}
                            onCommit={() => element.saveModel()}
                        />
                        <ToolTip title="Automatically scale the pie chart to fit the available space.">
                            <IconButton icon="fit_page" onClick={() => element.scaleChartToFit() } />
                        </ToolTip>
                    </WithLabel>
                    <WithLabel label="Rotate">
                        <Slider
                            value={element.model.startAngle ?? 0}
                            min={-180}
                            max={180}
                            step={1}
                            onChange={startAngle => element.refreshModel({ startAngle })}
                            onCommit={() => element.saveModel()}
                        />
                    </WithLabel>

                </PropertySection>
                <PropertySection>
                    <WithLabel label="Label Position">
                        <Dropdown
                            value={element.model.labelPosition ?? "auto"}
                            onChange={async labelPosition => {
                                await element.updateModel({ labelPosition });
                                element.scaleChartToFit();
                            }}
                        >
                            <MenuItem value={"auto"}>Auto<ToolTipBadge>Attempts to fit label within pie chart wedge if possible, otherwise label will be positioneed outside chart.</ToolTipBadge></MenuItem>
                            <MenuItem value={"outside"}>Outside</MenuItem>
                        </Dropdown>
                    </WithLabel>
                    <WithLabel label="Label Scale">
                        <Slider
                            value={element.model.labelScale ?? 1}
                            min={0.4}
                            max={1.5}
                            step={0.01}
                            onChange={labelScale => element.refreshModel({ labelScale })}
                            onCommit={() => element.saveModel()}
                        />
                    </WithLabel>
                </PropertySection>
            </>
        );
    }
}

export class PieChartSelection extends BaseElementSelection {
    componentDidMount() {
        PresentationEditorController.showElementPanel(this.props.element);
    }
}
