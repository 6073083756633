import { AssetType, AuthoringElementType } from "common/constants";
import * as geom from "js/core/utilities/geom";

import { FramedMediaElement } from "../../base/MediaElements/FramedMediaElement";
import { CalloutElement } from "./CalloutElement";

export class MediaCallout extends CalloutElement {
    get anchorPadding() {
        return this.options.anchorPadding || this.styles.anchorPadding || 0;
    }

    get anchorBounds() {
        return this.bounds.offset(this.bounds.position);
    }

    get authoringElementType() {
        return AuthoringElementType.CONTENT;
    }

    get referencePoint() {
        return this.bounds.center;
    }

    get referencePointAnchor() {
        return geom.AnchorType.CENTER;
    }

    get lockAspectRatio() {
        return true;
    }

    get minWidth() {
        return this.minMarkerSize;
    }

    get minHeight() {
        return 50;
    }

    get minMarkerSize() {
        return 50;
    }

    get maxMarkerSize() {
        return 400;
    }

    get canChangeTextDirection() {
        return this.options.canChangeTextDirection ?? false;
    }

    getMarkerSize() {
        return Math.clamp(this.model.width ?? this.styles.markerSize ?? 10, this.minMarkerSize, this.maxMarkerSize);
    }

    get resizeDirections() {
        return {
            left: true,
            right: true,
            top: false,
            bottom: false
        };
    }

    get widthKey() {
        return "width";
    }

    get fitToContents() {
        return {
            width: true,
            height: true
        };
    }

    get maxWidth() {
        return this.maxMarkerSize;
    }

    get maxHeight() {
        return this.maxMarkerSize;
    }

    get connectionShape() {
        let frameType = this.content.frameType;
        // We want the connector to end closer to icons when they don't have a decoration
        if (frameType === "none" && this.content.assetType === AssetType.ICON) {
            frameType = "circle";
        }

        return {
            bounds: this.bounds.inflate(this.anchorPadding),
            type: frameType
        };
    }

    get canDragWhenChildSelected() {
        return true;
    }

    get canScale() {
        return true;
    }

    get allowColorOnColor() {
        return true;
    }

    get markerColor() {
        return this.model.color ?? this.findClosestOfType("CollectionElement").collectionColor;
    }

    getDefaultConnectorColor() {
        return this.markerColor;
    }

    get mediaElement() {
        return this.content;
    }

    _build() {
        this.content = this.addElement("content", () => FramedMediaElement, {
            defaultAssetType: AssetType.ICON,
            canSelect: true,
            autoHeight: true,
            frameType: this.options.frameType,
            allowUnframedImages: true,
            allowColorOnColor: true,
            frameColor: this.markerColor,
        });
    }

    _calcProps(props, options) {
        const { size } = props;

        const availableSize = new geom.Size(
            Math.min(size.width, this.maxMarkerSize), Math.min(size.width, this.maxMarkerSize)).deflate(this.selectionPadding);

        let contentProps = this.content.calcProps(availableSize);
        contentProps.bounds = new geom.Rect(this.selectionPadding, this.selectionPadding, contentProps.size);

        return { size: contentProps.size.inflate(this.selectionPadding) };
    }

    _migrate_10_02() {
        delete this.model.iconColor;
    }
}
