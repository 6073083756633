import { _ } from "../../../../../../vendor";
import { tileImages } from "../../../../../../core/utilities/imageTiling";
import * as geom from "../../../../../../core/utilities/geom";
import { themeColors } from "../../../../../../react/sharedStyles";
import React from "react";
import { ImageOption } from "../../../../../../Components/ImageOptionList";
import { DeviceFrames } from "../../../DeviceFrames";
import { CollectionItemElementSelection } from "../../../../Editor/ElementSelections/CollectionItemElementSelection";

export class PreserveAspectLayout {
    getName() {
        return "Preserve Aspect Ratios";
    }

    getContainerSelection() {
        return null;
    }

    getCellSelection() {
        return CollectionItemElementSelection;
    }

    getDefaultLayout(itemCount) {
        return { fitGridLayout: 0 };
    }

    calcCellProps(container, props) {
        const { size, gridLayout, outerGutter, innerGutter } = props;

        let getAspectRatio = item => {
            if (item.childElement.model.frameType && item.childElement.model.frameType !== "none") {
                let frame = DeviceFrames.findById(item.childElement.model.frameType);
                if (frame && frame.category !== "Shape") {
                    return frame.height / frame.width;
                } else {
                    return 1;
                }
            } else {
                return 1 / (item.childElement.contentSize?.aspectRatio ?? 1);
            }
        };

        let ratios = _.map(container.itemElements, getAspectRatio);
        let layouts = tileImages(size.width, size.height, ratios);

        let layout = gridLayout.fitGridLayout || 0;

        if (layout >= layouts.length) {
            layout = 0;
        }

        let itemBounds = _.map(layouts[layout], rect => {
            return new geom.Rect(rect.x, rect.y, rect.w, rect.h).deflate(innerGutter / 2, innerGutter / 2);
        });

        container.itemElements.forEach((item, index) => {
            let itemProps = item.calcProps(itemBounds[index].size, { fitAsset: false });
            itemProps.bounds = itemBounds[index];
        });

        return { size };
    }

    renderThumbnails(items, elementSize) {
        if (items.length == 1) return null;

        let thumbnailWidth = 50 * elementSize.width / elementSize.height;

        let scale = Math.min(thumbnailWidth / elementSize.width, 50 / elementSize.height);
        let gridThumbnails = [];

        let ratios = _.map(items, item => 1 / (item?.childElement?.contentSize?.aspectRatio ?? 1));
        let tileLayouts = tileImages(elementSize.width, elementSize.height, ratios);

        for (let i = 0; i < Math.min(10, tileLayouts.length); i++) {
            let layout = tileLayouts[i];
            gridThumbnails.push(
                <ImageOption key={i} value={{ fitGridLayout: i }}>
                    <svg width="100%" height="100%" viewBox={`0 0 ${thumbnailWidth} 50`}>
                        <rect width={thumbnailWidth} height={50} fill="white" />
                        {layout.map((cell, index) => {
                            return (
                                <rect key={index}
                                    x={cell.x * scale}
                                    y={cell.y * scale + 2}
                                    width={cell.w * scale}
                                    height={cell.h * scale}
                                    fill={themeColors.ui_blue} stroke="White" strokeWidth={2} />
                            );
                        })}
                    </svg>
                </ImageOption>
            );
        }
        return gridThumbnails;
    }
}
