import React from "react";

import * as geom from "../../../../../core/utilities/geom";
import { getSVGProps, SVGGroup } from "../../../../../core/utilities/svgHelpers";

import { Icon } from "../../base/MediaElements/IconElement";
import { BaseElement } from "../../base/BaseElement";

class PictographIcons extends BaseElement {
    // TODO: figure out why powerpoint is not rendering svg correctly
    get exportAsImage() {
        return true;
    }

    get iconId() {
        return this.model.iconId || "figure-man";
    }

    get currentValue() {
        if (this.isAnimating) {
            return this.model.value * this.animationState.value;
        }

        return this.model.value;
    }

    _build() {
        this.icon = this.addElement("icon", () => Icon, {
            icon: this.iconId,
            preventExport: true,
            preventRender: true
        });
    }

    _calcProps(props, options) {
        const { size } = props;

        const rowHeight = (size.height - (this.styles.iconRows - 1) * this.styles.iconVGap) / this.styles.iconRows;
        const iconWidth = rowHeight;

        this.icon.calcProps(new geom.Size(iconWidth, rowHeight));

        const cols = Math.ceil((size.width - this.styles.iconHGap) / (iconWidth + this.styles.iconHGap));
        const colWidth = size.width / cols;

        return { size, cols, colWidth, rowHeight };
    }

    renderChildren(transition) {
        let props = this.calculatedProps;
        const { cols, colWidth, rowHeight } = props;

        let hilitedCount = (this.styles.iconRows * cols) * this.options.valueFunction();
        if (this.isAnimating) {
            hilitedCount *= this.animationState.value;
        }

        const decorationColor = this.getDecorationColor();

        let i = 0;
        let y = 0;
        const icons = [];
        for (let row = 0; row < this.styles.iconRows; row++) {
            for (let col = 0; col < Math.min(1000, cols); col++) {
                let color, opacity;
                if (i >= hilitedCount) {
                    color = decorationColor.toRgbString();
                    opacity = this.styles.unhilited_icons.fillOpacity;
                } else {
                    color = decorationColor.toRgbString();
                    opacity = this.styles.hilited_icons.fillOpacity;
                }
                let bounds = new geom.Rect(col * colWidth, y, this.icon.calculatedProps.size);
                icons.push(
                    <g
                        key={`${row}${col}`}
                        {...getSVGProps({
                            bounds: bounds,
                            styles: { ...this.styles.icon, fillOpacity: opacity, fillColor: color }
                        })
                        }
                    >
                        {this.icon.renderChildren(transition)}
                    </g>
                );
                i++;
            }
            y += rowHeight + this.styles.iconVGap;
        }

        return <SVGGroup key={this.id}>{icons}</SVGGroup>;
    }
}

class Pictograph extends BaseElement {
    get valuePosition() {
        return this.options.valuePosition || "top";
    }

    get showValue() {
        return this.valuePosition != "none" && this.options.showValue;
    }

    get _canRollover() {
        return false;
    }

    get currentValue() {
        if (this.isAnimating) {
            return this.model.value * this.animationState.value;
        }

        return this.model.value;
    }

    get labelText() {
        return this.getRootElement().formatValue(this.currentValue);
    }

    _build() {
        this.icons = this.addElement("icons", () => PictographIcons, {
            valuePosition: this.valuePosition,
            valueFunction: this.options.valueFunction
        });

        if (this.showValue) {
            this.label = this.addElement("label", () => this.options.labelElement, {
                model: {
                    label: this.labelText
                },
                autoWidth: false,
                autoHeight: true,
                canEdit: false
            });
        }
    }

    _calcProps(props, options) {
        const { size } = props;

        if (size.height == 0) {
            return { size };
        }

        let iconsBounds = new geom.Rect(0, 0, size);
        if (this.showValue) {
            this.label.styles.applyStyles(this.styles.label.position[this.valuePosition]);

            if (this.isAnimating) {
                this.label.updateText(this.labelText);
            }

            const labelProps = this.label.calcProps(size, options);
            switch (this.valuePosition) {
                case "top":
                    labelProps.bounds = new geom.Rect(size.width / 2 - labelProps.size.width / 2, 0, labelProps.size);
                    iconsBounds = iconsBounds.deflate({ top: labelProps.size.height });
                    break;
                case "middle":
                    labelProps.bounds = new geom.Rect(size.width / 2 - labelProps.size.width / 2, size.height / 2 - labelProps.size.height / 2, labelProps.size);
                    break;
                case "bottom":
                    labelProps.bounds = new geom.Rect(size.width / 2 - labelProps.size.width / 2, size.height - labelProps.size.height, labelProps.size);
                    iconsBounds = iconsBounds.deflate({ bottom: labelProps.size.height });
                    break;
            }
        }

        const iconsProps = this.icons.calcProps(iconsBounds.size, options);
        iconsProps.bounds = iconsBounds;

        return { size };
    }
}

export { Pictograph };
