import { _ } from "js/vendor";
import {
    DashboardControlBar,
    DashboardItemPropertyPanel,
    DashboardPlaceholderDefaultOverlay,
    DashboardPlaceholderPropertyPanel,
    DashboardPropertyPanel
} from "../../../Editor/ElementPropertyPanels/GridContainer/DashboardUI";
import {
    GridLayoutContainer,
    LayoutEngineType
} from "../LayoutContainers/GridLayoutContainer";
import { InfographicElementTypes } from "./InfographicManager";

class Dashboard extends GridLayoutContainer {
    static get schema() {
        return {
            layoutEngineType: LayoutEngineType.DASHBOARD,
            cellFrameType: "none",
        };
    }

    getElementPropertyPanel() {
        return DashboardPropertyPanel;
    }

    getElementControlBar() {
        return DashboardControlBar;
    }

    getSlideIdeas() {
        if (this.itemElements.length > 1) {
            // disable slide ideas for multi-cell dashboards
            return [];
        }
    }

    get defaultItemData() {
        return {
            childElement: {}
        };
    }

    get layoutEngineType() {
        if (this.options.singleCellGridLayout) {
            return LayoutEngineType.SINGLE_CELL;
        }

        return this.model.layoutEngineType ?? LayoutEngineType.DASHBOARD;
    }

    get showGutter() {
        return true;
    }

    _build() {
        if (this.hasStoredPropChanged("cellsCount", this.itemCollection.length)) {
            if (this.itemCollection.length > 1) {
                this.model.showTitle = "above";
            }
        }

        this.options.itemPropertyPanel = DashboardItemPropertyPanel;
        this.options.placeholderPropertyPanel = DashboardPlaceholderPropertyPanel;
        this.options.placeholderDefaultOverlay = DashboardPlaceholderDefaultOverlay;

        super._build();
    }

    _exportToSharedModel() {
        return this.itemElements.reduce(
            (model, itemElement) => _.mergeWith(model, itemElement.childElement._exportToSharedModel(), (a, b) => {
                if (_.isArray(a)) return a.concat(b);
            }), {});
    }

    _importFromSharedModel(model) {
        const items = this.itemElements.map(itemElement => ({
            ...itemElement.model, chart: itemElement.childElement._importFromSharedModel(model)
        }));

        const itemsWithPostProcess = items.filter(item => !!item.chart?.postProcessingFunction);
        const postProcessingFunction = itemsWithPostProcess.length
            ? canvas => itemsWithPostProcess.forEach(item => item.chart.postProcessingFunction(canvas)) : null;

        return { items, postProcessingFunction };
    }

    _migrate_10_02() {
        if (this.model.cells && this.model.cols && this.model.rows && this.canvas.model.template_id == "table-v2") {
            // Migrate a table to a single cell dashboard
            const tableModel = _.cloneDeep(this.model);

            Object.keys(this.model).forEach(key => {
                delete this.model[key];
            });

            this.model.items = [{
                componentType: InfographicElementTypes.TABLE,
                childElement: tableModel,
                cellColor: "none"
            }];
        } else {
            for (let item of this.itemCollection) {
                if (item.elementType == "piechart") {
                    item.componentType = InfographicElementTypes.PIE_CHART;
                    item.childElement = item.piechart;
                    item.childElement.data?.forEach(data => {
                        if (data.color == "auto") {
                            data.color = null;
                        }
                    });
                    item.childElement.collectionColor = this.canvas.getSlideColor();
                } else {
                    item.componentType = InfographicElementTypes.CHART;
                    item.childElement = item.chart;
                }
                if (this.model.showTitle === true || this.model.showTitle === "above") {
                    item.showTitle = "above";
                } else if (this.model.showTitle === "below") {
                    item.showTitle = "below";
                }

                item.cellColor = "none";
                delete item.chart;
                delete item.piechart;
                delete item.elementType;
            }
        }

        this.model.layoutEngineType = LayoutEngineType.DASHBOARD;

        if (this.itemCollection.length == 2) {
            switch (this.model.layout) {
                case "2-charts-vertical":
                    this.model.gridLayout = 1;
                    break;
                case "2-charts-horizontal":
                default:
                    this.model.gridLayout = 0;
                    break;
            }
        } else if (this.itemCollection.length == 3) {
            switch (this.model.layout) {
                case "3-charts-right":
                    this.model.gridLayout = 2;
                    break;
                case "3-charts-top":
                    this.model.gridLayout = 3;
                    break;
                case "3-charts-bottom":
                    this.model.gridLayout = 4;
                    break;
                case "3-charts-horizontal":
                    this.model.gridLayout = 1;
                    break;
                case "3-charts-left":
                default:
                    this.model.gridLayout = 0;
                    break;
            }
        }

        super._migrate_10_02();
    }
}

export const elements = {
    Dashboard
};
