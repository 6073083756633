export const DeviceFrames = [
    {
        "id": "none",
        "name": "No Frame",
        "file": "",
        "thumbnail": "frame_none.png",
        "category": "Shape",
        "fixedAspectRatio": false,
    },
    {
        "id": "rect",
        "name": "Rect",
        "file": "",
        "thumbnail": "Square.svg",
        "category": "Shape",
        "fixedAspectRatio": false,
    },
    {
        "id": "circle",
        "name": "Circle",
        "file": "",
        "thumbnail": "Circle.svg",
        "category": "Shape",
        "fixedAspectRatio": true,
    },
    {
        "id": "rounded_rect",
        "name": "Rounded Rect",
        "file": "",
        "thumbnail": "Rounded.svg",
        "category": "Shape",
        "fixedAspectRatio": false,
    },
    {
        "id": "octagon",
        "name": "Octagon",
        "file": "",
        "thumbnail": "Octagon.svg",
        "category": "Shape",
        "fixedAspectRatio": true,
    },
    {
        "id": "ipad_pro_13_inch_portait",
        "name": "Apple iPad Pro Portait",
        "file": "iPad-Pro-13-Inch-Portait.png",
        "thumbnail": "iPad-Pro-13-Inch-Portait-Thumbnail.png",
        "category": "Other",
        "fixedAspectRatio": true,
        "isFront": true,
        "clip": {
            "left": 93,
            "top": 95,
            "width": 2064,
            "height": 2752,
        },
        "cornerRadius": 59,
        "width": 2250,
        "height": 2937,
    },
    {
        "id": "ipad_mini_landscape",
        "name": "Apple iPad Mini Landscape",
        "file": "iPad-Mini-Landscape.png",
        "thumbnail": "iPad-Mini-Landscape-Thumbnail.png",
        "category": "Other",
        "fixedAspectRatio": true,
        "isFront": true,
        "clip": {
            "left": 124,
            "top": 120,
            "width": 2266,
            "height": 1488,
        },
        "cornerRadius": 43,
        "width": 2510,
        "height": 1728,
        "isHidden": true
    },
    {
        "id": "ipad_mini_portait",
        "name": "Apple iPad Mini Portait",
        "file": "iPad-Mini-Portait.png",
        "thumbnail": "iPad-Mini-Portait-Thumbnail.png",
        "category": "Other",
        "fixedAspectRatio": true,
        "isFront": true,
        "clip": {
            "left": 120,
            "top": 124,
            "width": 1488,
            "height": 2266,
        },
        "cornerRadius": 43,
        "width": 1728,
        "height": 2510,
        "isHidden": true
    },
    {
        "id": "ipad_pro_13_inch_landscape",
        "name": "Apple iPad Pro Landscape",
        "file": "iPad-Pro-13-Inch-Landscape.png",
        "thumbnail": "iPad-Pro-13-Inch-Landscape-Thumbnail.png",
        "category": "Other",
        "fixedAspectRatio": true,
        "isFront": true,
        "clip": {
            "left": 95,
            "top": 177,
            "width": 2752,
            "height": 2064,
        },
        "cornerRadius": 59,
        "width": 2937,
        "height": 2334,
    },
    {
        "id": "ipad_pro_11_inch_portait",
        "name": "Apple iPad Pro 11 Inch Portait",
        "file": "iPad-Pro-11-Inch-Portait.png",
        "thumbnail": "iPad-Pro-11-Inch-Portait-Thumbnail.png",
        "category": "Other",
        "fixedAspectRatio": true,
        "isFront": true,
        "clip": {
            "left": 91,
            "top": 95,
            "width": 1668,
            "height": 2420,
        },
        "cornerRadius": 60,
        "width": 1854,
        "height": 2605,
        "isHidden": true,
    },
    {
        "id": "apple_watch_ultra",
        "name": "Apple Watch Ultra",
        "file": "Apple-Watch-Ultra.png",
        "thumbnail": "Apple-Watch-Ultra-Thumbnail.png",
        "category": "Other",
        "fixedAspectRatio": true,
        "isFront": true,
        "clip": {
            "left": 62,
            "top": 197,
            "width": 410,
            "height": 502,
        },
        "cornerRadius": 108,
        "width": 563,
        "height": 897,
    },
    {
        "id": "polaroid",
        "name": "Polaroid",
        "file": "polaroid2.png",
        "thumbnail": "polaroid.png",
        "category": "Other",
        "fixedAspectRatio": true,
        "isFront": true,
        "clip": {
            "left": 100,
            "top": 72,
            "width": 635,
            "height": 650,
        },
        "width": 848,
        "height": 1000,
    },
    {
        "id": "apple_imac",
        "name": "Apple iMac",
        "file": "Apple iMac.png",
        "thumbnail": "Apple iMac.png",
        "category": "Computers",
        "fixedAspectRatio": true,
        "clip": {
            "left": 49,
            "top": 52,
            "width": 1111,
            "height": 623,
        },
        "width": 1208,
        "height": 1002,
        "isHidden": true,
    },
    {
        "id": "apple_macbook_air_13",
        "name": "Apple Macbook Air 13 Inch",
        "file": "Apple Macbook Air 13.png",
        "thumbnail": "Apple Macbook Air 13.png",
        "category": "Computers",
        "fixedAspectRatio": true,
        "clip": {
            "left": 262,
            "top": 80,
            "width": 1440,
            "height": 900,
        },
        "isHidden": true,
        "width": 1962,
        "height": 1145,
    },
    {
        "id": "apple_macbook_space_grey",
        "name": "Apple Macbook",
        "file": "Apple-Macbook-Space-Grey.png",
        "thumbnail": "Apple-Macbook-Space-Grey.png",
        "category": "Computers",
        "fixedAspectRatio": true,
        "clip": {
            "left": 213,
            "top": 72,
            "width": 1296,
            "height": 809,
        },
        "width": 1723,
        "height": 1009,
        "isHidden": true,
    },
    {
        "id": "macBook_pro_16_inch",
        "name": "Apple MacBook Pro 16 Inch",
        "file": "MacBook-Pro-16-Inch.png",
        "thumbnail": "MacBook-Pro-16-Inch-Thumbnail.png",
        "category": "Computers",
        "fixedAspectRatio": true,
        "isFront": true,
        "clip": {
            "left": 382,
            "top": 56,
            "width": 3456,
            "height": 2234,
        },
        "width": 4220,
        "height": 2577,
        "isHidden": true
    },
    {
        "id": "macBook_pro_14_inch",
        "name": "Apple MacBook Pro 14 Inch",
        "file": "MacBook-Pro-14-Inch.png",
        "thumbnail": "MacBook-Pro-14-Inch-Thumbnail.png",
        "category": "Computers",
        "fixedAspectRatio": true,
        "isFront": true,
        "clip": {
            "left": 390,
            "top": 55,
            "width": 3024,
            "height": 1964,
        },
        "width": 3804,
        "height": 2296,
    },
    {
        "id": "macBook_air_m2",
        "name": "Apple MacBook Air M2",
        "file": "MacBook-Air-M2.png",
        "thumbnail": "MacBook-Air-M2-Thumbnail.png",
        "category": "Computers",
        "fixedAspectRatio": true,
        "isFront": true,
        "clip": {
            "left": 324,
            "top": 66,
            "width": 2560,
            "height": 1664,
        },
        "width": 3207,
        "height": 1942,
        "isHidden": true
    },
    {
        "id": "iMac_24_inch",
        "name": "Apple IMac 24 Inch",
        "file": "iMac-24-Inch.png",
        "thumbnail": "iMac-24-Inch-Thumbnail.png",
        "category": "Computers",
        "fixedAspectRatio": true,
        "isFront": true,
        "clip": {
            "left": 119,
            "top": 120,
            "width": 4480,
            "height": 2520,
        },
        "width": 4718,
        "height": 3977,
    },
    {
        "id": "hp_omnibook_ultra",
        "name": "HP Omnibook Ultra",
        "file": "HP-Omnibook-Ultra.png",
        "thumbnail": "HP-Omnibook-Ultra.png",
        "category": "Computers",
        "fixedAspectRatio": true,
        "isFront": true,
        "clip": {
            "left": 243,
            "top": 91,
            "width": 2220,
            "height": 1400,
        },
        "width": 2706,
        "height": 2336,
    },
    {
        "id": "dell_xps_15",
        "name": "Dell XPS 15 Inch",
        "file": "Dell-XPS-15.png",
        "thumbnail": "Dell XPS 17.png",
        "category": "Computers",
        "fixedAspectRatio": true,
        "clip": {
            "left": 341,
            "top": 31,
            "width": 1920,
            "height": 1080,
        },
        "width": 2597,
        "height": 1322,
    },
    {
        "id": "microsoft_surface_book",
        "name": "Microsoft Surface Book",
        "file": "Microsoft Surface Book.png",
        "thumbnail": "Microsoft Surface Book.png",
        "category": "Computers",
        "fixedAspectRatio": true,
        "clip": {
            "left": 233,
            "top": 65,
            "width": 1252,
            "height": 834,
        },
        "width": 1719,
        "height": 1005,
    },
    {
        "id": "apple_thunderbolt_display",
        "name": "Apple Thunderbolt Display",
        "file": "Thunderbolt Display 27.png",
        "thumbnail": "Thunderbolt Display 28.png",
        "category": "Displays",
        "fixedAspectRatio": true,
        "clip": {
            "left": 52,
            "top": 52,
            "width": 1205,
            "height": 679,
        },
        "width": 1308,
        "height": 1002,
    },
    {
        "id": "dell_ultrasharp_hd_5k",
        "name": "Dell Ultrasharp HD 5K Display",
        "file": "Dell UltraSharp Ultra HD 5K Monitor 27.png",
        "thumbnail": "Dell UltraSharp Ultra HD 5K Monitor 29.png",
        "category": "Displays",
        "fixedAspectRatio": true,
        "clip": {
            "left": 38.24,
            "top": 40,
            "width": 1173,
            "height": 660,
        },
        "width": 1249,
        "height": 1001,
    },
    {
        "id": "sony_w850C",
        "name": "Sony W85OC 75",
        "file": "Sony W85OC 75.png",
        "thumbnail": "Sony W85OC 77.png",
        "category": "Displays",
        "fixedAspectRatio": true,
        "clip": {
            "left": 24,
            "top": 13,
            "width": 1286,
            "height": 721,
        },
        "width": 1360,
        "height": 829,
    },
    {
        "id": "iphone_x",
        "name": "Apple iPhone X",
        "file": "Apple iPhone X Silver.png",
        "thumbnail": "Apple iPhone X Silver.png",
        "category": "Phones",
        "fixedAspectRatio": true,
        "isFront": true,
        "clip": {
            "left": 50,
            "top": 64,
            "width": 404,
            "height": 872,
        },
        "isHidden": true,
        "width": 503,
        "height": 1000,
    },
    {
        "id": "apple_iphone_5c",
        "name": "Apple iPhone 5c",
        "file": "Apple iPhone 5c.png",
        "thumbnail": "Apple iPhone 5c.png",
        "category": "Phones",
        "fixedAspectRatio": true,
        "clip": {
            "left": 41,
            "top": 147,
            "width": 396,
            "height": 703,
        },
        "isHidden": true,
        "width": 474,
        "height": 1011,
    },
    {
        "id": "apple_iphone_7_matte_black",
        "name": "Apple iPhone 7 Black",
        "file": "Apple iPhone 7 Matte Black.png",
        "thumbnail": "Apple iPhone 7 Matte Black.png",
        "category": "Phones",
        "fixedAspectRatio": true,
        "clip": {
            "left": 63,
            "top": 155,
            "width": 388,
            "height": 690,
        },
        "isHidden": true,
        "width": 512,
        "height": 1000,
    },
    {
        "id": "apple_iphone_7_silver",
        "name": "Apple iPhone 7 Silver",
        "file": "Apple iPhone 7 Silver.png",
        "thumbnail": "Apple iPhone 7 Silver.png",
        "category": "Phones",
        "fixedAspectRatio": true,
        "clip": {
            "left": 63,
            "top": 155,
            "width": 388,
            "height": 690,
        },
        "isHidden": true,
        "width": 512,
        "height": 1000,
    },
    {
        "id": "google_pixel",
        "name": "Google Pixel",
        "file": "Google Pixel.png",
        "thumbnail": "Google Pixel.png",
        "category": "Phones",
        "fixedAspectRatio": true,
        "clip": {
            "left": 25,
            "top": 101.6,
            "width": 435,
            "height": 774,
        },
        "isHidden": true,
        "width": 489,
        "height": 1013,
    },
    {
        "id": "samsung_galaxy_s7_black",
        "name": "Samsung Galaxy S7 Black",
        "file": "Samsung Galaxy S7 Black.png",
        "thumbnail": "Samsung Galaxy S7 Black.png",
        "category": "Phones",
        "fixedAspectRatio": true,
        "clip": {
            "left": 42,
            "top": 118,
            "width": 429,
            "height": 762,
        },
        "isHidden": true,
        "width": 512,
        "height": 1000,
    },
    {
        "id": "samsung_galaxy_s7_silver",
        "name": "Samsung Galaxy S7 Silver",
        "file": "Samsung Galaxy S7 Silver.png",
        "thumbnail": "Samsung Galaxy S7 Silver.png",
        "category": "Phones",
        "fixedAspectRatio": true,
        "clip": {
            "left": 43,
            "top": 118,
            "width": 427,
            "height": 761,
        },
        "isHidden": true,
        "width": 512,
        "height": 1000,
    },
    {
        "id": "apple_watch",
        "name": "Apple Watch",
        "file": "apple watch.png",
        "thumbnail": "apple watch.png",
        "category": "Other",
        "fixedAspectRatio": true,
        "clip": {
            "left": 76,
            "top": 240,
            "width": 314,
            "height": 390,
        },
        "isHidden": true,
        "width": 486,
        "height": 870,
    },
    {
        "id": "ipad_portrait",
        "name": "Apple iPad Portrait",
        "file": "Apple iPad Air 2 Silver.png",
        "thumbnail": "Apple iPad Air 2 Silver.png",
        "category": "Other",
        "fixedAspectRatio": true,
        "clip": {
            "left": 60,
            "top": 107,
            "width": 590,
            "height": 785,
        },
        "isHidden": true,
        "width": 712,
        "height": 1000,
    },
    {
        "id": "ipad_landscape",
        "name": "Apple iPad Landscape",
        "file": "Apple iPad Air 2 Silver Landscape.png",
        "thumbnail": "Apple iPad Air 2 Silver Landscape.png",
        "category": "Other",
        "fixedAspectRatio": true,
        "clip": {
            "left": 108,
            "top": 66,
            "width": 786,
            "height": 590,
        },
        "isHidden": true,
        "width": 1003,
        "height": 720,
    },
    {
        "id": "microsoft_surface_pro_4",
        "name": "Microsoft Surface Pro 4",
        "file": "Microsoft Surface Pro 4.png",
        "thumbnail": "Microsoft Surface Pro 4.png",
        "category": "Other",
        "fixedAspectRatio": true,
        "clip": {
            "left": 107,
            "top": 89,
            "width": 1232,
            "height": 822,
        },
        "isHidden": true,
        "width": 1446,
        "height": 1000,
    },
    {
        "id": "cisco_board_pro",
        "name": "Webex BoardPro",
        "file": "Cisco Board Pro.png",
        "thumbnail": "Cisco Board Pro.png",
        "category": "Cisco",
        "fixedAspectRatio": true,
        "clip": {
            "left": 128,
            "top": 59,
            "width": 1179,
            "height": 662,
        },
        "width": 1466,
        "height": 1478,
    },
    {
        "id": "cisco_board_pro_wall",
        "name": "Webex BoardPro Wall",
        "file": "Cisco Board Pro Wall Mount.png",
        "thumbnail": "Cisco Board Pro Wall Mount.png",
        "category": "Cisco",
        "fixedAspectRatio": true,
        "clip": {
            "left": 153,
            "top": 110,
            "width": 1692,
            "height": 952,
        },
        "width": 2000,
        "height": 1257,
    },
    {
        "id": "cisco_desk",
        "name": "Webex Desk",
        "file": "Cisco Desk.png",
        "thumbnail": "Cisco Desk.png",
        "category": "Cisco",
        "fixedAspectRatio": true,
        "clip": {
            "left": 200,
            "top": 130,
            "width": 1600,
            "height": 900,
        },
        "width": 2000,
        "height": 1490,
    },
    {
        "id": "cisco_desk_mini",
        "name": "Webex Desk Mini",
        "file": "Cisco Desk Mini.png",
        "thumbnail": "Cisco Desk Mini.png",
        "category": "Cisco",
        "fixedAspectRatio": true,
        "clip": {
            "left": 196,
            "top": 84,
            "width": 1225,
            "height": 693,
        },
        "width": 1628,
        "height": 1526,
    },
    {
        "id": "ipad_13_portrait",
        "name": "Apple iPad",
        "file": "Apple iPad Pro 13 Silver - Portrait.png",
        "thumbnail": "Apple iPad Pro 13 Silver - Portrait.png",
        "category": "Other",
        "fixedAspectRatio": true,
        "isFront": true,
        "clip": {
            "left": 63,
            "top": 56,
            "width": 656,
            "height": 881,
        },
        "width": 782,
        "height": 1000,
        "isHidden": true,
    },
    {
        "id": "ipad_13_landscape",
        "name": "Apple iPad",
        "file": "Apple iPad Pro 13 Silver - Landscape.png",
        "thumbnail": "Apple iPad Pro 13 Silver - Landscape.png",
        "category": "Other",
        "fixedAspectRatio": true,
        "isFront": true,
        "clip": {
            "left": 81,
            "top": 81,
            "width": 1117,
            "height": 838,
        },
        "width": 1279,
        "height": 1000,
        "isHidden": true,
    },
    {
        "id": "apple_watch_6",
        "name": "Apple Watch",
        "file": "Apple Watch 6.png",
        "thumbnail": "Apple Watch 6.png",
        "category": "Other",
        "fixedAspectRatio": true,
        "isFront": true,
        "clip": {
            "left": 138,
            "top": 259,
            "width": 397,
            "height": 483,
        },
        "width": 673,
        "height": 1000,
        "isHidden": true,
    },
    {
        "id": "iphone_15_pro",
        "name": "Apple iPhone 15 Pro",
        "file": "iPhone-15-Pro.png",
        "thumbnail": "iPhone-15-Pro-Thumbnail.png",
        "category": "Phones",
        "fixedAspectRatio": true,
        "isFront": true,
        "clip": {
            "left": 58,
            "top": 49,
            "width": 1179,
            "height": 2556,
        },
        "cornerRadius": 166,
        "width": 1296,
        "height": 2655,
    },
    {
        "id": "iphone_15_pro_max",
        "name": "Apple iPhone 15 Pro Max",
        "file": "iPhone-15-Pro-Max.png",
        "thumbnail": "iPhone-15-Pro-Max-Thumbnail.png",
        "category": "Phones",
        "fixedAspectRatio": true,
        "isFront": true,
        "clip": {
            "left": 57,
            "top": 50,
            "width": 1290,
            "height": 2796,
        },
        "cornerRadius": 164,
        "width": 1404,
        "height": 2896,
        "isHidden": true
    },
    {
        "id": "google_pixel_5_landscape",
        "name": "Google Pixel",
        "file": "Google Pixel 5 - Landscape.png",
        "thumbnail": "Google Pixel 5 - Landscape.png",
        "category": "Phones",
        "fixedAspectRatio": true,
        "isFront": true,
        "clip": {
            "left": 134,
            "top": 135,
            "width": 1582,
            "height": 730,
        },
        "width": 1851,
        "height": 1000,
        "isHidden": true
    },
    {
        "id": "google_pixel_5_portrait",
        "name": "Google Pixel",
        "file": "Google Pixel 5 - Portrait.png",
        "thumbnail": "Google Pixel 5 - Portrait.png",
        "category": "Phones",
        "fixedAspectRatio": true,
        "isFront": true,
        "clip": {
            "left": 70,
            "top": 70,
            "width": 398,
            "height": 860,
        },
        "width": 540,
        "height": 1000,
        "isHidden": true
    },
    {
        "id": "samsung_galaxy_s21_landscape",
        "name": "Samsung Galaxy",
        "file": "Samsung Galaxy S21 5G - Landscape.png",
        "thumbnail": "Samsung Galaxy S21 5G - Landscape.png",
        "category": "Phones",
        "fixedAspectRatio": true,
        "isFront": true,
        "clip": {
            "left": 135,
            "top": 135,
            "width": 1622,
            "height": 730,
        },
        "width": 1892,
        "height": 1000,
        "isHidden": true
    },
    {
        "id": "samsung_galaxy_s21_portrait",
        "name": "Samsung Galaxy",
        "file": "Samsung Galaxy S21 5G - Portrait.png",
        "thumbnail": "Samsung Galaxy S21 5G - Portrait.png",
        "category": "Phones",
        "fixedAspectRatio": true,
        "isFront": true,
        "clip": {
            "left": 71,
            "top": 71,
            "width": 387,
            "height": 857,
        },
        "width": 529,
        "height": 1000,
    },
    {
        "id": "microsoft_surface_pro_X",
        "name": "Microsoft Surface",
        "file": "Surface Pro X - Landscape.png",
        "thumbnail": "Surface Pro X - Landscape.png",
        "category": "Other",
        "fixedAspectRatio": true,
        "isFront": true,
        "clip": {
            "left": 84,
            "top": 79,
            "width": 1262,
            "height": 842,
        },
        "width": 1429,
        "height": 1000,
    },
    {
        "id": "iphone_14_portrait",
        "name": "Apple iPhone",
        "file": "iPhone 14 - Midnight.png",
        "thumbnail": "iPhone 14 - Midnight.png",
        "category": "Phones",
        "fixedAspectRatio": true,
        "isFront": true,
        "clip": {
            "left": 60,
            "top": 55,
            "width": 407,
            "height": 881,
        },
        "cornerRadius": 30,
        "width": 524,
        "height": 1000,
        "isHidden": true,
    },
    {
        "id": "iphone_14_landscape",
        "name": "Apple iPhone",
        "file": "iPhone 14 - Landscape.png",
        "thumbnail": "iPhone 14 - Landscape.png",
        "category": "Phones",
        "fixedAspectRatio": true,
        "isFront": true,
        "clip": {
            "left": 106,
            "top": 109,
            "width": 1678,
            "height": 776,
        },
        "width": 1907,
        "height": 1000,
        "isHidden": true,
    },
    {
        "id": "browser_solid",
        "name": "Browser Black",
        "file": "Browser-solid.png",
        "thumbnail": "Browser-solid.png",
        "category": "Other",
        "fixedAspectRatio": true,
        "isFront": true,
        "clip": {
            "left": 50,
            "top": 130,
            "width": 1600,
            "height": 914,
        },
        "width": 1700,
        "height": 1100,
    },
    {
        "id": "browser_stroke",
        "name": "Browser White",
        "file": "Browser-stroke.png",
        "thumbnail": "Browser-stroke.png",
        "category": "Other",
        "fixedAspectRatio": true,
        "isFront": true,
        "clip": {
            "left": 62,
            "top": 126,
            "width": 1570,
            "height": 915,
        },
        "width": 1693,
        "height": 1100,
    },
    {
        "id": "desktop_solid",
        "name": "Black",
        "file": "Desktop-solid.png",
        "thumbnail": "Desktop-solid.png",
        "category": "Computers",
        "fixedAspectRatio": true,
        "isFront": true,
        "clip": {
            "left": 90,
            "top": 93,
            "width": 1193,
            "height": 699,
        },
        "width": 1372,
        "height": 1100,
    },
    {
        "id": "desktop_stroke",
        "name": "White",
        "file": "Desktop-stroke.png",
        "thumbnail": "Desktop-stroke.png",
        "category": "Computers",
        "fixedAspectRatio": true,
        "isFront": true,
        "clip": {
            "left": 91,
            "top": 94,
            "width": 1193,
            "height": 699,
        },
        "width": 1382,
        "height": 1110,
    },
    {
        "id": "laptop_solid",
        "name": "Black",
        "file": "Laptop-solid.png",
        "thumbnail": "Laptop-solid.png",
        "category": "Computers",
        "fixedAspectRatio": true,
        "isFront": true,
        "clip": {
            "left": 175,
            "top": 112,
            "width": 1350,
            "height": 820,
        },
        "width": 1700,
        "height": 1100,
    },
    {
        "id": "laptop_stroke",
        "name": "White",
        "file": "Laptop-stroke.png",
        "thumbnail": "Laptop-stroke.png",
        "category": "Computers",
        "fixedAspectRatio": true,
        "isFront": true,
        "clip": {
            "left": 182,
            "top": 120,
            "width": 1330,
            "height": 804,
        },
        "width": 1694,
        "height": 1100,
    },
    {
        "id": "phone_landscape_solid",
        "name": "Black Landscape",
        "file": "Phone-landscape-solid.png",
        "thumbnail": "Phone-landscape-solid.png",
        "category": "Phones",
        "fixedAspectRatio": true,
        "isFront": true,
        "clip": {
            "left": 95,
            "top": 95,
            "width": 1512,
            "height": 690,
        },
        "width": 1699,
        "height": 878,
        "isHidden": true
    },
    {
        "id": "phone_landscape_stroke",
        "name": "White Landscape",
        "file": "Phone-landscape-stroke.png",
        "thumbnail": "Phone-landscape-stroke.png",
        "category": "Phones",
        "fixedAspectRatio": true,
        "isFront": true,
        "clip": {
            "left": 104,
            "top": 105,
            "width": 1494,
            "height": 677,
        },
        "width": 1699,
        "height": 884,
        "isHidden": true
    },
    {
        "id": "phone_portrait_solid",
        "name": "Black",
        "file": "Phone-portrait-solid.png",
        "thumbnail": "Phone-portrait-solid.png",
        "category": "Phones",
        "fixedAspectRatio": true,
        "isFront": true,
        "clip": {
            "left": 78,
            "top": 78,
            "width": 431,
            "height": 945,
        },
        "width": 587,
        "height": 1100,
    },
    {
        "id": "phone_portrait_stroke",
        "name": "White",
        "file": "Phone-portrait-stroke.png",
        "thumbnail": "Phone-portrait-stroke.png",
        "category": "Phones",
        "fixedAspectRatio": true,
        "isFront": true,
        "clip": {
            "left": 82,
            "top": 83,
            "width": 423,
            "height": 935,
        },
        "width": 589,
        "height": 1100,
    },
    {
        "id": "tablet_landscape_solid",
        "name": "Tablet Black Landscape",
        "file": "Tablet-landscape-solid.png",
        "thumbnail": "Tablet-landscape-solid.png",
        "category": "Other",
        "fixedAspectRatio": true,
        "isFront": true,
        "clip": {
            "left": 147,
            "top": 107,
            "width": 1185,
            "height": 885,
        },
        "width": 1510,
        "height": 1100,
    },
    {
        "id": "tablet_landscape_stroke",
        "name": "Tablet White Landscape",
        "file": "Tablet-landscape-stroke.png",
        "thumbnail": "Tablet-landscape-stroke.png",
        "category": "Other",
        "fixedAspectRatio": true,
        "isFront": true,
        "clip": {
            "left": 156,
            "top": 117,
            "width": 1176,
            "height": 876,
        },
        "width": 1520,
        "height": 1110,
    },
    {
        "id": "tablet_portrait_solid",
        "name": "Tablet Black",
        "file": "Tablet-portrait-solid.png",
        "thumbnail": "Tablet-portrait-solid.png",
        "category": "Other",
        "fixedAspectRatio": true,
        "isFront": true,
        "clip": {
            "left": 91,
            "top": 118,
            "width": 628,
            "height": 839,
        },
        "width": 810,
        "height": 1100,
    },
    {
        "id": "tablet_portrait_stroke",
        "name": "Tablet White",
        "file": "Tablet-portrait-stroke.png",
        "thumbnail": "Tablet-portrait-stroke.png",
        "category": "Other",
        "fixedAspectRatio": true,
        "isFront": true,
        "clip": {
            "left": 96,
            "top": 124,
            "width": 619,
            "height": 829,
        },
        "width": 812,
        "height": 1100,
    },
    {
        "id": "triangle1",
        "name": "Triangle 1",
        "file": "",
        "thunbnail": "",
        "category": "Masks",
    },
    {
        "id": "triangle2",
        "name": "Triangle 2",
        "file": "",
        "thunbnail": "",
        "category": "Masks",
    },
    {
        "id": "parallelogram",
        "name": "parallelogram",
        "file": "",
        "thunbnail": "",
        "category": "Masks",
    },
    {
        "id": "stacked-capsules",
        "name": "stacked-capsules",
        "file": "",
        "thunbnail": "",
        "category": "Masks",
    },
    {
        "id": "arrows",
        "name": "arrows",
        "file": "",
        "thunbnail": "",
        "category": "Masks",
    },
    {
        "id": "boxes",
        "name": "boxes",
        "file": "",
        "thunbnail": "",
        "category": "Masks",
    },
];
