import React, { Component } from "react";
import styled from "styled-components";

import { isPPTAddin } from "js/config";

import { Icon } from "js/Components/Icon";
import { WithLabel } from "js/Components/WithLabel";
import { themeColors } from "js/react/sharedStyles";

import { PropertyPanelContainer } from "../../EditorComponents/PropertyPanel";

const Container = styled.div`
    height: 40px;
    width: 100%;
    background: #ddd;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-shrink: 0;
`;

const SlideMenuBarItem = styled.div`
    background: ${props => props.selected ? "#fff" : "none"};
    height: 100%;
    width: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 10px;
    cursor: default;

    .bai-icon {
        color: ${props => props.selected ? themeColors.ui_blue : "#555"};
    }

    .label-container {
        color: #666;
        color: ${themeColors.ui_blue};
        opacity: 0;
        transition: 300ms;

        label {
            font-size: 8px !important;
        }
    }

    &:hover {
        .label-container {
            opacity: 1;
        }
    }
`;

export class SlideMenuBar extends Component {
    render() {
        const { selectedTab, onChange, children, allowStyleChange = true } = this.props;

        return (
            <PropertyPanelContainer fullHeight>
                <Container>
                    <SlideMenuBarItem key="element" selected={selectedTab === "element"} onClick={() => onChange("element")}>
                        <WithLabel label="Element" small below center gap={-2}>
                            <Icon fill size={20}>app_registration</Icon>
                        </WithLabel>
                    </SlideMenuBarItem>
                    <SlideMenuBarItem key="add" selected={selectedTab === "add"} onClick={() => onChange("add")}>
                        <WithLabel label="Add" small below center gap={-2}>
                            <Icon fill size={20}>add</Icon>
                        </WithLabel>
                    </SlideMenuBarItem>
                    <SlideMenuBarItem key="ideas" selected={selectedTab === "ideas"} onClick={() => onChange("ideas")}>
                        <WithLabel label="Ideas" small below center gap={-2}>
                            <Icon size={20}>emoji_objects</Icon>
                        </WithLabel>
                    </SlideMenuBarItem>
                    {allowStyleChange && <SlideMenuBarItem key="styles" selected={selectedTab === "styles"} onClick={() => onChange("styles")}>
                        <WithLabel label="Styles" small below center gap={-2}>
                            <Icon size={20}>palette</Icon>
                        </WithLabel>
                    </SlideMenuBarItem>}
                    {!isPPTAddin && <SlideMenuBarItem key="animate" selected={selectedTab === "animate"} onClick={() => onChange("animate")}>
                        <WithLabel label="Playback" small below center gap={-2}>
                            <Icon size={20}>play_circle</Icon>
                        </WithLabel>
                    </SlideMenuBarItem>}
                    <SlideMenuBarItem key="manage-slide" selected={selectedTab === "manage-slide"} onClick={() => onChange("manage-slide")}>
                        <WithLabel label="More" small below center gap={-2}>
                            <Icon size={20}>more_vert</Icon>
                        </WithLabel>
                    </SlideMenuBarItem>

                </Container>
                {children}
            </PropertyPanelContainer>
        );
    }
}

