import React, { Component } from "react";
import styled from "styled-components";

import { Button, Icon } from "@material-ui/core";

import { AbsoluteBox, FlexBox, ScrollBox } from "../../../../react/components/LayoutGrid";
import { $ } from "../../../../vendor";
import { getStaticUrl } from "../../../../config";
import { themeColors } from "../../../../react/sharedStyles";
import { FlexSpacer, Gap10, Gap20 } from "../../../../react/components/Gap";
import { Popup, PopupContent, PopupPreview } from "../../../../Components/Popup";
import { StaticImage } from "../../../../Components/StaticImage";

import { DeviceFrames } from "../../elements/DeviceFrames";
import { calcElementFrameProps } from "../../elements/base/ElementFrame";
import { MixedValue } from "../../../../Components/MixedValue";

const Menu = styled.div`
    display: flex;
    background: #eee;
    width: 100%;
    align-items: center;
    padding-right: 20px;
`;

const MenuItem = styled.div`
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 600;
    text-align: center;
    border-bottom: ${props => props.selected ? `solid 3px ${themeColors.ui_blue}` : "solid 3px #eee"};
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px 30px;
`;

export class ImageFramePopupMenu extends Component {
    popupRef = React.createRef();

    handleContentChange = () => {
        if (this.popupRef.current) {
            this.popupRef.current.updatePopupPosition();
        }
    };

    render() {
        const { frameType, showPreview, onChange, allowedCategories = ["shape", "device"], showRemoveFrameButton, size = 40, position } = this.props;

        let frame = DeviceFrames.findById(frameType);
        if (!frame) {
            frame = DeviceFrames.findById("none");
        }

        const icon = showPreview ? null : "filter_frames";

        return (
            <Popup ref={this.popupRef} icon={icon} showArrow={showPreview} position={position}>
                {showPreview &&
                    <PopupPreview>
                        {frameType != "mixed" &&
                            <StaticImage src={"/images/frames/thumbnails/" + frame.thumbnail} height={size} />
                        }
                        {frameType == "mixed" && <MixedValue />}
                    </PopupPreview>
                }
                <PopupContent>
                    {closeMenu => (
                        <>
                            <ImageFrameMenu
                                selectedFrame={frameType}
                                allowedCategories={allowedCategories}
                                showRemoveFrameButton={showRemoveFrameButton}
                                onSelect={(frameType, aspectRatio, frame) => {
                                    onChange(frameType, aspectRatio, frame);
                                    // if (frame?.category !== "Shape") {
                                    closeMenu();
                                    // }
                                }}
                                onContentChange={this.handleContentChange}
                            />
                        </>
                    )}
                </PopupContent>
            </Popup>
        );
    }
}

export class ImageFrameMenu extends Component {
    state = {
        selectedTab: "shape"
    }

    constructor(props) {
        super(props);

        const category = DeviceFrames.find(frame => frame.id === props.selectedFrame)?.category ?? "Shape";
        let selectedTab = "shape";
        switch (category) {
            case "Shape":
                selectedTab = "shape";
                break;
            case "Decorative":
                selectedTab = "decorative";
                break;
            case "Masks":
                selectedTab = "masks";
                break;
            default:
                selectedTab = "device";
        }

        if (props.allowedCategories && !props.allowedCategories.includes(selectedTab)) {
            selectedTab = props.allowedCategories[0];
        }

        this.state = {
            selectedTab,
            selectedFrame: props.selectedFrame
        };
    }

    handleSelect = frame => {
        const img = $(`#${frame.id}`)[0];
        let aspectRatio = null;
        if (frame.fixedAspectRatio) {
            aspectRatio = img.naturalWidth / img.naturalHeight;
        }
        this.props.onSelect(frame.id, aspectRatio, frame);

        this.setState({ selectedFrame: frame.id });
        if (this.props.closePopup) {
            this.props.closePopup();
        }
    }

    handleRemoveFrame = () => {
        this.props.onSelect(null, null, null);
    }

    handleSelectStyle = option => {
        this.props.onSelectStyle(option.decorationStyle);
    }

    selectTab = selectedTab => {
        this.setState({ selectedTab }, () => {
            this.props.onContentChange && this.props.onContentChange();
            this.props.repositionPopup && this.props.repositionPopup();
        });
    }

    render() {
        const {
            allowedCategories = ["shape", "device", "masks", "decorative"],
            selectedStyle,
            showRemoveFrameButton = false
        } = this.props;
        const {
            selectedTab,
            selectedFrame,
        } = this.state;

        let menu;
        if (allowedCategories.length > 1) {
            menu = (
                <Menu>
                    {allowedCategories.contains("shape") &&
                        <MenuItem selected={selectedTab === "shape"}
                            onClick={() => this.selectTab("shape")}
                        >Shape Frames</MenuItem>
                    }
                    {allowedCategories.contains("device") &&
                        <MenuItem selected={selectedTab === "device"}
                            onClick={() => this.selectTab("device")}
                        >Device Frames</MenuItem>
                    }
                    {/*{allowedCategories.contains("masks") &&*/}
                    {/*    <MenuItem selected={selectedTab === "masks"}*/}
                    {/*        onClick={() => this.selectTab("masks")}*/}
                    {/*    >Masks</MenuItem>*/}
                    {/*}*/}
                    <FlexSpacer />
                    {showRemoveFrameButton &&
                        <Button onClick={this.handleRemoveFrame}>
                            <Icon>delete</Icon>
                            Remove Frame
                        </Button>
                    }
                </Menu>
            );
        }

        return (
            <FlexBox vertical width={800}>
                {menu}
                {selectedTab === "shape" &&
                    <ShapeFrames onSelect={this.handleSelect} onSelectStyle={this.handleSelectStyle}
                        selectedFrame={selectedFrame} selectedStyle={selectedStyle} />}
                {selectedTab === "masks" &&
                    <MaskFrames onSelect={this.handleSelect} selectedFrame={selectedFrame} />}
                {selectedTab === "device" &&
                    <DevicesFrames onSelect={this.handleSelect} selectedFrame={selectedFrame} />}
                {selectedTab === "decorative" &&
                    <DecorativeFrames onSelect={this.handleSelect} selectedFrame={selectedFrame} />}

            </FlexBox>
        );
    }
}

const FrameGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    width: 100%;
    padding: 10px;

    img {
        width: 70px;
        height: 70px;
        object-fit: contain;
    }
`;

const OptionsList = styled.div`
    display: flex;
    justify-content: flex-start;
    gap: 20px;
    padding: 0px 20px;

    div {
        label {
            font-size: 10px;
            text-transform: uppercase;
            font-weight: 600;
            margin-left: 10px;
        }
    }
`;

class ShapeFrames extends Component {
    render() {
        const {
            selectedFrame,
            selectedStyle,
        } = this.props;

        let frames = DeviceFrames.filter(frame => frame.category === "Shape");
        let isShapeFrameSelected = frames.filter(frame => frame.id == selectedFrame && selectedFrame != "none").length > 0;

        return (
            <FlexBox vertical fillWidth left>
                <FrameGrid>
                    {frames.map(frame => (<FrameItem key={frame.id} frame={frame} onSelect={this.props.onSelect}
                        selected={selectedFrame == frame.id} />))}
                </FrameGrid>
            </FlexBox>
        );
    }
}

const CategoryHeader = styled.div`
    font-size: 11px;
    text-transform: uppercase;
    font-weight: 600;
    color: #777;
    padding: 8px 0px;
    border-bottom: solid 1px #ddd;
    margin: 20px 0px 20px;

    &:first-of-type {
        margin-top: 10px;
    }
`;

const DeviceGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    column-gap: 0px;
    row-gap: 10px;

    img {
        width: 100%;
        height: 80px;
        object-fit: contain;
    }
`;

class DevicesFrames extends Component {
    render() {
        const { selectedFrame } = this.props;

        return (
            <ScrollBox fillWidth style={{ padding: "0px 20px", maxHeight: 500 }}>
                <CategoryHeader>Computers</CategoryHeader>
                <DeviceGrid>
                    {DeviceFrames.filter(frame => frame.category == "Computers" && !frame.isHidden).map(frame => (
                        <FrameItem key={frame.id} frame={frame}
                            selected={selectedFrame == frame.id} onSelect={this.props.onSelect} />))}
                </DeviceGrid>
                <CategoryHeader>Displays</CategoryHeader>
                <DeviceGrid>
                    {DeviceFrames.filter(frame => frame.category == "Displays" && !frame.isHidden).map(frame => (
                        <FrameItem key={frame.id} frame={frame}
                            selected={selectedFrame == frame.id} onSelect={this.props.onSelect} />))}
                </DeviceGrid>
                <CategoryHeader>Phones</CategoryHeader>
                <DeviceGrid>
                    {DeviceFrames.filter(frame => frame.category == "Phones" && !frame.isHidden).map(frame => (
                        <FrameItem key={frame.id} frame={frame}
                            selected={selectedFrame == frame.id} onSelect={this.props.onSelect} />))}
                </DeviceGrid>
                <CategoryHeader>Cisco Devices</CategoryHeader>
                <DeviceGrid>
                    {DeviceFrames.filter(frame => frame.category == "Cisco" && !frame.isHidden).map(frame => (
                        <FrameItem key={frame.id} frame={frame}
                            selected={selectedFrame == frame.id} onSelect={this.props.onSelect} />))}
                </DeviceGrid>
                <CategoryHeader>Other</CategoryHeader>
                <DeviceGrid>
                    {DeviceFrames.filter(frame => frame.category == "Other" && !frame.isHidden).map(frame => (
                        <FrameItem key={frame.id} frame={frame}
                            selected={selectedFrame == frame.id} onSelect={this.props.onSelect} />))}
                </DeviceGrid>
                <Gap20 />
            </ScrollBox>
        );
    }
}

class DecorativeFrames extends Component {
    render() {
        const { selectedFrame } = this.props;

        let frames = DeviceFrames.filter(frame => frame.category === "Decorative");

        return (
            <FlexBox vertical fillWidth left>
                <FrameGrid>
                    {frames.map(frame => (<FrameItem key={frame.id} frame={frame} selected={selectedFrame == frame.id}
                        onSelect={this.props.onSelect} />))}
                </FrameGrid>
            </FlexBox>
        );
    }
}

class MaskFrames extends Component {
    render() {
        const { selectedFrame } = this.props;

        let frames = DeviceFrames.filter(frame => frame.category === "Masks");

        return (
            <FlexBox vertical fillWidth left>
                <FrameGrid>
                    {frames.map(frame => (<MaskFrameItem key={frame.id} frame={frame} selected={selectedFrame == frame.id}
                        onSelect={this.props.onSelect} />))}
                </FrameGrid>
            </FlexBox>
        );
    }
}

const FrameItemContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;
    white-space: nowrap;
    padding: 20px;

    &:hover {
        background: #f1f1f1;
    }

    background: ${props => props.selected ? themeColors.selection : null};

    img {
        width: 100%;
        height: 80px;
        object-fit: contain;
    }
`;

class FrameItem extends Component {
    render() {
        const { frame, onSelect, selected } = this.props;

        return (
            <FrameItemContainer onClick={() => onSelect(frame)} selected={selected}>
                <img id={frame.id} src={getStaticUrl("/images/frames/thumbnails/" + frame.thumbnail)} />
                <Gap10 />
                <label>{frame.name}</label>
            </FrameItemContainer>
        );
    }
}

const MaskFramePreview = styled.div`
    width: 60px;
    height: 100px;
    background: ${themeColors.ui_blue};
    clip-path: url(#frame-preview-${props => props.frameId});
`;

class MaskFrameItem extends Component {
    render() {
        const { frame, onSelect, selected } = this.props;

        return (
            <FrameItemContainer onClick={() => onSelect(frame)} selected={selected}>
                <AbsoluteBox>
                    {calcElementFrameProps("frame-preview-" + frame.id, { frameType: frame.id }, { width: 60, height: 100 }).clipComponent}
                </AbsoluteBox>
                <MaskFramePreview frameId={frame.id} />
                <Gap10 />
                <label>{frame.name}</label>
            </FrameItemContainer>
        );
    }
}
