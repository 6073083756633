import { AssetType, CalloutType, DecorationStyle } from "common/constants";
import { FramedMediaElement } from "../../base/MediaElements/FramedMediaElement";
import * as geom from "js/core/utilities/geom";
import { MarkerCallout } from "./MarkerCallout";

export class MediaAndTextCallout extends MarkerCallout {
    static get schema() {
        return {
            frameType: "theme",
            markerSize: 75
        };
    }

    get showResizeSlider() {
        return true;
    }

    get authoringElementType() {
        return CalloutType.CONTENT_AND_TEXT;
    }

    get minMarkerSize() {
        return 50;
    }

    get canScale() {
        return true;
    }

    get widthKey() {
        return "markerSize";
    }

    get maxMarkerSize() {
        return 400;
    }

    get mediaElement() {
        return this.marker;
    }

    get connectionShape() {
        return {
            bounds: this.elements.content.offsetBounds,
            type: "rect"
        };
    }

    getMarkerSize(parentSize) {
        const width = Math.max(this.minMarkerSize, this.model.markerSize ?? this.styles.markerSize);
        return new geom.Size(width, width);
    }

    get showFrameButton() {
        return this.model.frameType === "none" || this.model.showFrameButton;
    }

    buildMarker() {
        return this.addElement("content", () => FramedMediaElement, {
            defaultAssetType: AssetType.ICON,
            canSelect: false,
            autoHeight: true,
            showFrameButton: this.showFrameButton,
            allowUnframedImages: true,
            maxMarkerSize: this.maxMarkerSize,
            minMarkerSize: this.minMarkerSize,
            allowColorOnColor: true,
            // frameColor: this.markerColor
        });
    }

    _migrate_10_02() {
        delete this.model.iconColor;
    }
}
