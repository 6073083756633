import React, { Component } from "react";
import { Button } from "../../../../../Components/Button";
import { Icon } from "../../../../../Components/Icon";
import { Popup, PopupContent } from "../../../../../Components/Popup";
import { WithLabel } from "../../../../../Components/WithLabel";
import PresentationEditorController, { PanelType } from "../../../../../editor/PresentationEditor/PresentationEditorController";

import { Box } from "../../../../../Components/Box";
import { PropertyPanelHeader } from "../../../../../editor/ElementEditor/PropertyPanelHeader";
import { SlideBackgroundColorPicker } from "../../../../../editor/PresentationEditor/Components/SlideBackgroundColorPicker";
import { PropertyPanelContainer, PropertySection } from "../../../../../EditorComponents/PropertyPanel";
import { ShowAssetDialog } from "../../../../../react/views/AddAssets/ShowAssetDialog";
import { InfographicElementTypes } from "../../../elements/elements/Dashboard/InfographicManager";
import { LayoutEngineType } from "../../../elements/elements/LayoutContainers/GridLayoutContainer";
import { CollectionElementControlBar } from "../../ElementControlBars/CollectionElementControlBar";
import { ControlBar, ControlBarGroup } from "../../ElementControlBars/Components/ControlBar";
import { DefaultOverlayContainer } from "../../ElementDefaultOverlays/Components/DefaultOverlayContainer";
import { GridLayoutPropertyPanel } from "./GridLayoutUI";
import { InfographicElementPicker } from "./Infographics/InfographicElementPicker";
import { LayoutContainerItemPropertyPanel } from "./LayoutContainerItemUI";
import { ShowWarningDialog } from "js/react/components/Dialogs/BaseDialog";

export class DashboardPropertyPanel extends Component {
    render() {
        const { isCellSelected } = this.props;

        return (
            <PropertyPanelContainer>
                {isCellSelected && (
                    <>
                        <PropertyPanelHeader>Dashboard</PropertyPanelHeader>
                        <PropertySection>
                            <WithLabel label="Slide Background" left>
                                <SlideBackgroundColorPicker {...this.props} />
                            </WithLabel>
                        </PropertySection>
                    </>
                )}
                <GridLayoutPropertyPanel {...this.props}
                    title="Dashboard Layout"
                    availableLayoutEngines={[LayoutEngineType.DASHBOARD, LayoutEngineType.CUSTOM]}
                    allowGutter={false} allowFullBleed={false} layoutCols={4}
                />
            </PropertyPanelContainer>
        );
    }
}

export class DashboardControlBar extends CollectionElementControlBar {
    handleEditChartData = cell => {
        const { canvasController } = this.props;

        PresentationEditorController.showElementPanel(cell.childElement);
        canvasController.selectionLayerController.setSelectedElements([cell.childElement]);
    }

    handleAddCell = () => {
        const { element } = this.props;
        if (element.layoutEngineType == LayoutEngineType.CUSTOM) {
            ShowWarningDialog({ title: "Adding Cells to a Custom Grid", message: "To add cells to a custom grid, please drag out new cells using the Add Cell handles in the grid." });
        } else {
            element.setGridLayout(element.getLayoutEngine().getDefaultLayout(element.itemCount + 1));
        }
        element.saveModel();
    }

    render() {
        const { element, position, selectedElements } = this.props;

        const containerElement = element.findClosestOfType("CollectionElement");
        const ContainerPropertyPanel = containerElement.getElementPropertyPanel();

        if (PresentationEditorController.activeBottomPanel === PanelType.ELEMENT) {
            return null;
        }

        let selectedCell = selectedElements.length == 1 ? selectedElements[0].findClosestOfType("LayoutContainerItem") : null;
        if (!selectedCell && element.itemElements.length == 1) {
            selectedCell = element.itemElements[0];
        }

        if (selectedCell?.componentType === InfographicElementTypes.CANVAS) {
            return null;
        }

        const isChartSelected = containerElement.itemCount == 1 && selectedCell && (selectedCell.componentType === InfographicElementTypes.CHART || selectedCell.componentType === InfographicElementTypes.PIE_CHART);

        return (
            <ControlBar position={position}>
                {isChartSelected &&
                    <ControlBarGroup>
                        <Button blue onClick={() => this.handleEditChartData(selectedCell)}><Icon>analytics</Icon>Edit Chart Data...</Button>
                    </ControlBarGroup>
                }
                {!element.isOnAuthoringCanvas && (
                    <ControlBarGroup>
                        <Button blue
                            onClick={this.handleAddCell}
                            disabled={containerElement.itemCount >= 8}
                            disabledTooltip="Maximum of 8 cells allowed"
                        ><Icon>add</Icon>Add Cell</Button>
                        <Popup icon="grid_view">
                            <PopupContent>
                                {closePopup => (
                                    <ContainerPropertyPanel {...this.props} element={element} isPopupMenu />
                                )}
                            </PopupContent>
                        </Popup>
                    </ControlBarGroup>
                )}
            </ControlBar>
        );
    }
}

export class DashboardItemPropertyPanel extends LayoutContainerItemPropertyPanel {
    renderSwitcher() {
        const { element, selectionLayerController } = this.props;

        let containerItemElement;
        if (element.isInstanceOf("LayoutContainerItem")) {
            containerItemElement = element;
        } else {
            containerItemElement = element.parentElement;
        }

        return (
            <Popup label={containerItemElement.childElement.name} showArrow>
                <PopupContent>
                    {closePopup => (
                        <InfographicElementPicker
                            containerElement={containerItemElement}
                            selectionLayerController={selectionLayerController}
                            size={40}
                            cols={6}
                            onChange={() => closePopup()}
                        />
                    )}
                </PopupContent>
            </Popup>
        );
    }
}

export class DashboardPlaceholderPropertyPanel extends Component {
    render() {
        const { element, selectionLayerController } = this.props;

        const containerElement = element.parentElement;

        return (
            <PropertyPanelContainer>
                <InfographicElementPicker
                    containerElement={containerElement}
                    selectionLayerController={selectionLayerController}
                    cols={5}
                />
            </PropertyPanelContainer>
        );
    }
}

export class DashboardPlaceholderDefaultOverlay extends Component {
    handleAddMedia = async () => {
        const { element } = this.props;
        let model = await ShowAssetDialog();
        await element.parentElement.updateModel({
            componentType: "TextAndImage",
            childElement: model,
        });
        element.canvas.selectionLayerController.setSelectedElements([element]);
    }

    handleSetElement = async ({ forceClearLayouter, getDefaultModel }) => {
        const { element } = this.props;

        const defaultModel = getDefaultModel(element);

        if (forceClearLayouter) {
            element.canvas.layouter.clear();
        }

        await element.parentElement.updateModel(defaultModel, { refreshStyles: true });

        element.canvas.selectionLayerController.setSelectedElements([element]);
    }

    render() {
        const { element, selectionLayerController } = this.props;
        const containerElement = element.parentElement;

        return (
            <DefaultOverlayContainer>
                <Box style={{ pointerEvents: "auto", padding: "5px 10px", borderRadius: 4, background: "white" }}>
                    <Popup icon="cached" label="Select contents...">
                        <PopupContent>
                            {closePopup => (
                                <InfographicElementPicker
                                    containerElement={containerElement}
                                    selectionLayerController={selectionLayerController}
                                    size={40}
                                    cols={6}
                                />
                            )}
                        </PopupContent>
                    </Popup>
                </Box>
            </DefaultOverlayContainer>
        );
    }
}
