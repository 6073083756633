import * as geom from "js/core/utilities/geom";
import { _ } from "js/vendor";

import { FlexGridItemSelection } from "../../../../Editor/ElementPropertyPanels/GridContainer/FlexGridSelection";

export class CustomGridLayout {
    constructor(element, props) {
        this.element = element;
    }

    getName() {
        return "Flex Grid";
    }

    getContainerSelection() {
        return null;
    }

    getCellSelection() {
        return FlexGridItemSelection;
    }

    getDefaultLayout(itemCount) {
        return {
            cols: this.element.model.customGridCols ?? 12,
            rows: this.element.model.customGridRows ?? 12,
            cells: [{ x: 0, y: 0, width: this.element.model.customGridCols ?? 12, height: this.element.model.customGridRows ?? 12 }],
        };
    }

    calcCellProps(container, props) {
        let { size, gridLayout, outerGutter = 0, innerGutter = 0 } = props;

        size = size.deflate(outerGutter);

        let hGap = innerGutter;
        let vGap = innerGutter;

        let cols = this.element.model.customGridCols ?? 12;
        let rows = this.element.model.customGridRows ?? 12;

        let colWidth = (size.width - (cols - 1) * innerGutter) / cols;
        let rowHeight = (size.height - (rows - 1) * innerGutter) / rows;

        let cells = gridLayout.cells;

        let outOfBounds = [];

        for (let i = 0; i < cells.length; i++) {
            let gridCell = gridLayout.cells[i];
            let item = container.itemElements[i];
            if (!item) {
                continue;
            }

            let itemBounds = new geom.Rect(gridCell.x, gridCell.y, Math.max(gridCell.width, 1), Math.max(gridCell.height, 1));

            if (itemBounds.left > cols - 1 || itemBounds.top > rows - 1) {
                outOfBounds.push(item);
                continue;
            }
            if (itemBounds.left + itemBounds.width > cols) {
                itemBounds.width = cols - itemBounds.left;
            }
            if (itemBounds.top + itemBounds.height > rows) {
                itemBounds.height = rows - itemBounds.top;                // item.model.height = itemBounds.height;
            }

            let x = outerGutter + itemBounds.left * (colWidth + hGap);
            let y = outerGutter + itemBounds.top * (rowHeight + vGap);
            let width = itemBounds.width * (colWidth + hGap) - hGap;
            let height = itemBounds.height * (rowHeight + vGap) - vGap;

            let itemProps = item.calcProps(new geom.Size(width, height));
            itemProps.bounds = new geom.Rect(x, y, width, height);
            itemProps.gridBounds = itemBounds;
        }

        // if (outOfBounds.length) {
        //     _.each(outOfBounds, item => {
        //         container.model.items.remove(item.model);
        //         delete this.elements[item.id];
        //     });
        // }

        return { size, colWidth, rowHeight, cols, rows, hGap, vGap, gridLayout };
    }

    renderThumbnails() {
        return [];
    }
}

