import React, { Component } from "react";
import moment from "moment";
import { MenuItem } from "@material-ui/core";

import { WithLabel } from "js/Components/WithLabel";
import { Dropdown } from "js/Components/Dropdown";
import { PropertyPanelContainer, PropertySection } from "js/EditorComponents/PropertyPanel";
import { ToggleSwitch } from "js/Components/ToggleSwitch";
import { ItemColorPicker } from "../EditorComponents/ColorPickers/ItemColorPicker";
import { Popup, PopupContainer, PopupContent } from "js/Components/Popup";
import { DatePicker } from "js/Components/DatePicker";
import { NumericStepper } from "js/Components/NumericStepper";
import { ShowDialog, ShowDialogAsync } from "js/react/components/Dialogs/BaseDialog";
import BadFitDialog from "js/react/components/Dialogs/BadFitDialog";
import { Button } from "js/Components/Button";
import { Icon } from "js/Components/Icon";
import { _ } from "js/vendor";

import { ControlBar, ControlBarGroup } from "../ElementControlBars/Components/ControlBar";
import { CollectionColorPicker } from "../EditorComponents/ColorPickers/CollectionColorPicker";
import { MenuItemDetails, MenuItemWithDetails } from "../../../../Components/Menu";

export class GanttChartPropertyPanel extends Component {
    render() {
        const { element } = this.props;
        return (
            <PropertyPanelContainer>
                <PropertySection>
                    <WithLabel label="Task Colors">
                        <CollectionColorPicker element={element.tasks} />
                    </WithLabel>
                </PropertySection>
                <PropertySection>
                    <WithLabel label="Axis Type" toolTip="Toggles between">
                        <Dropdown value={element.relativeTimescale}
                            onChange={value => {
                                const update = { relativeTimescale: value };

                                if (element.axisScale == "months" || element.axisScale == "quarters") {
                                    update.axisScale = "weeks";
                                }
                                element.updateModel(update);
                            }}>
                            <MenuItemWithDetails value={false}>
                                Calendar Time
                                <MenuItemDetails>Use fixed calendar dates for the X-Axis (ie. Jan 1, Feb 1, etc).</MenuItemDetails>
                            </MenuItemWithDetails>
                            <MenuItemWithDetails value={true}>
                                Project Time
                                <MenuItemDetails>Use relative project time for the X-Axis (ie. Week 1, Week 2, etc).</MenuItemDetails>
                            </MenuItemWithDetails>
                        </Dropdown>
                    </WithLabel>
                    <WithLabel label="Scale" toolTip="Sets the scale for the x-axis">
                        <Dropdown value={element.axisScale}
                            onChange={value => element.updateModel({ axisScale: value })}>
                            <MenuItem value="days">Days</MenuItem>
                            <MenuItem value="weeks">Weeks</MenuItem>
                            <MenuItem value="months" disabled={element.model.relativeTimescale}>Months</MenuItem>
                            <MenuItem value="quarters" disabled={element.model.relativeTimescale}>Quarters</MenuItem>
                        </Dropdown>
                    </WithLabel>

                    <WithLabel label="Show Grid Lines" flex>
                        <ToggleSwitch value={element.model.showGrid}
                            onChange={value => element.updateModel({ showGrid: value })} />
                    </WithLabel>

                </PropertySection>
                <PropertySection>
                    <WithLabel label="Show Task Icon/Image" flex>
                        <ToggleSwitch value={element.model.showImage}
                            onChange={value => element.updateModel({ showImage: value })} />
                    </WithLabel>
                    <WithLabel label="Task Details" flex>
                        <Dropdown value={element.model.showTaskDetail}
                            onChange={value => element.updateModel({ showTaskDetail: value })}>
                            <MenuItem value="none">None</MenuItem>
                            <MenuItem value="duration">Duration</MenuItem>
                            <MenuItem value="dates">Start/End Date</MenuItem>
                        </Dropdown>
                    </WithLabel>
                </PropertySection>

            </PropertyPanelContainer>
        );
    }
}

export class GanttChartControlBar extends Component {
    get canAddNewItem() {
        const { element } = this.props;
        return element.addItem && (element.maxItemCount ? (element.itemCount < element.maxItemCount) : true);
    }

    onAddTask = () => {
        const { element, selectionLayerController, selectedElements } = this.props;

        const tasks = element.tasks;

        const currentSelection = [...selectedElements];
        selectionLayerController.setSelectedElements([]);

        const { id } = tasks.addItem();
        tasks.canvas.updateCanvasModel(false)
            .then(() => {
                const newElement = tasks.elements[id];
                if (newElement) {
                    selectionLayerController.setSelectedElements([newElement]);
                }
            })
            .catch(() => {
                selectionLayerController.setSelectedElements(currentSelection);

                ShowDialog(BadFitDialog, {
                    title: "Sorry, we aren't able to fit another item to this layout",
                });
            });
    }

    onAddMilestone = () => {
        const { element, selectionLayerController, selectedElements } = this.props;

        const currentSelection = [...selectedElements];
        selectionLayerController.setSelectedElements([]);

        const day = Math.round(element.duration.asDays() * Math.random());
        const id = _.uniqueId() + new Date().getTime();
        element.model.milestones.push({
            id,
            date: element.startDate.clone().add(day, "days").toDate().getTime()
        });

        element.canvas.updateCanvasModel(false)
            .then(() => {
                const newElement = element.milestones.elements[id];
                if (newElement) {
                    selectionLayerController.setSelectedElements([newElement]);
                }
            })
            .catch(err => {
                selectionLayerController.setSelectedElements(currentSelection);
                ShowDialogAsync(BadFitDialog, {
                    title: "Sorry, we aren't able to fit another task on this chart",
                });
            });
    }

    render() {
        const { element, position } = this.props;

        const tasks = element.tasks;
        const milsteones = element.milestones;

        return (
            <ControlBar position={position}>
                <ControlBarGroup>
                    <Button blue small onClick={this.onAddTask} disabled={tasks.itemCount >= tasks.maxItemCount} disabledTooltip={`This slide only allows ${tasks.maxItemCount} items.`}>
                        <Icon>add_circle</Icon>
                        Add Task
                    </Button>
                </ControlBarGroup>
                <ControlBarGroup>
                    <Button blue small onClick={this.onAddMilestone}>
                        <Icon>tour</Icon>
                        Add Milestone
                    </Button>
                </ControlBarGroup>
            </ControlBar>
        );
    }
}

export class GanttChartTaskControlBar extends Component {
    render() {
        const { element } = this.props;
        return (
            <ControlBar>
                <ItemColorPicker element={element} />
                <Popup label="Set Task" showArrow>
                    {element.getRootElement().relativeTimescale && (
                        <PopupContent>
                            <PopupContainer>
                                <WithLabel label="Start Day" labelWidth={80}>
                                    <NumericStepper min={1} max={99999} step={1}
                                        disabled={element.itemCount == 1}
                                        value={(element.itemCount > 1) ? Math.round(moment.duration(element.taskStartDate.diff(element.getRootElement().startDate)).asDays()) + 1 : 1}
                                        onChange={value => {
                                            const duration = Math.round(moment.duration(element.taskEndDate.diff(element.taskStartDate)).asDays());
                                            const startDate = element.getRootElement().startDate.clone().add(value - 1, "days");
                                            element.model.startDate = startDate.startOf("day").toDate().getTime();
                                            element.model.endDate = startDate.add(duration - 1, "days").endOf("day").toDate().getTime();
                                            element.canvas.updateCanvasModel();
                                        }}
                                    />
                                </WithLabel>
                                <WithLabel label="Length" labelWidth={80}>
                                    <NumericStepper min={1} max={99999} step={1}
                                        value={Math.round(moment.duration(element.taskEndDate.diff(element.taskStartDate)).asDays())}
                                        onChange={value => {
                                            element.model.endDate = element.taskStartDate.clone().add(value - 1, "days").endOf("day").toDate().getTime();
                                            element.canvas.updateCanvasModel();
                                        }}
                                    />
                                </WithLabel>
                            </PopupContainer>
                        </PopupContent>
                    )}
                    {!element.getRootElement().relativeTimescale && (
                        <PopupContent>
                            <PopupContainer>
                                <WithLabel label="Start Date" labelWidth={80}>
                                    <DatePicker
                                        value={new Date(element.taskStartDate.format("YYYY-MM-DDT00:00:00.000"))}
                                        onChange={value => {
                                            const startDate = moment.utc(value).startOf("day");
                                            if (startDate.isAfter(element.taskEndDate)) {
                                                element.model.endDate = startDate.clone().endOf("day").toDate().getTime();
                                            }
                                            element.updateModel({ startDate: startDate.toDate().getTime() });
                                        }}
                                    />
                                </WithLabel>
                                <WithLabel label="End Date" labelWidth={80}>
                                    <DatePicker value={new Date(element.taskEndDate.format("YYYY-MM-DDT00:00:00.000"))}
                                        onChange={value => {
                                            const endDate = moment.utc(value).endOf("day");
                                            if (endDate.isBefore(element.taskStartDate)) {
                                                element.model.startDate = endDate.clone().startOf("day").toDate().getTime();
                                            }
                                            element.updateModel({ endDate: endDate.toDate().getTime() });
                                        }}
                                    />
                                </WithLabel>
                            </PopupContainer>
                        </PopupContent>
                    )}
                </Popup>
            </ControlBar>
        );
    }
}

export class GanttChartMilestoneControlBar extends Component {
    render() {
        return (
            <ControlBar>
                <ControlBarGroup>
                    <Button blue small onClick={() => this.props.element.switchFlipLabel()}>
                        <Icon>flip</Icon>
                        Flip Label
                    </Button>
                </ControlBarGroup>
            </ControlBar>
        );
    }
}
