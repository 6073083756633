import React, { Component } from "react";
import styled from "styled-components";
import { Icon } from "@material-ui/core";

import { CellChangeStyle, ForeColorType, FormatType, HorizontalAlignType } from "common/constants";
import { Button } from "js/Components/Button";
import { IconButton } from "js/Components/IconButton";
import { Popup, PopupContent, PopupPreview } from "js/Components/Popup";
import { getStaticUrl } from "js/config";
import { stopPropagation } from "js/core/utilities/stopPropagation";
import { ShowDialog } from "js/react/components/Dialogs/BaseDialog";
import { FlexBox } from "js/react/components/LayoutGrid";
import { themeColors } from "js/react/sharedStyles";
import { _ } from "js/vendor";
import { formatter } from "js/core/utilities/formatter";
import { PropertySection } from "js/EditorComponents/PropertyPanel";

import { ColorPicker } from "../../EditorComponents/ColorPickers/ColorPicker";
import { FormattingOptions } from "../../EditorComponents/FormattingOptions";
import { ControlBar, ControlBarGroup } from "../../ElementControlBars/Components/ControlBar";
import { NumericStepper } from "../../ElementControlBars/Components/NumericStepper";
import { AddLinkDialog } from "../Authoring/Editors/BlockEditors/AddLinkDialog";

const CellStyleContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

    background: ${props => props.selected ? `${themeColors.selection}` : "none"};
    width: 80px;
    margin-right: 0px;
    padding-bottom: 6px;
    cursor: pointer;

    > label {
        font-size: 10px;
        margin: 0px;
    }
`;

const ControlBarIconButton = styled(IconButton)`
    padding: 0 7px;
`;

class CellStylePopup extends Component {
    handleChangeCellStyle = async style => {
        const { selectedCells, element } = this.props;

        selectedCells.forEach(cell => {
            cell.model.cellStyle = style;

            if (!cell.model.color) {
                cell.model.color = "primary";
            }
        });
        await element.saveModel();
    }

    handleChangeCellColor = async color => {
        const { selectedCells, element } = this.props;

        selectedCells.forEach(cell => {
            cell.model.cellColor = color;

            if (!cell.cellStyle || cell.cellStyle === "none") {
                cell.model.cellStyle = "text";
            }
        });
        await element.saveModel();
    }

    render() {
        const { selectedCells, element } = this.props;

        const cellModels = selectedCells.map(cell => cell.model);
        const cellColors = _.uniqBy(cellModels, "cellColor").map(cell => cell.cellColor ? cell.cellColor : ForeColorType.PRIMARY).filter(Boolean);
        let cellColor;
        if (cellColors.length > 1) {
            cellColor = "mixed";
        } else {
            cellColor = cellColors[0] ?? ForeColorType.PRIMARY;
        }

        const cellStyles = _.uniqBy(selectedCells, "cellStyle").map(cell => cell.cellStyle).filter(Boolean);
        const cellStyle = cellStyles.length === 1 ? cellStyles[0] : "none";

        const cellFormats = _.uniq(selectedCells.map(cell => cell.model.format ?? FormatType.TEXT)).filter(Boolean);
        const cellFormat = cellFormats.length === 1 ? cellFormats[0] : FormatType.TEXT;

        const isInHeaderRow = false;

        return (
            <ColorPicker value={cellColor} canvas={element.canvas} onChange={this.handleChangeCellColor} size={20}
                showPrimary showPositiveNegative allowColorOnColor
                closeOnSelect={false}
                bottomSection={
                    <PropertySection>
                        <FlexBox left style={{ opacity: isInHeaderRow ? 0.5 : 1, pointerEvents: isInHeaderRow ? "none" : "unset" }}>
                            <CellStyleContainer selected={cellStyle === "text" || cellStyle === "none"} onClick={() => this.handleChangeCellStyle("text")}>
                                <img src={getStaticUrl("/images/elements/table/table-cell-color.svg")} />
                                <label>{cellFormat == FormatType.ICON ? "Icon" : "Text"}</label>
                            </CellStyleContainer>
                            <CellStyleContainer selected={cellStyle === "fill"} onClick={() => this.handleChangeCellStyle("fill")}>
                                <img src={getStaticUrl("/images/elements/table/table-cell-fill.svg")} />
                                <label>Fill</label>
                            </CellStyleContainer>
                            <CellStyleContainer selected={cellStyle === "stroke"} onClick={() => this.handleChangeCellStyle("stroke")}>
                                <img src={getStaticUrl("/images/elements/table/table-cell-stroke.svg")} />
                                <label>Stroke</label>
                            </CellStyleContainer>
                            <CellStyleContainer selected={cellStyle === "flag"} onClick={() => this.handleChangeCellStyle("flag")}>
                                <img src={getStaticUrl("/images/elements/table/table-cell-flag.svg")} />
                                <label>Flag</label>
                            </CellStyleContainer>
                        </FlexBox>
                    </PropertySection>
                }
            />
        );
    }
}

class CellFormatPopup extends Component {
    componentDidMount() {
        this.props.stopEditingCell();
    }

    handleChangeFormat = async format => {
        const { selectedCells, element } = this.props;

        selectedCells.forEach(cell => {
            cell.model.format = format;

            if (format === FormatType.ICON) {
                cell.model.content_type = "icon";
                cell.model.content_value = cell.model.content_value ?? "check-yes";
                cell.model.cellStyle = "text";
            }

            if (format === FormatType.TEXT) {
                cell.model.formatOptions.changeStyle = CellChangeStyle.NONE;
                cell.model.formatOptions.accountingStyle = false;
            }

            if (format === FormatType.PERCENT) {
                cell.model.formatOptions.accountingStyle = false;
                cell.model.formatOptions.changeColor = true;
            }
        });

        element.markStylesAsDirty();
        await element.saveModel();
    }

    handleChangeFormatOptions = async formatOptions => {
        const { selectedCells, element } = this.props;

        selectedCells.forEach(cell => {
            cell.model.formatOptions = formatOptions;
        });

        element.markStylesAsDirty();
        await element.saveModel();
    }

    handleChangeContentValue = async (contentType, contentValue) => {
        const { selectedCells, element } = this.props;

        selectedCells.forEach(cell => {
            cell.model.content_type = contentType;
            cell.model.content_value = contentValue;
        });

        element.markStylesAsDirty();
        await element.saveModel();
    }

    getNonUniqueFormatOptions = cells => {
        const formatOptionsList = cells.map(cell => cell.model.formatOptions);
        const nonUniqueProps = {};

        if (formatOptionsList.length > 0) {
            const allProps = Object.keys(formatOptionsList[0]);

            allProps.forEach(prop => {
                const values = formatOptionsList.map(options => options[prop]);
                const uniqueValues = _.uniq(values);

                if (uniqueValues.length > 1) {
                    nonUniqueProps[prop] = uniqueValues;
                }
            });
        }

        return nonUniqueProps;
    }

    render() {
        const { selectedCells, element, stopEditingCell } = this.props;

        const canvas = element.canvas;
        const theme = canvas.getTheme();

        // Create a copy of the selected cells with default format and text alignment
        const cells = selectedCells.map(cell => {
            // ensure format is set
            cell.model.format = cell.model.format ?? FormatType.TEXT;
            // ensure text alignment is set
            cell.model.formatOptions = {
                ...cell.model.formatOptions ?? formatter.getDefaultFormatOptions(),
                textAlign: cell.model.formatOptions?.textAlign ?? HorizontalAlignType.CENTER
            };

            return cell;
        });

        // Get the unique formats and text alignments of the selected cells
        const cellFormats = _.uniq(cells.map(cell => cell.model.format)).filter(Boolean);

        // Iterate through the selected cells to find the format options that are not unique
        // in all the selected cells
        const nonUniqueFormatOptions = this.getNonUniqueFormatOptions(cells);

        // If there is only one format and text alignment, we can use the format options of the first cell
        let formatOptions = {};
        if (cells.length > 0) {
            // Clone the format options to avoid changing the original first cell
            formatOptions = _.cloneDeep(cells[0].model.formatOptions);
            Object.keys(nonUniqueFormatOptions).forEach(prop => {
                formatOptions[prop] = null;
            });
        }

        return (
            <Popup onShow={() => stopEditingCell()}>
                <PopupPreview>
                    <label>
                        {cellFormats.length === 1 ? cellFormats[0] : "Mixed Formats"}
                    </label>
                    <Icon>arrow_drop_down</Icon>
                </PopupPreview>
                <PopupContent>
                    {() => {
                        return (
                            <FormattingOptions
                                allowAccountingStyle={true}
                                allowedFormats={[FormatType.TEXT, FormatType.NUMBER, FormatType.CURRENCY, FormatType.DATE, FormatType.PERCENT, FormatType.ICON]}
                                format={cellFormats.length === 1 ? cells[0].model.format : null}
                                formatOptions={formatOptions}
                                onChangeFormat={this.handleChangeFormat}
                                onChangeFormatOptions={this.handleChangeFormatOptions}
                                onChangeContentValue={this.handleChangeContentValue}
                                theme={theme}
                            />
                        );
                    }}
                </PopupContent>
            </Popup>
        );
    }
}

export class TableCellControlBar extends Component {
    handleMergeSelectedCells = async () => {
        const { selectedCells, cells, element, selectCells } = this.props;

        const startCol = _.minBy(selectedCells, cell => cell.left).left;
        const startRow = _.minBy(selectedCells, cell => cell.top).top;
        const endCol = _.maxBy(selectedCells, cell => cell.right).right;
        const endRow = _.maxBy(selectedCells, cell => cell.bottom).bottom;

        let cellText = "";

        for (let colIndex = startCol; colIndex <= endCol; colIndex++) {
            for (let rowIndex = startRow; rowIndex <= endRow; rowIndex++) {
                const cell = _.find(cells, { colIndex, rowIndex });
                if (colIndex === startCol && rowIndex === startRow) {
                    // We set the merged cells value to the top left cell
                    cellText = cell.getText();
                    cell.model.width = endCol - startCol + 1;
                    cell.model.height = endRow - startRow + 1;
                    cell.model.isHidden = false;
                } else {
                    cell.model.isHidden = true;
                }
            }
        }

        const mergedCell = _.find(cells, { colIndex: startCol, rowIndex: startRow });
        mergedCell.setText(cellText);

        await element.saveModel();

        const updatedMergedCell = _.find(this.props.cells, { colIndex: startCol, rowIndex: startRow });
        selectCells([updatedMergedCell]);
    }

    handleUnmergeSelectedCells = async () => {
        const { selectedCells, cells, element, selectCells } = this.props;

        const mergedCell = selectedCells.find(cell => cell.model.width > 1 || cell.model.height > 1);

        const startCol = mergedCell.model.col;
        const endCol = mergedCell.model.col + mergedCell.model.width;
        const startRow = mergedCell.model.row;
        const endRow = mergedCell.model.row + mergedCell.model.height;

        const unmergedCells = cells.filter(cell => cell.model.col >= startCol && cell.model.col < endCol && cell.model.row >= startRow && cell.model.row < endRow);
        unmergedCells.forEach(cell => {
            cell.model.isHidden = false;
            cell.model.width = cell.model.height = 1;
        });

        selectCells(unmergedCells);

        await element.saveModel();

        const updatedUmergedCells = this.props.cells.filter(cell => cell.model.col >= startCol && cell.model.col < endCol && cell.model.row >= startRow && cell.model.row < endRow);
        selectCells(updatedUmergedCells);
    }

    handleUpdateSelectedCellsModels = async updates => {
        const { selectedCells, element } = this.props;

        selectedCells.forEach(cell => {
            Object.assign(cell.model, updates);
        });

        await element.saveModel();
    }

    handleAddLink = async () => {
        const { selectedCells, element } = this.props;

        const cell = selectedCells.find(cell => !cell.model.isHidden);

        const value = cell.model.link;
        const selectedSlide = cell.model.linkToSlide;

        ShowDialog(AddLinkDialog, {
            value,
            selectedSlide,
            onRemoveLink: () => {
                for (const cell of selectedCells) {
                    cell.model.link = null;
                    cell.model.linkToSlide = null;
                }
                element.saveModel();
            },
            onSetLink: value => {
                for (const cell of selectedCells) {
                    cell.model.link = value;
                    cell.model.linkToSlide = null;
                }
                element.saveModel();
            },
            onSetSlide: slideId => {
                for (const cell of selectedCells) {
                    cell.model.link = null;
                    cell.model.linkToSlide = slideId;
                }
                element.saveModel();
            },
        });
    }

    render() {
        const { selectedCells } = this.props;

        const canMergeCells = selectedCells.filter(cell => !cell.model.isHidden).length > 1;
        const canUnmergeCells = !canMergeCells && selectedCells.some(cell => cell.model.width > 1 || cell.model.height > 1);

        const isBold = selectedCells.some(cell => cell.model.bold);
        const isItalic = selectedCells.some(cell => cell.model.italic);
        const isStrikethrough = selectedCells.some(cell => cell.model.strikeThrough);
        const hasLink = selectedCells.some(cell => cell.model.link || cell.model.linkToSlide);

        const cellFormats = _.uniq(selectedCells.map(cell => cell.model.format ?? FormatType.TEXT)).filter(Boolean);
        const isIcon = cellFormats.length === 1 && cellFormats[0] === FormatType.ICON;

        const cellFontSizes = _.uniq(selectedCells.map(cell => cell.model.fontSize ?? cell.styles.fontSize)).filter(Boolean);

        return (
            <ControlBar>
                {(canMergeCells || canUnmergeCells) &&
                    <ControlBarGroup onMouseDown={stopPropagation()}>
                        {canMergeCells &&
                            <Button blue small onClick={this.handleMergeSelectedCells}>
                                Merge cells
                            </Button>
                        }
                        {canUnmergeCells &&
                            <Button blue small onClick={this.handleUnmergeSelectedCells}>
                                Unmerge cells
                            </Button>
                        }
                    </ControlBarGroup>
                }

                <ControlBarGroup onMouseDown={stopPropagation()}>
                    <CellFormatPopup {...this.props} />
                </ControlBarGroup>

                <ControlBarGroup onMouseDown={stopPropagation()}>
                    <CellStylePopup {...this.props} />
                    {!isIcon && <>
                        <ControlBarIconButton
                            icon="format_bold"
                            onClick={() => this.handleUpdateSelectedCellsModels({ bold: !isBold })}
                            dimmed={!isBold}
                        />
                        <ControlBarIconButton
                            icon="format_italic"
                            onClick={() => this.handleUpdateSelectedCellsModels({ italic: !isItalic })}
                            dimmed={!isItalic}
                        />
                        <ControlBarIconButton
                            icon="format_strikethrough"
                            onClick={() => this.handleUpdateSelectedCellsModels({ strikeThrough: !isStrikethrough })}
                            dimmed={!isStrikethrough}
                        />
                        <ControlBarIconButton
                            icon="insert_link"
                            onClick={this.handleAddLink}
                            dimmed={!hasLink}
                        />
                        <NumericStepper
                            value={cellFontSizes[0]}
                            min={6}
                            max={99}
                            onChange={fontSize => this.handleUpdateSelectedCellsModels({ fontSize })}
                        />
                    </>}
                </ControlBarGroup>
            </ControlBar>
        );
    }
}
