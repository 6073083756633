import React from "react";
import styled from "styled-components";

import * as geom from "js/core/utilities/geom";
import { stopPropagation } from "js/core/utilities/stopPropagation";

import ElementSelectionBox from "./Components/ElementSelectionBox";

const CanvasDimmer = styled.div.attrs(({ canvasBounds, containerBounds }) => ({
    style: {
        ...canvasBounds.toObject(),
        clipPath: [`path(evenodd, "`,
            `M 0 0`,
            `L ${canvasBounds.width} 0`,
            `L ${canvasBounds.width} ${canvasBounds.height}`,
            `L 0 ${canvasBounds.height}`,
            `L 0 0`,
            `M ${containerBounds.left} ${containerBounds.top}`,
            `L ${containerBounds.right} ${containerBounds.top}`,
            `L ${containerBounds.right} ${containerBounds.bottom}`,
            `L ${containerBounds.left} ${containerBounds.bottom}`,
            `L ${containerBounds.left} ${containerBounds.top}`,
            `Z`,
            `")`].join(" ")
    }
}))`
    position: absolute;
    pointer-events: none;

    background: rgba(0, 0, 0, 0.4);
    z-index: 999998;
`;

const DragStartContainer = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    cursor: move;
    pointer-events: auto;
`;

export class PictureElementSelection extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isDragging: false,
            dragStartPoint: null
        };

        this.elementOffset = null;
        this.elementScale = null;
        this.isHandlingAction = false;
    }

    componentDidMount() {
        this.prepareElement();
    }

    componentWillUnmount() {
        this.resetElement();

        document.removeEventListener("mousemove", this.handleDrag);
        document.removeEventListener("mouseup", this.handleEndDrag);
    }

    _setRenderOnTop = renderOnTop => {
        const { element } = this.props;

        const contentElement = element.findClosestOfType("ContentElement");
        if (contentElement) {
            Object.values(contentElement.parentElement.elements).forEach(element => {
                element.renderOnTop = renderOnTop;
            });
        }
    }

    prepareElement() {
        const { element } = this.props;

        element.overflowHidden = false;

        this._setRenderOnTop(true);

        element.refreshRender();
    }

    resetElement() {
        const { element } = this.props;

        element.overflowHidden = true;

        this._setRenderOnTop(false);

        element.refreshRender();
    }

    handleStartDrag = event => {
        stopPropagation()(event);

        const { element } = this.props;

        this.setState({
            isDragging: true,
            dragStartPoint: new geom.Point(event.clientX, event.clientY)
        });

        this.elementOffset = element.getOffset(element.calculatedProps.size);
        this.elementScale = element.getScale(element.calculatedProps.size);

        document.addEventListener("mousemove", this.handleDrag);
        document.addEventListener("mouseup", this.handleEndDrag);
    }

    handleDrag = event => {
        stopPropagation()(event);

        if (this.isHandlingAction) {
            return;
        }
        this.isHandlingAction = true;

        const { element } = this.props;
        const { dragStartPoint } = this.state;

        const dragOffset = new geom.Point(event.clientX, event.clientY).delta(dragStartPoint);

        const hflip = element.model.flipHorizontal ? -1 : 1;
        element._setModel({ offset: this.elementOffset.plus(hflip * dragOffset.x / this.elementScale, dragOffset.y / this.elementScale) }, element.calculatedProps.size);

        element.canvas.refreshCanvas({ suppressRefreshCanvasEvent: true })
            .then(() => this.isHandlingAction = false);
    }

    handleEndDrag = event => {
        stopPropagation()(event);

        const { element } = this.props;

        document.removeEventListener("mousemove", this.handleDrag);
        document.removeEventListener("mouseup", this.handleEndDrag);

        this.setState({
            isDragging: false,
            dragStartPoint: null
        });

        this.elementOffset = null;
        this.elementScale = null;

        element.canvas.updateCanvasModel();
    }

    render() {
        const { element, canvasBounds, containerBounds } = this.props;

        const hasMedia = element.hasMedia;
        if (!hasMedia) {
            return null;
        }

        return (
            <ElementSelectionBox>
                <CanvasDimmer canvasBounds={canvasBounds} containerBounds={containerBounds} />
                <DragStartContainer onMouseDown={this.handleStartDrag} />
            </ElementSelectionBox>
        );
    }
}
