import { BaseElement } from "../base/BaseElement";
import { FramedMediaElement } from "../base/MediaElements/FramedMediaElement";
import { AssetType, DecorationStyle } from "common/constants";

class MediaBlock extends BaseElement {
    get hasValidAsset() {
        return this.media.hasValidAsset;
    }

    get allowDecorationStyles() {
        return this.media.allowDecorationStyles;
    }

    get decorationStyle() {
        if (this.model.frameType === "none") {
            return DecorationStyle.NONE;
        }

        if (this.model.decorationStyle) {
            return this.model.decorationStyle;
        }

        return this.canvas.getTheme().get("styleElementStyle");
    }

    getDecorationColor() {
        return this.palette.getColor(this.model.color ?? "theme");
    }

    _build() {
        this.media = this.addElement("media", () => FramedMediaElement, {
            preserveAspectRatioForUnframed: true,
            allowUnframedImages: true,
            fitToHeight: true,
            autoWidth: true,
            defaultAssetType: AssetType.ICON,
            allowColorOnColor: true
        });
    }

    _calcProps(props, options) {
        const { size } = props;

        const mediaProps = this.media.calcProps(size, { autoHeight: true });

        return { size: mediaProps.size };
    }

    _migrate_10_02() {
        if (!this.model.color) {
            this.model.color = this.canvas.getSlideColor();
            if (this.model.color == "auto") {
                // auto color is deprecated and must be null
                this.model.color = null;
            }
        }
    }
}

export const elements = {
    MediaBlock
};

