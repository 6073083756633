import React, { Component } from "react";
import moment from "moment/moment";

import { PropertyPanelContainer, PropertySection } from "js/EditorComponents/PropertyPanel";
import { WithLabel } from "js/Components/WithLabel";
import { ToggleSwitch } from "js/Components/ToggleSwitch";
import { Dropdown } from "js/Components/Dropdown";
import { MenuItem } from "js/Components/Menu";
import { DatePicker } from "js/Components/DatePicker";

import { CollectionColorPicker } from "../EditorComponents/ColorPickers/CollectionColorPicker";

export class CountdownPropertyPanel extends Component {
    render() {
        const { element } = this.props;

        return (
            <PropertyPanelContainer>
                <PropertySection>
                    <WithLabel label="Color">
                        <CollectionColorPicker element={element} />
                        {/*<ColorPicker value={element.model.color} canvas={element.canvas} showPrimary*/}
                        {/*    onChange={color => element.updateModel({ color }, { refreshStyles: true })}*/}
                        {/*/>*/}
                    </WithLabel>
                </PropertySection>
                <PropertySection>
                    <WithLabel label="Count">
                        <Dropdown value={element.model.direction || "down"}
                            onChange={value => {
                                element.updateModel({ direction: value });
                                element.resetCountdownDisplay();
                            }}
                        >
                            <MenuItem value="down">Down To Target</MenuItem>
                            <MenuItem value="up">Up From Target</MenuItem>
                        </Dropdown>
                    </WithLabel>
                    <WithLabel label="Target">
                        <DatePicker
                            value={new Date(element.timestamp)}
                            onChange={value => {
                                const date = moment(value).format("MM/DD/YYYY");
                                const time = moment(value).format("hh:mm A");

                                element.updateModel({ date, time });
                            }}
                            showTimeSelect
                            dateFormat="MMM d yyyy h:mm:ss aa"
                            onCalendarOpen={() => {
                                element.stopAnimation();
                            }}
                            onCalendarClose={() => {
                                element.resetCountdownDisplay();
                            }}
                        />
                    </WithLabel>
                </PropertySection>
                <PropertySection>
                    <WithLabel label="Show Seconds">
                        <ToggleSwitch value={element.model.showSeconds}
                            onChange={value => element.updateModel({ showSeconds: value })}
                        />
                    </WithLabel>
                </PropertySection>
            </PropertyPanelContainer>
        );
    }
}

