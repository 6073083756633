import React from "react";

import * as geom from "js/core/utilities/geom";
import { _ } from "js/vendor";
import { getSVGStyleProps, SVGGroup } from "js/core/utilities/svgHelpers";
import { formatter } from "js/core/utilities/formatter";
import { FormatType, HorizontalAlignType, BlockStructureType, DecorationStyle, ShadeColorsType } from "common/constants";
import { Shape } from "js/core/utilities/shapes";
import { getValidChartDataFromCsv } from "js/core/utilities/xlsx";
import { detectTabularData } from "js/core/services/sharedModelManager";

import { BaseElement } from "../base/BaseElement";
import { CollectionElement, CollectionItemElement } from "../base/CollectionElement";
import { SVGPathElement, SVGCircleElement } from "../base/SVGElement";
import { TextElement } from "../base/Text/TextElement";
import { PieChartWedgeSelection } from "../../Editor/ElementSelections/PieChartWedgeSelection";
import { PieChartPropertyPanel, PieChartSelection } from "../../Editor/ElementPropertyPanels/ChartEditor/PieChartUI";
import { EditPieChartDataPanel } from "../../Editor/ElementPropertyPanels/ChartEditor/EditPieChartDataPanel";

class PieChart extends CollectionElement {
    get _canSelect() {
        return true;
    }

    get canSelectChildElements() {
        return this.isSelected || this.isChildSelected;
    }

    get _canRollover() {
        return false;
    }

    get allowDirectDoubleClickOnChildrenSiblings() {
        return true;
    }

    getElementPropertyPanel() {
        return PieChartPropertyPanel;
    }

    getElementSelection() {
        return PieChartSelection;
    }

    getElementPanel() {
        return EditPieChartDataPanel;
    }

    getChildItemType() {
        return PieChartWedge;
    }

    getElementControlBar() {
        return null;
    }

    get collectionPropertyName() {
        return "data";
    }

    get showLegend() {
        return this.model.legendPosition == "left" || this.model.legendPosition == "right";
    }

    get labelPosition() {
        return this.model.labelPosition || "auto";
    }

    getCanvasMargins() {
        return {
            top: this.canvas.styleSheet.Canvas.marginTop,
            left: this.canvas.styleSheet.Canvas.marginLeft,
            right: this.canvas.styleSheet.Canvas.marginRight,
            bottom: 0
        };
    }

    get format() {
        // migration from old formatting
        if (!this.model.format) {
            this.migrateFormat();
        }
        return this.model.format || FormatType.PERCENT;
    }

    get chartScale() {
        return this.model.chartScale || 1;
    }

    get shadeColors() {
        return ShadeColorsType.DARK;
    }

    get decorationStyle() {
        return DecorationStyle.FILLED;
    }

    // migrate from pre-April 2019 labelFormat model to support FormatOptionsMenu
    migrateFormat() {
        switch (this.model.labelFormat) {
            case "numeric":
                this.model.format = FormatType.NUMBER;
                break;
            case "none":
                this.model.format = FormatType.NONE;
                break;
            case "percentage":
            default:
                this.model.format = FormatType.PERCENT;
                break;
        }
    }

    get _formatOptions() {
        return this.model.formatOptions || formatter.getDefaultFormatOptions();
    }

    formatValue(value) {
        let displayValue = value;
        if (this.format == FormatType.PERCENT) {
            let totalValue = _.sumBy(this.model.data, m => m.value);
            displayValue = value / totalValue;
        } else {
            displayValue = value;
        }

        return formatter.formatValue(displayValue, this.format, this.formatOptions);
    }

    _build() {
        if (!this.model.data) {
            Object.assign(this.model, this.canvas.chartUtils.getDefaultPieChartModel(), this.model);
        }

        // if it is a legacy pie chart with no chartScale property we need to initially calculate the chartScale
        // and then check whether the chartScale is within the bounds of 0 and 1.
        // Otherwise, clamp the value to 0 or 1
        if (this.model.chartScale) {
            this.model.chartScale = _.clamp(this.chartScale, 0, 1);
        }

        let data = this.model.data;
        data.forEach(d => d.value == null ? d.value = 0 : d.value);
        if (this.model.sortValues) {
            data = _.reverse(_.sortBy(data, o => o.value));
        }

        const maxValue = _.maxBy(data, "value").value;

        if (data.filter(d => d.value === 0).length === data.length - 1) {
            data[data.length - 1].value = maxValue / 100000;
            data[data.length - 1].showZeroFlag = true;
        }

        this.wedges = [];
        for (let i = 0; i < data.length; i++) {
            let id = "wedge" + i;
            let model = data[i];
            model.id = id;
            this.wedges.push(this.addElement(id, () => PieChartWedge, { model }));
        }

        if (this.showLegend) {
            this.legend = this.addElement("legend", () => PieChartLegend);
        }
    }

    get minWidth() {
        return 150;
    }

    get minHeight() {
        return 150;
    }

    _calcProps(props) {
        const { size } = props;

        let legendProps;
        let legendSize = new geom.Size(0, 0);
        if (this.showLegend) {
            legendProps = this.legend.calcProps(size);
            legendSize = legendProps.size;
        }

        this.pieChartOffsetX = 0;
        this.pieChartOffsetY = 0;

        let pieChartBounds;
        if (this.model.chartScale) {
            // set radius based on size * chartScale
            let r = Math.round(Math.min(size.width - legendSize.width - this.styles.legendGap, size.height) / 2) * this.chartScale;
            pieChartBounds = this.calcPieLayout(props, new geom.Size(size.width - legendSize.width, size.height), r);

            if (this.showLegend) {
                let clonedSize = _.cloneDeep(size);
                if (pieChartBounds.height > size.height) {
                    clonedSize.height = size.height - (pieChartBounds.height - size.height);
                }

                if (pieChartBounds.width + legendSize.width + this.styles.legendGap > size.width) {
                    clonedSize.width = size.width - (pieChartBounds.width + legendSize.width + this.styles.legendGap - size.width);
                }
                r = Math.round(Math.min(clonedSize.width - legendSize.width - this.styles.legendGap, clonedSize.height) / 2) * this.chartScale;
                pieChartBounds = this.calcPieLayout(props, new geom.Size(clonedSize.width - legendSize.width, clonedSize.height), r);
            } else {
                const clonedSize = _.cloneDeep(size);

                // Adjust height if necessary
                if (pieChartBounds.height > size.height) {
                    clonedSize.height = size.height - props.options.childPadding.paddingBottom - props.options.childPadding.paddingTop;
                }

                // Adjust width if necessary
                if (pieChartBounds.width > size.width) {
                    clonedSize.width = size.width - props.options.childPadding.paddingLeft - props.options.childPadding.paddingRight;
                }

                // Calculate radius
                const radius = Math.round(Math.min(clonedSize.width, clonedSize.height) / 2) * this.chartScale;

                // Recalculate pie chart bounds
                pieChartBounds = this.calcPieLayout(props, clonedSize, radius);

                const fitBounds = pieChartBounds.centerInRect(new geom.Rect(0, 0, size));
                this.pieChartOffsetX = fitBounds.left - pieChartBounds.left;
                this.pieChartOffsetY = fitBounds.top - pieChartBounds.top;
            }
        } else {
            // legacy pie chart with no chartScale property uses autoFit
            pieChartBounds = this.autoFit(props);
        }

        if (this.showLegend) {
            let totalWidth = legendSize.width + this.styles.legendGap + pieChartBounds.width;
            let offsetX = size.width / 2 - totalWidth / 2;

            if (this.model.legendPosition == "left") {
                this.pieChartOffsetX += offsetX + this.styles.legendGap + legendSize.width - pieChartBounds.left;
                legendProps.bounds = new geom.Rect(offsetX, size.height / 2 - legendSize.height / 2, legendSize);
            } else if (this.model.legendPosition === "right") {
                // Calculate the offset for the pie chart
                this.pieChartOffsetX += offsetX - pieChartBounds.left;

                // Calculate the bounds for the legend
                const legendX = offsetX + pieChartBounds.width + this.styles.legendGap;
                const legendY = (size.height - legendSize.height) / 2;
                legendProps.bounds = new geom.Rect(legendX, legendY, legendSize.width, legendSize.height);
            } else {
                this.pieChartOffsetX += offsetX - pieChartBounds.left - this.styles.legendGap;
                legendProps.bounds = new geom.Rect(offsetX + pieChartBounds.width, size.height / 2 - legendSize.height / 2, legendSize);
            }
        }

        for (let wedge of this.wedges) {
            wedge.centerX += this.pieChartOffsetX;
            wedge.bounds.left += this.pieChartOffsetX;

            wedge.bounds.top += this.pieChartOffsetY;
            wedge.centerY += this.pieChartOffsetY;
        }

        return { size };
    }

    autoFit(props) {
        let r = Math.round(Math.min(props.allowedSize.width, props.allowedSize.height) / 2);

        let totalLabelBounds;
        let attempts = 0;
        while (attempts < 25) {
            totalLabelBounds = this.calcPieLayout(props, props.allowedSize, r);
            if (totalLabelBounds.top > 0 && totalLabelBounds.bottom < props.allowedSize.height && totalLabelBounds.left > 0 && totalLabelBounds.right < props.allowedSize.width) {
                break;
            }
            r -= 5;
            attempts++;
        }

        this.model.chartScale = r / Math.round(Math.min(props.allowedSize.width, props.allowedSize.height) / 2);
        return totalLabelBounds;
    }

    async scaleChartToFit() {
        const { model, canvas } = this;
        model.chartScale = 1;
        canvas.updateCanvasModel(false);
    }

    calcPieLayout(props, size, radius) {
        const total = _.sumBy(this.model.data, m => m.value);
        const innerRadius = this.model.isDonut ? this.model.innerRadius || this.styles.innerRadius : 0;
        const maxLabelScale = this.model.labelScale || 1;

        const doesIntersect = (source, list) => {
            for (let target of list) {
                if (!target.equals(source) && source.inflate(5).intersects(target.inflate(5))) {
                    return true;
                }
            }
            return false;
        };

        const doesWedgeLabelIntersect = (wedge, bounds) => {
            let intersects = false;

            const sourceValueBounds = wedge.label.value?.bounds.offset(bounds.left, bounds.top).inflate(5);
            const sourceLabelBounds = wedge.label.label?.bounds.offset(bounds.left, bounds.top).inflate(5);

            if (!sourceValueBounds && !sourceLabelBounds) return false;

            for (let otherWedge of this.wedges) {
                if (wedge.id !== otherWedge.id) {
                    let targetValueBounds;
                    if (sourceValueBounds && otherWedge.label.value) {
                        targetValueBounds = otherWedge.label.value.bounds.offset(otherWedge.label.bounds.left, otherWedge.label.bounds.top).inflate(5);
                        if (sourceValueBounds.intersects(targetValueBounds)) {
                            intersects = true;
                            break;
                        }
                    }

                    if (sourceLabelBounds && otherWedge.label.label) {
                        let targetLabelBounds = otherWedge.label.label.bounds.offset(otherWedge.label.bounds.left, otherWedge.label.bounds.top).inflate(5);
                        if ((targetValueBounds && sourceLabelBounds.intersects(targetValueBounds)) || (sourceValueBounds && sourceValueBounds.intersects(targetLabelBounds)) || sourceLabelBounds.intersects(targetLabelBounds)) {
                            intersects = true;
                            break;
                        }
                    }
                }
            }
            return intersects;
        };

        const outsideLabelOffset = 10;

        let angle = 0;

        // This function sets the percentage of each wedge based on the total value
        // and ensures that no wedge has a percentage less than 1%
        // This is to avoid rendering issues with small wedges
        // it also makes sure that if there are small wedges, they are being taken into account
        // so the total percentage is 1
        const setWedgePercentage = () => {
            let totalPercentage = 1;
            // make sure percentage is at least 1% to avoid rendering issues
            const minPercentage = 0.01;
            const smallWedges = this.wedges.filter(wedge => (wedge.model.value / total) < minPercentage);

            smallWedges.forEach(wedge => {
                totalPercentage -= minPercentage;
                wedge.percentage = minPercentage;
            });

            const remainingWedges = this.wedges.filter(wedge => !smallWedges.includes(wedge));
            const remainingTotal = _.sumBy(remainingWedges, "model.value");
            remainingWedges.forEach(wedge => {
                wedge.percentage = (wedge.model.value / remainingTotal) * totalPercentage;
            });
        };

        setWedgePercentage();

        // set wedge options so they can lay themselves out
        const allLabelBounds = this.wedges.map(wedge => {
            wedge.radius = radius;
            wedge.angle = angle;
            wedge.startAngle = this.model.startAngle || 0;
            wedge.innerRadius = innerRadius;
            wedge.label.showConnector = false;
            const wedgeProps = wedge.calcProps(size);
            wedgeProps.bounds = new geom.Rect(0, 0, size);

            angle += 360 * wedge.percentage;

            let labelProps;

            if (this.labelPosition === "outside" || size.width < 300 || size.height < 300) {
                labelProps = wedge.label.calcLabelProps(props, false, Math.min(0.6, maxLabelScale), outsideLabelOffset, 0);
                //wedge.label.showConnector = true;
            } else {
                labelProps = wedge.label.calcLabelProps(props, true, maxLabelScale, 0, 0);

                const paddedLabelBounds = labelProps.bounds.inflate(5);
                // check if label intersects with wedge and recalculate label bounds outside radius with smaller scale
                if (paddedLabelBounds.intersectsLine(wedge.edgeLines[0][0], wedge.edgeLines[0][1], wedge.edgeLines[0][2], wedge.edgeLines[0][3]).any || paddedLabelBounds.intersectsLine(wedge.edgeLines[1][0], wedge.edgeLines[1][1], wedge.edgeLines[1][2], wedge.edgeLines[1][3]).any) {
                    labelProps = wedge.label.calcLabelProps(props, false, Math.min(0.6, maxLabelScale), outsideLabelOffset, 0);
                    //wedge.label.showConnector = wedge.percentage < 0.05;
                } else if (innerRadius > 0) {
                    labelProps = wedge.label.calcLabelProps(props, false, maxLabelScale, outsideLabelOffset, 0);
                    //wedge.label.showConnector = wedge.percentage < 0.05;
                }
            }
            return labelProps.bounds;
        });

        //try rotating labels to fit
        rotateLoop: for (let i = 0; i < this.wedges.length; i++) {
            const wedge = this.wedges[i];
            if (doesWedgeLabelIntersect(wedge, wedge.label.calculatedProps.bounds)) {
                for (let angleOffset = 0.01; angleOffset < wedge.percentage * Math.PI + Math.PI * 0.01; angleOffset = angleOffset + 0.01) {
                    const labelProps = wedge.label.calcLabelProps(props, false, Math.min(0.6, maxLabelScale), outsideLabelOffset, -angleOffset);
                    if (!doesWedgeLabelIntersect(wedge, labelProps.bounds)) {
                        allLabelBounds[i] = labelProps.bounds;
                        wedge.label.showConnector = true;
                        wedge.label.isRotated = true;
                        break rotateLoop;
                    }
                }
                for (let angleOffset = wedge.percentage * Math.PI + Math.PI * 0.01; 0 < angleOffset; angleOffset = angleOffset - 0.01) {
                    const labelProps = wedge.label.calcLabelProps(props, false, Math.min(0.6, maxLabelScale), outsideLabelOffset, angleOffset);
                    if (!doesWedgeLabelIntersect(wedge, labelProps.bounds)) {
                        allLabelBounds[i] = labelProps.bounds;
                        wedge.label.showConnector = true;
                        wedge.label.isRotated = true;
                        break rotateLoop;
                    }
                }

                wedge.label.isRotated = false;
            }
        }

        // if the label is still overlapping other labels, move it outwards until it no longer overlaps
        const offsetLabelBounds = [];
        _.sortBy(this.wedges, w => w.model.value).forEach((wedge, i) => {
            // check for intersection while increasing distance
            let offset = 1;
            offsetLabelBounds.push(wedge.label.calculatedProps.bounds);

            while (doesIntersect(wedge.label.calculatedProps.bounds, offsetLabelBounds)) {
                const labelProps = wedge.label.calcLabelProps(props, false, Math.min(0.6, maxLabelScale), offset, 0);
                offsetLabelBounds[i] = labelProps.bounds;

                if (offset++ >= 300) break;
            }
        });

        const pieBounds = new geom.Rect(size.width / 2 - radius, size.height / 2 - radius, radius * 2, radius * 2);

        if (this.model.format === "none") {
            return pieBounds;
        }

        let totalLabelBounds = null;
        this.wedges.forEach(wedge => {
            if (totalLabelBounds) {
                totalLabelBounds = totalLabelBounds.union(wedge.label.bounds);
            } else {
                totalLabelBounds = wedge.label.calculatedProps.bounds;
            }
        });

        return pieBounds.union(totalLabelBounds);
    }

    updateChartData(chartData, initialImport = false) {
        const newCategories = chartData.categories || [];
        const newValues = chartData.series[0].data;

        if (!newCategories.length || !newValues.length || newCategories.length !== newValues.length) {
            throw new Error("Invalid PieChart data passed");
        }

        const newData = [];
        let newWedgeIndex = 0;

        for (let i = 0; i < Math.min(this.model.data.length, newCategories.length); i++) {
            let dataObj = { ...this.model.data[i], value: newValues[i] };
            dataObj.label.blocks[0].html = newCategories[i];
            if (dataObj.id.startsWith("wedge")) {
                newWedgeIndex = parseInt(dataObj.id.substring(5));
            }
            newData.push(dataObj);
        }

        if (newData.length < newCategories.length) {
            for (let i = newData.length; i < newCategories.length; i++) {
                newWedgeIndex += 1;
                newData.push({
                    id: "wedge" + newWedgeIndex,
                    offset: 0,
                    value: newValues[i],
                    label: {
                        blocks: [{
                            html: newCategories[i],
                            id: "migrated-label",
                            indent: 0,
                            listStyle: null,
                            textStyle: "_use_styles_",
                            type: "text",
                        }]
                    }
                });
            }
        }

        this.model.data = newData;

        if (initialImport && chartData.yAxisFormat) {
            this.model.format = chartData.yAxisFormat;
            this.model.format = chartData.yAxisFormat;
        }

        return this.model;
    }

    renderChildren(transition) {
        const renderChildren = super.renderChildren(transition);

        const lines = this.wedges
            .filter(wedge => wedge.label.showConnector && (wedge.label.showValue || wedge.label.showLabel))
            .map((wedge, idx) => {
                const sourceShapeBounds = wedge.label.bounds.inflate(3)
                    .offset(this.pieChartOffsetX, this.pieChartOffsetY);
                const sourcePoint = sourceShapeBounds.center;

                const targetPoint = wedge.connectorPoint;

                const x1 = sourcePoint.x;
                const y1 = sourcePoint.y;
                const x2 = targetPoint.x;
                const y2 = targetPoint.y;

                const getColor = this.palette.getColor("primary", this.getBackgroundColor()).toRgbString();

                const getIntersectionWithSourceBounds = () => {
                    const leftIntersection = new geom.Point(sourceShapeBounds.left, y2 + (sourceShapeBounds.left - x2) / (x1 - x2) * (y1 - y2));
                    if (leftIntersection.y >= sourceShapeBounds.top && leftIntersection.y <= sourceShapeBounds.bottom && ((x1 <= sourceShapeBounds.left && x2 >= sourceShapeBounds.left) || (x1 >= sourceShapeBounds.left && x2 <= sourceShapeBounds.left))) {
                        return leftIntersection;
                    }

                    const rightIntersection = new geom.Point(sourceShapeBounds.right, y2 + (sourceShapeBounds.right - x2) / (x1 - x2) * (y1 - y2));
                    if (rightIntersection.y >= sourceShapeBounds.top && rightIntersection.y <= sourceShapeBounds.bottom && ((x1 <= sourceShapeBounds.right && x2 >= sourceShapeBounds.right) || (x1 >= sourceShapeBounds.right && x2 <= sourceShapeBounds.right))) {
                        return rightIntersection;
                    }

                    const topIntersection = new geom.Point(x2 + (sourceShapeBounds.top - y2) / (y1 - y2) * (x1 - x2), sourceShapeBounds.top);
                    if (topIntersection.x >= sourceShapeBounds.left && topIntersection.x <= sourceShapeBounds.right && ((y1 <= sourceShapeBounds.top && y2 >= sourceShapeBounds.top) || (y1 >= sourceShapeBounds.top && y2 <= sourceShapeBounds.top))) {
                        return topIntersection;
                    }

                    const bottomIntersection = new geom.Point(x2 + (sourceShapeBounds.bottom - y2) / (y1 - y2) * (x1 - x2), sourceShapeBounds.bottom);
                    if (bottomIntersection.x >= sourceShapeBounds.left && bottomIntersection.x <= sourceShapeBounds.right && ((y1 <= sourceShapeBounds.bottom && y2 >= sourceShapeBounds.bottom) || (y1 > sourceShapeBounds.bottom && y2 <= sourceShapeBounds.bottom))) {
                        return bottomIntersection;
                    }

                    return sourcePoint;
                };

                const startPoint = getIntersectionWithSourceBounds();

                return (
                    <line
                        key={idx}
                        {...getSVGStyleProps(this.styles.connectors)}
                        stroke={getColor}
                        x1={startPoint.x}
                        y1={startPoint.y}
                        x2={x2}
                        y2={y2}
                    />
                );
            });

        if (lines.length > 0) {
            renderChildren.insert(<SVGGroup key={this.id}>{lines}</SVGGroup>, 0);
        }

        return renderChildren;
    }

    getAnimations() {
        const animations = this._getAnimations();
        animations.forEach(animation => animation.element = this);

        if (this.animateChildren) {
            Object.values(this.elements)
                // Sorting wedges by their value
                .sort((elementA, elementB) => elementA.model.value - elementB.model.value)
                .forEach(element => {
                    animations.push(...element.getAnimations());
                });
        }

        if (this.disableAnimationsByDefault) {
            animations.forEach(animation => animation.disabledByDefault = true);
        }

        return animations;
    }

    _exportToSharedModel() {
        const values = this.itemElements.map(item => Math.round(item.percentage * 100));

        const textContent = this.itemElements.map((item, i) => ({
            mainText: { text: item.model.label.text ?? item.label.label.blocks[0].textContent },
            secondaryTexts: [{ text: values[i] }]
        }));

        const compareContent = this.itemElements.map((item, i) => ({
            value: values[i], text: textContent[i],
            format: this.model.format, emphasized: !!item.model.emphasized
        }));

        const tabularData = [{
            data: [
                textContent.map(t => t.mainText.text),
                values
            ],
            dataSourceLink: _.omit(this.parentElement.model.dataSourceLink, ["useFirstRowAsCategory", "useFirstColAsLegend", "isDataTransposed"]),
            categories: textContent.map(t => t.mainText.text),
            series: [{ values }]
        }];

        return { textContent, compareContent, tabularData };
    }

    _importFromSharedModel(model) {
        const tabularData = detectTabularData(model);
        if (!tabularData) return;

        const chartData = getValidChartDataFromCsv(tabularData.data, true);
        if (chartData?.error) {
            throw new Error(`Pie chart data validation failed with error "${chartData.error}"`);
        }

        return this.updateChartData(chartData, true);
    }

    _migrate_10() {
        if (this.model.format === FormatType.PERCENT && !this.model.formatOptions) {
            this.model.formatOptions = formatter.getDefaultFormatOptions();
            this.model.formatOptions.decimal = 2;
        }
    }

    _migrate_10_02() {
        if (this.model.color == "auto") {
            this.model.color = null;
        }
    }
}

class PieChartWedge extends CollectionItemElement {
    get _canSelect() {
        return true;
    }

    get requireParentSelection() {
        return true;
    }

    get pieChart() {
        return this.parentElement;
    }

    getElementSelection() {
        return PieChartWedgeSelection;
    }

    containsPoint(pt) {
        const selectionBounds = this.selectionBounds;
        const radius = Math.sqrt(Math.pow(selectionBounds.centerH - pt.x, 2) + Math.pow(selectionBounds.centerV - pt.y, 2));
        let angle = Math.radiansToDegrees(Math.atan2(pt.y - selectionBounds.centerV, pt.x - selectionBounds.centerH));

        if (angle < 0) {
            angle = 360 + angle;
        }

        const startAngle = (this.startAngle + this.angle) % 360;
        let endAngle = (this.startAngle + this.angle + 360 * this.percentage) % 360;
        if (endAngle == 0) endAngle = 360;

        return (
            radius < this.outerRadius &&
            ((angle >= startAngle && angle <= endAngle) ||
                (endAngle < startAngle && angle <= endAngle))
        );
    }

    get connectorPoint() {
        return new geom.Point(this.centerX + this.outerRadius * Math.cos(this.centerAngle), this.centerY + this.outerRadius * Math.sin(this.centerAngle));
    }

    _build() {
        this.shape = this.addElement("shape", () => SVGPathElement);
        this.label = this.addElement("label", () => PieChartLabel, {
            canSelect: true,
            canRollover: true,
            backgroundElement: this.shape
        });
    }

    _calcProps(props) {
        const { size } = props;
        this.centerX = size.width / 2;
        this.centerY = size.height / 2;

        const startAngle = Math.degreesToRadians(this.startAngle + this.angle);
        this.centerAngle = Math.degreesToRadians(this.startAngle + this.angle + 180 * this.percentage);
        const endAngle = Math.degreesToRadians(this.startAngle + this.angle + 360 * this.percentage);
        const offset = this.model.emphasized ? (this.styles.offset || 10) : 0;

        this.outerRadius = this.radius + offset;
        this.innerRadius = this.radius * this.innerRadius / 100;

        this.edgeLines = [];

        if (this.innerRadius > 0) {
            this.edgeLines.push([this.centerX + this.innerRadius * Math.cos(startAngle), this.centerY + this.innerRadius * Math.sin(startAngle), this.centerX + this.outerRadius * Math.cos(startAngle), this.centerY + this.outerRadius * Math.sin(startAngle)]);
            this.edgeLines.push([this.centerX + this.outerRadius * Math.cos(endAngle), this.centerY + this.outerRadius * Math.sin(endAngle), this.centerX + this.innerRadius * Math.cos(endAngle), this.centerY + this.innerRadius * Math.sin(endAngle)]);
        } else {
            this.edgeLines.push([this.centerX, this.centerY, this.centerX + this.outerRadius * Math.cos(startAngle), this.centerY + this.outerRadius * Math.sin(startAngle)]);
            this.edgeLines.push([this.centerX + this.outerRadius * Math.cos(endAngle), this.centerY + this.outerRadius * Math.sin(endAngle), this.centerX, this.centerY]);
        }

        this.shape.createProps({
            path: Shape.drawWedge(this.centerX, this.centerY, this.innerRadius, this.outerRadius, startAngle, endAngle).toPathData(),
            forceTransition: false,
            layer: -1
        });
        return {
            size,
            startAngle,
            endAngle,
            innerRadius: this.innerRadius,
            outerRadius: this.outerRadius
        };
    }

    _applyColors() {
        this.shape.colorSet.fillColor = this.palette.getColor(this.getDecorationColor());
        this.shape.colorSet.backgroundColor = this.shape.colorSet.fillColor;
        this.shape.colorSet.strokeColor = this.palette.getColor(this.getBackgroundColor());
    }

    get animationElementName() {
        return `Wedge ${this.model.label?.text || this.pieChart.formatValue(this.model.value) || `#${this.itemIndex}`}`;
    }
}

class PieChartLabel extends BaseElement {
    get _canSelect() {
        return true;
    }

    get _canRollover() {
        return false;
    }

    get wedge() {
        return this.parentElement;
    }

    get showLabel() {
        return !this.wedge.pieChart.showLegend;
    }

    get showValue() {
        return this.wedge.pieChart.format !== FormatType.NONE;
    }

    _build() {
        if (this.showValue) {
            this.value = this.addElement("value", () => TextElement, {
                canEdit: false,
                canSelect: false,
                canRollover: false,
                placeholder: " ",
                autoHeight: true,
                autoWidth: true,
                html: this.wedge.pieChart.formatValue(this.model.value),
                backgroundElement: this.options.backgroundElement
            });
        }

        if (this.showLabel) {
            this.label = this.addElement("label", () => TextElement, {
                autoHeight: true,
                autoWidth: true,
                backgroundElement: this.options.backgroundElement,
            });
        }
    }

    _calcProps(props, { stylesScale, horizontalAlign }) {
        const { size } = props;

        if (this.showLabel && this.showValue) {
            const layouter = this.getLayouter(props, [this.label, this.value], size);
            layouter.distributeVertically({
                gap: this.styles.gap ?? 0,
                horizontalAlign: horizontalAlign,
                itemOptions: { stylesScale }
            });

            return { size: layouter.size };
        }

        if (this.showValue) {
            const valueProps = this.value.calcProps(size, { stylesScale });
            return { size: valueProps.size };
        }

        if (this.showLabel) {
            const labelProps = this.label.calcProps(size, { stylesScale });
            return { size: labelProps.size };
        }

        return { size };
    }

    _applyColors() {
        if (this.calculatedProps.isOutside) {
            if (this.showValue) {
                this.value.options.backgroundElement = this.getRootElement();
            }
            if (this.showLabel) {
                this.label.options.backgroundElement = this.getRootElement();
            }
        }
    }

    resetCalculatedProps() {
        // NOOP
    }

    calcLabelProps(props, isInside, scale, offset, rotation) {
        let labelDistance;
        if (isInside) {
            labelDistance = this.wedge.outerRadius * .58;
            props.isOutside = false;
        } else {
            labelDistance = this.wedge.outerRadius + offset;
            props.isOutside = true;
        }
        const labelPoint = new geom.Point(this.wedge.centerX + labelDistance * Math.cos(this.wedge.centerAngle + rotation), this.wedge.centerY + labelDistance * Math.sin(this.wedge.centerAngle + rotation));

        if (this.wedge.pieChart.showLegend) {
            this.updateStyles(this.styles.valueOnly, true);
        }

        const horizontalAlign = labelPoint.x < this.wedge.centerX ? HorizontalAlignType.RIGHT : HorizontalAlignType.LEFT;

        const labelProps = this.calcProps(new geom.Size(170, 300), { stylesScale: scale, horizontalAlign });
        labelProps.isOutside = !isInside;
        const labelWidth = labelProps.size.width;
        const labelHeight = labelProps.size.height;

        let labelLoc;
        if (isInside) {
            labelLoc = new geom.Point(labelPoint.x - labelProps.size.width / 2, labelPoint.y - labelProps.size.height / 2);
        } else {
            if (labelPoint.x < this.wedge.centerX && labelPoint.y < this.wedge.centerY) {
                labelLoc = new geom.Point(labelPoint.x - labelWidth, labelPoint.y - labelHeight);
            } else if (labelPoint.x >= this.wedge.centerX && labelPoint.y < this.wedge.centerY) {
                labelLoc = new geom.Point(labelPoint.x, labelPoint.y - labelHeight);
            } else if (labelPoint.x < this.wedge.centerX && labelPoint.y >= this.wedge.centerY) {
                labelLoc = new geom.Point(labelPoint.x - labelWidth, labelPoint.y);
            } else {
                labelLoc = new geom.Point(labelPoint.x, labelPoint.y);
            }
        }

        labelProps.bounds = new geom.Rect(labelLoc, labelProps.size);
        return labelProps;
    }
}

class PieChartLegend extends CollectionElement {
    get _canSelect() {
        return false;
    }

    getChildItemType(itemModel) {
        return PieChartLegendItem;
    }

    get collectionPropertyName() {
        return "data";
    }

    _calcProps(props, options) {
        const { size } = props;
        const layouter = this.getLayouter(props, this.itemElements, size);
        layouter.distributeVertically({
            gap: this.styles.vGap,
            horizontalAlign: HorizontalAlignType.LEFT
        });

        props.isFit = true;

        return { size: layouter.size };
    }
}

class PieChartLegendItem extends CollectionItemElement {
    get _canSelect() {
        return false;
    }

    _build() {
        this.circle = this.addElement("circle", () => SVGCircleElement);
        this.label = this.addElement("label", () => TextElement, {
            blockStructure: BlockStructureType.SINGLE_BLOCK,
            canEdit: true,
            autoWidth: true,
            autoHeight: true
        });
    }

    _calcProps(props, options) {
        const { size } = props;
        const hGap = this.styles.hGap;
        const circleSize = this.styles.circleSize;

        const labelProps = this.label.calcProps(new geom.Size(size.width - hGap - circleSize, size.height));

        const circleProps = this.circle.createProps();
        circleProps.bounds = new geom.Rect(0, labelProps.size.height / 2 - circleSize / 2, circleSize, circleSize);

        labelProps.bounds = new geom.Rect(circleSize + hGap, 0, labelProps.size);
        return { size: new geom.Size(labelProps.size.width + circleSize + hGap, 30) };
    }

    _applyColors() {
        this.circle.colorSet.fillColor = this.findClosestOfType("PieChart").itemElements[this.itemIndex].colorSet.decorationColor;
    }
}

export { PieChart, PieChartLabel };
