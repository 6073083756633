import getLogger, { LogGroup } from "js/core/logger";
import { $, _ } from "js/vendor";
import { v4 as uuid } from "uuid";
import React from "react";

import * as geom from "js/core/utilities/geom";
import {
    AssetType,
    VerticalAlignType,
    BackgroundStyleType,
    BlockStructureType,
    AuthoringBlockType,
    TextStyleType,
    ElementTextBlockPositionType, DecorationStyle,
} from "common/constants";
import { getStaticUrl } from "js/config";
import { sanitizeSvg } from "js/core/utilities/dompurify";
import { detectListContent } from "js/core/services/sharedModelManager";

import { CollectionElement, CollectionItemElement } from "../base/CollectionElement";
import { TextElement } from "../base/Text/TextElement";
import { ContentElement } from "../base/ContentElement";
import { BaseElement } from "../base/BaseElement";
import { configs } from "./config/config-journey";
import { JourneyItemControlBar, JourneyPropertyPanel } from "../../Editor/ElementPropertyPanels/JourneyUI";
import DecorationStyles from "../../../../react/components/DecorationStyles";
import { FramedMediaElement } from "../base/MediaElements/FramedMediaElement";

const logger = getLogger(LogGroup.ELEMENTS);

class Journey extends CollectionElement {
    getElementPropertyPanel() {
        return JourneyPropertyPanel;
    }

    getChildItemType() {
        return JourneyItem;
    }

    get configType() {
        const icons = this.model.milestoneStyle || "no-icons";
        return `${icons}`;
    }
    set configType(value) {
        this.model.configType = value;
    }

    get config() {
        let result = configs[this.configType];
        if (!result) {
            this.configType = null;
            result = configs[this.configType];
        }
        return result;
    }

    get pathType() {
        const start = this.model.showStartMarker ? "with-start" : "no-start";
        const end = this.model.showEndMarker ? "with-end" : "no-end";
        return `${start}-${end}`;
    }

    get maxItemCount() {
        return Object.keys(this.config.itemConfigsMap).length;
    }

    get minHeight() {
        return 200;
    }

    getCanvasMargins() {
        return {
            top: 0,
            left: 0,
            right: 0,
            bottom: this.canvas.model.layout.elementTextBlockPosition == ElementTextBlockPositionType.INLINE ? 50 : 0,
        };
    }

    get decorationStyle() {
        // Journey ignores theme for markers
        return DecorationStyle.FILLED;
    }

    _build() {
        this.markerSrc = this.config.markerSrc;
        this.contentConfig = this.config.contentConfig;
        this.path = this.addElement("path", () => JourneySvg, {
            src: this.config.pathSrc[this.pathType],
            preserveStroke: true,
        });
        if (this.model.showStartMarker) {
            this.model.startConfig = this.config.startConfig;
            this.startText = this.addElement("start_marker", () => JourneyStart, {
                model: this.model.startMarker
            });
        }
        if (this.model.showEndMarker) {
            this.model.endConfig = this.config.endConfig;
            this.end = this.addElement("end_marker", () => JourneyEnd, {
                markerSrc: this.model.endConfig.marker.src,
                color: this.model.endColor,
                model: this.model.endMarker,
            });
        }
        super._build();
    }

    getMarkerConfig(itemConfig) {
        const size = new geom.Size(
            ...(
                itemConfig.marker.size ||
                this.config.markerSize ||
                [52, 52]
            )
        );
        const bounds = new geom.Rect(this.centerOffset.plus(...itemConfig.marker.pos), size);
        return {
            bounds,
        };
    }

    getTextConfig(itemConfig) {
        const size = new geom.Size(
            ...(
                itemConfig.text.size ||
                this.config.textSize ||
                [200, 120]
            )
        );
        const bounds = new geom.Rect(this.centerOffset.plus(...itemConfig.text.pos), size);
        const align = itemConfig.text.align || null;
        const verticalAlign = itemConfig.text.verticalAlign || VerticalAlignType.TOP;
        return {
            bounds,
            align,
            verticalAlign,
        };
    }

    _calcProps(props, options) {
        let { size, children } = props;
        const minSize = new geom.Size(...this.config.minSize);

        props.isFit = (
            minSize.width <= size.width &&
            minSize.height <= size.height
        );

        this.centerOffset = new geom.Point(
            (size.width - minSize.width) / 2,
            (size.height - minSize.height) / 2,
            // 0, 0
        );

        const count = Math.min(this.maxItemCount, this.itemElements.length);
        const itemConfigs = this.config.itemConfigsMap[count];
        for (let index = 0; index < count; ++index) {
            const item = this.itemElements[index];
            const itemConfig = itemConfigs[index];

            const markerConfig = this.getMarkerConfig(itemConfig);
            const textConfig = this.getTextConfig(itemConfig);

            let itemBounds = markerConfig.bounds.union(textConfig.bounds);

            // Adjust the bounds to be relative to the 'itemBounds'.
            {
                const { x, y } = itemBounds.position;
                markerConfig.bounds = markerConfig.bounds.offset(-x, -y);
                textConfig.bounds = textConfig.bounds.offset(-x, -y);
            }

            const itemOptions = {
                markerConfig,
                textConfig,
            };
            const itemProps = item.calcProps(itemBounds.size, itemOptions);
            {
                const x = itemBounds.x;
                const y = itemBounds.y + itemProps.vOffset;
                itemBounds = new geom.Rect(x, y, itemProps.size);
            }

            if (item.isDragging) {
                itemBounds = new geom.Rect(item.dragPosition, itemBounds.size);
            }
            itemProps.bounds = itemBounds;
        }

        // Path
        {
            const pathProps = this.path.calcProps(size);
            pathProps.bounds = new geom.Rect(this.centerOffset, pathProps.size); // Force the full size on the path
        }

        // Start
        if (this.model.showStartMarker) {
            const itemConfig = this.model.startConfig;
            const textConfig = this.getTextConfig(itemConfig);

            const itemProps = this.startText.calcProps(textConfig.bounds.size, {
                textAlign: textConfig.align,
                verticalAlign: textConfig.verticalAlign,
            });
            textConfig.bounds = new geom.Rect(textConfig.bounds.position, itemProps.size);
            itemProps.bounds = textConfig.bounds;
        }

        // End
        if (this.model.showEndMarker) {
            const itemConfig = this.model.endConfig;
            const markerConfig = this.getMarkerConfig(itemConfig);
            const textConfig = this.getTextConfig(itemConfig);

            let itemBounds = markerConfig.bounds.union(textConfig.bounds);

            // Adjust the bounds to be relative to the 'itemBounds'.
            {
                const { x, y } = itemBounds.position;
                markerConfig.bounds = markerConfig.bounds.offset(-x, -y);
                textConfig.bounds = textConfig.bounds.offset(-x, -y);
            }

            const itemProps = this.end.calcProps(itemBounds.size, {
                markerConfig,
                textConfig,
            });
            itemBounds = new geom.Rect(itemBounds.position, itemProps.size);
            itemProps.bounds = itemBounds;
        }

        return { size };
    }

    _exportToSharedModel() {
        const startMarker = this.model.showStartMarker ? this.startText._exportToSharedModel().textContent[0] : null;
        const endMarker = this.model.showEndMarker ? this.end.text._exportToSharedModel().textContent[0] : null;

        const listContent = this.itemElements.map(item => ({
            text: item.text._exportToSharedModel().textContent[0],
            asset: item.model.content_value ? {
                type: item.model.content_type,
                value: item.model.content_value,
                props: item.model.assetProps,
                name: item.model.assetName,
            } : null,
        }));

        const assets = this.model.items.filter(item => item.content_value).map(item => ({
            type: item.content_type,
            value: item.content_value,
            props: item.assetProps,
            name: item.assetName,
        }));

        const textContent = this.itemElements.reduce((textContent, itemElement) => ([
            ...textContent, ...itemElement.text._exportToSharedModel().textContent
        ]), []);

        return { listContent, assets, textContent, startMarker, endMarker, collectionColor: this.collectionColor };
    }

    _importFromSharedModel(model) {
        const listContent = detectListContent(model);
        if (!listContent?.length) return;

        const { startMarker, endMarker } = model;

        const items = listContent.map(({ text, asset }) => ({
            ...(asset ? {
                content_type: asset.type,
                content_value: asset.value,
                assetProps: asset.props,
                assetName: asset.name,
                ...(asset.configProps ?? {})
            } : {}),
            ...(text ? {
                text: {
                    blocks: [
                        {
                            html: text.mainText.text,
                            textStyle: TextStyleType.TITLE,
                            type: AuthoringBlockType.TEXT,
                        },
                        ...text.secondaryTexts.map(secondaryText => ({
                            html: secondaryText.text,
                            textStyle: TextStyleType.BODY,
                            type: AuthoringBlockType.TEXT,
                        })),
                    ]
                }
            } : {})
        }));

        return {
            items,
            ...(startMarker ? {
                showStartMarker: true,
                startMarker: {
                    start_marker: {
                        blocks: [
                            {
                                html: startMarker.mainText.text,
                                textStyle: TextStyleType.TITLE,
                                type: AuthoringBlockType.TEXT,
                            },
                            ...startMarker.secondaryTexts.map(secondaryText => ({
                                html: secondaryText.text,
                                textStyle: TextStyleType.BODY,
                                type: AuthoringBlockType.TEXT,
                            })),
                        ]
                    }
                }
            } : {}),
            ...(endMarker ? {
                showEndMarker: true,
                endMarker: {
                    end_marker: {
                        text: endMarker.mainText.text,
                        blocks: [
                            {
                                html: endMarker.mainText.text,
                                textStyle: TextStyleType.TITLE,
                                type: AuthoringBlockType.TEXT,
                            },
                            ...endMarker.secondaryTexts.map(secondaryText => ({
                                html: secondaryText.text,
                                textStyle: TextStyleType.BODY,
                                type: AuthoringBlockType.TEXT,
                            })),
                        ]
                    }
                }
            } : {}),
            milestoneStyle: listContent.some(({ asset }) => asset) ? "with-icons" : "no-icons",
            collectionColor: model.collectionColor,
        };
    }
}

class JourneyItem extends CollectionItemElement {
    getElementControlBar() {
        return JourneyItemControlBar;
    }

    get name() {
        return "JourneyItem";
    }

    get canDelete() {
        return true;
    }

    get selectionPadding() {
        return 10;
    }

    async convertToAuthoring(exportElementAsGroup, exportChildrenAsGroup) {
        if (this.marker) {
            await exportChildrenAsGroup([
                this.text,
                this.marker
            ]);
        } else if (this.content) {
            await exportChildrenAsGroup([
                this.text,
                this.content
            ]);
        } else if (this.text) {
            await exportChildrenAsGroup(this.text);
        }
    }

    get mediaElement() {
        return this.content;
    }

    get shapeStyle() {
        return "circle";
    }

    _build() {
        this.text = this.addElement("text", () => TextElement, {
            blockStructure: BlockStructureType.TITLE_AND_BODY,
            autoHeight: true,
            syncFontSizeWithSiblings: true,
            scaleTextToFit: true,
        });

        if (this.parentElement.contentConfig) {
            this.content = this.addElement("content", () => JourneyIconMarker, {
                model: this.model,
                defaultAssetType: AssetType.ICON,
            });
            this.marker = null;
        } else {
            this.marker = this.addElement("marker", () => JourneySvg, {
                src: this.parentElement.markerSrc,
            });
            this.content = null;
        }

        super._build();
    }

    _calcProps(props, options) {
        const { size } = props;
        const {
            markerConfig,
            textConfig,
        } = options;

        if (this.content) {
            this.content.decoration.updateStyles({
                fillColor: "slide",
                strokeColor: this.getBackgroundColor().toRgbString(),
            });
        }

        const marker = this.marker || this.content;

        const markerProps = marker.calcProps(markerConfig.bounds.size);

        const textProps = this.text.calcProps(textConfig.bounds.size, {
            textAlign: textConfig.align,
        });
        let vOffset = 0;
        switch (textConfig.verticalAlign) {
            case VerticalAlignType.BOTTOM:
                {
                    vOffset = (textConfig.bounds.height - textProps.size.height);
                    const x = markerConfig.bounds.x;
                    const y = markerConfig.bounds.y - vOffset;
                    markerProps.bounds = new geom.Rect(x, y, markerProps.size);
                    textProps.bounds = new geom.Rect(textConfig.bounds.position, textProps.size);
                }
                break;
            case VerticalAlignType.MIDDLE:
                {
                    vOffset = ((textConfig.bounds.height - textProps.size.height) / 2);
                    const x = markerConfig.bounds.x;
                    const y = markerConfig.bounds.y - vOffset;
                    markerProps.bounds = new geom.Rect(x, y, markerProps.size);
                    textProps.bounds = new geom.Rect(textConfig.bounds.position, textProps.size);
                }
                break;
            case VerticalAlignType.TOP:
            default:
                {
                    markerProps.bounds = new geom.Rect(markerConfig.bounds.position, markerProps.size);
                    textProps.bounds = new geom.Rect(textConfig.bounds.position, textProps.size);
                }
                break;
        }

        // vOffset needs to be exposed to the parent so we can properly shrink wrap the bounds
        return { size: markerProps.bounds.union(textProps.bounds).size, vOffset };
    }
}

class JourneyIconMarker extends ContentElement {
    // override the default shapeStyle to force circles
    get shapeStyle() {
        return "circle";
    }

    _applyColors() {
        this.colorSet.backgroundColor = this.getDecorationColor();
    }

    get decorationStyle() {
        // Journey ignores theme for markers
        return DecorationStyle.FILLED;
    }
}

class JourneySvg extends BaseElement {
    get name() {
        return "JourneySvg";
    }

    get _canSelect() {
        return false;
    }

    get _canRollover() {
        return false;
    }

    get _doubleClickToSelect() {
        return false;
    }

    get _passThroughSelection() {
        return false;
    }

    _build() {
    }

    _load() {
        // don't reload if the src hasn't change
        if (this.options.src && this.options.src === this.loadedSrc) {
            return;
        }

        this.loadedSrc = null;

        if (!this.options.src) {
            return;
        }

        const url = getStaticUrl(this.options.src);

        return fetch(url)
            .then(res => res.text())
            .then(svg => {
                // Getting the size of the svg
                const $svgElement = $(svg);
                this.svgSize = new geom.Size(parseInt($svgElement.attr("width")), parseInt($svgElement.attr("height")));

                this.svgProps = {
                    svg,
                };
                this.loadedSrc = this.options.src;
            }).catch(err => {
                // remove any existing src paths
                if (err.statusCode !== 404) {
                    logger.error(err, "[Journey] failed to load svg");
                }
            });
    }

    _calcProps(props, options = {}) {
        const { size } = props;
        return {
            size: this.svgSize || size,
            ...this.svgProps
        };
    }

    renderChildren(transition) {
        let props = this.calculatedProps;
        const {
            svg,
            bounds,
        } = props;

        if (svg) {
            // Replace color tags
            const backgroundStyle = this.canvas.layouter.elements.background.canvasBackgroundStyle;
            const colorsEnabled = (
                backgroundStyle == BackgroundStyleType.LIGHT ||
                backgroundStyle == BackgroundStyleType.DARK ||
                backgroundStyle == BackgroundStyleType.ACCENT
            );

            let primaryColor = this.palette.getColor("primary", this.getBackgroundColor());
            let color = this.palette.getColor(this.model.color ?? this.getRootElement().collectionColor ?? "theme", this.getBackgroundColor(), { itemIndex: this.itemIndex });

            const coloredSvg = svg
                .replace(/\[background\]/, this.getBackgroundColor().toRgbString())
                .replace(/\[foreground\]/, colorsEnabled ? color.toRgbString() : "white")
                .replace(/\[foreground-auto\]/, primaryColor.toRgbString());

            return (
                <svg
                    key={this.id}
                    width={bounds.width}
                    height={bounds.height}
                    style={{ position: "absolute" }}
                    dangerouslySetInnerHTML={{ __html: sanitizeSvg(coloredSvg) }}
                ></svg>
            );
        }
    }
}

class JourneyStart extends TextElement {
    get allowStyling() {
        return true;
    }
}

class JourneyEnd extends BaseElement {
    get name() {
        return "JourneyEnd";
    }

    get _canSelect() {
        return true;
    }

    get selectionPadding() {
        return 10;
    }

    _build() {
        this.text = this.addElement("text", () => TextElement, {
            bindTo: "end_marker",

        });
        this.marker = this.addElement("marker", () => JourneySvg, {
            src: this.options.markerSrc,
        });

        super._build();
    }

    _calcProps(props, options) {
        const { size } = props;
        const {
            markerConfig,
            textConfig,
        } = options;

        const markerProps = this.marker.calcProps(markerConfig.bounds.size);
        markerProps.bounds = new geom.Rect(markerConfig.bounds.position, markerProps.size);

        const textProps = this.text.calcProps(textConfig.bounds.size, {
            textAlign: textConfig.align,
        });
        switch (textConfig.verticalAlign) {
            case VerticalAlignType.BOTTOM:
                {
                    const x = textConfig.bounds.x;
                    const y = textConfig.bounds.y + (textConfig.bounds.height - textProps.size.height);
                    textProps.bounds = new geom.Rect(x, y, textProps.size);
                }
                break;
            case VerticalAlignType.MIDDLE:
                {
                    const x = textConfig.bounds.x;
                    const y = textConfig.bounds.y + ((textConfig.bounds.height - textProps.size.height) / 2);
                    textProps.bounds = new geom.Rect(x, y, textProps.size);
                }
                break;
            case VerticalAlignType.TOP:
            default:
                textProps.bounds = new geom.Rect(textConfig.bounds.position, textProps.size);
                break;
        }

        return { size: markerProps.bounds.union(textConfig.bounds).size };
    }
}

export const elements = {
    Journey
};
