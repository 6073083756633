import React, { Component } from "react";
import styled from "styled-components";

import { _ } from "js/vendor";
import { Key } from "js/core/utilities/keys";

const CellEditorContainer = styled.div.attrs(({ bounds }) => ({
    style: {
        ...bounds
    }
}))`
    position: absolute;
    background: #ecf8fc;
    border: solid 2px #50bbe6;
    padding: 0 10px;

    display: flex;
    align-items: center;
    justify-content: center;

    > textarea {
        resize: none;
        border: none;
        outline: none;
        background: none;
        width: 100%;
        font-family: "Source Sans Pro";
        font-size: 15px;
        color: #000;
        text-align: center;
        box-sizing: border-box;
        overflow: hidden;
        height: 25px;
    }
`;

function stopPropagation(handler) {
    return event => {
        event.stopPropagation();
        event.nativeEvent.stopImmediatePropagation();
        handler(event);
    };
}

export class TableCellEditor extends Component {
    constructor(props) {
        super(props);

        this.state = {
            text: props.cell.getText(),
            editorHeight: 25 // Initial height
        };

        this.textRef = React.createRef();
    }

    componentDidMount() {
        let newChar;
        if (event?.type === "keydown") {
            // check if the key is a letter or a number
            // then set the newChar to the key
            // otherwise set the cell to the current value

            //The regular expression ^[\p{L}\p{N}]$/u uses Unicode property escapes
            // to match any kind of letter (\p{L}) or number (\p{N}) from any language.
            // The u flag enables Unicode mode for the regular expression.
            if (/^[\p{L}\p{N}]$/u.test(event.key)) {
                newChar = event.key;
            } else if (event.which === Key.ENTER) {
                newChar = "";
            }

            event.preventDefault();
        }

        _.defer(() => {
            this.textRef.current.focus();
            this.textRef.current.select();

            if (newChar) {
                this.setState({ text: newChar }, this.resizeTextarea);
            } else {
                this.resizeTextarea();
            }
        });
    }

    componentDidUpdate() {
        this.resizeTextarea();
    }

    componentWillUnmount() {
        this.commit();
    }

    commit = () => {
        const { cell, onCommit } = this.props;
        const { text } = this.state;

        // text can be null when the cell was set to icon before
        cell.setText(text ?? "");
        onCommit();
    }

    handleChange = event => {
        this.setState({ text: event.target.value }, this.resizeTextarea);
    }

    handleKeyDown = event => {
        const { text } = this.state;
        const { moveCellSelection, cell } = this.props;

        const input = this.textRef.current;
        switch (event.which) {
            case Key.TAB:
                moveCellSelection("right", false, true);
                break;
            case Key.RIGHT_ARROW:
                if (input.selectionStart === text.length) {
                    moveCellSelection("right");
                }
                break;
            case Key.DOWN_ARROW:
                if (input.selectionStart === text.length) {
                    moveCellSelection("down");
                }
                break;
            case Key.UP_ARROW:
                if (input.selectionStart === 0) {
                    moveCellSelection("up");
                }
                break;
            case Key.LEFT_ARROW:
                if (input.selectionStart === 0) {
                    moveCellSelection("left");
                }
                break;
            case Key.ESCAPE:
                event.preventDefault();
                // Reset the text to the original value
                // We cannot use cell.getText() here because an icon cell will return the content_value which is not correct here
                this.setState({ text: cell.model.cellText ? cell.model.cellText.text : "" }, () => this.commit());
                break;
            case Key.ENTER:
                if (!event.shiftKey) {
                    event.preventDefault();
                    this.commit();
                }
                break;
        }
    }

    resizeTextarea = () => {
        const textarea = this.textRef.current;
        if (textarea) {
            textarea.style.height = "auto";
            const newHeight = textarea.scrollHeight;
            textarea.style.height = `${newHeight}px`;

            if (newHeight !== this.state.editorHeight) {
                this.setState({ editorHeight: newHeight });
            }
        }
    }

    render() {
        const { cell: { bounds } } = this.props;
        const { text, editorHeight } = this.state;

        let editorBounds = { ...bounds };
        if (bounds.width < 100) {
            editorBounds.left = bounds.left - (100 - bounds.width) / 2;
            editorBounds.width = 100;
        }
        if (bounds.height < 25) {
            editorBounds.top = bounds.top - (25 - bounds.height) / 2;
            editorBounds.height = 25;
        }

        editorBounds.height = Math.max(editorBounds.height, editorHeight);

        return (
            <CellEditorContainer bounds={editorBounds}>
                <textarea
                    ref={this.textRef}
                    value={text}
                    onChange={this.handleChange}
                    onKeyDown={stopPropagation(this.handleKeyDown)}
                />
            </CellEditorContainer>
        );
    }
}

