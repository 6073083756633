import React, { Component } from "react";
import { MenuItem } from "@material-ui/core";

import { NodeType, PositionType } from "common/constants";
import { WithLabel } from "js/Components/WithLabel";
import { ToggleSwitch } from "js/Components/ToggleSwitch";
import { ImagePopup } from "js/Components/ImagePopup";
import { Dropdown } from "js/Components/Dropdown";
import { Slider } from "js/Components/Slider";
import { PropertyPanelContainer, PropertySection } from "js/EditorComponents/PropertyPanel";

import { ConnectorPropertyPanel } from "../EditorComponents/ConnectorPropertyPanel";
import { getContentItemImageOptions } from "../EditorComponents/getContentItemImageOptions";
import { CollectionColorPicker } from "../EditorComponents/ColorPickers/CollectionColorPicker";
import { ControlBar } from "../ElementControlBars/Components/ControlBar";
import { MediaPopup } from "../EditorComponents/MediaPopup";
import { ItemColorPicker } from "../EditorComponents/ColorPickers/ItemColorPicker";
import { BaseElementSelection } from "../ElementSelections/BaseElementSelection";
import { ContentItemSelection } from "../ElementSelections/ContentItemSelection";
import { ImageFramePopupMenu } from "../EditorComponents/ImageFrameMenu";

export class HubAndSpokePropertyPanel extends Component {
    renderHubSizeElement() {
        const { element } = this.props;

        switch (element.model.hub.nodeType) {
            case NodeType.CONTENT:
                return (
                    <WithLabel label="Hub Size">
                        <Slider value={element.model.hub.size}
                            min={50} max={300} step={1}
                            onChange={value => element.hub.refreshModel({ size: value })}
                            onCommit={value => {
                                element.hub.refreshModel({ size: value });
                                element.hub.saveModel();
                            }}
                        />
                    </WithLabel>
                );
            case NodeType.CIRCLE:
            case NodeType.BOX:
            case NodeType.DIAMOND:
                return (
                    <WithLabel label="Hub Size">
                        <Slider value={element.model.hub.textWidth}
                            min={50} max={300} step={1}
                            onChange={value => element.hub.refreshModel({ textWidth: value })}
                            onCommit={value => element.hub.updateModel({ textWidth: value })}
                        />
                    </WithLabel>
                );
            // if the hub is a text node, we don't want to show the size slider
            // nor the label for it
            default:
                return null;
        }
    }

    render() {
        const { element, canvas } = this.props;

        return (
            <PropertyPanelContainer>
                <PropertySection>
                    <WithLabel label="Hub Style">
                        <ImagePopup value={element.model.hub.nodeType}
                            onChange={value => element.hub.updateModel({ nodeType: value })}
                            size={70} previewSize={50} border={false}
                        >
                            {getContentItemImageOptions([NodeType.CIRCLE, NodeType.BOX, NodeType.DIAMOND, NodeType.TEXT, NodeType.CONTENT])}
                        </ImagePopup>
                    </WithLabel>
                    {element.model.hub.nodeType == NodeType.CONTENT && (
                        <WithLabel label="Hub Frame">
                            <ImageFramePopupMenu frameType={element.hub.model.frameType}
                                showPreview
                                allowedCategories={["shape", "device"]}
                                onChange={frame => element.hub.updateModel({ frameType: frame })}
                            />
                        </WithLabel>
                    )}
                    <WithLabel label="Hub Color">
                        <ItemColorPicker element={element.hub} size={25}
                            showDefault={false}
                            showBackgroundColors
                            showDecorationStyles
                        />
                    </WithLabel>
                    {this.renderHubSizeElement()}
                    <WithLabel label="Hub Position" flex>
                        <Dropdown value={element.hubLayout}
                            onChange={layout => {
                                const modelToUpdate = { layout };
                                if (layout !== PositionType.CENTER) {
                                    // Reset the rotation when the hub is not in the center, as it is not used
                                    element.updateModel({ ...modelToUpdate, useAlternateRotation: false });
                                } else {
                                    element.updateModel({ ...modelToUpdate });
                                }
                            }}
                        >
                            <MenuItem value={PositionType.CENTER}>Center</MenuItem>
                            <MenuItem value={PositionType.TOP}>Top</MenuItem>
                            <MenuItem value={PositionType.BOTTOM}>Bottom</MenuItem>
                        </Dropdown>
                    </WithLabel>
                </PropertySection>

                <PropertySection>
                    <WithLabel label="Spoke Item Style">
                        <ImagePopup value={element.spokeNodes[0].model.nodeType}
                            size={70} previewSize={50} border={false}
                            onChange={value => {
                                for (let spokeNode of element.spokeNodes) {
                                    spokeNode.model.nodeType = value;
                                }
                                element.saveModel(false);
                            }}
                        >
                            {getContentItemImageOptions([NodeType.CONTENT_AND_TEXT, NodeType.BULLET_TEXT, NodeType.NUMBERED_TEXT, NodeType.LETTERED_TEXT])}
                        </ImagePopup>
                    </WithLabel>
                    <WithLabel label="Spoke Item Color">
                        <CollectionColorPicker element={element} showDecorationStyles />
                    </WithLabel>
                    <WithLabel label="Spoke Length" flex>
                        <Slider value={element.model.ellipse_size}
                            min={1} max={3} step={0.01}
                            onChange={(value => element.refreshModel({ ellipse_size: value }))}
                            onCommit={() => element.saveModel()}
                        />
                    </WithLabel>

                    <WithLabel label="Rotate Spokes" flex>
                        <ToggleSwitch value={!!(element.hubLayout === PositionType.CENTER && element.model.useAlternateRotation)}
                            onChange={value => element.updateModel({ useAlternateRotation: value })}
                            disabled={!(element.hubLayout === PositionType.CENTER && (element.spokeNodes.length === 2 || element.spokeNodes.length === 4 || element.spokeNodes.length === 6))}
                        />
                    </WithLabel>
                </PropertySection>

                <ConnectorPropertyPanel canvas={canvas} elements={element.connectors?.itemElements ?? []} />

            </PropertyPanelContainer>
        );
    }
}

export class SpokeControlBar extends Component {
    render() {
        const { element } = this.props;

        const nodeType = element.model.nodeType;

        return (
            <ControlBar>
                {(nodeType == NodeType.CONTENT_AND_TEXT || nodeType == NodeType.CONTENT) &&
                    <MediaPopup element={element} />
                }
                <ItemColorPicker element={element} />
            </ControlBar>
        );
    }
}

export class HubSelection extends BaseElementSelection {
    get canDrag() {
        return false;
    }

    get canDelete() {
        return false;
    }
}

export class SpokeItemSelection extends ContentItemSelection {
    get dragAsCollectionItem() {
        return true;
    }
}
