import * as geom from "js/core/utilities/geom";
import { AssetType, HorizontalAlignType, BlockStructureType, TextStyleType, AuthoringBlockType, DecorationStyle } from "common/constants";
import { _ } from "js/vendor";
import { CollectionElement, CollectionItemElement } from "../base/CollectionElement";
import { TextElement } from "../base/Text/TextElement";
import { SVGPolygonElement, SVGPolylineElement } from "../base/SVGElement";
import { ContentElement } from "../base/ContentElement";
import { detectTextContent } from "js/core/services/sharedModelManager";
import { PyramidPropertyPanel, PyramidSectionControlBar } from "../../Editor/ElementPropertyPanels/PyramidUI";

class Pyramid extends CollectionElement {
    static get schema() {
        return {
            style: "sequence",
            showDescription: true
        };
    }

    getElementPropertyPanel() {
        return PyramidPropertyPanel;
    }

    getChildItemType() {
        return PyramidSection;
    }

    get maxItemCount() {
        return 8;
    }

    get style() {
        return this.model.style || "text";
    }

    get shadeColorsDefault() {
        return true;
    }

    _calcProps(props, options) {
        let { size } = props;

        let gap = this.styles.gap || 0;

        if (this.decorationStyle == DecorationStyle.FILLED || this.decorationStyle == DecorationStyle.MUTED) {
            gap = 6;
        }

        let pyramidCapSize = size.width * (this.model.capSize || 0) / 100;
        let pyramidBaseSize = size.width * (this.model.baseSize != undefined ? this.model.baseSize : 50) / 100;
        if (this.model.inverted) {
            const capSize = pyramidCapSize;
            pyramidCapSize = pyramidBaseSize;
            pyramidBaseSize = capSize;
        }

        let pyramidWidth = Math.max(pyramidBaseSize, pyramidCapSize);

        // calculate the section heights and total height of pyramid
        let sectionHeight = Math.max(this.styles.minItemHeight, Math.floor(size.height / this.itemCount)) - gap;
        let totalHeight = (sectionHeight + gap) * this.itemCount - gap;
        if (totalHeight > size.height) {
            props.isFit = false;
        }

        // calculate the pyramid width
        let maxPyramidWidth = size.width;
        let descriptionWidth = 0;
        if (this.model.showDescription) {
            maxPyramidWidth -= (this.styles.minDescriptionWidth || 300);
            pyramidWidth = Math.min(pyramidWidth, maxPyramidWidth);

            descriptionWidth = Math.min(this.styles.maxDescriptionWidth || 500, size.width - pyramidWidth);
            if (pyramidCapSize > pyramidBaseSize) {
                pyramidCapSize = pyramidWidth;
            } else {
                pyramidBaseSize = pyramidWidth;
            }
        }

        pyramidCapSize = Math.min(pyramidCapSize, maxPyramidWidth);
        pyramidBaseSize = Math.min(pyramidBaseSize, maxPyramidWidth);

        // total width is pyramid width + any description width
        let totalWidth = pyramidWidth + descriptionWidth;
        let step = this.model.step;

        // calc props for the sections
        let y = 0;

        // calc props for the sections
        const calcSectionProps = forceTextScale => {
            let y = 0;
            let max = 0;
            let minTextScale = 1;

            for (let i = 0; i < this.itemElements.length; i++) {
                let slope = 1 / (totalHeight / (pyramidBaseSize - pyramidCapSize));
                let topWidth = pyramidCapSize + slope * i / this.itemCount * totalHeight;
                let botWidth = pyramidCapSize + slope * ((i + 1) / this.itemCount * totalHeight - gap);

                if (this.model.step) {
                    topWidth = botWidth = Math.max(topWidth, botWidth);
                }
                let midWidth = 0.5 * (topWidth + botWidth);
                let offsetX = this.model.showDescription ? 0 : size.width / 2 - totalWidth / 2;

                let element = this.itemElements[i];
                let sectionProps = this.itemElements[i].calcProps(new geom.Size(totalWidth, sectionHeight), {
                    pyramidWidth, topWidth, botWidth, midWidth, gap, step,
                    showDescription: this.model.showDescription,
                    capAlign: this.model.capAlign,
                    style: this.model.style,
                    textScale: forceTextScale
                });
                sectionProps.bounds = new geom.Rect(offsetX, y, sectionProps.size);

                y += sectionHeight + gap;

                // track the minimum text scale that was used across all the sections
                minTextScale = Math.min(minTextScale, sectionProps.textScale);
            }

            return minTextScale;
        };

        let textScale = calcSectionProps();
        // recalc text props at the minimum text scale in all sections
        if (textScale < 1) {
            calcSectionProps(textScale);
        }

        return { size, textScale };
    }

    _exportToSharedModel() {
        const assets = (this.style === "image" || this.style === "icon")
            ? this.itemElements.map(item => item.pyramidContent._exportToSharedModel().assets) : [];

        const textContent = this.itemElements.map(item => {
            const label = item.text?._exportToSharedModel().textContent[0];
            const content = item.pyramidContent._exportToSharedModel().textContent[0];
            if (!label && !content) return;
            return label ? { ...label, secondaryTexts: [...label.secondaryTexts, content.mainText] } : content;
        }).filter(Boolean);

        return { assets, textContent, collectionColor: this.collectionColor };
    }

    _importFromSharedModel(model) {
        const textContent = detectTextContent(model);
        if (!textContent) return;

        const items = textContent.map(({ mainText, secondaryTexts }) => ({
            text: {
                blocks: [
                    {
                        html: mainText.text,
                        textStyle: TextStyleType.TITLE,
                        type: AuthoringBlockType.TEXT,
                    },
                    ...secondaryTexts.map(secondaryText => ({
                        html: secondaryText.text,
                        textStyle: TextStyleType.BODY,
                        type: AuthoringBlockType.TEXT,
                    })),
                ]
            }
        }));

        items.splice(this.maxItemCount);
        return { items, collectionColor: model.collectionColor };
    }
}

class PyramidSection extends CollectionItemElement {
    getElementControlBar() {
        return PyramidSectionControlBar;
    }

    get selectionPadding() {
        return 0;
    }

    get name() {
        return "Section";
    }

    get showRule() {
        return this.parentElement.model.showDescription && !this.parentElement.model.step;
    }

    get mediaElement() {
        if (this.parentElement.style == "icon") {
            return this.pyramidContent;
        }
    }

    _build() {
        this.shape = this.addElement("shape", () => SVGPolygonElement);

        switch (this.parentElement.style) {
            case "text":
                this.pyramidContent = this.addElement("pyramidLabel", () => TextElement, {
                    autoHeight: true,
                    scaleTextToFit: true,
                    constrainWidth: true,
                    syncFontSizeWithSiblings: true,
                    textFormatBarOffset: 30,
                    backgroundElement: this.shape
                });
                break;
            case "sequence":
                this.pyramidContent = this.addElement("number", () => TextElement, {
                    html: this.itemIndex + 1,
                    canRollover: false,
                    canEdit: false,
                    canSelect: false,
                    isTabbable: false,
                    autoHeight: true,
                    backgroundElement: this.shape
                });
                break;
            case "icon":
            case "image":
                this.pyramidContent = this.addElement("content", () => PyramidIcon, {
                    defaultAssetType: AssetType.ICON,
                    backgroundElement: this.shape,
                });
                break;
        }

        if (this.showRule) {
            this.rule = this.addElement("rule", () => SVGPolylineElement);
        }

        if (this.parentElement.model.showDescription) {
            this.text = this.addElement("text", () => TextElement, {
                blockStructure: BlockStructureType.TITLE_AND_BODY,
                autoHeight: true,
                scaleTextToFit: true,
                syncFontSizeWithSiblings: true,
                textFormatBarOffset: 30
            });
        }
    }

    _calcProps(props, options) {
        const { size } = props;

        let { midWidth, pyramidWidth, topWidth, botWidth } = options;

        let leftX, sectionPath, ruleX, titleX, textX;

        let midY = size.height / 2;

        if (topWidth < 50) {
            midY = size.height * .66;
        }

        switch (options.capAlign || "center") {
            case "center":
                sectionPath = [
                    [(pyramidWidth - topWidth) / 2, 0],
                    [(pyramidWidth + topWidth) / 2, 0],
                    [(pyramidWidth + botWidth) / 2, size.height],
                    [(pyramidWidth - botWidth) / 2, size.height],
                    [(pyramidWidth - topWidth) / 2, 0]
                ];
                leftX = Math.min((pyramidWidth - botWidth) / 2, (pyramidWidth - topWidth) / 2);
                ruleX = pyramidWidth / 2 + topWidth / 2 + 20;
                titleX = (pyramidWidth - midWidth) / 2;
                textX = (pyramidWidth + Math.max(botWidth, topWidth)) / 2 + 20;

                break;
            case "left":
                sectionPath = [
                    [0, 0],
                    [topWidth, 0],
                    [botWidth, size.height],
                    [0, size.height],
                    [0, 0]
                ];
                leftX = 0;
                ruleX = topWidth + 20;
                titleX = 20;
                textX = Math.max(botWidth, topWidth) + 20;

                if (this.parentElement.model.step) {
                    midWidth -= 40;
                }

                break;
        }

        let pyramidContentProps;

        switch (options.style) {
            case "text":
            case "sequence":
                switch (options.capAlign || "center") {
                    case "center":
                        this.pyramidContent.styles.textAlign = HorizontalAlignType.CENTER;
                        break;
                    case "left":
                        this.pyramidContent.styles.textAlign = HorizontalAlignType.LEFT;
                        break;
                }

                pyramidContentProps = this.pyramidContent.calcProps(new geom.Size(midWidth, size.height));
                pyramidContentProps.bounds = new geom.Rect(titleX, midY - pyramidContentProps.size.height / 2, midWidth, pyramidContentProps.size.height);

                break;
            case "icon":
            case "image":
                pyramidContentProps = this.pyramidContent.calcProps(new geom.Size(Math.max(botWidth, topWidth), size.height), { framePadding: 1 });

                if (this.pyramidContent.assetType === AssetType.IMAGE || this.pyramidContent.assetType === AssetType.VIDEO || this.pyramidContent.assetType === AssetType.STOCK_VIDEO) {
                    pyramidContentProps.bounds = new geom.Rect(leftX, size.height / 2 - pyramidContentProps.size.height / 2, pyramidContentProps.size);
                    pyramidContentProps.clipPath = `polygon(${sectionPath.filter((value, idx) => idx !== sectionPath.length - 1).map(pt => [pt[0] - leftX, pt[1]].map(v => v + "px").join(" ")).join(", ")})`;
                } else {
                    pyramidContentProps.bounds = new geom.Rect(leftX, midY - pyramidContentProps.size.height / 2, pyramidContentProps.size);
                }
                break;
        }

        let textScale;
        if (options.showDescription) {
            const textSize = new geom.Size(size.width - ((options.pyramidWidth + Math.max(botWidth, topWidth)) / 2 + 20), size.height);

            const textProps = (this.text || this.textGroup).calcProps(textSize, { forceTextScale: options.textScale });
            textProps.bounds = new geom.Rect(textX, size.height / 2 - textProps.size.height / 2, textProps.size);

            textScale = textProps.textScale;
        }

        const shapeProps = this.shape.calcProps(size);
        shapeProps.path = sectionPath;
        shapeProps.layer = -1;

        // if (this.shape.styles.fillColor !== "none" && this.shadeColors && this.model.color == null) {
        //     this.calcShadedColors(this.shape);
        // }

        if (options.showDescription) {
            if (!this.parentElement.model.step) {
                let ruleY;
                if (this.itemIndex === 0) {
                    ruleY = 1;
                } else {
                    ruleY = -options.gap / 2;
                }
                const ruleProps = this.rule.calcProps(size);
                ruleProps.path = [[ruleX, ruleY], [size.width, ruleY]];
            }
        }

        return { size, textScale };
    }

    _applyColors() {
        if (this.showRule) {
            this.rule.colorSet.strokeColor = this.palette.getColor("secondary", this.getBackgroundColor()).setAlpha(0.3);
        }
        if (this.parentElement.model.sectionContentColor) {
            this.pyramidContent.colorSet.fontColor = this.parentElement.model.sectionContentColor;
        }
    }

    _migrate_10() {
        if (this.parentElement.model.style == "text") {
            this.model.pyramidLabel = _.cloneDeep(this.model.title);
            delete this.model.title;
        }
    }

    _migrate_10_02() {
        super._migrate_10_02();
        if (this.decorationStyle == DecorationStyle.MUTED) {
            this.model.decorationStyle = DecorationStyle.FILLED;
        }
    }
}

class PyramidIcon extends ContentElement {
    get shapeStyle() {
        // this will suppress the selection box in the default overlay
        return "custom";
    }

    _applyColors() {
        if (this.parentElement.parentElement.model.sectionContentColor || this.parentElement.parentElement.getBackgroundColor().isColor) {
            this.assetElement.colorSet.iconColor = this.palette.getColor(this.parentElement.parentElement.model.sectionContentColor);
        } else {
            super._applyColors();
        }
    }
}

export const elements = {
    Pyramid,
};
