import React, { Component, Fragment } from "react";
import { isObject, isString } from "lodash";
import { Menu, MenuItem } from "@material-ui/core";
import { ThemeProvider as MuiThemeProvider } from "@material-ui/core/styles";
import SelectAll from "mdi-material-ui/SelectAll";
import styled from "styled-components";
import { v4 as uuid } from "uuid";

import {
    AssetType,
    AuthoringBlockType,
    AuthoringElementType,
    AuthoringShapeType,
    HorizontalAlignType,
    SNAP_TOLERANCE,
    SnapLineBindingPoint,
    SnapLineDirection,
    TextStyleType,
    VerticalAlignType,
    DOUBLE_CLICK_THRESHOLD_MS
} from "common/constants";
import { getStaticUrl } from "js/config";
import * as geom from "js/core/utilities/geom";
import { AnchorType } from "js/core/utilities/geom";
import { sanitizeHtmlText } from "js/core/utilities/htmlTextHelpers";
import { Key } from "js/core/utilities/keys";
import { PolyLinePath } from "js/core/utilities/shapes";
import { app } from "js/namespaces";
import { ShowConfirmationDialog } from "js/react/components/Dialogs/BaseDialog";
import { dialogTheme } from "js/react/materialThemeOverrides";
import { themeColors } from "js/react/sharedStyles";
import { $, _ } from "js/vendor";
import { ClipboardType, clipboardRead, clipboardWrite } from "js/core/utilities/clipboard";
import PresentationEditorController from "js/editor/PresentationEditor/PresentationEditorController";

import { AuthoringControlBar } from "./AuthoringControlBar";
import { getEditorForSelection } from "./Editors/AuthoringEditorsManager";
import { SelectionBox } from "./SelectionBox";
import { SelectionContextMenu } from "./SelectionContextMenu";
import { SelectionUI } from "./SelectionUI";
import { ChangeChartType } from "../../ElementPropertyPanels/ChartEditor/ChartUI";

const ContainerBox = styled.div.attrs(({ bounds, canvasScale, cursor }) => ({
    style: {
        left: `${bounds.left * canvasScale}px`,
        top: `${bounds.top * canvasScale}px`,
        width: `${bounds.width * canvasScale}px`,
        height: `${bounds.height * canvasScale}px`,
        cursor: cursor ?? "unset"
    }
}))`
    position: absolute;
    font-size: 20px;
    pointer-events: none;
    z-index: 10;
`;

const SnapLine = styled.div.attrs(({ bounds, canvasScale }) => ({
    style: {
        left: `${bounds.left * canvasScale}px`,
        top: `${bounds.top * canvasScale}px`,
        width: `${bounds.width * canvasScale}px`,
        height: `${bounds.height * canvasScale}px`
    }
}))`
    position: absolute;
    outline: dashed 1px #ec407a75;
`;

const GridLine = styled.div.attrs(({ bounds, opacity, isHilited, canvasScale }) => ({
    style: {
        left: `${bounds.left * canvasScale}px`,
        top: `${bounds.top * canvasScale}px`,
        width: `${bounds.width * canvasScale}px`,
        height: `${bounds.height * canvasScale}px`,
        opacity,
        outline: isHilited ? "dashed 1.2px rgba(0, 0, 0, 0.2)" : "dashed 1px rgba(0, 0, 0, 0.1)"
    }
}))`
    position: absolute;
    transition: opacity 0.5s;
`;

export const DragType = {
    SELECTION: "selection",
    POSITION: "position",
    RESIZE: "resize",
    ROTATE: "rotate",
    CONNECTOR_POINT: "connector-point"
};

const COPIED_STYLES = [
    "fill",
    "stroke",
    "strokeWidth",
    "strokeStyle",
    "shadow",
    "opacity",
    "textInset",
];

const TABLE_STYLES = [
    "showBorder",
    "showColGridLines",
    "showRowGridLines",
    "showTopLeftCell",
    "alternateRows",
    "tableBackgroundColor"
];

export class AuthoringLayer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            rolloverElements: [],
            snapLines: [],
            gridLines: [],
            isMouseDown: false,
            isDragging: false,
            dragType: null,
            hasDragged: false,
            dragSelectionBounds: null,
            dragCreateModel: null,
            cursor: null,
            chartType: null,
            showContextMenu: false,
            contextMenuPositionX: 0,
            contextMenuPositionY: 0,
            showGridLines: false,
            selectionElement: null,
            showSelectionBox: true,
            contentMenuItemSelected: false
        };

        this.allowDragMove = true;
        this.dragOffset = null;
        this.resizeAnchor = null;
        this.modelBeforeResize = null;
        this.referencePointsBeforeResize = null;
        this.modelBackup = {};
        this.hasPendingModelChanges = false;
        this.mouseMoveHandledAt = null;
        this.authoringControlBarBounds = null;

        this.containerBoxRef = React.createRef();
        this.editorRef = React.createRef();
        this.authoringControlBarRef = React.createRef();
    }

    get hasSelection() {
        const { selectedElements } = this.props;
        return selectedElements.length > 0;
    }

    // checks for windows that might be overlaying the editor
    // area which can be used to avoid using certain mouse interactions
    get isOverlayShowing() {
        const overlays = [
            ".overlay--arrange-presentation-slides"
        ];

        return $(overlays.join(", ")).length > 0;
    }

    // checks for popovers that might be overlaying the editor
    // area which can be used to avoid using certain mouse interactions
    get isPopoverShowing() {
        const popovers = [
            ".MuiPopover-root"
        ];

        return $(popovers.join(", ")).length > 0;
    }

    get canGroupSelection() {
        const { selectedElements } = this.props;
        if (selectedElements.length < 2) {
            return false;
        }

        const selectedGroups = Array.from(new Set(selectedElements.map(element => element.groupId)));
        // All belong to one group
        if (selectedGroups.length === 1 && selectedGroups[0]) {
            return false;
        }

        return true;
    }

    get canUngroupSelection() {
        const { selectedElements } = this.props;
        return selectedElements.some(element => element.groupId);
    }

    get selectionHasLockedItems() {
        const { selectedElements } = this.props;
        return selectedElements.some(element => element.isLocked);
    }

    get snapToGrid() {
        const { containerElement } = this.props;
        return !this.selectionHasLockedItems && containerElement.snapToGrid;
    }

    get allowCopyPasteStyles() {
        const { containerElement } = this.props;
        return containerElement.allowCopyPasteStyles;
    }

    get allowSnapLines() {
        const { containerElement } = this.props;
        return !this.selectionHasLockedItems && containerElement.showSnapLines;
    }

    get gridSpacing() {
        const { containerElement } = this.props;
        return containerElement.gridSpacing;
    }

    get snapLines() {
        return this.state.snapLines;
    }

    get containerPadding() {
        const { containerElement } = this.props;
        return containerElement.padding;
    }

    get canvas() {
        const { containerElement } = this.props;
        return containerElement.canvas;
    }

    get isCanvasLayouterGenerating() {
        if (!this.canvas.layouter) {
            return false;
        }

        return this.canvas.layouter.isGenerating;
    }

    get isCanvasAnimating() {
        return this.canvas.isAnimating;
    }

    get selection() {
        const { selectedElements } = this.props;
        return this.getAuthoringContainers(selectedElements);
    }

    get editingElement() {
        const { selectedElements } = this.props;
        const containers = this.getElements();
        return selectedElements.find(element => containers.some(container => element.isChildOf(container)));
    }

    get isEditingElement() {
        return !!this.editingElement;
    }

    handleEditComponentElement = element => {
        if (element.childElement.type == "LayoutContainerItem") {
            this.setSelection([element.childElement.childElement]);
        } else {
            this.setSelection(element.childElement);
        }
    }

    getAuthoringContainers = elements => {
        const { containerElement } = this.props;
        return [...new Set(
            elements
                .filter(element => element.isChildOf(containerElement))
                .map(element => element.parentElement === containerElement ? element : element.findClosestOfType("AuthoringElementContainer"))
                .filter(Boolean)
        )];
    }

    setStateAsync = state => new Promise(resolve => this.setState(state, resolve));

    setContentMenuItemSelected = value => {
        this.setState({
            contentMenuItemSelected: value
        });
    }

    setSelection = selection => {
        const { selectionLayerController } = this.props;
        return selectionLayerController.setSelectedElements(selection);
    }

    setRolloverElements = rolloverElements => {
        const { selectionLayerController } = this.props;
        return selectionLayerController.setRolloverElements(rolloverElements);
    }

    setDragCreateModel = async (model, chartType) => {
        await this.setSelection([]);
        await this.setStateAsync({
            dragCreateModel: model,
            cursor: "move",
            chartType: chartType
        });
    }

    componentDidMount() {
        this.saveModelBackup();

        this.calcGridLines();

        document.addEventListener("keydown", this.handleEvent);
        document.addEventListener("keyup", this.handleEvent);
        document.addEventListener("mousedown", this.handleEvent);
        document.addEventListener("mousemove", this.handleEvent);
        document.addEventListener("mouseup", this.handleEvent);

        // make sure the canSelect state is correctly false for all text elements (this can happen when undo is called)
        for (const element of this.props.containerElement.itemElements) {
            if (element.childElement.text?.blockContainerRef?.current) {
                element.childElement.text.blockContainerRef.current.setState({ canSelect: false });
            }
        }
    }

    componentWillUnmount() {
        document.removeEventListener("keydown", this.handleEvent);
        document.removeEventListener("keyup", this.handleEvent);
        document.removeEventListener("mousedown", this.handleEvent);
        document.removeEventListener("mousemove", this.handleEvent);
        document.removeEventListener("mouseup", this.handleEvent);
    }

    componentDidUpdate(prevProps, prevState) {
        const { containerElement, selectedElements } = this.props;
        const { hideControlBar, isDragging, rolloverElements } = this.state;

        // Handling locking/unlocking
        if (this.canvas.isCurrentCanvas) {
            if (selectedElements > 0 && (prevProps.selectedElements.length === 0 || !this.canvas.isLockedForCollaborators())) {
                this.canvas.lockSlideForCollaborators(30);
            } else if (selectedElements === 0 && this.canvas.isLockedForCollaborators()) {
                this.canvas.unlockSlideForCollaborators();
            }
        }

        // Hiding authoring control bar if it overlaps with the editor control bar
        const authoringControlBarBounds = this.authoringControlBarRef.current?.bounds;
        if (authoringControlBarBounds) {
            // Preserving authoring control bar bounds when it's hidden
            this.authoringControlBarBounds = authoringControlBarBounds;
        }

        if (this.authoringControlBarBounds) {
            const editorBarBounds = this.editorRef.current?.controlBarRef?.current?.gridBounds;

            if (editorBarBounds) {
                const shouldHideControlBar = editorBarBounds.inflate(10).intersects(this.authoringControlBarBounds);
                if (shouldHideControlBar !== hideControlBar) {
                    this.setState({ hideControlBar: shouldHideControlBar });
                }
            } else if (hideControlBar) {
                this.setState({ hideControlBar: false });
            }
        }

        if (!_.isEqual(prevProps.selectedElements.map(el => el.id), selectedElements.map(el => el.id)) && rolloverElements.length > 0) {
            const selection = this.selection;
            if (rolloverElements.some(el => !selection.includes(el))) {
                this.setState({ rolloverElements: rolloverElements.filter(el => selection.includes(el)) });
            }
        }
    }

    calcGridLines() {
        const { containerElement } = this.props;

        const gridLines = [];
        for (let x = 0; x < containerElement.bounds.width; x += this.gridSpacing) {
            const shouldHilite = x === this.containerPadding || x === containerElement.bounds.width - this.containerPadding;
            if (shouldHilite) {
                gridLines.push(...[
                    { bounds: { left: x, top: 0, width: 0, height: this.containerPadding } },
                    { bounds: { left: x, top: this.containerPadding, width: 0, height: containerElement.bounds.height - this.containerPadding * 2 }, isHilited: true },
                    { bounds: { left: x, top: containerElement.bounds.height - this.containerPadding, width: 0, height: this.containerPadding } },
                ]);
            } else {
                gridLines.push({
                    bounds: { left: x, top: 0, width: 0, height: containerElement.bounds.height }
                });
            }
        }
        for (let y = 0; y < containerElement.bounds.height; y += this.gridSpacing) {
            const shouldHilite = y === this.containerPadding || y === containerElement.bounds.height - this.containerPadding;
            if (shouldHilite) {
                gridLines.push(...[
                    { bounds: { left: 0, top: y, width: this.containerPadding, height: 0 } },
                    { bounds: { left: this.containerPadding, top: y, width: containerElement.bounds.width - this.containerPadding * 2, height: 0 }, isHilited: true },
                    { bounds: { left: containerElement.bounds.width - this.containerPadding, top: y, width: this.containerPadding, height: 0 } }
                ]);
            } else {
                gridLines.push({
                    bounds: { left: 0, top: y, width: containerElement.bounds.width, height: 0 }
                });
            }
        }

        this.setState({ gridLines });
    }

    /**
     * Please use this instead of element.selectionBounds in order to keep bounds in sync with model
     */
    getElementSelectionBounds = elementContainer => {
        const { containerElement } = this.props;

        if (elementContainer === containerElement) {
            return this.getContainerBounds();
        }

        let bounds = new geom.Rect(elementContainer.model.x, elementContainer.model.y, elementContainer.model.width, elementContainer.model.height);
        if (elementContainer.referencePoint) {
            bounds = bounds.offset(-elementContainer.referencePoint.x, -elementContainer.referencePoint.y);
        }
        bounds = bounds.inflate(elementContainer.selectionPadding);

        return bounds;
    }

    /**
     * Preserves the correct order of elements, puts header and footer behind
     */
    getElements = () => {
        const { containerElement } = this.props;

        return containerElement.model.elements
            .map(({ id }) => containerElement.elements[id])
            // Make sure element is initialized/rendered
            .filter(element => element);
    }

    getElementsInVisualOrder() {
        return this.getElements()
            .map(el => {
                // this is a very simple way to help prioritize left to right, with the top having some
                // bearing on ordering. After trying several more complicated ways, this works well
                // without a bunch of added complexity
                const { left, top } = el.calculatedProps.bounds;
                const priority = Math.sqrt(left + (top / (left || 1)));

                return { el, priority };
            })
            .sort((a, b) => a.priority - b.priority)
            .map(el => el.el);
    }

    getNotSelectedElements = () => {
        const { containerElement } = this.props;

        return [
            ...this.getElements().filter(element => !this.selection.includes(element)),
            containerElement,
        ];
    }

    getElementsAtPoint(selectionPoint) {
        return this.getHitElements(selectionPoint);
    }

    getHitElements = pt => {
        const { containerElement } = this.props;

        const mx = pt.x;
        const my = pt.y;
        const hitElements = [];

        for (const element of this.getElements()) {
            if (element.model.rotation && element.model.rotation % 360 !== 0) {
                let matrix = [];
                const matrixVal = $(element.ref.current.ref.current).css("transform");

                if (matrixVal != "none") {
                    const matrixParsed = matrixVal.substr(7, matrixVal.length - 8).split(",");
                    for (const i in matrixParsed) matrix[i] = parseFloat(matrixParsed[i]);
                } else {
                    matrix = [1, 0, 0, 1, 0, 0];
                }

                const hW = element.model.width / 2; //Half of width
                const hH = element.model.height / 2; //Half of height
                const o = {
                    x: element.model.x + hW,
                    y: element.model.y + element.model.height / 2
                }; //Transform origin

                //Define shape points and transform by matrix
                const p1 = {
                    x: o.x + matrix[0] * -hW + matrix[2] * -hH + matrix[4],
                    y: o.y + matrix[1] * -hW + matrix[3] * -hH + matrix[5]
                }; //Left top

                const p2 = {
                    x: o.x + matrix[0] * +hW + matrix[2] * -hH + matrix[4],
                    y: o.y + matrix[1] * +hW + matrix[3] * -hH + matrix[5]
                }; //Right top

                const p3 = {
                    x: o.x + matrix[0] * +hW + matrix[2] * +hH + matrix[4],
                    y: o.y + matrix[1] * +hW + matrix[3] * +hH + matrix[5]
                }; //Right bottom

                const p4 = {
                    x: o.x + matrix[0] * -hW + matrix[2] * +hH + matrix[4],
                    y: o.y + matrix[1] * -hW + matrix[3] * +hH + matrix[5]
                }; //Left bottom

                //Calculate edge normal vectors & C vars
                const v1 = { x: -(p2.y - p1.y), y: (p2.x - p1.x) }; //Top
                const v2 = { x: -(p3.y - p2.y), y: (p3.x - p2.x) }; //Right
                const v3 = { x: -(p4.y - p3.y), y: (p4.x - p3.x) }; //Bottom
                const v4 = { x: -(p1.y - p4.y), y: (p1.x - p4.x) }; //Left

                const c1 = -(v1.x * p1.x + v1.y * p1.y);
                const c2 = -(v2.x * p2.x + v2.y * p2.y);
                const c3 = -(v3.x * p3.x + v3.y * p3.y);
                const c4 = -(v4.x * p4.x + v4.y * p4.y);

                //Check cursor distance from edge using general line quation: ax + by + c = 0
                const isInner = function(v, c, x, y) {
                    return (v.x * x + v.y * y + c) / Math.sqrt(v.x * v.x + v.y * v.y) > 0;
                };

                //Check if mouse point is in shape coords using general line equation
                if (isInner(v1, c1, mx, my) && isInner(v2, c2, mx, my) && isInner(v3, c3, mx, my) && isInner(v4, c4, mx, my)) {
                    hitElements.push(element);
                }
            } else {
                const offset = containerElement.canvasBounds.position;
                const point = pt.offset(offset.x, offset.y);
                if (element.containsPoint(point, this.selection.contains(element))) {
                    hitElements.push(element);
                }
            }
        }

        return hitElements;
    }

    getElementsInRect(selectionBounds) {
        return this.getElements()
            .filter(element => {
                if (element.model.type == AuthoringElementType.SHAPE && !element.childElement.isTextBox && element.model.text.blocks.length == 0 && element.model.shape == "rect" && element.childElement.fill == "none") {
                    let bounds = this.getElementSelectionBounds(element);
                    if (selectionBounds.intersectsLine(bounds.left, bounds.top, bounds.left, bounds.bottom).any) {
                        return true;
                    } else if (selectionBounds.intersectsLine(bounds.left, bounds.top, bounds.right, bounds.top).any) {
                        return true;
                    } else if (selectionBounds.intersectsLine(bounds.right, bounds.top, bounds.right, bounds.bottom).any) {
                        return true;
                    } else if (selectionBounds.intersectsLine(bounds.left, bounds.bottom, bounds.right, bounds.bottom).any) {
                        return true;
                    }
                } else {
                    return this.getElementSelectionBounds(element).intersects(selectionBounds);
                }
            });
    }

    getGroupElements(element) {
        if (!element.groupId) {
            return [element];
        }

        return this.getElements().filter(el => el.groupId === element.groupId);
    }

    groupSelection = async () => {
        if (!this.canGroupSelection) {
            return;
        }

        const groupId = uuid();
        this.selection.forEach(element => element.groupId = groupId);

        await this.refreshCanvasAndSaveChanges();
    }

    ungroupSelection = async () => {
        if (!this.canUngroupSelection) {
            return;
        }
        this.selection.forEach(element => element.groupId = null);

        await this.setSelection([]);
        await this.refreshCanvasAndSaveChanges();
    }

    toggleLock = () => {
        // if any of the selected item are locked, default to unlocked
        const hasLockedElements = this.selection.find(element => element.model.isLocked);
        if (hasLockedElements) {
            this.unlockSelection();
        } else {
            this.lockSelection();
        }
    }

    lockSelection = async () => {
        this.selection.forEach(element => element.model.isLocked = true);
        await this.refreshCanvasAndSaveChanges();
    }

    unlockSelection = async () => {
        this.selection.forEach(element => element.model.isLocked = false);
        await this.refreshCanvasAndSaveChanges();
    }

    toggleSnapToGrid = () => {
        const { containerElement } = this.props;
        containerElement.model.snapToGrid = !this.snapToGrid;
        return this.refreshCanvasAndSaveChanges();
    }

    selectElement = async element => {
        await this.setSelection([element]);
        await this.setStateAsync({
            showContextMenu: false
        });
    }

    clearSelection = async () => {
        await this.setSelection([]);
    }

    removeSelectedElements = async () => {
        const { containerElement, selectionLayerController } = this.props;

        if (containerElement.minItemCount > 0 && containerElement.itemCount - this.selection.length < containerElement.minItemCount) {
            return;
        }

        if (this.selection.some(element => element.model.isLocked)) {
            if (!await ShowConfirmationDialog({ title: "Are you sure you want to delete locked elements?" })) {
                return;
            }
        }

        for (const element of this.selection) {
            containerElement.model.elements.remove(element.model);

            if (this.getGroupElements(element).length == 1) {
                this.getGroupElements(element)[0].groupId = null;
            }

            if (containerElement.connectors) {
                _.remove(containerElement.model.connections.items, ({ source, target }) => source === element.model.id || target === element.model.id);
                // if we add a connector not in the flow chart, we do not have source and target
                // so we need to remove it from the items
                containerElement.model.connections.items.remove(element.model);
            }
        }

        await selectionLayerController.setSelectedElements([]);
        // ensure we are setting panel to avoid empty panel
        await PresentationEditorController.setSelectedPropertyPanelTab("element");
        await this.refreshCanvasAndSaveChanges();
    }

    getElementsSelectionBounds = elements => {
        let selectionBounds;
        for (const element of elements) {
            if (!selectionBounds) {
                selectionBounds = this.getElementSelectionBounds(element);
            } else {
                selectionBounds = selectionBounds.union(this.getElementSelectionBounds(element));
            }
        }
        return selectionBounds;
    }

    getPositionFromEvent = event => {
        const containerBoundingBox = this.containerBoxRef.current.getBoundingClientRect();

        return new geom.Point(
            (event.pageX - containerBoundingBox.left) / this.canvas.canvasScale,
            (event.pageY - containerBoundingBox.top) / this.canvas.canvasScale);
    }

    /**
     * WARNING: offsets the actual container bounds to 0, 0
     */
    getContainerBounds = () => {
        const { containerElement } = this.props;
        return new geom.Rect(0, 0, containerElement.selectionBounds.width, containerElement.selectionBounds.height);
    }

    /**
     * WARNING: offsets the canvas bounds relative to the container bounds
     */
    getCanvasBounds = () => {
        const { containerElement } = this.props;
        return new geom.Rect(-containerElement.selectionBounds.left, -containerElement.selectionBounds.top, containerElement.canvas.CANVAS_WIDTH, containerElement.canvas.CANVAS_HEIGHT);
    }

    /**
     * WARNING: offsets the actual container bounds to 0, 0
     */
    getPaddedContainerBounds = () => {
        return this.getContainerBounds().deflate(this.containerPadding);
    }

    saveModelBackup = () => {
        for (const element of this.getElements()) {
            this.modelBackup[element.model.id] = _.cloneDeep(element.model);
        }
    }

    restoreModelBackup = () => {
        for (const element of this.getElements()) {
            Object.entries(this.modelBackup[element.model.id]).forEach(([key, value]) => {
                element.model[key] = value;
            });
        }
    }

    /**
     * Returns a boolean value that indicates if model was reverted due to canvas not fit
     */
    refreshCanvas = async () => {
        const { containerElement } = this.props;

        containerElement.markStylesAsDirty();

        let modelWasReverted = false;
        try {
            await this.canvas.refreshCanvas({ suppressRefreshCanvasEvent: true });
        } catch (err) {
            // Layout didn't fit, restoring backup and refreshing canvas again
            this.restoreModelBackup();
            modelWasReverted = true;
            await this.canvas.refreshCanvas({ suppressRefreshCanvasEvent: true });
        }

        if (!modelWasReverted) {
            this.saveModelBackup();
        }

        return modelWasReverted;
    }

    /**
     * Returns a boolean value that indicates if model was reverted due to canvas not fit
     */
    refreshElement = () => {
        const { containerElement } = this.props;

        let modelWasReverted = false;

        try {
            containerElement.refreshElement();
        } catch (err) {
            // Layout didn't fit, restoring backup and refreshing canvas again
            this.restoreModelBackup();
            modelWasReverted = true;
            containerElement.refreshElement();
        }

        if (!modelWasReverted) {
            this.saveModelBackup();
        }

        return modelWasReverted;
    }

    saveChanges = () => this.canvas.saveCanvasModel();

    refreshCanvasAndSaveChanges = async () => {
        await this.refreshCanvas();

        // Won't wait for the actual model save because the UI doesn't depend on it
        this.saveChanges().then(() => this.hasPendingModelChanges = false);
    }

    /**
     * Please use this method for handling all events
     */
    handleEvent = event => {
        const { isCanvasAnimating, isCanvasGenerating, isCanvasRendered } = this.props;

        if (!isCanvasRendered || isCanvasGenerating || isCanvasAnimating) {
            return;
        }

        if (!this.containerBoxRef.current || !this.containerBoxRef.current.offsetParent) {
            return;
        }

        if (this.isCanvasLayouterGenerating) {
            return;
        }

        if (this.isCanvasAnimating) {
            return;
        }

        if (app.dialogManager.openDialogs.length >= 1 && !app.dialogManager.openDialogs.some(d => (
            ["SpeakerNotes", "AnimationDialog", "AnimationPanel"].includes(d.type)
        ))) {
            return;
        }

        const { type } = event;

        if (type === "contextmenu") {
            return this.handleContextMenu(event);
        }

        if (type === "keydown") {
            return this.handleKeyDown(event);
        }

        if (type === "keyup") {
            return this.handleKeyUp(event);
        }

        if (type === "mousedown") {
            return this.handleMouseDown(event);
        }

        if (type === "mousemove") {
            return this.handleMouseMove(event);
        }

        if (type === "mouseup") {
            return this.handleMouseUp(event);
        }
    }

    handleKeyDown = async event => {
        const { containerElement } = this.props;
        const { isDragging, dragCreateModel } = this.state;
        const key = event.which;

        // handle cycling through fields
        if (key === Key.TAB) {
            const elements = this.getElementsInVisualOrder();
            let index = 0;

            // something is selected, we need to try and
            // figure out which one is next
            if (this.selection) {
                const direction = event.shiftKey ? -1 : 1;
                const { length: total } = elements;
                index = elements.indexOf(this.selection[0]);
                index = (index + direction + total) % total;
            }

            // replace the selection
            const selection = [elements[index]];
            this.setState({ selection });
            event.preventDefault();
            return;
        }

        if (this.isEditingElement) return;
        if (event.target.nodeName === "TEXTAREA") return;
        if (event.target.nodeName === "INPUT") return;
        if (event.target.nodeName === "DIV" && event.target.getAttribute("contenteditable") === "true") return;

        if (key == Key.ALT && isDragging) {
            this.createDragCopy();
        }

        // Canvas hotkeys
        if ((event.ctrlKey || event.metaKey)) {
            switch (key) {
                case Key.KEY_A:
                    event.preventDefault();
                    this.selectAll();
                    break;
                case Key.KEY_G:
                    event.preventDefault();
                    if (event.shiftKey) {
                        this.ungroupSelection();
                    } else {
                        this.groupSelection();
                    }
                    break;
                case Key.KEY_D:
                    event.preventDefault();
                    if (this.selection.length > 0) {
                        this.duplicateSelection();
                    }
                    break;
                case Key.OPEN_BRACKET:
                    event.preventDefault();
                    this.sendSelectionToBack();
                    break;
                case Key.CLOSE_BRACKET:
                    event.preventDefault();
                    this.bringSelectionToFront();
                    break;
                case Key.KEY_C:
                case Key.KEY_X:
                    event.preventDefault();
                    this.copyToClipboard(event.which == Key.KEY_X, event);
                    break;
                case Key.KEY_L:
                    event.preventDefault();
                    if (event.shiftKey) {
                        this.toggleLock();

                        // do not do the normal event -- In Safari this
                        // opens the bookmark tab
                        event.stopPropagation();
                        event.preventDefault();
                        event.stopImmediatePropagation();
                    }
                    break;
            }
            return;
        }

        // create shortcuts
        if (this.selection.length == 0 && !containerElement.isCallouts) {
            switch (key) {
                case Key.KEY_R:
                    this.setState({
                        dragCreateModel: {
                            type: AuthoringElementType.SHAPE,
                            shape: AuthoringShapeType.RECT
                        }
                    });
                    break;
                case Key.KEY_O:
                    this.setState({
                        dragCreateModel: {
                            type: AuthoringElementType.SHAPE,
                            shape: AuthoringShapeType.ELLIPSE
                        }
                    });
                    break;
                case Key.KEY_P:
                    this.setState({
                        dragCreateModel: {
                            type: AuthoringElementType.PATH
                        }
                    });
                    break;
                case Key.KEY_T:
                    this.setState({
                        dragCreateModel: {
                            type: AuthoringElementType.SHAPE,
                            shape: AuthoringShapeType.RECT,
                            fill: "none",
                            stroke: "none",
                            textAlign: HorizontalAlignType.LEFT,
                            verticalAlign: VerticalAlignType.TOP,
                            fitToText: true,
                            text: {
                                blocks: [{
                                    id: uuid(),
                                    type: AuthoringBlockType.TEXT,
                                    textStyle: TextStyleType.TITLE,
                                    html: ""
                                }]
                            }
                        }
                    });
                    break;
            }
        }

        if (key == Key.ESCAPE && dragCreateModel) {
            this.setState({ dragCreateModel: null, cursor: null, chartType: null });
            return;
        }

        // Selection modifier keys
        if (this.selection.length === 0) {
            return;
        }

        switch (key) {
            case Key.DELETE:
            case Key.BACKSPACE:
                this.removeSelectedElements();
                break;
            case Key.RIGHT_ARROW:
            case Key.LEFT_ARROW:
            case Key.UP_ARROW:
            case Key.DOWN_ARROW:
                event.stopPropagation();
                const nudge = event.shiftKey ? 10 : 1;
                for (const element of this.selection) {
                    if (!element.isLocked) {
                        if (key === Key.UP_ARROW) {
                            element.model.y -= nudge;
                        } else if (key === Key.DOWN_ARROW) {
                            element.model.y += nudge;
                        } else if (key === Key.RIGHT_ARROW) {
                            element.model.x += nudge;
                        } else if (key === Key.LEFT_ARROW) {
                            element.model.x -= nudge;
                        }
                    }
                }
                this.refreshCanvasAndSaveChanges();
                break;
            default:
                // Create block into shape without any text on first keypress
                if (this.selection.length == 1 && this.editorRef && this.editorRef.current && this.editorRef.current.createInitialTextBlock) {
                    if (!["Shift", "Ctrl", "Alt"].includes(event.key) && this.selection[0].model.text.blocks.length === 0) {
                        this.editorRef.current.createInitialTextBlock();
                    }
                }
        }
    }

    handleKeyUp = event => {
        const { isDragging } = this.state;
        const key = event.which;

        if (key == Key.ALT && isDragging && this.isCopyDragging) {
            this.destroyDragCopy();
        }
    }

    handleContextMenu = event => {
        event.preventDefault();
        event.stopPropagation();

        if (this.isOverlayShowing) {
            return;
        }

        this.setState({
            showContextMenu: true,
            contextMenuPositionX: event.pageX,
            contextMenuPositionY: event.pageY
        });
    }

    handleMouseDown = async event => {
        const { selectionLayerController, selectedElements: prevSelectedElements, allowAreaSelect } = this.props;
        const { dragCreateModel, contentMenuItemSelected } = this.state;
        const isDragCreatingElement = dragCreateModel !== null;

        const rawClickPosition = new geom.Point(event.clientX, event.clientY);
        const clickPosition = this.getPositionFromEvent(event);
        const containerBounds = this.getContainerBounds();
        const allElementsBounds = this.getElements().filter(element => !!element?.calculatedProps).map(element => this.getElementSelectionBounds(element));

        if (selectionLayerController.canvasController.canvas.hasVideoOverlay()) {
            const position = new geom.Point(event.clientX, event.clientY);
            const selectedElement = selectionLayerController.canvasController.canvas.findSelectableElementsAtPoint(position)[0];
            if (selectedElement === selectionLayerController.canvasController.canvas.getCanvasElement().videoOverlay) return;
        }

        if (!containerBounds.contains(clickPosition) && !allElementsBounds.some(bounds => bounds.contains(clickPosition))) {
            return;
        }

        if (this.getElements().length === 0 && !dragCreateModel) {
            return;
        }

        event.stopPropagation();

        if (contentMenuItemSelected) {
            this.setState({
                contentMenuItemSelected: false
            });
            return;
        }

        // Special case to avoid Safari removing text selection when clicked
        // on a click-away listener of a popup
        if (event.target?.getAttribute("aria-hidden") === "true") {
            return;
        }

        // Check for context menu
        if (event.button === 2) {
            if (this.isOverlayShowing) {
                return;
            }

            // tentatively mark the context menu as visible
            this.setState({
                showContextMenu: true,
                contextMenuPositionX: event.pageX,
                contextMenuPositionY: event.pageY
            });
            return;
        }

        // Not requesting the context menu
        this.setState({
            showContextMenu: false
        });

        let isDoubleClick = false;
        if (selectionLayerController.prevMouseDownAt + DOUBLE_CLICK_THRESHOLD_MS > Date.now()) {
            const distance = rawClickPosition.distance(selectionLayerController.prevMouseDownPosition);
            if (distance < 5) {
                isDoubleClick = true;
            }
        }

        selectionLayerController.prevMouseDownAt = Date.now();
        selectionLayerController.prevMouseDownPosition = rawClickPosition.clone();

        const selectedElements = selectionLayerController.getElementsForSelection(event, isDoubleClick);
        await this.setSelection(selectedElements);
        this.selectionChangedOnMouseDown = prevSelectedElements.length !== selectedElements.length || prevSelectedElements.some((element, index) => element !== selectedElements[index]);

        this.lastDraggedOffset = null;

        const elementsInClickBounds = this.getElementsAtPoint(clickPosition).reverse();

        this.setState({ elementsUnderMouse: elementsInClickBounds, rolloverElements: [] });

        // User wants to reposition or copy or select
        if (elementsInClickBounds.length > 0 && !isDragCreatingElement && this.selection.length > 0) {
            const selectionBounds = this.getElementsSelectionBounds(this.selection);

            if (this.selection.length == 1 && this.selection[0].childElement?.referencePoint) {
                this.dragOffset = this.selection[0].childElement.referencePoint.offset(this.selection[0].childElement.selectionPadding.left, this.selection[0].childElement.selectionPadding.top);
            } else {
                this.dragOffset = new geom.Point(clickPosition.x - selectionBounds.left, clickPosition.y - selectionBounds.top);
            }

            this.setState({
                selectionBoundsBeforeDrag: selectionBounds,
                isMouseDown: true,
                mouseDownPoint: new geom.Point(event.pageX, event.pageY)
            });
            return;
        }

        // User wants to multiselect or drag create
        const dragSelectionBounds = new geom.Rect(clickPosition.x, clickPosition.y, 1, 1);

        this.setState({
            snapLines: [],
            isMouseDown: true,
            dragSelectionBounds
        });

        // Since there is a dragCreateModel defined, drag create the new element
        if (isDragCreatingElement) {
            await this.startDragCreate(event);
            return;
        }

        if (allowAreaSelect) {
            // Start a drag selection
            this.startDrag(DragType.SELECTION);
        }
    }

    startDrag = dragType => {
        this.lastDraggedOffset = new geom.Point(0, 0);
        this.setState({
            isDragging: true,
            dragType,
            selectedElementsOnDragSelection: [...this.selection],
            showGridLines: [DragType.RESIZE, DragType.POSITION].includes(dragType) && this.snapToGrid
        });
        app.isDraggingItem = true;
    }

    clearSnapLines = () => {
        this.setState({
            snapLines: []
        });
    }

    handleMouseUp = event => {
        const { isMouseDown, isDragging } = this.state;
        if (!isMouseDown) {
            return;
        }

        if (!this.isCopyDragging) {
            this.lastDraggedOffset = null; // after copy drag, we preserve this for cmd-d
        }
        this.isCopyDragging = false;

        // need to disable interactions immediately
        this.setState({
            isDragging: false,
            hasDragged: false,
            dragType: null,
            isMouseDown: false,
            showGridLines: false,
            snapLines: [],
            dragSelectionBounds: null,
            dragCreateModel: null,
            cursor: null,
            selectedElementsOnDragSelection: null,
            mouseDownPoint: null
        });

        app.isDraggingItem = false;

        if (!isDragging && !this.selectionChangedOnMouseDown) {
            const clickPosition = this.getPositionFromEvent(event);
            const elementsInClickBounds = this.getElementsAtPoint(clickPosition).reverse();
            if (elementsInClickBounds.length > 0 && elementsInClickBounds.every(element => this.selection.includes(element)) && elementsInClickBounds.length < this.selection.length) {
                this.setSelection(elementsInClickBounds);
            }
        }

        for (const element of this.selection) {
            if (element.options.onDragEnd) {
                element.options.onDragEnd();
            }
        }

        if (this.hasPendingModelChanges) {
            this.refreshCanvasAndSaveChanges();
        }
    }

    handleMouseMove = event => {
        // Avoid handling mouse move when there's a popover
        if (this.isPopoverShowing) {
            return;
        }

        const { selectedElements, containerElement } = this.props;
        const { isMouseDown, isDragging, mouseDownPoint } = this.state;

        if (containerElement.canvas.layouter.isGenerating) {
            return;
        }
        if (containerElement.canvas.hasRenderedOnTopElements()) {
            return;
        }

        const rolloverElements = [];

        const elementsUnderCursor = this.getElementsAtPoint(this.getPositionFromEvent(event));
        elementsUnderCursor.sort((a, b) => b.calculatedProps.layer - a.calculatedProps.layer);

        if (!isMouseDown) {
            const setRolloverElements = elements => {
                const { rolloverElements } = this.state;
                if (!_.isEqual(rolloverElements.map(el => el.id), elements.map(el => el.id))) {
                    this.setState({ rolloverElements: elements });
                }
            };

            // get the first not-locked layer
            // const overElement = elementsUnderCursor.find(element => !element.isLocked);
            const overElement = elementsUnderCursor[0];
            if (overElement?.canRollover === false) {
                return;
            }

            // check if the layer being hovered is part of the same group
            const partOfSameGroup = this.selection.length && this.selection[0]?.model?.groupIds?.[0] === overElement?.model?.groupIds?.[0];

            // if this is a child of a group
            if (event.ctrlKey || event.metaKey || partOfSameGroup) {
                // check to focus on a single element
                if (overElement && !overElement.groupIds?.[0] && !this.selection.find(selected => selected.id === overElement.id)) {
                    rolloverElements.push(overElement);
                }

                // replace the hover elements
                setRolloverElements(rolloverElements);
                return;
            } else {
                // check if part of a group
                const over = elementsUnderCursor.find(element => !element.isLocked || (element.model.type == AuthoringElementType.SHAPE && element.childElement?.hasText));

                // inside of a group
                if (over?.model?.groupIds?.length) {
                    // merge all
                    const [id] = over.model.groupIds;
                    const layers = this.getElements().filter(element => element.model?.groupIds?.[0] === id);
                    const group = new SelectionGroup(layers);
                    setRolloverElements([group]);

                    return;
                } else if (over) {
                    setRolloverElements([over]);
                    return;
                }
            }

            // if the item is inside of a group, hover the whole group
            setRolloverElements([]);
            return;
        }

        if (isDragging) {
            this.handleMouseMoveOnDrag(event);
            return;
        }

        if (mouseDownPoint?.distance(new geom.Point(event.pageX, event.pageY)) > 5) {
            if (this.selection.length === selectedElements.length && this.selection.every((element, index) => element === selectedElements[index])) {
                if (event.altKey) {
                    this.createDragCopy();
                }
                this.startDrag(DragType.POSITION);
            } else if (this.selection.length === 1 && selectedElements.length === 1 && selectedElements[0].isChildOf(this.selection[0]) && this.selection[0].canDragWhenChildSelected) {
                this.setSelection(this.selection).then(() => {
                    this.startDrag(DragType.POSITION);
                });
            }
        }
    }

    handleMouseMoveOnDrag = event => {
        const { dragType, mouseDownPoint } = this.state;

        // Making dragging smoother
        window.requestAnimationFrame(timestamp => {
            const { isCanvasAnimating, isCanvasGenerating, isCanvasRendered } = this.props;
            if (!isCanvasRendered || isCanvasGenerating || isCanvasAnimating) {
                return;
            }

            // It's possible that we stopped dragging while were waiting for the next frame
            const { isDragging, hasDragged } = this.state;
            if (!isDragging) {
                return;
            }

            if (this.mouseMoveHandledAt === timestamp) {
                return;
            }

            this.mouseMoveHandledAt = timestamp;

            if (!hasDragged) {
                this.setState({ hasDragged: true });
            }

            if (dragType === DragType.POSITION && new geom.Point(event.pageX, event.pageY).distance(mouseDownPoint) > (5 / this.canvas.canvasScale)) {
                return this.handleDragPosition(event);
            }

            if (dragType === DragType.SELECTION) {
                return this.handleDragSelection(event);
            }

            if (dragType === DragType.RESIZE) {
                return this.handleDragResize(event);
            }

            if (dragType === DragType.CONNECTOR_POINT) {
                return this.handleDragConnectorPoint(event);
            }

            if (dragType === DragType.ROTATE) {
                return this.handleDragRotate(event);
            }
        });
    }

    getSnapToGridOffset = coordinate => {
        const offset = coordinate % this.gridSpacing;
        if (offset >= this.gridSpacing / 2) {
            return this.gridSpacing - offset;
        }
        return -offset;
    }

    createDragCopy = () => {
        const { containerElement } = this.props;

        if (this.isCopyDragging) return;

        const { containerModels, connectorModels } = this.duplicateElementModels(this.selection);

        this.copiedElements = [];

        for (let i = 0; i < this.selection.length; i++) {
            let dragModel = this.selection[i].model;
            let copyModel = containerModels[i];

            let layer = containerElement.model.elements.indexOf(dragModel);
            containerElement.model.elements.insert(copyModel, layer + 1);

            this.copiedElements.push(dragModel);
        }

        if (connectorModels.length > 0) {
            containerElement.model.connections.items.push(...connectorModels);
        }

        this.isCopyDragging = true;
        this.hasPendingModelChanges = true;

        this.refreshCanvas()
            .then(() => {
                for (let element of this.selection) {
                    element.model.x -= this.lastDraggedOffset.x;
                    element.model.y -= this.lastDraggedOffset.y;
                }

                const newSelection = this.getElements().filter(element => containerModels.some(({ id }) => element.model.id === id));
                this.setSelection(newSelection);
            });
    }

    destroyDragCopy = () => {
        const { containerElement } = this.props;

        if (!this.isCopyDragging) return;
        this.isCopyDragging = false;

        for (let model of this.copiedElements) {
            containerElement.model.elements.remove(model);
        }
        this.hasPendingModelChanges = true;
        this.refreshCanvasAndSaveChanges();
    }

    handleDragPosition(event) {
        if (this.isCanvasLayouterGenerating) {
            return;
        }

        const { containerElement } = this.props;
        const { mouseDownPoint, selectionBoundsBeforeDrag } = this.state;
        let dragOffset = this.dragOffset;

        if (this.selection.length == 1 && this.selection[0].childElement?.referencePoint) {
            dragOffset = this.selection[0].childElement.referencePoint.offset(this.selection[0].childElement.selectionPadding.left, this.selection[0].childElement.selectionPadding.top);
        }

        const simulatedEvent = { pageX: event.pageX, pageY: event.pageY };
        if (event.shiftKey) {
            if (Math.abs(event.pageX - mouseDownPoint.x) < Math.abs(event.pageY - mouseDownPoint.y)) {
                simulatedEvent.pageX = mouseDownPoint.x;
            } else {
                simulatedEvent.pageY = mouseDownPoint.y;
            }
        }

        const dragPosition = this.getPositionFromEvent(simulatedEvent);

        const selectionBounds = this.getElementsSelectionBounds(this.selection.filter(el => !el.isLocked));
        if (!selectionBounds) {
            return;
        }

        const newSelectionPosition = new geom.Point(dragPosition.x - dragOffset.x, dragPosition.y - dragOffset.y);

        let diffX = newSelectionPosition.x - selectionBounds.left;
        let diffY = newSelectionPosition.y - selectionBounds.top;

        if (diffX === 0 && diffY === 0) {
            return;
        }

        if (containerElement.restrictElementsToBounds) {
            const containerBounds = new geom.Rect(0, 0, containerElement.selectionBounds.width, containerElement.selectionBounds.height);
            let elementsBounds = selectionBounds;
            if (containerElement.restrictElementsToBoundsByRegistrationPoint) {
                elementsBounds = this.selection.reduce(
                    (bounds, element) => bounds
                        ? bounds.union(new geom.Rect(element.model.x, element.model.y, 0, 0))
                        : new geom.Rect(element.model.x, element.model.y, 0, 0),
                    null
                );
            }

            if (diffX > 0) {
                diffX = Math.clamp(diffX, 0, containerBounds.right - elementsBounds.right);
            } else {
                diffX = Math.clamp(diffX, containerBounds.left - elementsBounds.left, 0);
            }
            if (diffY > 0) {
                diffY = Math.clamp(diffY, 0, containerBounds.bottom - elementsBounds.bottom);
            } else {
                diffY = Math.clamp(diffY, containerBounds.top - elementsBounds.top, 0);
            }
        }

        if (diffX === 0 && diffY === 0) {
            return;
        }

        for (const element of this.selection.filter(el => !el.isLocked)) {
            element.model.x = element.model.x + diffX;
            element.model.y = element.model.y + diffY;

            if (this.snapToGrid) {
                element.model.x += this.getSnapToGridOffset(element.model.x);
                element.model.y += this.getSnapToGridOffset(element.model.y);
            }

            element.model.x = Math.round(element.model.x);
            element.model.y = Math.round(element.model.y);

            if (element.options.onDrag) {
                element.options.onDrag();
            }
        }

        this.lastDraggedOffset = this.getElementsSelectionBounds(this.selection).position.minus(selectionBoundsBeforeDrag.position);

        this.setSnapLines(event, this.selection);
    }

    setSnapLines(event, selection) {
        const { containerElement } = this.props;

        const snapLines = [];
        if ((!this.snapToGrid && !event.metaKey) && this.allowSnapLines) {
            const selectionSnapLines = this.getSnapLines(selection, true);
            const destinationSnapLines = this.getSnapLines(this.getNotSelectedElements(), false);
            if (containerElement.allowSnapToNonAuthoringElements) {
                destinationSnapLines.push(...this.getSnapLines(this.getSnappableNonAuthoringElements(this.canvas.layouter), false));
            }

            const { horizontalSnapLine, verticalSnapLine } = this.calcSnap(selectionSnapLines, destinationSnapLines);
            if (horizontalSnapLine || verticalSnapLine) {
                for (const element of selection) {
                    if (verticalSnapLine) {
                        element.model.x = Math.round(element.model.x + verticalSnapLine.offset);
                        snapLines.push(verticalSnapLine);
                    }
                    if (horizontalSnapLine) {
                        element.model.y = Math.round(element.model.y + horizontalSnapLine.offset);
                        snapLines.push(horizontalSnapLine);
                    }
                }
            }
        }

        this.hasPendingModelChanges = true;

        const modelWasReverted = this.refreshElement(false);
        if (!modelWasReverted) {
            this.setState({ snapLines });
        }
    }

    renderSnapLinesForExternalElement = async elementBounds => {
        const snapLines = [];

        const selectionSnapLines = this.getSnapLinesForBounds(elementBounds);
        const destinationSnapLines = this.getSnapLines(this.getNotSelectedElements(), false);
        destinationSnapLines.push(...this.getSnapLines(this.getSnappableNonAuthoringElements(this.canvas.layouter), false));

        const { horizontalSnapLine, verticalSnapLine } = this.calcSnap(selectionSnapLines, destinationSnapLines);
        if (horizontalSnapLine || verticalSnapLine) {
            if (verticalSnapLine) {
                snapLines.push(verticalSnapLine);
            }
            if (horizontalSnapLine) {
                snapLines.push(horizontalSnapLine);
            }
        }

        await this.setStateAsync({ snapLines });

        return snapLines;
    }

    removeSnapLines = async () => {
        await this.setStateAsync({ snapLines: [] });
    }

    handleDragSelection(event) {
        const { dragSelectionBounds, selectedElementsOnDragSelection } = this.state;
        if (!dragSelectionBounds) {
            return;
        }

        const dragPosition = this.getPositionFromEvent(event);
        // Preserving left and top of dragSelectionBounds in order to avoid
        // creating additional variable for storing the initial drag position
        // So dragSelectionBounds may have negative width or heigth so we have
        // to normalize() it before doing calculations
        const newDragSelectionBounds = new geom.Rect(dragSelectionBounds.left, dragSelectionBounds.top, dragPosition.x - dragSelectionBounds.left, dragPosition.y - dragSelectionBounds.top);

        this.setState({
            dragSelectionBounds: newDragSelectionBounds
        });

        if (Math.abs(newDragSelectionBounds.width) < 5 && Math.abs(newDragSelectionBounds.height) < 5) {
            return;
        }

        const elements = this.getElements();
        let elementsInSelection = this.getElementsInRect(newDragSelectionBounds.normalize());
        elementsInSelection = elementsInSelection.filter(element => !element.isLocked);

        const selectedGroupIds = Array.from(new Set(elementsInSelection.map(element => element.groupId))).filter(groupId => groupId);

        let dragSelectedElements = elements.filter(element => elementsInSelection.includes(element) || selectedGroupIds.includes(element.groupId));

        let selection;
        if (event.shiftKey) {
            selection = [...selectedElementsOnDragSelection];
            for (let element of dragSelectedElements) {
                if (selectedElementsOnDragSelection.contains(element)) {
                    selection.remove(element);
                } else {
                    selection.push(element);
                }
            }
        } else {
            selection = dragSelectedElements;
        }

        this.setSelection(selection);
    }

    onRotateStarted = (event, anchor) => {
        let centerPoint = this.selection[0].canvasBounds.center;
        this.startAngle = new geom.Point(event.pageX, event.pageY).angleToPoint(centerPoint);
        this.startRotation = this.selection[0].model.rotation ?? 0;

        this.setState({
            isMouseDown: true,
            cursor: `url(${getStaticUrl("/images/cursors/rotate.svg")}) 5 5, auto`
        });

        this.startDrag(DragType.ROTATE);
    }

    handleDragRotate = event => {
        let centerPoint = this.selection[0].canvasBounds.center;
        let angle = new geom.Point(event.pageX, event.pageY).angleToPoint(centerPoint);

        let rotate = (this.startRotation + angle - this.startAngle) % 360;
        if (rotate < 0) {
            rotate = 360 + rotate;
        }

        if (event.shiftKey) {
            rotate = Math.ceil(rotate / 22.5) * 22.5;
        }

        this.selection[0].model.rotation = rotate;

        this.refreshElement(false);
    }

    onResizeStarted = (event, anchor) => {
        const { containerElement } = this.props;

        this.resizeAnchor = anchor;
        this.modelBeforeResize = _.cloneDeep(containerElement.model);
        this.referencePointsBeforeResize = this.selection.reduce((regPoints, element) => ({ ...regPoints, [element.id]: element.referencePoint?.clone() }), {});
        this.elementTextWidthBeforeResize = this.selection.reduce((textWidths, element) => ({ ...textWidths, [element.id]: element?.textWidth }), {});

        let cursor;
        switch (anchor) {
            case AnchorType.TOP_LEFT:
            case AnchorType.BOTTOM_RIGHT:
                cursor = "nwse-resize";
                break;
            case AnchorType.TOP_RIGHT:
            case AnchorType.BOTTOM_LEFT:
                cursor = "nesw-resize";
                break;
            case AnchorType.LEFT:
            case AnchorType.RIGHT:
                cursor = "ew-resize";
                break;
            case AnchorType.TOP:
            case AnchorType.BOTTOM:
                cursor = "ns-resize";
                break;
        }

        this.setState({
            isMouseDown: true,
            cursor: cursor
        });

        this.startDrag(DragType.RESIZE);
    }

    handleDragResize = event => {
        if (this.selectionHasLockedItems) {
            return;
        }

        const resizeAnchor = this.resizeAnchor;
        let preserveAspectRatio;
        const lockAspectRatio = this.selection.some(element => element.lockAspectRatio);
        const preserveAspectRatioOnCornerResize = this.selection.some(element => element.preserveAspectRatioOnCornerResize);

        if (lockAspectRatio || (preserveAspectRatioOnCornerResize && resizeAnchor.equalsAnyOf(AnchorType.TOP_RIGHT, AnchorType.TOP_LEFT, AnchorType.BOTTOM_LEFT, AnchorType.BOTTOM_RIGHT))) {
            preserveAspectRatio = true;
        } else {
            if (resizeAnchor.equalsAnyOf(AnchorType.LEFT, AnchorType.TOP, AnchorType.RIGHT, AnchorType.BOTTOM)) {
                preserveAspectRatio = false;
            } else {
                preserveAspectRatio = event.shiftKey;
            }
            if (this.selection.length === 1 && this.selection[0].preserveAspectRatio) {
                preserveAspectRatio = !preserveAspectRatio;
            }
        }

        const dragPosition = this.getPositionFromEvent(event);

        this.resizeSelection(dragPosition, preserveAspectRatio);

        this.hasPendingModelChanges = true;

        let modelWasReverted = this.refreshElement(false);
        if (modelWasReverted) {
            return;
        }

        const snapLines = [];
        if (!this.snapToGrid && !event.metaKey) {
            const selectionSnapLines = this.getSnapLines(this.selection, true);
            const destinationSnapLines = this.getSnapLines(this.getNotSelectedElements(), false);
            let { horizontalSnapLine, verticalSnapLine } = this.calcSnap(selectionSnapLines, destinationSnapLines);

            if (resizeAnchor.equalsAnyOf(AnchorType.LEFT, AnchorType.RIGHT)) {
                horizontalSnapLine = null;
            } else if (resizeAnchor.equalsAnyOf(AnchorType.TOP, AnchorType.BOTTOM)) {
                verticalSnapLine = null;
            }

            if (verticalSnapLine || horizontalSnapLine) {
                const selectionBounds = this.getElementsSelectionBounds(this.selection);
                let resizeDragPosition = selectionBounds.getPoint(resizeAnchor);

                // Altering current drag position to snap to the calced snap lines
                if (verticalSnapLine && verticalSnapLine.offset !== 0) {
                    const scaledOffset = verticalSnapLine.bindingPoint === SnapLineBindingPoint.CENTER ? verticalSnapLine.offset * 2 : verticalSnapLine.offset;
                    resizeDragPosition = resizeDragPosition.offset(scaledOffset, 0);
                    snapLines.push(verticalSnapLine);
                }
                if (horizontalSnapLine && horizontalSnapLine.offset !== 0) {
                    const scaledOffset = horizontalSnapLine.bindingPoint === SnapLineBindingPoint.CENTER ? horizontalSnapLine.offset * 2 : horizontalSnapLine.offset;
                    resizeDragPosition = resizeDragPosition.offset(0, scaledOffset);
                    snapLines.push(horizontalSnapLine);
                }

                this.resizeSelection(resizeDragPosition, preserveAspectRatio);

                modelWasReverted = this.refreshElement(false);
                if (!modelWasReverted) {
                    this.setState({ snapLines });
                }
            } else {
                this.setState({ snapLines });
            }
        }
    }

    resizeSelection = (dragPosition, preserveAspectRatio) => {
        const resizeAnchor = this.resizeAnchor;
        const modelBeforeResize = this.modelBeforeResize;

        // Calculating selection before resize based on the saved model
        const selectionBeforeResize = this.getElementsSelectionBounds(
            modelBeforeResize.elements
                .filter(model => this.selection.some(element => element.model.id === model.id))
                .map(model => ({ model, referencePoint: this.referencePointsBeforeResize[model.id] }))
        );

        let scalingAnchorPoint;
        let newWidth, newHeight;
        if (resizeAnchor === AnchorType.BOTTOM_RIGHT) {
            scalingAnchorPoint = selectionBeforeResize.getPoint(AnchorType.TOP_LEFT);
            newWidth = dragPosition.x - selectionBeforeResize.left;
            newHeight = dragPosition.y - selectionBeforeResize.top;
        } else if (resizeAnchor === AnchorType.BOTTOM) {
            scalingAnchorPoint = selectionBeforeResize.getPoint(AnchorType.TOP);
            newHeight = dragPosition.y - selectionBeforeResize.top;
        } else if (resizeAnchor === AnchorType.RIGHT) {
            scalingAnchorPoint = selectionBeforeResize.getPoint(AnchorType.LEFT);
            newWidth = dragPosition.x - selectionBeforeResize.left;
        } else if (resizeAnchor === AnchorType.TOP_RIGHT) {
            scalingAnchorPoint = selectionBeforeResize.getPoint(AnchorType.BOTTOM_LEFT);
            newWidth = dragPosition.x - selectionBeforeResize.left;
            newHeight = selectionBeforeResize.top + selectionBeforeResize.height - dragPosition.y;
        } else if (resizeAnchor === AnchorType.TOP) {
            scalingAnchorPoint = selectionBeforeResize.getPoint(AnchorType.BOTTOM);
            newHeight = selectionBeforeResize.top + selectionBeforeResize.height - dragPosition.y;
        } else if (resizeAnchor === AnchorType.TOP_LEFT) {
            scalingAnchorPoint = selectionBeforeResize.getPoint(AnchorType.BOTTOM_RIGHT);
            newWidth = selectionBeforeResize.left + selectionBeforeResize.width - dragPosition.x;
            newHeight = selectionBeforeResize.top + selectionBeforeResize.height - dragPosition.y;
        } else if (resizeAnchor === AnchorType.LEFT) {
            scalingAnchorPoint = selectionBeforeResize.getPoint(AnchorType.RIGHT);
            newWidth = selectionBeforeResize.left + selectionBeforeResize.width - dragPosition.x;
        } else if (resizeAnchor === AnchorType.BOTTOM_LEFT) {
            scalingAnchorPoint = selectionBeforeResize.getPoint(AnchorType.TOP_RIGHT);
            newWidth = selectionBeforeResize.left + selectionBeforeResize.width - dragPosition.x;
            newHeight = dragPosition.y - selectionBeforeResize.top;
        }

        let xScale = newWidth ? Math.max(newWidth / selectionBeforeResize.width, 0) : null;
        let yScale = newHeight ? Math.max(newHeight / selectionBeforeResize.height, 0) : null;

        if (preserveAspectRatio) {
            if (xScale && yScale) {
                xScale = yScale = Math.max(xScale, yScale);
            } else if (xScale) {
                yScale = xScale;
            } else if (yScale) {
                xScale = yScale;
            }
        }

        for (const element of this.selection) {
            const elementModelBeforeResize = modelBeforeResize.elements.find(model => model.id === element.model.id);
            const elementReferencePointBeforeResize = this.referencePointsBeforeResize[element.model.id];
            const elementTextWidth = this.elementTextWidthBeforeResize[element.model.id];
            const boundsBeforeResize = this.getElementSelectionBounds({ model: element.model });

            if (xScale) {
                const adjustedXScale = Math.min(xScale, element.maxWidth / elementModelBeforeResize.width);

                let offset = 0;
                if (elementReferencePointBeforeResize) {
                    if (element.referencePointAnchor === AnchorType.RIGHT) {
                        offset = -(elementModelBeforeResize.width - elementReferencePointBeforeResize.x) * (adjustedXScale - 1);
                    } else if (element.referencePointAnchor === AnchorType.LEFT) {
                        offset = elementReferencePointBeforeResize.x * (adjustedXScale - 1);
                    }
                }
                element.model.x = scalingAnchorPoint.x + (elementModelBeforeResize.x - scalingAnchorPoint.x) * adjustedXScale - offset;
                element.model.width = elementModelBeforeResize.width * adjustedXScale;

                element.model.x = Math.round(element.model.x);

                if (elementTextWidth && element.hasText) {
                    element.setUserWidth(elementTextWidth - (elementModelBeforeResize.width - element.model.width));
                }
            }

            if (yScale) {
                const adjustedYScale = Math.min(yScale, element.maxHeight / elementModelBeforeResize.height);

                let offset = 0;
                if (elementReferencePointBeforeResize) {
                    if (element.referencePointAnchor === AnchorType.BOTTOM) {
                        offset = (elementModelBeforeResize.height - elementReferencePointBeforeResize.y) * (adjustedYScale - 1);
                    } else if (element.referencePointAnchor === AnchorType.TOP) {
                        offset = elementReferencePointBeforeResize.y * (adjustedYScale - 1);
                    }
                }
                element.model.y = scalingAnchorPoint.y + (elementModelBeforeResize.y - scalingAnchorPoint.y) * adjustedYScale - offset;
                element.model.height = elementModelBeforeResize.height * adjustedYScale;

                element.model.y = Math.round(element.model.y);
            }

            element.onResize(boundsBeforeResize);
        }
    }

    startDragCreate = event => {
        const { containerElement } = this.props;
        const { dragCreateModel, chartType } = this.state;

        const clickPosition = this.getPositionFromEvent(event);
        // Disabling drag processing until we generate new element
        this.setState({ dragType: null });

        // Create a new element at the clicked point using the dragCreateModel
        const newModel = {
            ...dragCreateModel,
            id: uuid(),
            x: clickPosition.x,
            y: clickPosition.y,
            width: dragCreateModel.width || 150,
            height: dragCreateModel.height || 150
        };
        containerElement.model.elements.push(newModel);

        this.hasPendingModelChanges = true;

        if (chartType) {
            this.refreshCanvas()
                .then(() => {
                    const newElement = this.getElements().find(element => element.model.id === newModel.id);
                    ChangeChartType(newElement, chartType);
                });
        }

        this.refreshCanvas()
            .then(async () => {
                const newElement = this.getElements().find(element => element.model.id === newModel.id);
                // After refreshing canvas, select the new element and start drag resizing...
                if (newModel.type === AuthoringElementType.PATH) {
                    await this.setSelection([newElement.childElement]);
                    this.editorRef.current.designerRef.current.handleMouseDown(event, newModel.points[1]);
                } else {
                    await this.setSelection([newElement]);
                    this.startDrag(DragType.RESIZE);
                    this.onResizeStarted(event, AnchorType.BOTTOM_RIGHT);
                }
            });
    }

    calcSnap = (sourceSnapLines, destinationSnapLines) => {
        const snapLines = [];
        for (const sourceSnapLine of sourceSnapLines) {
            for (const destinationSnapLine of destinationSnapLines) {
                if (
                    sourceSnapLine.direction === destinationSnapLine.direction &&
                    sourceSnapLine.corridorBounds.intersects(destinationSnapLine.corridorBounds)
                ) {
                    // Enriching snap line with offset in order to reposition selected elements in future
                    // Also replacing bindingPoint with the binding point from the selection, will be used
                    // for calculating correct offset
                    const offset = sourceSnapLine.direction === SnapLineDirection.HORIZONTAL
                        ? destinationSnapLine.snapLineBounds.y - sourceSnapLine.snapLineBounds.y
                        : destinationSnapLine.snapLineBounds.x - sourceSnapLine.snapLineBounds.x;
                    const calculatedSnapLine = { ...destinationSnapLine, bindingPoint: sourceSnapLine.bindingPoint, offset };

                    // Cutting snap line
                    const emitterAndReceiverBounds = sourceSnapLine.sourceBounds.union(destinationSnapLine.sourceBounds);
                    calculatedSnapLine.snapLineBounds = calculatedSnapLine.snapLineBounds.intersection(emitterAndReceiverBounds);

                    snapLines.push(calculatedSnapLine);
                }
            }
        }

        if (snapLines.length === 0) {
            return {};
        }

        // We want to apply only the closest horizontal and vertical snap lines (one of each type)
        const sortedSnapLines = snapLines.sort(snapLine => snapLine.offset);
        const horizontalSnapLine = sortedSnapLines.find(snapLine => snapLine.direction === SnapLineDirection.HORIZONTAL);
        const verticalSnapLine = sortedSnapLines.find(snapLine => snapLine.direction === SnapLineDirection.VERTICAL);

        return { horizontalSnapLine, verticalSnapLine };
    }

    getSnapLines = (fromElements, unionElements) => {
        const { containerElement } = this.props;

        const snapLines = [];
        if (unionElements) {
            const bounds = this.getElementsSelectionBounds(fromElements);
            snapLines.push(...this.getSnapLinesForBounds(bounds));
        } else {
            for (const element of fromElements) {
                let bounds;
                if (!element.isInstanceOf("AuthoringElementContainer")) {
                    bounds = element.canvasBounds;
                } else {
                    bounds = this.getElementSelectionBounds(element);
                }
                snapLines.push(...this.getSnapLinesForBounds(bounds));

                if (element === containerElement) {
                    const paddedBounds = this.getPaddedContainerBounds();
                    const paddedSnapLines = this.getSnapLinesForBounds(paddedBounds, false);
                    // Special casa to allow special rendering of container padded frame
                    paddedSnapLines.forEach(snapLine => snapLine.isContainerPadded = true);
                    snapLines.push(...paddedSnapLines);
                }
            }
        }

        return snapLines;
    }

    getSnappableNonAuthoringElements = (parent, elements = []) => {
        for (const element of Object.values(parent.elements)) {
            if (this.selection.some(selectionElement => selectionElement.allChildElements.includes(element))) {
                continue;
            }
            if (element.allowElementSnap) {
                elements.push(element);
            } else {
                this.getSnappableNonAuthoringElements(element, elements);
            }
        }
        return elements;
    }

    getSnapLinesForBounds = (bounds, includeCenterLines = true) => {
        const containerBounds = this.getContainerBounds();
        bounds = bounds.fitInRect(containerBounds);

        const snapLines = [
            {
                direction: SnapLineDirection.HORIZONTAL,
                bindingPoint: SnapLineBindingPoint.START,
                corridorBounds: new geom.Rect(containerBounds.left, bounds.top - SNAP_TOLERANCE / 2, containerBounds.width, SNAP_TOLERANCE),
                snapLineBounds: new geom.Rect(containerBounds.left, bounds.top, containerBounds.width, 0)
            },
            {
                direction: SnapLineDirection.HORIZONTAL,
                bindingPoint: SnapLineBindingPoint.CENTER,
                corridorBounds: new geom.Rect(containerBounds.left, bounds.centerV - SNAP_TOLERANCE / 2, containerBounds.width, SNAP_TOLERANCE),
                snapLineBounds: new geom.Rect(containerBounds.left, bounds.centerV, containerBounds.width, 0)
            },
            {
                direction: SnapLineDirection.HORIZONTAL,
                bindingPoint: SnapLineBindingPoint.END,
                corridorBounds: new geom.Rect(containerBounds.left, bounds.bottom - SNAP_TOLERANCE / 2, containerBounds.width, SNAP_TOLERANCE),
                snapLineBounds: new geom.Rect(containerBounds.left, bounds.bottom, containerBounds.width, 0)
            },
            {
                direction: SnapLineDirection.VERTICAL,
                bindingPoint: SnapLineBindingPoint.START,
                corridorBounds: new geom.Rect(bounds.left - SNAP_TOLERANCE / 2, containerBounds.top, SNAP_TOLERANCE, containerBounds.height),
                snapLineBounds: new geom.Rect(bounds.left, containerBounds.top, 0, containerBounds.height)
            },
            {
                direction: SnapLineDirection.VERTICAL,
                bindingPoint: SnapLineBindingPoint.CENTER,
                corridorBounds: new geom.Rect(bounds.centerH - SNAP_TOLERANCE / 2, containerBounds.top, SNAP_TOLERANCE, containerBounds.height),
                snapLineBounds: new geom.Rect(bounds.centerH, containerBounds.top, 0, containerBounds.height)
            },
            {
                direction: SnapLineDirection.VERTICAL,
                bindingPoint: SnapLineBindingPoint.END,
                corridorBounds: new geom.Rect(bounds.right - SNAP_TOLERANCE / 2, containerBounds.top, SNAP_TOLERANCE, containerBounds.height),
                snapLineBounds: new geom.Rect(bounds.right, containerBounds.top, 0, containerBounds.height)
            }
        ]
            // Adding source bounds to each snap line
            .map(snapLine => ({ ...snapLine, sourceBounds: bounds }));

        if (includeCenterLines) {
            return snapLines;
        }

        return snapLines.filter(snapLine => snapLine.bindingPoint !== SnapLineBindingPoint.CENTER);
    }

    onSelectionAction = action => {
        this.setState({ showContextMenu: false });

        switch (action) {
            case "bringToFront":
                return this.bringSelectionToFront();
            case "sendToBack":
                return this.sendSelectionToBack();
            case "cut":
                return this.copyToClipboard(true);
            case "copy":
                return this.copyToClipboard(false);
            case "copyStyles":
                return this.copyStyles();
            case "pasteStyles":
                return this.pasteStyles();
            case "delete":
                return this.removeSelectedElements();
            case "group":
                return this.groupSelection();
            case "ungroup":
                return this.ungroupSelection();
            case "lock":
                return this.lockSelection();
            case "unlock":
                return this.unlockSelection();
            case "align-left":
            case "align-center":
            case "align-right":
            case "align-top":
            case "align-middle":
            case "align-bottom":
                return this.alignSelection(action);
            case "select-all":
                return this.selectAll();
            case "distribute-horizontal":
            case "distribute-vertical":
                return this.distributeSelection(action);
        }
    }

    selectAll = () => this.setSelection(this.getElements())

    copyToClipboard = (cut, event) => {
        if (this.selection.length > 0) {
            event?.stopPropagation();
            event?.preventDefault();

            clipboardWrite({
                [ClipboardType.AUTHORING]: this.selection.map(element => {
                    return { ...element.model, elementParentName: element.getRootElement().name };
                }),
            });

            if (cut) {
                this.removeSelectedElements();
            }
        }
    }

    pasteFromClipboard = async data => {
        // Lock the slide first
        this.canvas.lockSlideForCollaborators(10);

        if (isObject(data)) {
            this.pasteElementsFromClipboard(data);
        } else if (isString(data)) {
            this.pasteTextFromClipboard(data);
        }
    }

    pasteAssetFromClipboard = async asset => {
        const { containerElement } = this.props;
        if (asset) {
            const bounds = new geom.Rect(0, 0, asset.get("w"), asset.get("h")).fitToSize(new geom.Size(this.canvas.CANVAS_WIDTH, this.canvas.CANVAS_HEIGHT)).centerInRect(new geom.Rect(0, 0, this.canvas.CANVAS_WIDTH, this.canvas.CANVAS_HEIGHT));
            const model = {
                id: uuid(),
                type: AuthoringElementType.CONTENT,
                ...bounds.toXYObject(),
                element: {
                    content_value: asset.id,
                    content_type: AssetType.IMAGE
                }
            };
            containerElement.model.elements.push(model);
            await this.refreshCanvasAndSaveChanges();

            this.setSelection([]);
        }
    }

    pasteElementsFromClipboard = (data, useContextMenuPosition = false) => {
        const { containerElement, selectionLayerController } = this.props;
        const { contextMenuPositionX, contextMenuPositionY } = this.state;

        const addedConnectors = [];
        if (containerElement.isCallouts) {
            for (const modelToPaste of data.filter(model => model.connectorType)) {
                delete modelToPaste.id;

                if (modelToPaste.targetPoint && modelToPaste.sourcePoint) {
                    // Offset in a perpendicular direction to ensure
                    //   the new item does not overlap the old item
                    let offset = new geom.Point(
                        modelToPaste.targetPoint.x,
                        modelToPaste.targetPoint.y,
                    )
                        .minus(
                            modelToPaste.sourcePoint.x,
                            modelToPaste.sourcePoint.y,
                        )
                        .normalize() // direction vector from source to target
                        .rotate90CW()
                        .scale(10); // offset 10 units

                    modelToPaste.sourcePoint.x += offset.x;
                    modelToPaste.sourcePoint.y += offset.y;
                    modelToPaste.targetPoint.x += offset.x;
                    modelToPaste.targetPoint.y += offset.y;
                } else if (modelToPaste.targetPoint) {
                    modelToPaste.targetPoint.x += 30;
                    modelToPaste.targetPoint.y += 30;
                }

                addedConnectors.push(containerElement.connectors.addItem(modelToPaste));
            }
        }

        const addedNodes = data
            .filter(model => (containerElement.name !== "Classic Slide" && model.type === AuthoringElementType.CALLOUT) || containerElement.name === "Classic Slide")
            .map(model => ({ ...model, id: uuid(), isLocked: false }));

        if (addedNodes.length === 0 && addedConnectors.length === 0) {
            return;
        }

        if (addedNodes.length > 0) {
            const groupIdsMapping = {};
            addedNodes.forEach(model => {
                if (model.groupIds) {
                    const mappedGroupIds = [];
                    model.groupIds.forEach(groupId => {
                        if (!groupIdsMapping[groupId]) {
                            groupIdsMapping[groupId] = uuid();
                        }
                        mappedGroupIds.push(groupIdsMapping[groupId]);
                    });
                    model.groupIds = mappedGroupIds;
                }
            });

            if (useContextMenuPosition) {
                // Put pasted elements in the context menu position
                let copiedElementsBounds;
                addedNodes.forEach(model => {
                    const modelBounds = new geom.Rect(model.x, model.y, model.width, model.height);
                    if (!copiedElementsBounds) {
                        copiedElementsBounds = modelBounds;
                    } else {
                        copiedElementsBounds = copiedElementsBounds.union(modelBounds);
                    }
                });

                const newCenterPosition = this.getPositionFromEvent({ pageX: contextMenuPositionX, pageY: contextMenuPositionY });
                const offset = copiedElementsBounds.center.delta(newCenterPosition);
                addedNodes.forEach(model => {
                    model.x += offset.x;
                    model.y += offset.y;
                });
            } else {
                // Offset pasted elements by 50px
                addedNodes.forEach(model => {
                    model.x += 50;
                    model.y += 50;
                });
            }

            containerElement.model.elements.push(...addedNodes);
        }

        this.refreshCanvasAndSaveChanges()
            .then(() => {
                if (addedNodes.length > 0) {
                    const newSelection = this.getElements().filter(element => addedNodes.some(({ id }) => element.model.id === id));
                    this.setSelection(newSelection);
                } else if (addedConnectors.length > 0) {
                    selectionLayerController.setSelectedElements(addedConnectors.map(({ id }) => containerElement.connectors.getItemElementById(id)));
                }
            });
    }

    pasteTextFromClipboard = async text => {
        const { containerElement } = this.props;

        const bounds = new geom.Rect(200, 200, this.canvas.CANVAS_WIDTH - 400, this.canvas.CANVAS_HEIGHT - 400);
        const model = {
            id: uuid(),
            type: AuthoringElementType.SHAPE,
            ...bounds.toXYObject(),
            fitToText: true,
            fill: "none",
            stroke: "none",
            text: {
                blocks: [{
                    id: uuid(),
                    type: AuthoringBlockType.TEXT,
                    textStyle: TextStyleType.BODY,
                    html: sanitizeHtmlText(text)
                }]
            }
        };
        containerElement.model.elements.push(model);
        await this.refreshCanvasAndSaveChanges();
        await this.setSelection([]);
    }

    copyStyles = () => {
        if (this.selection.length > 0) {
            const container = this.selection[0];
            const model = container.element?.model || container.model;
            if (model) {
                let styles = {};
                for (const prop of COPIED_STYLES) {
                    if (prop in model) {
                        styles[prop] = model[prop];
                    }
                }

                // check for special copied data
                if (model.componentType === "TableFrame") {
                    styles.tableStyles = {};
                    for (const prop of TABLE_STYLES) {
                        if (prop in model.element) {
                            styles.tableStyles[prop] = model.element[prop];
                        }
                    }

                    // also, copy the leading column/row style
                    const [leadRow] = model.element.rows || [];
                    const [leadCol] = model.element.cols || [];
                    if (leadCol) {
                        styles.tableStyles.leadColStyle = leadCol.style;
                    }
                    if (leadRow) {
                        styles.tableStyles.leadRowStyle = leadRow.style;
                    }
                }

                clipboardWrite({
                    [ClipboardType.STYLES]: styles,
                });
            }
        }
    }

    pasteStyles = async () => {
        let styles = await clipboardRead([ClipboardType.STYLES]);
        if (styles) {
            for (const container of this.selection) {
                const model = container.element?.model || container.model;
                if (model) {
                    // Only copy over expected properties
                    for (const prop of COPIED_STYLES) {
                        if (prop in styles) {
                            model[prop] = styles[prop];
                        }
                    }

                    // copy table styles, if any
                    if (model.componentType === "TableFrame" && styles.tableStyles) {
                        for (const prop of TABLE_STYLES) {
                            if (prop in styles.tableStyles) {
                                model.element[prop] = styles.tableStyles[prop];
                            }
                        }

                        // set lead styles
                        if (styles.tableStyles.leadColStyle && model.element.cols[0]) {
                            model.element.cols[0].style = styles.tableStyles.leadColStyle;
                        }

                        if (styles.tableStyles.leadRowStyle && model.element.rows[0]) {
                            model.element.rows[0].style = styles.tableStyles.leadRowStyle;
                        }
                    }
                }
            }
            this.refreshCanvasAndSaveChanges();
        }
    }

    bringSelectionToFront = () => {
        const { containerElement } = this.props;

        for (const element of this.selection) {
            containerElement.model.elements.move(containerElement.model.elements.indexOf(element.model), containerElement.model.elements.length - 1);
        }

        this.refreshCanvasAndSaveChanges();
    }

    sendSelectionToBack = () => {
        const { containerElement } = this.props;

        for (const element of this.selection) {
            containerElement.model.elements.move(containerElement.model.elements.indexOf(element.model), 0);
        }

        this.refreshCanvasAndSaveChanges();
    }

    getSelectionGroups = () => {
        const containerGroups = {};
        for (const container of this.selection) {
            if (container.groupId) {
                if (!containerGroups[container.groupId]) {
                    containerGroups[container.groupId] = [container];
                } else {
                    containerGroups[container.groupId].push(container);
                }
            } else {
                containerGroups[uuid()] = [container];
            }
        }

        return Object.entries(containerGroups)
            .map(([groupId, containers]) => ({
                bounds: this.getElementsSelectionBounds(containers),
                containers,
                groupId
            }));
    }

    alignSelection = align => {
        const containerGroups = this.getSelectionGroups();
        const selectionBounds = this.getElementsSelectionBounds(this.selection);

        switch (align) {
            case "align-left":
                for (const { bounds, containers } of containerGroups) {
                    const shift = selectionBounds.left - bounds.left;
                    for (const container of containers) {
                        container.model.x += shift;
                    }
                }
                break;
            case "align-center":
                for (const { bounds, containers } of containerGroups) {
                    const shift = selectionBounds.centerH - bounds.centerH;
                    for (const container of containers) {
                        container.model.x += shift;
                    }
                }
                break;
            case "align-right":
                for (const { bounds, containers } of containerGroups) {
                    const shift = selectionBounds.right - bounds.right;
                    for (const container of containers) {
                        container.model.x += shift;
                    }
                }
                break;
            case "align-top":
                for (const { bounds, containers } of containerGroups) {
                    const shift = selectionBounds.top - bounds.top;
                    for (const container of containers) {
                        container.model.y += shift;
                    }
                }
                break;
            case "align-middle":
                for (const { bounds, containers } of containerGroups) {
                    const shift = selectionBounds.centerV - bounds.centerV;
                    for (const container of containers) {
                        container.model.y += shift;
                    }
                }
                break;
            case "align-bottom":
                for (const { bounds, containers } of containerGroups) {
                    const shift = selectionBounds.bottom - bounds.bottom;
                    for (const container of containers) {
                        container.model.y += shift;
                    }
                }
                break;
        }

        this.refreshCanvasAndSaveChanges();
    }

    distributeSelection = direction => {
        const containerGroups = this.getSelectionGroups();
        const selectionBounds = this.getElementsSelectionBounds(this.selection);

        switch (direction) {
            case "distribute-horizontal":
                const hGap = (selectionBounds.width - _.sumBy(containerGroups, ({ bounds }) => bounds.width)) / (containerGroups.length - 1);
                let x = selectionBounds.left;
                for (const containerGroup of _.sortBy(containerGroups, ({ bounds }) => bounds.left)) {
                    const shift = x - containerGroup.bounds.left;
                    for (const container of containerGroup.containers) {
                        container.model.x += shift;
                    }
                    x += containerGroup.bounds.width + hGap;
                }
                break;
            case "distribute-vertical":
                const vGap = (selectionBounds.height - _.sumBy(containerGroups, ({ bounds }) => bounds.height)) / (containerGroups.length - 1);
                let y = selectionBounds.top;
                for (const containerGroup of _.sortBy(containerGroups, ({ bounds }) => bounds.top)) {
                    const shift = y - containerGroup.bounds.top;
                    for (const container of containerGroup.containers) {
                        container.model.y += shift;
                    }
                    y += containerGroup.bounds.height + vGap;
                }
                break;
        }

        this.refreshCanvasAndSaveChanges();
    }

    duplicateElementModels = selection => {
        const containerModels = [];
        const connectorModels = [];

        for (const element of selection) {
            const model = _.cloneDeep(element.model);
            model.id = uuid();
            containerModels.push(model);

            if (element.childElement?.connectorsFromNode) {
                for (const connector of element.childElement.connectorsFromNode) {
                    if (selection.some(({ model }) => model.id === connector.model.target)) {
                        continue;
                    }
                    const connectorModel = _.cloneDeep(connector.model);
                    connectorModel.id = uuid();
                    connectorModel.source = model.id;
                    connectorModels.push(connectorModel);
                }
            }

            if (element.childElement?.connectorsToNode) {
                for (const connector of element.childElement.connectorsToNode) {
                    if (selection.some(({ model }) => model.id === connector.model.source)) {
                        continue;
                    }
                    const connectorModel = _.cloneDeep(connector.model);
                    connectorModel.id = uuid();
                    connectorModel.target = model.id;
                    connectorModels.push(connectorModel);
                }
            }
        }

        const groupIdsMapping = {};
        containerModels.forEach(model => {
            if (model.groupIds) {
                const mappedGroupIds = [];
                model.groupIds.forEach(groupId => {
                    if (!groupIdsMapping[groupId]) {
                        groupIdsMapping[groupId] = uuid();
                    }
                    mappedGroupIds.push(groupIdsMapping[groupId]);
                });
                model.groupIds = mappedGroupIds;
            }
        });

        return { containerModels, connectorModels };
    }

    duplicateSelection = async () => {
        const { selectedElements } = this.props;
        const { containerElement } = this.props;

        const { containerModels, connectorModels } = this.duplicateElementModels(selectedElements);
        if (this.lastDraggedOffset) {
            for (let model of containerModels) {
                model.x += this.lastDraggedOffset.x;
                model.y += this.lastDraggedOffset.y;
            }
        }
        containerElement.model.elements.push(...containerModels);

        if (connectorModels.length > 0) {
            containerElement.model.connections.items.push(...connectorModels);
        }

        this.hasPendingModelChanges = true;
        this.refreshCanvasAndSaveChanges()
            .then(() => {
                const newSelection = this.getElements().filter(element => containerModels.some(({ id }) => element.model.id === id));
                this.setSelection(newSelection);
            });
    }

    renderElementSelectionOutline = (elementOrGroup, idx) => {
        const { containerElement } = this.props;

        const { type } = elementOrGroup;
        let elementBounds;

        // appears to be a group
        if (elementOrGroup instanceof SelectionGroup) {
            const { elements } = elementOrGroup;
            elementBounds = this.getElementSelectionBounds(elements[0]);
            for (let i = 1; i < elements.length; i++) {
                elementBounds = elementBounds.union(this.getElementSelectionBounds(elements[i]));
            }
        } else {
            // appears to be a single element
            elementBounds = this.getElementSelectionBounds(elementOrGroup);
        }

        if (!elementBounds) {
            return;
        }

        const canvasScale = containerElement.canvas.getScale();

        elementBounds = elementBounds.multiply(canvasScale);

        const outline = {
            fill: "none",
            stroke: themeColors.ui_blue,
            strokeWidth: 1,
            strokeDasharray: "1 1"
        };

        let contents;
        switch (type) {
            case AuthoringElementType.SHAPE:
                contents = elementOrGroup.element.renderShape(elementOrGroup.model.shape, outline, elementBounds.zeroOffset());
                break;

            case AuthoringElementType.PATH:
                const path = new PolyLinePath();
                path.points = elementOrGroup.model.points.map(({ x, y }) => new geom.Point(x, y).multiply(canvasScale));
                contents = <path d={path.toPathData()} style={outline} />;
                break;

            default:
                const { width, height } = elementBounds;
                if (width && height) {
                    contents = <rect width={width} height={height} {...outline} />;
                }

                break;
        }

        if (contents) {
            return (
                <svg
                    key={idx}
                    width="100%" height="100%"
                    style={{
                        position: "absolute",
                        top: elementBounds.top,
                        left: elementBounds.left,
                        overflow: "visible"
                    }}>
                    {contents}
                </svg>
            );
        }
    }

    render() {
        const {
            containerElement,
            selectedElements,
            canvasController,
            selectionLayerController,
            selectAllDisabled = false,
            canvasBounds,
            minimizedControlBar,
        } = this.props;
        let {
            rolloverElements,
            snapLines,
            isMouseDown,
            hasDragged,
            dragCreateModel,
            dragType,
            dragSelectionBounds,
            cursor,
            showContextMenu,
            contextMenuPositionX,
            contextMenuPositionY,
            hideControlBar,
            showSelectionBox
        } = this.state;

        const selection = this.selection;

        const showDragSelection = dragType === DragType.SELECTION && isMouseDown;

        if (this.isEditingElement) {
            showSelectionBox = false;
        }

        const selectionBounds = this.getElementsSelectionBounds(selection);

        // rollup all the resize directions for the selected elements into a single object
        const resizeDirections = selection.reduce((acc, element) => {
            Object.keys(element.childElement?.resizeDirections ?? {}).forEach(key => {
                if (!element.childElement.resizeDirections[key]) {
                    acc[key] = false;
                }
            });
            return acc;
        }, { left: true, right: true, top: true, bottom: true });

        const canAlignAndDistribute = this.getSelectionGroups().length > 1 && !this.selectionHasLockedItems;

        const canvas = containerElement.canvas;
        const canvasScale = canvas.getScale();

        return (
            <Fragment>
                {!hideControlBar && <AuthoringControlBar
                    ref={this.authoringControlBarRef}
                    minimized={minimizedControlBar}
                    canvasBounds={canvasBounds}
                    dragCreate={dragCreateModel}
                    snapToGrid={this.snapToGrid}
                    toggleSnapToGrid={this.toggleSnapToGrid}
                    containerElement={containerElement}
                    selectElement={this.selectElement}
                    setDragCreateModel={this.setDragCreateModel}
                    canGroup={this.canGroupSelection}
                    canUngroup={this.canUngroupSelection}
                    clearSelection={this.clearSelection}
                    refreshCanvasAndSaveChanges={this.refreshCanvasAndSaveChanges}
                    onAction={this.onSelectionAction}
                    selection={selection}
                />}
                <MuiThemeProvider theme={dialogTheme}>
                    <ContainerBox
                        ref={this.containerBoxRef}
                        bounds={this.getContainerBounds()}
                        canvasScale={canvasScale}
                        onContextMenu={this.handleEvent}
                        cursor={cursor}
                    >
                        {/*{gridLines.map((gridLine, idx) => (*/}
                        {/*    <GridLine*/}
                        {/*        key={idx}*/}
                        {/*        canvasScale={containerElement.canvas.canvasScale}*/}
                        {/*        opacity={showGridLines ? 1 : 0}*/}
                        {/*        bounds={gridLine.bounds}*/}
                        {/*        isHilited={gridLine.isHilited}*/}
                        {/*        canvasScale={canvasScale}*/}
                        {/*    />*/}
                        {/*))}*/}
                        {/* Container padded snap lines will de rendered as a frame, see below */}
                        {snapLines.filter(snapLine => !snapLine.isContainerPadded).map((snapLine, idx) => (
                            <SnapLine
                                key={idx}
                                bounds={snapLine.snapLineBounds}
                                canvasScale={canvasScale}
                            />
                        ))}
                        {/* Will be rendering the whole padded frame instead of separate padded snap lines */}
                        {snapLines.some(snapLine => snapLine.isContainerPadded) &&
                            <SnapLine
                                bounds={this.getPaddedContainerBounds()}
                                canvasScale={canvasScale}
                            />
                        }
                        {showDragSelection &&
                            <SelectionBox
                                bounds={dragSelectionBounds.normalize()}
                                canvasScale={canvasScale}
                            />}
                        {showSelectionBox && rolloverElements.map((element, idx) => this.renderElementSelectionOutline(element, idx))}
                        {selection.length > 0 &&
                            <SelectionUI
                                isLocked={this.selectionHasLockedItems}
                                isEditing={this.isEditingElement}
                                canGroup={this.canGroupSelection}
                                canUngroup={this.canUngroupSelection}
                                canAlignAndDistribute={canAlignAndDistribute}
                                allowCopyPasteStyles={this.allowCopyPasteStyles}
                                canCopyStyles={selection.length === 1}
                                bounds={selectionBounds}
                                onResizeStarted={this.onResizeStarted}
                                onRotateStarted={this.onRotateStarted}
                                removeSelectedElements={this.removeSelectedElements}
                                onContextMenuAction={this.onSelectionAction}
                                resizeDirections={resizeDirections}
                                showSelectionBox={showSelectionBox}
                                selection={selection}
                                canvasScale={canvasScale}
                                dragType={dragType}
                            >
                                {selection.length > 0 && !hasDragged &&
                                    getEditorForSelection({
                                        ref: this.editorRef,
                                        key: selection.map(element => element.id).join(""),
                                        isLocked: this.selectionHasLockedItems,
                                        selection,
                                        bounds: selectionBounds.multiply(this.canvas.canvasScale),
                                        allowMultipleBlocks: true,
                                        editingElement: this.editingElement,
                                        refreshCanvas: this.refreshCanvas,
                                        refreshCanvasAndSaveChanges: this.refreshCanvasAndSaveChanges,
                                        refreshElement: this.refreshElement,
                                        isEditing: this.isEditingElement,
                                        startDragCreateModel: this.startDragCreateModel,
                                        startEditing: () => this.handleEditComponentElement(selection[0]),
                                        stopEditing: () => this.setSelection([selection]),
                                        saveChanges: this.saveChanges,
                                        ungroupSelection: this.ungroupSelection,
                                        calcSnap: this.calcSnap,
                                        snapToGrid: this.snapToGrid,
                                        getSnapToGridOffset: this.getSnapToGridOffset,
                                        getSnapLines: this.getSnapLines,
                                        getElements: this.getElements,
                                        containerElement,
                                        drawSnapLines: snapLines => this.setState({ snapLines }),
                                        showGridLines: () => this.setState({ showGridLines: true }),
                                        hideGridLines: () => this.setState({ showGridLines: false }),
                                        setShowSelectionBox: showSelectionBox => this.setState({ showSelectionBox }),
                                        showSelectionBox,
                                        selectionElement: selectedElements[0],
                                        canvasController,
                                        selectionLayerController
                                    })
                                }
                            </SelectionUI>
                        }

                        {(selection.length > 0 && containerElement.allowSelectionContextMenu) &&
                            <SelectionContextMenu
                                open={showContextMenu}
                                selection={selection}
                                contextMenuPosition={{ x: contextMenuPositionX, y: contextMenuPositionY }}
                                isLocked={this.selectionHasLockedItems}
                                allowDragMove={this.allowDragMove}
                                canGroup={this.canGroupSelection}
                                canUngroup={this.canUngroupSelection}
                                canAlignAndDistribute={canAlignAndDistribute}
                                allowCopyPasteStyles={this.allowCopyPasteStyles}
                                canCopyStyles={selection.length === 1}
                                onContextMenuAction={this.onSelectionAction}
                                setContentMenuItemSelected={this.setContentMenuItemSelected}
                                onClose={() => {
                                    this.setState({ showContextMenu: false });
                                }}
                                elementsUnderMouse={selection}
                                onSelectElement={this.selectElement}
                            />
                        }

                        {selection.length == 0 && !selectAllDisabled && containerElement.allowContextMenu &&
                            <Menu
                                open={showContextMenu}
                                anchorReference="anchorPosition"
                                anchorPosition={{ left: contextMenuPositionX, top: contextMenuPositionY }}
                                transformOrigin={{ vertical: "top", horizontal: "left" }}
                                onClose={() => this.setState({ showContextMenu: false })}
                            >
                                <MenuItem onMouseDown={() => this.onSelectionAction("select-all")}>
                                    <SelectAll />Select All
                                </MenuItem>
                            </Menu>
                        }
                    </ContainerBox>
                </MuiThemeProvider>
            </Fragment>
        );
    }
}

class SelectionGroup {
    constructor(elements) {
        this.elements = elements;
    }

    get groupId() {
        return this.elements[0]?.groupIds?.[0];
    }
}
