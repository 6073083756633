import React, { useEffect, useRef } from "react";

import { numeral } from "js/vendor";
import * as geom from "js/core/utilities/geom";
import { ShowDialog } from "js/react/components/Dialogs/BaseDialog";
import EditTextDialog from "js/react/components/Dialogs/EditTextDialog";
import { FormatType } from "common/constants";
import { formatter } from "js/core/utilities/formatter";

export function ValueLabelSelection({ element, selectionLayerController }) {
    useEffect(() => {
        const selectionBounds = element.selectionBounds;
        const screenBounds = element.getScreenBounds().offset(0, -selectionBounds.height / 2);
        const targetPt = new geom.Point(screenBounds.centerH, screenBounds.centerV);

        const valueOriginal = element.parentElement.model.value;
        const valueScaled = element.parentElement.valueScaled;

        ShowDialog(EditTextDialog, {
            targetPt,
            target: element,
            minWidth: 150,
            value: valueScaled ?? valueOriginal,
            onClose: () => {
                selectionLayerController.setSelectedElements([element.parentElement]);
            },
            callback: value => {
                const { format, formatOptions } = formatter.detectFormatFromString(value);

                // To make sure if we pass a percentage value, we convert it to a string first
                let number = numeral._.stringToNumber(value);
                if (!numeral.validateEx(number)) {
                    return;
                }

                number = numeral(number).value();
                if (valueScaled !== undefined && valueOriginal !== valueScaled) {
                    number = number * (valueOriginal / valueScaled);
                }

                if (Math.abs(number) > 999999999999) {
                    number = number > 0 ? 999999999999 : -9999999999999;
                }

                // We manage the format and formatOptions in the root element
                // so we need to update the root element instead of the current element
                const rootElement = element.getRootElement();
                rootElement.model.formatOptions = formatOptions;

                // if it's currency we should disregard it, and if users want to change it to a number,
                //  they should do it in from the PP itself
                if (format === "currency" || format === "percent") {
                    rootElement.model.format = format;
                }

                element.parentElement.model.value = number;
                element.parentElement.canvas.updateCanvasModel(false).then(() => {
                    selectionLayerController.setSelectedElements([element.parentElement]);
                });
            },
        });
    }, []);

    return null;
}

// TODO: We may want to refactor this to use the same component as ValueLabelSelection
export function ValuePictorialChartLabelSelection({ element, selectionLayerController }) {
    useEffect(() => {
        const selectionBounds = element.selectionBounds;
        const screenBounds = element.getScreenBounds().offset(0, -selectionBounds.height / 2);
        const targetPt = new geom.Point(screenBounds.centerH, screenBounds.centerV);

        const valueOriginal = element.parentElement.model.value;
        const valueScaled = element.parentElement.valueScaled;

        const value = element.parentElement.format === FormatType.PERCENT
            ? (valueScaled ?? valueOriginal) : element.parentElement.model.labelValue;

        ShowDialog(EditTextDialog, {
            targetPt,
            target: element,
            minWidth: 150,
            value,
            onClose: () => {
                selectionLayerController.setSelectedElements([element.parentElement]);
            },
            callback: value => {
                const { format, formatOptions } = formatter.detectFormatFromString(value);

                // To make sure if we pass a percentage value, we convert it to a string first
                let number = numeral._.stringToNumber(value);
                if (!numeral.validateEx(number)) {
                    return;
                }

                if (element.parentElement.format === FormatType.PERCENT) {
                    number = numeral(number).value();
                    if (valueScaled !== undefined && valueOriginal !== valueScaled) {
                        number = number * (valueOriginal / valueScaled);
                    }

                    if (Math.abs(number) > 999999999999) {
                        number = number > 0 ? 999999999999 : -9999999999999;
                    }

                    element.parentElement.model.value = number;
                } else {
                    element.parentElement.model.labelValue = numeral(number).value();
                }

                // if it's currency we should disregard it, and if users want to change it to a number,
                //  they should do it in from the PP itself
                if (format === "percent") {
                    element.model.format = format;
                }

                // Making sure if we pass currency we do not override the formatOptions,
                // It shouldn't happen but just in case
                if (format !== "currency") {
                    element.model.formatOptions = formatOptions;
                }

                element.parentElement.canvas.updateCanvasModel(false).then(() => {
                    selectionLayerController.setSelectedElements([element.parentElement]);
                });
            },
        });
    }, []);

    return null;
}

