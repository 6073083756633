import React, { Component, Fragment } from "react";
import { Button, Icon, MenuItem } from "@material-ui/core";
import styled from "styled-components";
import getLocalStorage from "js/core/utilities/localStorage";
import Spinner from "js/react/components/Spinner";
import { delay } from "js/core/utilities/promiseHelper";
import { $, _ } from "js/vendor";
import { uuid } from "js/core/utilities/utilities";
import { uploadFileAndCreateTask } from "js/core/services/tasks";
import { TaskState, TaskType } from "common/constants";
import { ShowErrorDialog, ShowWarningDialog } from "js/react/components/Dialogs/BaseDialog";
import { Key } from "js/core/utilities/keys";
import { PopupMenu } from "js/react/components/PopupMenu";
import { NestedMenuItem } from "js/react/components/NestedMenuItem";
import getLogger, { LogGroup } from "js/core/logger";

import { ControlBar, ControlBarGroup } from "../../Editor/ElementControlBars/Components/ControlBar";

const logger = getLogger(LogGroup.ELEMENTS);

const Container = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
`;

const VideoFrame = styled.div`
  background: black;
  border-radius: 50%;
  pointer-events: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  transition: opacity 500ms;
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 1;

  video {
    height: 100%;
  }

  &:hover {
    .btn {
      opacity: 1;
    }
  }

  .btn {
    display: flex;
    position: absolute;
    align-items: center;
    justify-content: center;
    width: 70px;
    height: 70px;
    border-radius: 50%;
    background: rgba(0, 0, 0, .5);
    color: white;
    opacity: 0;

    .MuiIcon-root {
      font-size: 50px;
    }

    &.rewind-icon {
      width: 30px;
      height: 30px;
      left: 75%;

      .MuiIcon-root {
        font-size: 20px;
      }
    }
  }
`;

const ElementFrame = styled.div`
  position: absolute;
`;

const MAX_RECORDING_TIME_MINUTES = 10;

export class VideoRecorder extends Component {
    constructor(props) {
        super(props);

        let elementBounds = props.element.DOMNode.getBoundingClientRect();
        this.elementTransform = {
            left: elementBounds.x,
            top: elementBounds.y,
            width: elementBounds.width,
            height: elementBounds.height
        };

        this.videoFrameRef = React.createRef();
        this.videoRef = React.createRef();
        this.previewRef = React.createRef();

        let localStorage = getLocalStorage();
        let videoInputDeviceId = localStorage.getItem("videoInputDeviceId");
        let audioInputDeviceId = localStorage.getItem("audioInputDeviceId");

        this.state = {
            isReady: false,
            isRecording: false,
            previewTake: null,
            videoInputs: [],
            audioInputs: [],
            audioInputDeviceId,
            videoInputDeviceId,
        };
    }

    handleClose = () => {
        this.props.onClose();
    }

    turnOffInputs = () => {
        // you can get the MediaStreamTracks with getTracks():
        if (this.videoRef.current) {
            const tracks = this.videoRef.current.srcObject?.getTracks();
            tracks?.forEach(track => track.stop());
        }
    }

    componentDidMount() {
        const { selectionLayerController } = this.props;

        selectionLayerController.setFreezeSelection(true);

        this.setupRecording();
        document.addEventListener("keydown", this.handleKeyDown);

        window.navigator.mediaDevices.enumerateDevices().then(devices => {
            let audioInputs = [];
            let videoInputs = [];

            for (const { kind, deviceId, label } of devices) {
                if (!label.contains("(Virtual)")) {
                    if (kind === "audioinput") {
                        audioInputs.push({ label, deviceId });
                    } else if (kind === "videoinput") {
                        videoInputs.push({ label, deviceId });
                    }
                }
            }

            this.setState({ audioInputs, videoInputs });
        });
    }

    componentWillUnmount() {
        const { selectionLayerController } = this.props;

        selectionLayerController.setFreezeSelection(false);

        this.turnOffInputs();
        this.videoRef.current = null;
        document.removeEventListener("keydown", this.handleKeyDown);
        this.previewRef?.current?.removeEventListener("ended", this.disablePlaying);
        this.canvas?.remove();
    }

    permissionsDialog = _.debounce(() => {
        ShowWarningDialog({
            title: "Beautiful.ai needs permission to access your system microphone or camera",
            message: <Fragment>
                Please enable access in your browser settings to continue.
            </Fragment>
        });
    }, 100)

    setupRecording = async () => {
        const { videoInputDeviceId, audioInputDeviceId, hasTake } = this.state;

        if (hasTake) return;

        const video = this.videoRef.current;

        let constraints = {};

        if (videoInputDeviceId) {
            constraints.video = {
                deviceId: videoInputDeviceId
            };
        } else {
            constraints.video = true;
        }
        if (audioInputDeviceId) {
            constraints.audio = {
                deviceId: audioInputDeviceId
            };
        } else {
            constraints.audio = true;
        }

        try {
            const stream = await navigator.mediaDevices.getUserMedia(constraints);

            await new Promise(resolve => {
                video.srcObject = stream;
                video.addEventListener("loadedmetadata", () => {
                    this.canvas = document.createElement("canvas");
                    this.canvas.width = video.videoWidth;
                    this.canvas.height = video.videoHeight;

                    // For capture stream on firefox, we need to call ctx before we call the capture stream
                    // https://bugzilla.mozilla.org/show_bug.cgi?id=1572422
                    const ctx = this.canvas.getContext("2d");
                    this.stream = this.canvas.captureStream();
                    this.stream.addTrack(stream.getAudioTracks()[0]);

                    video.addEventListener("play", () => {
                        resolve();
                        const step = () => {
                            if (this.videoRef.current) {
                                ctx.drawImage(video, 0, 0, this.canvas.width, this.canvas.height);
                                window.requestAnimationFrame(step);
                            }
                        };
                        window.requestAnimationFrame(step);
                    });
                    video.play();
                });
            });
            this.setState({ isReady: true });
        } catch (err) {
            this.permissionsDialog();
            this.handleClose();
        }
    }

    handleKeyDown = event => {
        switch (event.which) {
            case Key.ESCAPE:
                if (this.state.isRecording) {
                    this.handleStopRecording();
                } else {
                    this.handleClose();
                }
                break;
        }
    }

    formatDuration(durationSeconds) {
        let seconds = durationSeconds;
        const hours = Math.floor(seconds / (60 * 60));
        seconds -= hours * 60 * 60;
        const minutes = Math.floor(seconds / 60);
        seconds -= minutes * 60;
        return `${_.padStart(minutes, 2, "0")}:${_.padStart(seconds.toFixed(3), 6, "0")}`;
    }

    handleRecordTake = async () => {
        let $frame = $(this.videoFrameRef.current);

        let $countdown = $frame.addEl($.div("video-record-countdown", "3"));
        await delay(1000);
        $countdown.remove();
        $countdown = $frame.addEl($.div("video-record-countdown", "2"));
        await delay(1000);
        $countdown.remove();
        $countdown = $frame.addEl($.div("video-record-countdown", "1"));
        await delay(1000);
        $countdown.remove();

        this.recorder = new MediaRecorder(this.stream);

        this.recorder.onstart = () => {
            this.setState({
                isRecording: true,
            });

            this.recordingDurationSeconds = 0;
            this.recordingTimer = setInterval(() => {
                this.recordingDurationSeconds += 0.01;
                this.setState({ recordDuration: this.formatDuration(this.recordingDurationSeconds) });

                if (this.recordingDurationSeconds > MAX_RECORDING_TIME_MINUTES * 60) {
                    this.handleStopRecording();
                    ShowErrorDialog({
                        title: `Video Bubble recording is limited to ${MAX_RECORDING_TIME_MINUTES} minutes.`
                    });
                }
            }, 10);
        };

        this.recorder.onstop = () => {
            this.setState({
                isRecording: false
            });

            clearInterval(this.recordingTimer);
        };
        this.recorder.onerror = event => {
            logger.warn("[VideoRecorder] this.recorder.onerror()", { name: event.name });

            this.setState({
                isRecording: false
            });
            clearInterval(this.recordingTimer);
        };

        this.recorder.start();
    }

    handleStopRecording = () => {
        if (this.recorder.state === "recording") {
            this.recorder.ondataavailable = event => {
                this.handlePreviewTake(event.data);
            };

            this.recorder.stop();
        }
        //  Turn off audio and video
        this.turnOffInputs();
    }

    handlePreviewTake = blob => {
        this.setState({
            hasTake: true,
            blob,
            previewTake: URL.createObjectURL(blob),
            isPlaying: true
        });

        this.previewRef.current.addEventListener("ended", this.disablePlaying);
    }

    disablePlaying = e => {
        this.setState({
            isPlaying: false
        });
    }

    handleSaveRecording = async () => {
        let { element } = this.props;
        let { blob } = this.state;

        this.handlePause();
        this.setState({
            isSaving: true
        }, async () => {
            const file = new File([blob], "recorded");
            uploadFileAndCreateTask(file, TaskType.VIDEO_UPLOAD, async task => {
                switch (task.state) {
                    case TaskState.ERROR:
                        ShowErrorDialog({
                            title: "We're sorry but an error occurred while saving the video file"
                        });
                        return;
                    case TaskState.FINISHED:
                        element.model.videoAssetId = task.videoAssetId;
                        element.asset = null;
                        await element.canvas.updateCanvasModel();

                        this.handleClose();
                }
            }, { suppressUserAsset: true }, uuid());
        });
    }

    handleDiscardRecording = () => {
        this.setState({
            hasTake: false,
            blob: null,
            previewTake: null
        }, async () => {
            await this.setupRecording();
        });
    }

    handlePlay = () => {
        this.setState({
            isPlaying: true
        });
        this.previewRef.current.play();
    }

    handlePause = () => {
        this.setState({
            isPlaying: false
        });
        this.previewRef.current.pause();
    }

    handleRewind = () => {
        this.previewRef.current.currentTime = 0;
        this.handlePlay();
    }

    handleSetVideoInputDevice = deviceId => {
        getLocalStorage().setItem("videoInputDeviceId", deviceId);
        this.setState({ videoInputDeviceId: deviceId }, this.setupRecording);
    }

    handleSetAudioInputDevice = deviceId => {
        getLocalStorage().setItem("audioInputDeviceId", deviceId);
        this.setState({ audioInputDeviceId: deviceId }, this.setupRecording);
    }

    render() {
        const { isReady, isRecording, isPlaying, isSaving, hasTake, previewTake, audioInputs, videoInputs, audioInputDeviceId, videoInputDeviceId, recordDuration } = this.state;

        return (
            <Container>
                <ElementFrame style={{ ...this.elementTransform }}>
                    <VideoFrame ref={this.videoFrameRef}>
                        {!hasTake &&
                            <video ref={this.videoRef} muted />
                        }
                        {hasTake &&
                            <video ref={this.previewRef} autoPlay src={previewTake} />
                        }

                        {hasTake && !isPlaying &&
                            <div className="btn play-icon" onClick={this.handlePlay}><Icon>play_arrow</Icon></div>
                        }
                        {hasTake && isPlaying &&
                            <div className="btn pause-icon" onClick={this.handlePause}><Icon>pause</Icon></div>
                        }
                        {hasTake &&
                            <div className="btn rewind-icon" onClick={this.handleRewind}><Icon>replay</Icon></div>
                        }

                        {(!isReady || isSaving) && <Spinner />}
                    </VideoFrame>

                    {isReady && !isSaving &&
                        <ControlBar zIndex={1}>
                            {!isRecording && !hasTake &&
                                <ControlBarGroup color="#333">
                                    <Button onClick={this.handleRecordTake}>
                                        <Icon>video_call</Icon>
                                        Record Take
                                    </Button>
                                    <PopupMenu icon="settings" childrenAreMenuItems>
                                        <NestedMenuItem label="Camera">
                                            {videoInputs.map(({ deviceId, label }, index) => (<MenuItem
                                                key={index}
                                                selected={deviceId == videoInputDeviceId}
                                                onClick={() => this.handleSetVideoInputDevice(deviceId)}
                                            >
                                                {label}
                                            </MenuItem>))}
                                        </NestedMenuItem>
                                        <NestedMenuItem label="Microphone">
                                            {audioInputs.map(({ deviceId, label }, index) => (<MenuItem
                                                key={index}
                                                selected={deviceId == audioInputDeviceId}
                                                onClick={() => this.handleSetAudioInputDevice(deviceId)}
                                            >
                                                {label}
                                            </MenuItem>))}
                                        </NestedMenuItem>
                                    </PopupMenu>
                                </ControlBarGroup>
                            }
                            {isRecording &&
                                <ControlBarGroup color="#333">
                                    <Button onClick={this.handleStopRecording}>
                                        <Icon>stop</Icon>
                                        Stop Recording (ESC)
                                    </Button>
                                </ControlBarGroup>
                            }
                            {isRecording &&
                                <ControlBarGroup>
                                    <span style={{ padding: "0 10px" }}>{recordDuration}</span>
                                </ControlBarGroup>
                            }

                            {!isRecording && hasTake &&
                                <ControlBarGroup color="#009900">
                                    <Button onClick={this.handleSaveRecording}>
                                        <Icon>save</Icon>
                                        Save Recording
                                    </Button>
                                </ControlBarGroup>
                            }
                            {!isRecording && hasTake &&
                                <ControlBarGroup color="#AA0000">
                                    <Button onClick={this.handleDiscardRecording}>
                                        <Icon>delete</Icon>
                                        Discard
                                    </Button>
                                </ControlBarGroup>
                            }

                            {!isRecording && !hasTake &&
                                <ControlBarGroup>
                                    <Button onClick={this.handleClose}>Close</Button>
                                </ControlBarGroup>
                            }
                        </ControlBar>
                    }
                </ElementFrame>
            </Container>
        );
    }
}
