import React from "reactn";
import {
    CanvasEventType,
    TrayType,
    BackgroundStyleType,
    ElementTextBlockPositionType,
    DEFAULT_ANIMATION_DURATION_MS,
    AnimationsArrangementType,
    AnimationsState, ForeColorType
} from "common/constants";
import { $, _, Backbone } from "js/vendor";
import { ds } from "js/core/models/dataService";
import getLogger, { LogGroup } from "js/core/logger";
import { app } from "js/namespaces";
import * as geom from "js/core/utilities/geom";
import Profiler from "js/core/profiler";
import { audioContext, getAudioBufferFromUrl } from "js/core/utilities/audioUtilities";
import { getExperiments } from "js/core/services/experiments";
import { trackActivity } from "js/core/utilities/utilities";
import { getStaticUrl, isRenderer } from "js/config";
import { isSafari } from "js/core/utilities/browser";
import { Convert } from "js/core/utilities/geom";
import { ShowMessageDialog, ShowErrorDialog, ShowConvertedToClassicDialog } from "js/react/components/Dialogs/BaseDialog";
import { createHash } from "js/core/utilities/utilities";
import { Key } from "js/core/utilities/keys";
import { slides as slidesApi } from "apis/callables";
import { getSlideDataStateFromAttributes } from "js/core/models/slide";
import { MetricName } from "common/interfaces/IMetric";

import { ConvertSlideToAuthoring } from "../Editor/ElementSelections/Authoring/Editors/ConvertToAuthoring";

import { SlideCanvasLayouter } from "./slideCanvasLayouter";

import { AuthoringCanvas } from "../elements/elements/AuthoringCanvas";
import { BaseElement } from "../elements/base/BaseElement";
import { CanvasExporter } from "./exporter/CanvasExporter";
import { slideTemplates, variations, chartUtils, tableUtils } from "../slideTemplates/slideTemplates";
import { clearAuthoringBlockPropsCache } from "../elements/base/Text/TextLayoutHelpers";

// Will be re exported via canvas
import elementManager from "../elements/elementManager";
import { AuthoringShapeElement } from "../elements/elements/authoring/AuthoringShape";
import { BigNumbers } from "../elements/elements/BigNumbers";

import "css/canvas.scss";
import "css/controls.scss";
import presentationEditorController from "js/editor/PresentationEditor/PresentationEditorController";
import { htmlToText } from "html-to-text";

const VERSION = 10;

const logger = getLogger(LogGroup.CANVAS);

const profiler = Profiler.create({
    name: "Canvas",
    type: Profiler.AVG_LOAD_TIME,
    warnTime: 1500
});

const SlideCanvas = Backbone.View.extend({
    selectedElement: null,
    isSelectable: true,
    isRendered: false,
    className: "slide_canvas",
    bundleVersion: VERSION,
    migrationVersion: 10.03,

    initialize: function(options) {
        this.options = options;
        this.isNew = !!options.isNew;

        this.$el.attr("id", options.dataModel.id);

        this.dataModel = options.dataModel;
        this.slide = this.dataModel;

        this.CANVAS_WIDTH = this.options.canvasWidth || 1280;
        this.CANVAS_HEIGHT = this.options.canvasHeight || 720;

        this.playerView = options.playerView;

        this.generateImages = options.generateImages ?? true;

        this.isPlayback = options.isPlayback;
        this.showTextPlaceholders = options.showTextPlaceholders;
        this.isEditable = false;

        this.shouldHandleAnimationFrames = false;
        this.lastAnimationFrameHandledAt = null;

        this.errorStateOptions = options.errorStateOptions || { showErrorMessage: true };

        // Exports for consumers
        this.elementManager = elementManager;
        this.AuthoringShapeElement = AuthoringShapeElement;
        this.BigNumbers = BigNumbers;
        this.slideTemplates = slideTemplates;
        this.chartUtils = chartUtils;
        this.tableUtils = tableUtils;
        this.variations = variations;
        //

        // Dummy slideTempalteso ui will work before slide is rendered
        this.slideTemplate = new slideTemplates["slidePlaceholder"]();

        // Theme styles
        this.styleSheet = null;
        this.decorationStyles = null;
        //

        this.canvasController = null;
        this.selectionLayerController = null;

        this.ignoreSpellcheck = app.user?.get("ignoreSpellcheck") ?? false;

        if (
            !isRenderer &&
            !window.isPlayer &&
            !window.isOfflinePlayer
        ) {
            this.listenTo(app.user, "change:ignoreSpellcheck", () => {
                this.ignoreSpellcheck = app.user.get("ignoreSpellcheck");
                if (this.isRendered && !this.layouter.isGenerating) {
                    this.refreshRender();
                }
            });
        }

        this.listenTo(this.dataModel, "destroy", () => {
            this.cancelRender();
        });

        this.listenTo(this.dataModel, "updateDataState", (model, options) => {
            (async () => {
                const prevTemplateId = this.model ? this.model.template_id : null;
                this.model = model.dataState;

                if (!this.isRendered) {
                    this.trigger(CanvasEventType.DATA_STATE_UPDATED);
                    return;
                }

                // Refresh the canvas
                if (this.model.template_id !== prevTemplateId) {
                    await this.updateTemplate(this.dataModel.dataState.template_id);
                }

                await this.refreshCanvasAutoRevert();

                this.trigger(CanvasEventType.DATA_STATE_UPDATED);
            })();
        });

        this.throttledRefreshCanvas = _.throttle(() => this.refreshCanvas(), 16);

        this.showSpinnerTimeout = null;

        this.isAnimating = false;

        this.elementsAwaitingRefresh = new Set();
        this.refreshElementsTimeout = null;
    },

    getSlideIdeas: function() {
        const popupIsOpen = presentationEditorController.getPopupOpenState();
        let ideasForCurrentSlide = popupIsOpen ? null : this.getPrimaryElement().getSlideIdeas();
        if (ideasForCurrentSlide) {
            return ideasForCurrentSlide;
        } else if (this.slideTemplate.variations) {
            return this.variations[this.slideTemplate.variations];
        } else {
            return [];
        }
    },

    async reportState(stateUpdates) {
        await this.canvasController?.reportCanvasState(stateUpdates);
    },

    getTemplate() {
        let template = slideTemplates[this.dataModel.get("template_id")];
        if (!template) {
            template = slideTemplates["slidePlaceholder"];
        }
        return template;
    },

    canMigrate: function() {
        return this.slideTemplate.canMigrate !== false;
    },

    getExporter: function(pptx) {
        return new CanvasExporter(pptx, this);
    },

    getSlideIndex() {
        // Slide index may be explicitly passed as a parameter
        if (typeof this.options.slideIndex === "number") {
            return this.options.slideIndex;
        }

        if (!this.slide.presentation) {
            return 0;
        }

        const playableSlideModels = this.slide.presentation.getPlayableSlideModels();
        let slideIndex = 0;
        for (slideIndex; slideIndex < playableSlideModels.length; slideIndex++) {
            if (playableSlideModels[slideIndex].get("id") !== this.dataModel.id) {
                continue;
            }
            break;
        }
        return slideIndex;
    },

    cancelRender: function() {
        this.renderSlidePromise = null;
        this.isReady = false;
        this.isRendered = false;
        this.hideSpinner();
    },

    updateTemplate: async function(templateId) {
        this.$watermark = null;
        this.$el.removeClass("slide_placeholder_canvas");

        if (this.layouter) {
            this.layouter.destroy();
            this.layouter = null;
        }

        // make sure we reset this when the template changes
        this.clearPlaybackStages();

        // find slide template for the slide associated with this canvas
        const template = _.find(slideTemplates, { id: templateId });
        if (template) {
            this.slideTemplate = new template();
        } else {
            logger.info(`No template found for templateId: ${templateId}. Using slidePlaceholder template instead`, { slideId: this.dataModel.id, templateId });
            this.slideTemplate = new slideTemplates["slidePlaceholder"]();
        }

        // check template version
        if (this.slideTemplate.migrate) {
            try {
                this.slideTemplate.migrate(this.dataModel.get("template_version") || 0, this.dataModel);
            } catch (err) {
                logger.error(err, "updateTemplate() failed to migrate template", { slideId: this.dataModel.id });
            }
        }

        // create a new BaseCanvasLayouter for the slide
        this.layouter = this.getLayouter();

        await this.reportState({ isRendered: false });
    },

    getLayouter: function() {
        return new SlideCanvasLayouter(this, this.slideTemplate);
    },

    getTheme: function() {
        return this.options.theme ?? app.currentTheme;
    },

    getBackgroundColor: function() {
        if (this.model.layout.trayLayout == TrayType.BACKGROUND) {
            return BackgroundStyleType.IMAGE;
        } else {
            return this.layouter.canvasElement.background.canvasBackgroundColor;
        }
    },

    getSlideColor: function() {
        let slideColor = this.model.layout.slideColor || this.getTheme().get("defaultSlideColor") || "theme";
        if (slideColor == "neutral") {
            // convert legacy neutral color to primary
            slideColor = ForeColorType.PRIMARY;
        }
        return slideColor;
    },

    getScale: function() {
        return this.canvasScale ?? 1;
    },

    getMaxScale: function() {
        return this.maxCanvasScale ?? this.canvasScale;
    },

    getClosestPrimaryElementForBlockElement: function(forElement) {
        const elementPath = forElement.getElementPath();
        return elementPath.find(element => element.isBlockElement);
    },

    getElementByUniquePath: function(uniquePath) {
        if (uniquePath.startsWith("/")) {
            uniquePath = uniquePath.substr(1);
        }
        const ids = uniquePath.split("/");

        let element = this.layouter.canvasElement.elements[ids[0]];
        for (let i = 1; i < ids.length; i++) {
            element = element.getChild(ids[i]);
        }
        return element;
    },

    getElementsByType: function(elementType, useIsInstanceOf = false) {
        const elements = [];
        const getByType = (element, type) => {
            if (!element) {
                return;
            }

            if (element.type === type || (useIsInstanceOf && element.isInstanceOf(type))) {
                elements.push(element);
            }

            Object.values(element.elements || {})
                .forEach(child => getByType(child, type));
        };
        getByType(this.layouter.canvasElement, elementType);
        return elements;
    },

    getElements: function() {
        if (!this.layouter?.canvasElement) {
            return [];
        }

        const elements = [];
        const getElements = element => {
            if (!element) {
                return;
            }

            elements.push(element);

            Object.values(element.elements || {})
                .forEach(child => getElements(child));
        };
        getElements(this.layouter.canvasElement);
        return elements;
    },

    hasRenderedOnTopElements: function() {
        return this.getElements().some(element => element.renderOnTop);
    },

    markStylesAsDirty: function() {
        if (this.layouter.canvasElement) {
            this.layouter.canvasElement.markStylesAsDirty();
        }
    },

    getStructuredSlideText: function(targetBlockId, targetPlaceholderText) {
        let slideText = "<Slide>";

        for (let textElement of this.getElementsByType("TextElement", true)) {
            let nodeType;
            if (textElement.parentElement.isInstanceOf("CollectionItemElement")) {
                nodeType = "Item";
            } else if (textElement.getRootElement().isInstanceOf("BottomTray")) {
                nodeType = "Summary";
            } else {
                nodeType = textElement.getRootElement().type;
            }

            slideText += `<${nodeType}>`;

            for (let block of textElement.textModel?.blocks ?? []) {
                let textStyle = block.textStyle;
                if (block.textStyle == "bulleted") {
                    if (block.indent == 0) {
                        textStyle = "bullet";
                    } else {
                        textStyle = "subbullet";
                    }
                } else if (block.textStyle == "_use_styles_") {
                    textStyle = "text";
                }

                let text;
                if (block.id == targetBlockId) {
                    text = targetPlaceholderText;
                } else {
                    text = htmlToText(block.html, {
                        wordwrap: false
                    });
                }

                if (text !== "") {
                    slideText += `<${textStyle}>${text}</${textStyle}>`;
                }
            }
            slideText += `</${nodeType}>`;
        }

        slideText += "</Slide>";

        return slideText;
    },

    //-----------------------------------------------------------------------------------------------------------------------
    // Render and Layout
    //-----------------------------------------------------------------------------------------------------------------------
    clearAuthoringBlockPropsCache: function() {
        clearAuthoringBlockPropsCache();
    },

    render: function() {
        this.$el.empty();
        this.$el.css({
            width: this.CANVAS_WIDTH,
            height: this.CANVAS_HEIGHT,
        });
        this.renderSlidePromise = null;

        if (!this.isPlayback) {
            this.$clickShield = this.$el.addEl($.div("slide_click_shield"));
        }

        return this;
    },

    _migrate_5() {
        // these templates had defaultTrayLayout in slide_template but never saved in model
        if (this.model.template_id === "bullets_image" && !this.model.layout.trayLayout) {
            this.model.layout.trayLayout = "left_inline_half";
        }
        if (this.model.template_id === "long_bio" && !this.model.layout.trayLayout) {
            this.model.layout.trayLayout = "left_tray_half";
        }
        if (this.model.template_id === "title" && this.model.layout.trayLayout) {
            this.model.oldTrayLayout = {
                layout: this.model.layout.trayLayout,
                tray: _.clone(this.model.elements.tray)
            };
            this.model.layout.trayLayout = TrayType.NONE;
        }
        if ((this.model.template_id === "contactus" || this.model.template_id === "business_card")) {
            this.model.layout.trayLayout = this.model.layout.trayLayout || "left_half_tray";
            this.model.oldTrayLayout = {
                layout: this.model.layout.trayLayout,
                tray: _.clone(this.model.elements.tray)
            };
            this.model.layout.trayLayout = TrayType.NONE;
            this.model.elements.tray = null;
        }

        // migrate trays
        switch (this.model.layout.trayLayout) {
            case "bottom_tray":
            case "top_tray":
            case "top_tray_half":
                // top and bottom tray are deprecated
                this.model.layout.trayLayout = TrayType.NONE;
                break;
            // half trays migrate to tray with trayWidth set to half canvas_width
            case "left_tray_half":
                this.model.layout.trayLayout = TrayType.LEFT_TRAY;
                this.model.elements.tray && (this.model.elements.tray.trayWidth = this.CANVAS_WIDTH / 2);
                break;
            case "right_tray_half":
                this.model.layout.trayLayout = TrayType.RIGHT_TRAY;
                this.model.elements.tray && (this.model.elements.tray.trayWidth = this.CANVAS_WIDTH / 2);
                break;
            case "right_inline_half":
                this.model.layout.trayLayout = TrayType.RIGHT_INLINE;
                this.model.elements.tray && (this.model.elements.tray.trayWidth = 590);
                break;
            case "left_inline_half":
                this.model.layout.trayLayout = TrayType.LEFT_INLINE;
                this.model.elements.tray && (this.model.elements.tray.trayWidth = 590);
                break;
            // other trays need to have their trayWidth set to their preset widths from old version
            case TrayType.LEFT_TRAY:
            case TrayType.RIGHT_TRAY:
                this.model.elements.tray && (this.model.elements.tray.trayWidth = 333);
                break;
            case TrayType.LEFT_INLINE:
            case TrayType.RIGHT_INLINE:
                this.model.elements.tray && (this.model.elements.tray.trayWidth = 377);
                break;
        }

        // show gutter should be off for all trays
        this.model.elements.tray && (this.model.elements.tray.showGutter = false);

        // migrate header
        if (this.model.layout.showHeader == true) {
            this.model.layout.headerPosition = "top";
        } else {
            if (this.model.layout.headerPosition != "top" || this.model.layout.showHeader != undefined) {
                this.model.layout.headerPosition = "none";
            }
        }

        // migrate old elementTextBlockPosition
        if (this.model.layout.elementTextBlockPosition == "above") {
            this.model.layout.elementTextBlockPosition = ElementTextBlockPositionType.NONE;
        }
        if (this.model.layout.elementTextBlockPosition == "below") {
            this.model.layout.elementTextBlockPosition = ElementTextBlockPositionType.INLINE;
        }
        if (this.model.layout.showElementTitle) {
            this.model.layout.showElementTitle = null;
        }
    },

    loadStyles: async function(reloadCache = false) {
        const { styleSheet, decorationStyles } = await this.getTheme().getStyles(VERSION, reloadCache);
        this.styleSheet = styleSheet;
        this.decorationStyles = decorationStyles;
    },

    renderModel: async function(reloadStylesCache = false) {
        if ((this.dataModel.dataState.version ?? 5) <= 9) {
            // Will migrate to 10 using the server service
            try {
                const { migratedSlide } = await slidesApi.migrateModel({ slide: this.dataModel.attributes });
                const migratedDataState = getSlideDataStateFromAttributes(migratedSlide);
                Object.assign(this.dataModel.dataState, migratedDataState);
            } catch (err) {
                logger.error(err, "renderModel() server-side migration to v10 failed, will try rendering as is", { slideId: this.dataModel.id });
            }
        }

        this.model = this.dataModel.dataState;

        // Load styles (cached)
        await this.loadStyles(reloadStylesCache);

        // Always set template on the first render
        await this.updateTemplate(this.model.template_id);

        this.isRendered = true;

        return this.refreshCanvas({ forceRender: true });
    },

    updateCanvasModel: async function(transition = false, forceRender = false, options = {}) {
        await this.refreshCanvasAutoRevert({ transition, forceRender });
        await new Promise((resolve, reject) =>
            _.defer(() => this.saveCanvasModel(options)
                .then(resolve)
                .catch(reject)
            ));
    },

    updateCanvasModelWithErrorDialog: async function(transition = false, forceRender = false, options = {}) {
        try {
            await this.updateCanvasModel(transition, forceRender, options);
        } catch (err) {
            this.selectionLayerController.showLayoutError(err);
        }
    },

    saveCanvasModel: async function(options) {
        this.dataModel.dataState = this.model;
        const dataState = this.dataModel.commit(options);

        this.dataModel.updatePromise.catch(err => {
            ShowErrorDialog({
                title: "We were unable to update your presentation.",
                message: <>
                    {err.status === 409 && <span>
                        Oops! Another user made changes to this slide at the same time you did. We’ve loaded the most recent version for you to edit.<br />
                        If the problem persists, please contact <a href="mailto:support@beautiful.ai">support@beautiful.ai</a>
                    </span>}
                    {err.status !== 409 && <span>
                        There may be a temporary problem with your internet connection.<br />
                        If the problem persists, please contact <a href="mailto:support@beautiful.ai">support@beautiful.ai</a>
                    </span>}
                </>,
            });
        });

        // NOTE: this function is sync, we don't await the update promise to avoid blocking the UI,
        // but we keep it async and we do await it everywhere, so we can easily add updatePromise awaiting
        // here if needed

        return dataState;
    },

    revertCanvasModel: async function({
        transition = false,
        forceRender = false
    } = {}) {
        const prevTemplateId = this.model.template_id;

        this.dataModel.discardChanges();
        this.model = this.dataModel.dataState;

        if (this.model.template_id !== prevTemplateId) {
            await this.updateTemplate(this.model.template_id);
        }

        return this.layouter.generate(
            this.model,
            {
                transition,
                forceRender
            },
            new geom.Size(this.CANVAS_WIDTH, this.CANVAS_HEIGHT)
        ).then(() => {
            this.trigger(CanvasEventType.REFRESH);
        });
    },

    refreshCanvasAutoRevert: async function({
        transition = false,
        forceRender = false
    } = {}) {
        try {
            await this.refreshCanvas({
                transition,
                forceRender
            });
        } catch (err) {
            logger.warn(`refreshCanvasAutoRevert() failed, error message: ${err.message}`, { slideId: this.dataModel.id });
            await this.revertCanvasModel({ forceRender: true });
            throw err;
        }
    },

    async refreshCanvas({
        transition = false,
        forceRender = false,
        suppressRefreshCanvasEvent,
    } = {}) {
        if (this.model.template_id !== this.dataModel.get("template_id")) {
            await this.updateTemplate(this.model.template_id);
        }

        await this.layouter.generate(
            this.model,
            {
                transition,
                forceRender,
                suppressRefreshCanvasEvent
            },
            new geom.Size(this.CANVAS_WIDTH, this.CANVAS_HEIGHT)
        );

        if (!suppressRefreshCanvasEvent) {
            if (this.layouter.isGenerating) {
                logger.warn("refreshCanvas() attempting to refresh canvas while another generate is still in progress. This can cause exception because calculatedProps = null!", { slideId: this.dataModel.id });
            }
            this.trigger(CanvasEventType.REFRESH);
        }

        await this.renderWatermark();
    },

    async dryRefreshCanvas() {
        if (this.model.template_id !== this.dataModel.get("template_id")) {
            await this.updateTemplate(this.model.template_id);
        }

        return this.layouter.generate(
            this.model,
            {
                skipRender: true
            },
            new geom.Size(this.CANVAS_WIDTH, this.CANVAS_HEIGHT)
        );
    },

    applyColors() {
        this.layouter.canvasElement.applyColors();
    },

    refreshElement(element, transition, suppressRefreshCanvasEvent = false, requireFit = false) {
        this.layouter.renderElement(element, transition, requireFit);
        if (!suppressRefreshCanvasEvent) {
            this.trigger(CanvasEventType.REFRESH);
        }
    },

    refreshElements(elements, transition, suppressRefreshCanvasEvent = false, requireFit = false) {
        this.layouter.renderElements(elements, transition, requireFit);
        if (!suppressRefreshCanvasEvent) {
            this.trigger(CanvasEventType.REFRESH);
        }
    },

    refreshRender(transition, suppressRefreshCanvasEvent = false) {
        this.layouter.refreshRender(transition);
        if (!suppressRefreshCanvasEvent) {
            this.trigger(CanvasEventType.REFRESH);
        }
    },

    requestRefreshElement(element) {
        if (!this.layouter?.isLayedOut) {
            return;
        }

        this.elementsAwaitingRefresh.add(element);

        if (!this.refreshElementsTimeout) {
            this.refreshElementsTimeout = setTimeout(() => {
                const elements = [...this.elementsAwaitingRefresh];

                this.elementsAwaitingRefresh.clear();
                this.refreshElementsTimeout = null;

                if (!this.layouter?.isLayedOut) {
                    return;
                }

                if (this.layouter.isGenerating) {
                    this.layouter.runPostRender(() => this.refreshElements(elements, false, false));
                } else {
                    this.refreshElements(elements, false, false);
                }
            }, 300);
        }
    },

    onRendered() {
        this.trigger(CanvasEventType.RENDER);
    },

    isAuthoringCanvas: function() {
        return this.layouter.canvasElement.elements.primary instanceof AuthoringCanvas;
    },

    showBrandingWatermark: function() {
        return !!this.options.showBranding;
    },

    renderWatermark: async function() {
        if (this.showBrandingWatermark()) {
            await this.renderBrandingWatermark();
        } else {
            if (this.$watermark) {
                this.$watermark.remove();
                this.$watermark = null;
            }
        }
    },

    renderBrandingWatermark: async function() {
        if (!this.$watermark) {
            this.$watermark = this.$el.addEl($.div(""));
        }
        let watermarkStyle = "watermark";
        let backgroundColor = this.getBackgroundColor().name;

        if ((this.getCanvasElement().background.canvasBackgroundStyle != "backgroundImage" && backgroundColor !== "background_light" && backgroundColor !== "background_accent") || this.getCanvasElement().background.model.customBackgroundImage?.colorStyle === "dark" || (this.getCanvasElement().background.model.backgroundStyle === "backgroundImage" && this.getCanvasElement().background.model.backgroundColor !== "custom-asset")) {
            watermarkStyle += " reverse";
        }
        if (!this.layouter.showFooter) {
            watermarkStyle += " no-footer";
        }

        this.$watermark.css("background", this.getBackgroundColor().toHexString());

        if (this.$watermark[0].className != watermarkStyle) {
            this.$watermark[0].className = watermarkStyle;

            const image = new Image();
            this.$watermark.html(image);

            await new Promise((resolve, reject) => {
                image.onload = () => {
                    resolve();
                };
                image.onerror = () => {
                    reject(new Error("Could not load branding image"));
                };

                if (watermarkStyle.contains("reverse")) {
                    image.src = getStaticUrl(`/images/beautifulai-logos/watermark-reverse.svg`);
                } else {
                    image.src = getStaticUrl(`/images/beautifulai-logos/watermark.svg`);
                }
            });
        }
    },

    renderSmartSlideWatermark: async function() {
        if (!this.$watermark) {
            this.$watermark = this.$el.addEl($.div("smart-slide-watermark"));
            const image = new Image();
            this.$watermark.html(image);
            await new Promise((resolve, reject) => {
                image.onload = () => {
                    resolve();
                };
                image.onerror = () => {
                    reject(new Error("Could not load branding image"));
                };

                image.src = getStaticUrl(`/images/watermark.svg`);
            });
        }

        if (isSafari) {
            // Safari can't normally render mixBlendMode unless all elements
            // that overlap with it have forced 3d acceleration which makes rendering
            // super slow
            if (this.getBackgroundColor().isDark()) {
                this.$watermark.css({
                    opacity: 0.15
                });
            } else {
                this.$watermark.css({
                    opacity: 0.2
                });
            }
        } else {
            if (this.getBackgroundColor().isDark()) {
                this.$watermark.css({
                    opacity: 0.1,
                    mixBlendMode: "difference"
                });
            } else {
                this.$watermark.css({
                    opacity: 0.04,
                    mixBlendMode: "difference"
                });
            }
        }
    },

    showSpinner: function(delay, showSolidShield = false) {
        clearTimeout(this.showSpinnerTimeout);
        if (delay) {
            this.showSpinnerTimeout = setTimeout(() => {
                this.$el.spinner(true);
            }, delay);
        } else {
            this.$el.spinner(true);
        }

        if (showSolidShield) {
            let backgroundColor = "#fff";
            if (this.isRendered && this.layouter?.canvasElement?.background) {
                backgroundColor = this.layouter.canvasElement.background.canvasBackgroundColor ?? backgroundColor;
            }
            this.$spinnerShield = this.$el.addEl($.div("spinner-shield").css("background", backgroundColor));
        }
    },

    hideSpinner: function() {
        clearTimeout(this.showSpinnerTimeout);
        this.$el.spinner(false);

        this.$el.find("div.spinner-shield").remove();
    },

    renderSlide: function(reloadStylesCache = false) {
        if (this.detached) {
            return Promise.resolve(false);
        }

        // if renderSlidePromise exists, we have already triggered the render slide, so don't trigger it again.
        if (!this.renderSlidePromise) {
            const profilerId = profiler.start("renderSlide");
            this.isReady = false;
            this.isRendered = false;

            if (this.isNew) {
                // We treat creating a new slide differently so it loads and appears faster in the app.
                // This essentially short circuits the rendering by avoiding the load and the ready check.
                this.isNew = false;
                const promise = this.renderModel(reloadStylesCache).then(() => {
                    // at any time the renderSlide can be cancelled and restarted, this check makes sure that we are still
                    // processing the same promise.
                    if (promise !== this.renderSlidePromise) {
                        return;
                    }
                    this.hideSpinner();
                    this.isReady = true;
                    profiler.end(profilerId);
                    return true;
                });
                this.renderSlidePromise = promise;
            } else {
                logger.info(`renderSlide() loading slide ${this.dataModel.id}`, { slideId: this.dataModel.id });
                const promise = this.dataModel.load()
                    .then(() => {
                        if (promise !== this.renderSlidePromise) {
                            return;
                        }
                        return this.renderModel(reloadStylesCache);
                    }).then(() => {
                        // Cancelled render check
                        if (promise !== this.renderSlidePromise) {
                            return;
                        }
                        return this.resetPlayback(false);
                    })
                    .then(() => {
                        this.hideSpinner();
                        this.isReady = true;
                        profiler.end(profilerId);
                        return true;
                    });

                this.renderSlidePromise = promise;
            }
        }

        return this.renderSlidePromise;
    },

    //-----------------------------------------------------------------------------------------------------------------------
    // Mouse handler
    //-----------------------------------------------------------------------------------------------------------------------
    onClickCanvas: function(event) {
        if (!this.isSelectable) return;
        if (!this.options.editable) return;
        if (event.originalEvent.hasSelectedElement) return;

        event.stopPropagation();
    },

    show: function() {
        this.isVisible = true;
        this.$el.css("opacity", 1);
    },

    hide: function() {
        this.isVisible = false;
        this.$el.css("opacity", 0);
    },

    getAnimators: function() {
        return this.layouter.canvasElement.animators;
    },

    handleAnimationFrame: function(timestamp) {
        if (!this.shouldHandleAnimationFrames) {
            // Breaking the loop
            return;
        }

        // Wrapper for easier flow control (i.e. with returns)
        const handleFrame = () => {
            if (this.lastAnimationFrameHandledAt === timestamp) {
                // Fail safe logic to mitigate muliple loops, should never happen, but better safe than sorry
                return;
            }
            this.lastAnimationFrameHandledAt = timestamp;

            if (this.isAnimating) {
                // Build animation is playing, no need to interfere
                return;
            }
            if (!this.isCurrentCanvas) {
                // Should never end up here, but better safe than sorry
                return;
            }
            if (!this.layouter?.isLayedOut) {
                // Same as above
                return;
            }
            if (this.layouter.isGenerating) {
                // Rendering in progress, no need to interfere
                return;
            }

            const animators = this.getAnimators();
            if (animators.length === 0) {
                // Nothing to animate
                return;
            }

            animators.forEach(animator => {
                // Let animators process the frame and update their elements
                animator.handleAnimationFrame(timestamp);
            });

            // Refresh
            this.layouter.refreshRender(false);
        };
        handleFrame(timestamp);

        // Continuing the loop
        window.requestAnimationFrame(ts => this.handleAnimationFrame(ts));
    },

    setAsCurrentCanvas: function() {
        this.isCurrentCanvas = true;

        this.$el.addClass("current_slide");

        if (this.$clickShield) {
            this.$clickShield.hide();
        }

        this.isEditable = true;

        if (this.layouter?.isLayedOut && !this.layouter.isGenerating) {
            this.layouter.refreshRender(false);
        }

        if (!this.shouldHandleAnimationFrames) {
            // Kick off animation frame handling loop
            this.shouldHandleAnimationFrames = true;
            window.requestAnimationFrame(ts => this.handleAnimationFrame(ts));
        }
    },

    removeAsCurrentCanvas: async function(awaitFinishedEditing = true) {
        this.isCurrentCanvas = false;

        this.outroAnimations = null;

        this.stopElements();

        this.$el.removeClass("current_slide");

        this.isEditable = false;

        if (this.$clickShield) {
            this.$clickShield.show();
        }

        if (this.layouter?.isLayedOut && !this.layouter.isGenerating) {
            this.layouter.refreshRender(false);
        }

        if (this.shouldHandleAnimationFrames) {
            // Send a signal to stop the animation frame handling loop
            this.shouldHandleAnimationFrames = false;
        }

        if (this.dataModel.hasChanges && this.dataModel.adapter.connected) {
            if (awaitFinishedEditing) {
                await this.dataModel.finishedEditing();
            } else {
                this.dataModel.finishedEditing()
                    .catch(err => {
                        logger.error("[Canvas] finishedEditing() failed", { err });
                    });
            }

            // Fire and forget
            (async () => {
                const {
                    template_recommendations: { enabled: hasTemplateRecommendations }
                } = await getExperiments(["template_recommendations"]);

                const props = {
                    "slide_id": this.dataModel.get("id"),
                    "slides_created": 0,
                    "source_presentation_id": this.dataModel.presentation?.get("sourcePresentationId") || "",
                    "source_presentation_name": this.dataModel.presentation?.get("sourcePresentationName") || "",
                    "is_from_recommended_template": this.dataModel.presentation?.get("metadata")?.isFromRecommendedTemplate ?? false,
                    "object": event?.target?.localName || "",
                    "object_label": event?.target?.innerText || "",
                    "action": event?.type || "",
                    "experiment_id": "5FC18A79E182FE7C764425D4F852F501",
                    "experiment_group_assignment": hasTemplateRecommendations ? "variant-a" : "control"
                };
                trackActivity("Slide", "ContentModified", null, null, props, { skipAmplitude: true });
            })().catch(err => {
                logger.error("[Canvas] removeAsCurrentCanvas() post-remove actions failed", { err });
            });
        }

        this.reportMetrics();
    },

    reportMetrics: function() {
        // Fail safe, should never happen
        if (!this.layouter) {
            return;
        }

        const generateStats = this.layouter.generateStats;
        const renderElementStats = this.layouter.renderElementStats;

        this.layouter.generateStats = [];
        this.layouter.renderElementStats = [];

        const slideId = this.dataModel?.id ?? null;
        const presentationId = this.dataModel?.presentation?.id ?? null;

        const reportMetric = (metricType, stats) => {
            const templateIds = _.uniq(stats.map(stat => stat.templateId));
            const migrationVersions = _.uniq(stats.map(stat => stat.migrationVersion));
            const failedStates = [true, false];

            templateIds.forEach(templateId => migrationVersions.forEach(migrationVersion => failedStates.forEach(failed => {
                const filteredStats = stats.filter(stat => stat.templateId === templateId && stat.migrationVersion === migrationVersion && stat.failed === failed);
                if (filteredStats.length > 0) {
                    const metricData = {
                        durationMs: _.meanBy(filteredStats, "durationMs"),
                        templateId,
                        migrationVersion,
                        slideId,
                        presentationId,
                        failed
                    };
                    logger.metric(metricType, metricData);
                }
            })));
        };

        if (generateStats.length > 0) {
            reportMetric(MetricName.CANVAS_GENERATE_TIME, generateStats);
        }
        if (renderElementStats.length > 0) {
            reportMetric(MetricName.CANVAS_RENDER_ELEMENT_TIME, renderElementStats);
        }
    },

    //-----------------------------------------------------------------------------------------------------------------------
    // Editable and Locking
    //-----------------------------------------------------------------------------------------------------------------------

    /**
     * Locks slide for collaborators for lockTimeSeconds
     */
    lockSlideForCollaborators: function(lockTimeSeconds = 5) {
        this.canvasController?.lockSlideForCollaborators(lockTimeSeconds);
    },

    /**
     * Explicitly unlocks slide for collaborators
     */
    unlockSlideForCollaborators: function() {
        this.canvasController?.unlockSlideForCollaborators();
    },

    /**
     * Is slide locked by user (locked for other collaborators)
     */
    isLockedForCollaborators: function() {
        return !!this.canvasController?.isLockedForCollaborators();
    },

    //-----------------------------------------------------------------------------------------------------------------------
    // Animations
    //-----------------------------------------------------------------------------------------------------------------------

    animationIsOldCustomTimeline: function() {
        const state = this.model.animations.general?.state;
        // If there's a saved state which isn't explicitly set to old custom timeline
        // then it can't be it
        if (state && state !== AnimationsState.CUSTOM_OLD_TIMELINE) {
            return false;
        }

        // Pulling animations
        const animations = this.layouter.canvasElement.getAnimations();
        animations.forEach(animation => {
            // Assigning old ids
            animation.id = createHash(`${animation.element.uniquePath}${animation.name}`);
        });

        // Checking if we have any saved start or duration
        for (const animation of animations) {
            const savedAnimation = this.model.animations[animation.id];
            if (savedAnimation?.start != null || savedAnimation?.duration != null) {
                return true;
            }
        }

        return false;
    },

    /**
     * Returns a list of animations for the current slide
     */
    getAnimations: function() {
        let generalAnimationsSettings = this.getGeneralAnimationsSettings();

        const isOldCustomTimeline = this.animationIsOldCustomTimeline();
        if (generalAnimationsSettings.state !== AnimationsState.CUSTOM_OLD_TIMELINE && isOldCustomTimeline) {
            // Force custom old timeline state and regenerate animations
            this.updateGeneralAnimationsSettings({ state: AnimationsState.CUSTOM_OLD_TIMELINE });
            return this.getAnimations();
        }

        const getAnimationId = animation => {
            if (animation.id) {
                // Animation has explicitly defined id
                return animation.id;
            }

            if (generalAnimationsSettings.state === AnimationsState.CUSTOM_OLD_TIMELINE) {
                // Generating an id in the old way to avoid ruining old timelines
                return createHash(`${animation.element.uniquePath}${animation.name}`);
            }

            // New way of generating ids
            return createHash(`${animation.element.pathByElementIndexes}${animation.name}`);
        };

        const setAnimationDefaultValues = (animation, isCustom) => {
            animation.id = getAnimationId(animation);
            animation.animatingElements = animation.animatingElements ?? (animation.element ? [animation.element, ...animation.element.allChildElements] : []);
            animation.elementId = animation.element?.uniquePath ?? null;
            animation.elementName = animation.elementName ?? animation.element?.animationElementName ?? null;
            animation.disabledByDefault = animation.disabledByDefault ?? false;
            animation.disabled = animation.disabledByDefault;
            animation.waitForClick = false;

            // Making sure all callbacks are defined
            const prepare = animation.prepare ?? (() => { });
            animation.prepare = (...args) => {
                animation.animatingElements.forEach(element => element.isAnimating = true);
                return prepare(...args);
            };
            const finalize = animation.finalize ?? (() => { });
            animation.finalize = (...args) => {
                animation.animatingElements.forEach(element => element.isAnimating = false);
                return finalize(...args);
            };
            animation.onBeforeAnimationFrame = animation.onBeforeAnimationFrame ?? (() => { });

            // Default duration has to be always defined as well
            animation.defaultDuration = (animation.defaultDuration ?? DEFAULT_ANIMATION_DURATION_MS);

            animation.easing = animation.easing ?? "swing";

            animation.isCustom = isCustom;

            return animation;
        };

        const animations = this.layouter.canvasElement.getAnimations();
        // Setting default values for animations
        animations.forEach(animation => setAnimationDefaultValues(animation, false));

        // Obsolete timeline model
        const timelineModel = this.slide.get("timeline");
        if (timelineModel && !generalAnimationsSettings.migrated) {
            // Migrating preset
            switch (timelineModel.preset) {
                case "none":
                    this.updateGeneralAnimationsSettings({ state: AnimationsState.NONE });
                    break;
                case "wait":
                    this.updateGeneralAnimationsSettings({ state: AnimationsState.ON_CLICK });
                    break;
                case "custom":
                    this.updateGeneralAnimationsSettings({ state: AnimationsState.CUSTOM });
                    // Migrating arrangement
                    switch (timelineModel.buildAnimationType) {
                        case "overlapping":
                            this.updateGeneralAnimationsSettings({ arrangementType: AnimationsArrangementType.OVERLAPPING });
                            break;
                        case "sequential":
                            this.updateGeneralAnimationsSettings({ arrangementType: AnimationsArrangementType.SEQUENTIAL });
                            break;
                        case "silmultaneous":
                            this.updateGeneralAnimationsSettings({ arrangementType: AnimationsArrangementType.SIMULTANEOUS });
                            break;
                        case "none":
                            this.updateGeneralAnimationsSettings({ state: AnimationsState.NONE });
                            break;
                    }
                    // Migrating speed
                    switch (timelineModel.speed) {
                        case "slow":
                            this.updateGeneralAnimationsSettings({ speedMultiplier: 0.5 });
                            break;
                        case "normal":
                            this.updateGeneralAnimationsSettings({ speedMultiplier: 1 });
                            break;
                        case "fast":
                            this.updateGeneralAnimationsSettings({ speedMultiplier: 2 });
                            break;
                    }
                    break;
            }
        }

        if (!generalAnimationsSettings.migrated) {
            // Marking as migrated
            this.updateGeneralAnimationsSettings({ migrated: true });
        }

        // Reload general settings in case were migrated
        generalAnimationsSettings = this.getGeneralAnimationsSettings();
        const {
            arrangementType,
            state,
            customAnimationIds,
            speedMultiplier
        } = generalAnimationsSettings;

        // If the animation was created in v10, replace the saved ID with the v11 version to get the correct order
        const animationsOrder = generalAnimationsSettings.animationsOrder.map(savedId=> animations.find(a=> (a.id_v10 === savedId))?.id ?? savedId);

        if (state === AnimationsState.CUSTOM) {
            // Pulling custom animations
            customAnimationIds.forEach(animationId => {
                animations.push(setAnimationDefaultValues({ id: animationId }, true));
            });

            // Composing default animations order
            const defaultAnimationsOrder = animations.map(({ id }) => id);
            // Stripping out non-existing animations from the saved order
            const normalizedAnimationsOrder = animationsOrder.filter(id => defaultAnimationsOrder.includes(id));
            // Generating merged order
            const mergedAnimationsOrder = _.union(normalizedAnimationsOrder, defaultAnimationsOrder);
            // Sorting according to merged order
            animations.sort((a, b) => mergedAnimationsOrder.indexOf(a.id) - mergedAnimationsOrder.indexOf(b.id));
        }

        // Applying saved values
        animations
            .filter(({ id }) => !!this.model.animations[id])
            .forEach(animation => {
                const savedAnimation = this.model.animations[animation.id];
                // ↓ Obsolete values ↓
                animation.start = savedAnimation.start ?? null;
                animation.duration = savedAnimation.duration ?? null;
                // ↑ Obsolete values ↑
                animation.disabled = savedAnimation.disabled ?? animation.disabled;
                animation.waitForClick = savedAnimation.waitForClick ?? animation.waitForClick;
                animation.name = savedAnimation.name ?? animation.name;
            });

        if (speedMultiplier !== 1) {
            // Scaling default durations, saved durations of custom animations won't be scaled
            animations.forEach(animation => {
                animation.defaultDuration /= speedMultiplier;
            });
        }

        if (state === AnimationsState.CUSTOM) {
            // Will be rearranging by blocks between custom animations according to selected arrangement
            let animationsToArrange = [];
            let currentStart = 0;
            animations
                .filter(({ disabled }) => !disabled)
                .forEach(animation => {
                    if (!animation.isCustom) {
                        animationsToArrange.push(animation);
                    } else {
                        if (animationsToArrange.length > 0) {
                            this.arrangeAnimations(animationsToArrange, arrangementType, true, currentStart);
                            currentStart = _.max(animationsToArrange.map(({ start, duration }) => start + duration));
                            animationsToArrange = [];
                        }

                        this.arrangeAnimations([animation], AnimationsArrangementType.SEQUENTIAL, true, currentStart);
                        currentStart = animation.start + animation.duration;
                    }
                });
            if (animationsToArrange.length > 0) {
                this.arrangeAnimations(animationsToArrange, arrangementType, true, currentStart);
            }
        } else if (state === AnimationsState.AUTO || state === AnimationsState.ON_CLICK) {
            animations.forEach(animation => {
                // Will ignore saved disabled settings
                animation.disabled = animation.disabledByDefault;
                // Force set wait for clicks
                animation.waitForClick = state === AnimationsState.ON_CLICK;
            });

            this.arrangeAnimations(
                animations.filter(({ disabled }) => !disabled),
                // Force sequential arrangement for on click state
                state === AnimationsState.ON_CLICK ? AnimationsArrangementType.SEQUENTIAL : arrangementType,
                true
            );
        } else if (state === AnimationsState.CUSTOM_OLD_TIMELINE) {
            // If some animations don't have start or duration, then we'll rearrange them according to
            // current arrangement type
            if (animations.some(({ start, duration }) => start == null || duration == null)) {
                this.arrangeAnimations(animations, arrangementType, false);
            }
        } else if (state === AnimationsState.NONE) {
            animations.forEach(animation => animation.disabled = true);
        }

        // Ensuring animation indexes
        animations.forEach((animation, idx) => animation.index = idx);

        return animations;
    },

    /**
     * Arranges a list of animations, mutates the list
     * @param {*} animations - list of animations
     * @param {*} arrangementType - type of arrangement
     * @param {*} normalizeStart - sets the first animation start to zero
     * @returns mutated list of animations
     */
    arrangeAnimations: function(animations, arrangementType, normalizeStart = false, normalizeStartTo = 0) {
        let start = normalizeStartTo;
        if (!normalizeStart && !animations.some(({ start }) => start == null)) {
            start = _.min(animations.map(({ start }) => start));
        }

        // Making sure all animations have duration
        animations.forEach(animation => {
            animation.duration = animation.duration ?? animation.defaultDuration;
        });

        if (arrangementType === AnimationsArrangementType.SEQUENTIAL || arrangementType === AnimationsArrangementType.OVERLAPPING) {
            animations.forEach((animation, idx) => {
                animation.start = start;
                if (arrangementType === AnimationsArrangementType.SEQUENTIAL) {
                    start += animation.duration;
                } else {
                    if (
                        idx < animations.length - 1 &&
                        animations[idx + 1].name === animation.name &&
                        animations[idx + 1].element?.parentElement === animation.element?.parentElement &&
                        animation.overlapWithSameAnimationMultiplier != null
                    ) {
                        start += animation.duration * animation.overlapWithSameAnimationMultiplier;
                    } else {
                        start += animation.duration * 0.33;
                    }
                }
            });
        } else if (arrangementType === AnimationsArrangementType.SIMULTANEOUS) {
            animations.forEach(animation => {
                animation.start = start;
            });
        }

        return animations;
    },

    /**
     * Updates the saved values for an animation
     */
    updateAnimation: function(animationId,
        {
            start = undefined,
            duration = undefined,
            disabled = undefined,
            waitForClick = undefined,
            name = undefined
        }
    ) {
        if (!this.model.animations[animationId]) {
            this.model.animations[animationId] = {};
        }

        if (start !== undefined) {
            this.model.animations[animationId].start = start;
        }
        if (duration !== undefined) {
            this.model.animations[animationId].duration = duration;
        }
        if (disabled !== undefined) {
            this.model.animations[animationId].disabled = disabled;
        }
        if (waitForClick !== undefined) {
            this.model.animations[animationId].waitForClick = waitForClick;
        }
        if (name !== undefined) {
            this.model.animations[animationId].name = name;
        }
    },

    /**
     * Returns general animations settings (arrangementType)
     */
    getGeneralAnimationsSettings: function() {
        return {
            // Defaults to overlapping
            arrangementType: this.model.animations.general?.arrangementType ?? this.layouter?.primary?.defaultAnimationsArrangementType ?? AnimationsArrangementType.OVERLAPPING,
            state: this.model.animations.general?.state ?? (
                this.layouter.canvasElement.disableAllAnimationsByDefault
                    ? AnimationsState.NONE
                    : AnimationsState.AUTO
            ),
            migrated: this.model.animations.general?.migrated ?? false,
            speedMultiplier: this.model.animations.general?.speedMultiplier ?? 1,
            customAnimationIds: this.model.animations.general?.customAnimationIds ?? [],
            animationsOrder: this.model.animations.general?.animationsOrder ?? []
        };
    },

    /**
     * Updates general animations settings (arrangementType)
     */
    updateGeneralAnimationsSettings: function({
        arrangementType = undefined,
        state = undefined,
        migrated = undefined,
        speedMultiplier = undefined,
        customAnimationIds = undefined,
        animationsOrder = undefined
    }) {
        if (!this.model.animations.general) {
            this.model.animations.general = {};
        }

        if (arrangementType !== undefined) {
            this.model.animations.general.arrangementType = arrangementType;
        }

        if (state !== undefined) {
            this.model.animations.general.state = state;
        }

        if (migrated !== undefined) {
            this.model.animations.general.migrated = migrated;
        }

        if (speedMultiplier !== undefined) {
            this.model.animations.general.speedMultiplier = speedMultiplier;
        }

        if (customAnimationIds !== undefined) {
            this.model.animations.general.customAnimationIds = customAnimationIds;
        }

        if (animationsOrder !== undefined) {
            this.model.animations.general.animationsOrder = animationsOrder;
        }
    },

    /**
     * Shows a full screen click shield that calls onClick
     * on every click and onKeyDown on every key down (on window)
     * @returns function that correctly destroys the shield
     */
    showClickShield: function(onClick = null, onKeyDown = null, customCursor = null) {
        const $clickShield = $(document.body).addEl($.div("fullscreen-click-shield"));

        if (customCursor) {
            $clickShield.css("cursor", customCursor);
        }

        const clickHandler = event => {
            event.stopPropagation();
            event.preventDefault();

            if (onClick) {
                onClick(event);
            }
        };
        $clickShield.on("click", clickHandler);

        const keyDownHandler = event => {
            event.stopPropagation();
            event.preventDefault();

            if (onKeyDown) {
                onKeyDown(event);
            }
        };
        document.addEventListener("keydown", keyDownHandler);

        const wheelHandler = event => {
            event.stopPropagation();
        };
        document.addEventListener("wheel", wheelHandler);

        return () => {
            $clickShield.off("click", clickHandler);
            document.removeEventListener("keydown", keyDownHandler);
            document.removeEventListener("wheel", wheelHandler);
            $clickShield.remove();
        };
    },

    /**
     * Returns a promise that resolves after user clicks on the screen or presses a key,
     * returns true if ESC key pressed
     */
    waitForClick: function() {
        return new Promise(resolve => {
            let removeClickShield;
            const onInteraction = event => {
                removeClickShield();
                resolve(event.which === Key.ESCAPE);
            };
            removeClickShield = this.showClickShield(onInteraction, onInteraction, "pointer");
        });
    },

    /**
     * Element types may have their own click handling logic and need a way to
     * signal the canvas that animation should progress. This function finds the
     * underlying element that controls the animation, checks whether it is an interactive
     * element or not, and advances the animation if required.
     */
    possiblyAdvanceAnimation: function(clickedElement) {
        if (!this.playerView) {
            return;
        }
        let animatingElement = this
            .getEnabledAnimations()
            .filter(a => a.waitForClick)
            .map(a => a.element)
            .find(x => (x === clickedElement) || clickedElement.isChildOf(x));

        if (!animatingElement) {
            return;
        }
        if (animatingElement.isInteractive && animatingElement.animationState?.fadeInProgress === 0) {
            this.options.advanceToSlide(1);
        } else if (!animatingElement.isInteractive) {
            this.options.advanceToSlide(1);
        }
    },

    getEnabledAnimations: function() {
        return this.getAnimations().filter(animation => !animation.disabled);
    },

    /**
     * NOTE: this won't take into account the gap between the first animation and zero
     */
    getAnimationsDuration: function(animations) {
        return _.max(animations.map(({ start, duration }) => start + duration)) ?? 0;
    },

    prepareForAnimation: async function() {
        if (this.layouter.isInErrorState) {
            return;
        }

        const enabledAnimations = this.getEnabledAnimations();
        enabledAnimations.forEach(({ prepare }) => prepare());

        this.beforeShowElements();

        this.isAnimating = true;
        this.animationStartedAt = null; // Will be set upon the first animation frame
        this.animationShouldJumpToNextWaitForClick = false;
        this.animationFutureWaitForClickStart = _.min(enabledAnimations.filter(({ waitForClick }) => waitForClick).map(({ start }) => start)) ?? null;

        await this.reportState({ isAnimating: true });

        if (enabledAnimations.length === 0) {
            return;
        }

        // Refresh render first to quickly render the fadin-in elements in the faded out state
        this.layouter.refreshRender();
        // Then do a full refresh to render the elements that need full recalc or rebuild before animating
        await this.refreshCanvas({ forceRender: true });
    },

    finishAnimation: async function() {
        if (this.layouter.isInErrorState) {
            return;
        }

        const enabledAnimations = this.getEnabledAnimations();
        enabledAnimations.forEach(({ finalize }) => finalize());

        this.isAnimating = false;
        this.animationStartedAt = null;
        this.animationShouldJumpToNextWaitForClick = false;
        this.animationFutureWaitForClickStart = false;

        await this.reportState({ isAnimating: false });

        if (enabledAnimations.length === 0) {
            return;
        }

        // Full refresh to render the elements that need full recalc or rebuild after animating
        await this.refreshCanvas({ forceRender: true });
    },

    renderAnimationState: function(progress) {
        if (!this.isAnimating) {
            throw new Error("Can't render animation state while not animating, run prepareForAnimation() first");
        }

        const animations = this.getEnabledAnimations();
        if (animations.length === 0) {
            return;
        }

        const elementsNeedRecalc = new Set();
        for (const animation of animations) {
            const animationProgress = Math.clamp((progress - animation.start) / animation.duration, 0, 1);
            const element = animation.onBeforeAnimationFrame(animationProgress);
            if (element) {
                elementsNeedRecalc.add(element);
            }
        }

        [...elementsNeedRecalc].forEach(element => {
            element.recalcProps();
        });

        this.layouter.refreshRender(false);
    },

    jumpToNextWaitForClick: function() {
        if (!this.animationFutureWaitForClickStart) {
            throw new Error("No future wait for clicks found");
        }

        this.animationShouldJumpToNextWaitForClick = true;
    },

    animate: async function(onAnimationProgress = () => { }, showClickShield = true, onBuildAnimationFinished = null, onWaitForClick = null) {
        if (this.layouter.isInErrorState) {
            return;
        }

        if (!this.isAnimating) {
            // Prepare may have been run before by the player
            await this.prepareForAnimation();
        }

        this.layouter.canvasElement.onCanvasAnimationStart();
        // Reporting zero progress before we've actually started
        onAnimationProgress(0, !!this.animationFutureWaitForClickStart, []);

        // Handling audio
        let isAudioPlaying = false;
        await this.setupAudio()
            .catch(err => {
                logger.error(err, "setupAudio() failed", { slideId: this.dataModel.id });
            });

        if (this.hasAudio()) {
            // Waiting for audio to start playing
            isAudioPlaying = true;
            this.playAudio()
                .catch(err => logger.error(err, "playAudio() failed", { slideId: this.dataModel.id }))
                .finally(() => isAudioPlaying = false);
        }

        if (this.hasVideoOverlay()) {
            isAudioPlaying = true;
            let video = this.layouter.elements.videoOverlay.videoRef.current;
            video.addEventListener("ended", () => {
                isAudioPlaying = false;
            });
        }

        // Allow the elements to animate
        //   (currently used by VideoElement)
        _.each(this.layouter.elements, element => {
            element.animate();
        });

        // Rendering click shield, will stop animation on ESC key press
        let removeClickShield;
        if (showClickShield) {
            removeClickShield = this.showClickShield(null, event => {
                if (event.which == Key.ESCAPE) {
                    this.stopAnimation();
                }
            }, "wait");
        }

        const animations = this.getEnabledAnimations();

        const animationsDuration = this.getAnimationsDuration(animations);

        let hasBeenCancelled = false;
        let hasBuildAnimationFinished = false;

        // Animations sequence
        await new Promise(resolve => {
            const startedAnimationIds = [];
            const finishedAnimationIds = [];

            let paused = false;
            let pausedAt = null;

            const handleAnimationFrame = timestamp => {
                if (!this.animationStartedAt) {
                    this.animationStartedAt = timestamp;
                }

                if (this.animationShouldStop) {
                    // Force disable pause if requested animation to stop
                    paused = false;
                }

                if (paused) {
                    // We're paused, will be looping until resumed
                    window.requestAnimationFrame(handleAnimationFrame);
                    return;
                } else if (pausedAt) {
                    this.animationStartedAt += timestamp - pausedAt;
                    pausedAt = null;
                }

                if (this.animationShouldJumpToNextWaitForClick) {
                    // Adjusting started at timestamp
                    this.animationStartedAt -= this.animationFutureWaitForClickStart - timestamp + this.animationStartedAt;

                    this.animationShouldJumpToNextWaitForClick = false;
                    this.animationFutureWaitForClickStart = null;
                }

                // Current progress in ms
                let progress = timestamp - this.animationStartedAt;

                const futureAnimations = animations.filter(({ id }) => !finishedAnimationIds.includes(id) && !startedAnimationIds.includes(id));
                this.animationFutureWaitForClickStart = _.min(futureAnimations.filter(({ waitForClick }) => waitForClick).map(({ start }) => start)) ?? null;

                const elementsNeedRecalc = new Set();
                let shouldWaitForClick = false;
                for (const animation of animations.filter(({ id, start }) => start <= progress && !finishedAnimationIds.includes(id))) {
                    let animationRequestedWaitForClick = false;
                    let easing = $.Velocity.Easings[animation.easing];
                    let animationProgress = easing(Math.min((progress - animation.start) / animation.duration, 1));
                    if (!startedAnimationIds.includes(animation.id)) {
                        startedAnimationIds.push(animation.id);
                        animationRequestedWaitForClick = animation.waitForClick;
                        if (shouldWaitForClick) {
                            animationProgress = 0;
                        }
                    }
                    // onBeforeAnimationFrame() may return an element which requres props recalc before refreshing render
                    // Force progress to zero for animations that requested wait for click
                    const element = animation.onBeforeAnimationFrame(animationRequestedWaitForClick ? 0 : animationProgress);
                    if (element) {
                        elementsNeedRecalc.add(element);
                    }

                    if (animationProgress === 1) {
                        finishedAnimationIds.push(animation.id);
                    }

                    shouldWaitForClick = shouldWaitForClick || animationRequestedWaitForClick;

                    // If there is an autoplay video on a Video slide, skip animations until it is visible
                    // Will not work if the video is part of a nested element
                    if (shouldWaitForClick) {
                        for (const anim of futureAnimations) {
                            if (anim.element?.model?.autoPlay) {
                                shouldWaitForClick = false;
                            }
                        }
                    }
                }

                // Recalculating props if requested
                [...elementsNeedRecalc].forEach(element => element.recalcProps());

                if (elementsNeedRecalc.size > 0) {
                    // Applying colors to elements if some elements were recalc'd
                    this.applyColors();
                }

                // Refreshing the canvas
                this.layouter.refreshRender(false);

                // Reporting progress
                const currentAnimationIds = animations.filter(({ id, start }) => start <= progress && !finishedAnimationIds.includes(id)).map(({ id }) => id);
                onAnimationProgress(progress, !!this.animationFutureWaitForClickStart, currentAnimationIds);

                // Waiting for click if requested
                if (shouldWaitForClick) {
                    paused = true;
                    pausedAt = timestamp;

                    if (!showClickShield && onWaitForClick) {
                        onWaitForClick().then(() => {
                            animations
                                .filter(animation => currentAnimationIds.includes(animation.id))
                                .map(animation => {
                                    try {
                                        animation.element.findChildElements("VideoElement").map(v => {
                                            v.playFromStart();
                                        });
                                    } catch (err) {
                                        // ignore errors
                                    }
                                }
                                );
                            paused = false;
                        });
                    } else {
                        this.waitForClick()
                            .then(isEscapePressed => {
                                paused = false;

                                if (isEscapePressed) {
                                    this.stopAnimation();
                                }
                            });
                    }

                    window.requestAnimationFrame(handleAnimationFrame);
                    return;
                }

                if (progress >= animationsDuration && !hasBuildAnimationFinished) {
                    hasBuildAnimationFinished = true;
                    if (onBuildAnimationFinished) {
                        // This will never get called if the animation has been cancelled
                        onBuildAnimationFinished();
                    }
                }

                // Checking if we should finish
                if ((progress >= animationsDuration && !isAudioPlaying) || this.animationShouldStop) {
                    if (this.animationShouldStop) {
                        this.animationShouldStop = false;
                        hasBeenCancelled = true;
                    }
                    if (isAudioPlaying) {
                        this.stopAudio();
                    }
                    resolve();
                    return;
                }

                window.requestAnimationFrame(handleAnimationFrame);
            };

            window.requestAnimationFrame(handleAnimationFrame);
        });

        // Reportin the exact durations progress for better user experience
        onAnimationProgress(animationsDuration, false, []);

        await this.finishAnimation();

        if (showClickShield) {
            removeClickShield();
        }

        return hasBeenCancelled;
    },

    /**
     * Requests current animation process to stop
     */
    stopAnimation: async function() {
        if (!this.isAnimating) {
            return;
        }

        this.animationShouldStop = true;
    },

    registerOutroAnimation: function(element) {
        this.outroAnimations = this.outroAnimations || [];
        this.outroAnimations.push(element);
    },

    hasOutroAnimations: function() {
        return this.outroAnimations && this.outroAnimations.length > 0;
    },

    playOutroAnimations: async function() {
        if (this.outroAnimations) {
            await Promise.all(this.outroAnimations.map(element => element.playAnimationOut()));
            this.outroAnimations = null;
        }
    },

    //-----------------------------------------------------------------------------------------------------------------------
    // Playback
    //-----------------------------------------------------------------------------------------------------------------------

    currentPlaybackStage: 0,
    playbackStages: [],

    resetPlayback: async function(animate = true) {
        if (this.playbackStages.length) {
            await this.playbackStages[0].callback(this.playbackStages[0], animate);
            this.currentPlaybackStage = 0;
        }
    },

    stopPlayback: function() {

    },

    clearPlaybackStages: function() {
        this.playbackStages = [];
    },

    addPlaybackStage: function(stage) {
        this.playbackStages.push(stage);
    },

    isPlaybackComplete: function() {
        return this.currentPlaybackStage >= this.playbackStages.length - 1;
    },

    goToStage: async function(index, animate = true) {
        if (this.currentPlaybackStage !== index && index >= 0 && index < this.playbackStages.length) {
            if (this.layouter.isGenerating) {
                // Let the canvas finish calculating and rendering before transitioning to the new stage
                await this.layouter.generationPromise;
            }

            const prevPlaybackStage = this.currentPlaybackStage;
            this.currentPlaybackStage = index;
            const playbackStage = this.playbackStages[this.currentPlaybackStage];
            await playbackStage.callback(playbackStage, animate);
            this.trigger(CanvasEventType.PLAYBACK_STAGE_CHANGED, { current: this.currentPlaybackStage, prev: prevPlaybackStage });
        }
    },

    playNextStage: function(animate = true) {
        return this.goToStage(this.currentPlaybackStage + 1, animate);
    },

    playPrevStage: function(animate = true) {
        return this.goToStage(this.currentPlaybackStage - 1, animate);
    },

    requestConsent: () => ShowMessageDialog({
        title: "Click to play slide",
        buttonText: "Play"
    }),

    beforeShowElements() {
        if (this.layouter) {
            _.each(this.layouter.elements, element => {
                element.beforeShowElement();
            });
        }
    },

    prepareToShowElements: async function() {
        if (this.layouter) {
            _.each(this.layouter.elements, element => {
                element.prepareToShowElement();
            });

            await this.renderWatermark()
                .catch(err => logger.error(err, "renderWatermark() failed", { slideId: this.dataModel.id }));
        }
    },

    stopElements: function() {
        if (this.layouter) {
            _.each(this.layouter.elements, element => {
                element.stopElement();
            });
        }
    },

    reloadElementsOnPresenterToggle: async function() {
        if (this.layouter) {
            const elements = Object.values(this.layouter.elements);
            await Promise.all(elements.map(element =>
                element.reloadOnPresenterToggle()
            ));
        }
    },

    hasAudio: function() {
        return this.dataModel && this.dataModel.has("audioAsset");
    },

    hasVideoOverlay: function() {
        return !!this.layouter.elements?.videoOverlay;
    },

    setupAudio: function() {
        if (this.hasAudio()) {
            return ds.assets.getAssetById(this.dataModel.get("audioAsset"), "audio")
                .then(asset => asset.getBaseUrl())
                .then(assetUrl => getAudioBufferFromUrl(assetUrl))
                .then(audioBuffer => {
                    this.audioBuffer = audioBuffer;
                })
                .catch(err => {
                    logger.error(err, "setupAudio() failed", { slideId: this.dataModel.id });

                    this.isPlayingAudio = false;
                    throw new Error(err);
                });
        } else {
            return Promise.resolve();
        }
    },

    playAudio: function(onBeforePlay = null) {
        if (this.hasAudio()) {
            const play = () => new Promise((resolve, reject) => {
                if (!this.audioBuffer) {
                    throw new Error("No loaded audioBuffer");
                }
                this.isPlayingAudio = true;
                this.audio = audioContext.createBufferSource();
                this.audio.buffer = this.audioBuffer;
                this.audio.connect(audioContext.destination);
                this.audio.start(0);

                this.audio.onended = () => {
                    let interruptedPlay = !this.isPlayingAudio;
                    this.isPlayingAudio = false;
                    this.audioBuffer = null;
                    resolve(interruptedPlay);
                };
                this.audio.onerror = () => {
                    reject(new Error("Audio stream failed"));
                };
            });

            if (onBeforePlay) {
                onBeforePlay();
            }
            return play();
        } else {
            return Promise.resolve();
        }
    },

    resumeAudio: function() {
        this.isPlayingAudio = true;
        audioContext.resume();
    },

    stopAudio: function() {
        this.isPlayingAudio = false;
        if (this.audio) {
            this.audio.stop();
            this.audio = null;
            this.audioBuffer = null;
        }
    },

    //-----------------------------------------------------------------------------------------------------------------------
    // Elements
    //-----------------------------------------------------------------------------------------------------------------------

    getCanvasElement() {
        return this.layouter.canvasElement;
    },

    getPrimaryElement: function() {
        return this.layouter.canvasElement.elements.primary;
    },

    getHeaderElement: function() {
        return this.layouter.canvasElement.elements.header;
    },

    getCalloutsElement: function() {
        return this.layouter.canvasElement.elements.callouts;
    },

    getTrayElement: function() {
        return this.layouter.canvasElement.elements.tray;
    },

    getSideBarElement: function() {
        return this.layouter.canvasElement.elements.tray;
    },

    getTextTrayElement: function() {
        return this.layouter.canvasElement.elements.elementTextBlock;
    },

    getFooterElement: function() {
        return this.layouter.canvasElement.elements.footer;
    },

    //---------------------------------------------------------------------------------------------------------------------
    // Find stuff at the mouse point
    //---------------------------------------------------------------------------------------------------------------------
    findRolloverElementsAtPoint(x, y) {
        if (!this.layouter) {
            return [];
        }

        return this.findElementsAtPoint(this.layouter, x, y, element => element.canSelectChildElements)
            .filter(element => element.canRollover);
    },

    findSelectableElementsAtPoint(x, y) {
        if (!this.layouter) {
            return [];
        }

        return this.findElementsAtPoint(this.layouter, x, y, element => element.canSelectChildElements)
            .filter(element => element.canSelect);
    },

    findDoubleClickableElementsAtPoint(x, y) {
        if (!this.layouter) {
            return [];
        }

        return this.findElementsAtPoint(this.layouter, x, y, element => element.canDoubleClickSelectChildElements)
            .filter(element => element.doubleClickToSelect);
    },

    findInteractiveElementsAtPoint(x, y) {
        if (!this.layouter) {
            return [];
        }

        return this.findElementsAtPoint(this.layouter, x, y, element => element.canSelectChildElements)
            .filter(element => element.isInteractive);
    },

    findElementsAtPoint(element, x, y, canUseChildren = () => true) {
        const findAtPoint = element => {
            const elementsAtPoint = [];

            const childElements = _.sortBy(element.elements, element => element.calculatedProps?.layer ?? 0)
                .filter(element => element instanceof BaseElement);

            childElements.forEach(childElement => {
                const elementPt = Convert.ScreenToCanvasCoordinates(this, x, y);
                if (childElement.calculatedProps && childElement.containsPoint(elementPt)) {
                    elementsAtPoint.push(childElement);
                }

                if (canUseChildren(childElement)) {
                    elementsAtPoint.push(...findAtPoint(childElement, x, y));
                }
            });

            return elementsAtPoint;
        };

        // Reverse so the deepest element is first
        return findAtPoint(element).reverse();
    },

    findTextBlockAtPoint(element, x, y) {
        const pt = Convert.ScreenToCanvasCoordinates(this, x, y);
        for (let block of element.calculatedProps.blockProps) {
            const blockBounds = block.textBounds
                ?.offset(element.canvasBounds.position)
                .offset(element.textBounds.position);
            if (blockBounds?.contains(pt)) {
                return block;
            }
        }
        return null;
    },

    hasSlideNotes: function() {
        return !!this.dataModel.get("slide_notes");
    },

    isPointInsideBounds: function(bounds, x, y) {
        const elementPt = Convert.ScreenToCanvasCoordinates(this, x, y);
        return bounds.contains(elementPt);
    },

    /**
     * Filters out all control chars except for line breaks, carriage returns and tabs
     * (they can break powerpoint when exported)
     */
    getNormalizedSlideNotes: function() {
        let slideNotes = this.dataModel.get("slide_notes");

        let $text = $.div().html(slideNotes);
        let textContent = $text[0].textContent;

        const allowedControlCharsCodes = [9, 10, 13];
        return [...textContent].filter(char => {
            const charCode = char.charCodeAt(0);
            return charCode > 31 || allowedControlCharsCodes.includes(charCode);
        }).join("");
    },

    waitForImages() {
        return this.layouter.canvasElement.imagesLoadPromise;
    },

    convertToClassic() {
        ShowConvertedToClassicDialog();
        ConvertSlideToAuthoring(this);
    },

    emptyCanvas: function() {
        this.stopElements();

        if (this.layouter) {
            this.layouter.destroy();
            this.layouter = null;
        }
        this.cancelRender();
        this.isRendered = false;
        this.isReady = false;
    },

    remove: function() {
        this.emptyCanvas();
        this.renderSlidePromise = null;
        this.detached = true;
        this.isReady = false;
        this.audioBuffer = null;

        this.elementsAwaitingRefresh.clear();
        clearTimeout(this.refreshElementsTimeout);
        this.refreshElementsTimeout = null;

        Backbone.View.prototype.remove.apply(this, arguments);
    }
});

export { SlideCanvas };

// DEV-START
if (import.meta.webpackHot) {
    import("static/themes/v10/default.scss");

    const reloadCanvases = async () => {
        if (window.isReloading) {
            // eslint-disable-next-line no-console
            console.log("[HMR] Already reloading canvases");
            return;
        }

        window.isReloading = true;
        try {
            const canvas = app.currentCanvas;
            if (canvas?.isRendered && !canvas.layouter?.isGenerating) {
                canvas.getLayouter = () => new SlideCanvasLayouter(canvas, canvas.slideTemplate);
                canvas.elementManager = elementManager;
                if (canvas.canvasController) {
                    await canvas.canvasController.reloadCanvas(null, true);
                } else {
                    await canvas.renderModel(true);
                }
            }
        } catch (err) {
            // eslint-disable-next-line no-console
            console.error("[HMR] Error reloading canvases", err);
        } finally {
            window.isReloading = false;
        }
    };

    import.meta.webpackHot.accept(["static/themes/v10/default.scss"], () => {
        return reloadCanvases();
    });

    let isReady = false;
    const onWebpackReload = status => {
        if (status === "ready") {
            isReady = true;
            return;
        }

        if (status === "idle" && isReady) {
            return reloadCanvases();
        }
    };

    import.meta.webpackHot.addStatusHandler(onWebpackReload);
}
// DEV-END
