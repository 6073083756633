import equal from "fast-deep-equal/es6";

import getLogger, { LogGroup } from "js/core/logger";
import { ds } from "js/core/models/dataService";
import * as geom from "js/core/utilities/geom";
import { createHash, uuid } from "js/core/utilities/utilities";
import { _, tinycolor } from "js/vendor";

import { AuthoringPropertyPanel } from "../../Editor/ElementPropertyPanels/AuthoringUI";
import { AuthoringCanvasSelection } from "../../Editor/ElementSelections/AuthoringCanvasSelection";
import { CollectionElement } from "../base/CollectionElement";
import { AuthoringElementContainer } from "./AuthoringElementContainer";
import { AuthoringElementsGroup } from "./AuthoringElementsGroup";
import { AuthoringBlockType, AuthoringElementType, AuthoringShapeType, CalloutType, ConnectorType, DirectionType, HorizontalAlignType, PaletteColorType, TextStyleType, VerticalAlignType } from "common/constants";

const logger = getLogger(LogGroup.AUTHORING);

export class AuthoringCanvas extends CollectionElement {
    static get schema() {
        return {
            snapToGrid: false,
            elements: [],
        };
    }

    get allowTransformProperties() {
        return true;
    }

    getAllowedCalloutTypes() {
        return [CalloutType.BOX, CalloutType.CIRCLE, CalloutType.FLEX_CIRCLE, CalloutType.TEXT, CalloutType.BULLET_TEXT, CalloutType.NUMBERED_TEXT, CalloutType.CONTENT_AND_TEXT, CalloutType.CONTENT, CalloutType.LINE_ARROW];
    }

    get allowCopyPasteStyles() {
        return true;
    }

    getElementPropertyPanel() {
        return AuthoringPropertyPanel;
    }

    getElementControlBar() {
        return null; //allow the authoring layer to handle the control bar
    }

    get name() {
        return "Classic Slide";
    }

    get _canSelect() {
        return true;
    }

    get restrictElementsToBounds() {
        return false;
    }

    get restrictElementsToBoundsByRegistrationPoint() {
        return false;
    }

    get collectionPropertyName() {
        return "elements";
    }

    get minItemCount() {
        return 0;
    }

    get gridSize() {
        return 10;
    }

    get snapToGrid() {
        return !!this.model.snapToGrid || !!this.options.snapToGrid;
    }

    snapPointToGrid(pt) {
        return new geom.Point(Math.round(pt.x / this.gridSize) * this.gridSize, Math.round(pt.y / this.gridSize) * this.gridSize);
    }

    get gridSpacing() {
        return 20;
    }

    get padding() {
        return 40;
    }

    get disableAllAnimationsByDefault() {
        return this.options.disableAllAnimationsByDefault ?? true;
    }

    get canRefreshElement() {
        return true;
    }

    get canPasteImage() {
        return true;
    }

    get startPointsAreLocked() {
        return true;
    }

    get showSnapLines() {
        return this.model.showSnapLines ?? true;
    }

    get allowSelectionContextMenu() {
        return this.options.allowSelectionContextMenu ?? true;
    }

    pasteFromClipboard(data) {
        this.authoringLayer.pasteFromClipboard(data);
    }

    get allowContextMenu() {
        return this.options.allowContextMenu ?? true;
    }

    get isCallouts() {
        return false;
    }

    get allowStepConnetors() {
        return false;
    }

    get allowSnapToNonAuthoringElements() {
        return true;
    }

    getDefaultConnectorModel(source) {
        return {
            source,
            startAnchor: geom.AnchorType.CENTER,
            endAnchor: geom.AnchorType.FREE,
            target: null,
            targetPoint: null,
            endDecoration: "circle",
            connectorType: ConnectorType.STRAIGHT
        };
    }

    getChildOptions(model) {
        return {
            // don't show the connection points on the authoring canvas
            allowConnection: false
        };
    }

    getDefaultModel(authoringElementType, {
        textStyle = TextStyleType.TITLE,
        shape = AuthoringShapeType.RECT,
        componentType = null,
        calloutType = null
    }) {
        switch (authoringElementType) {
            case AuthoringElementType.TEXT:
                return {
                    type: authoringElementType,
                    width: 400,
                    height: 150,
                    shape: AuthoringShapeType.RECT,
                    fill: "none",
                    stroke: "none",
                    textAlign: HorizontalAlignType.LEFT,
                    verticalAlign: VerticalAlignType.TOP,
                    fitToText: true,
                    text: {
                        blocks: [{
                            id: uuid(),
                            type: AuthoringBlockType.TEXT,
                            textStyle,
                            html: ""
                        }]
                    }
                };
            case AuthoringElementType.SHAPE:
                return {
                    type: authoringElementType,
                    shape,
                    width: 150,
                    height: 150
                };
            case AuthoringElementType.COMPONENT:
                return {
                    type: authoringElementType,
                    componentType,
                    // Give components bigger default size
                    width: 400,
                    height: 200,
                    // Will force the component to use this object
                    // for its model
                    element: {}
                };
            case AuthoringElementType.CALLOUT:
                let defaultOptions = {};
                switch (calloutType) {
                    case CalloutType.FLEX_CIRCLE:
                    case CalloutType.CIRCLE: {
                        defaultOptions = {
                            width: 130,
                            height: 130,
                            fitToText: false,
                            color: PaletteColorType.THEME
                        };
                        break;
                    }
                    case CalloutType.TEXT: {
                        defaultOptions = {
                            width: 130,
                            height: 150,
                        };
                        break;
                    }
                    case CalloutType.CONTENT: {
                        defaultOptions = {
                            width: 80,
                            height: 80,
                        };
                        break;
                    }
                    case CalloutType.BULLET_TEXT:
                    case CalloutType.NUMBERED_TEXT:
                    case CalloutType.LETTERED_TEXT:
                    case CalloutType.CONTENT_AND_TEXT: {
                        defaultOptions = {
                            width: 200,
                            height: 150,
                            color: PaletteColorType.THEME,
                            textDirection: DirectionType.AUTO
                        };
                        break;
                    }
                    default: {
                        defaultOptions = {
                            width: 130,
                            height: 100,
                        };
                    }
                }

                // The callout type doesn't have a default background color
                return {
                    // Takes care of the resizing
                    fitToText: true,
                    type: authoringElementType,
                    calloutType,
                    ...defaultOptions
                };
            default:
                throw new Error(`Unknown authoring element type: ${authoringElementType}`);
        }
    }

    containsPoint(pt) {
        return false;
    }

    refreshElement(transition, requireFit = false) {
        this.canvas.refreshElement(this, transition, true, requireFit);
    }

    getDefaultElementSize(elementType) {
        return new geom.Size(200, 200);
    }

    getCanvasMargins() {
        return { left: 0, top: 0, right: 0, bottom: 0 };
    }

    getChildItemType() {
        return AuthoringElementContainer;
    }

    resetUserColors() {
        return false;
    }

    getElementSelection() {
        return AuthoringCanvasSelection;
    }

    get forceShowSelection() {
        return true;
    }

    get authoringLayer() {
        return this.uiRefs.selectionRef.current?.authoringLayer;
    }

    getElementRollover() {
        return null;
    }

    async handlePasteModel(model) {
        let hasAsset = model.hasOwnProperty("content_value");
        if (hasAsset) {
            try {
                let asset = await ds.assets.getAssetById(model.content_value);
                this.authoringLayer.pasteAssetFromClipboard(asset);
            } catch (err) {
                throw `An error occured while attempting to add the media on the clipboard to the slide: ${err}`;
            }
        } else {
            throw `Classic slides allow pasting of media elements or other elements copied from Classic slides only.`;
        }
    }

    _calcProps(props, options) {
        const { size } = props;

        this.itemElements.forEach((element, elementIndex) => {
            // BA-13455 had a case where a callouts x/y were NaN - this protects against that
            if (isNaN(element.model.x) || isNaN(element.model.y)) {
                element.model.x = this.canvas.CANVAS_WIDTH / 2;
                element.model.y = this.canvas.CANVAS_HEIGHT / 2;
            }

            // Only calc props on authoring elements that have had their model changed - not everything on the authoring canvas
            if (!equal(element.model, element.calculatedProps?.calculatedModel)) {
                const sizeForCalcProps = new geom.Size(element.model.width ?? size.width, element.model.height ?? size.height);
                if (element.childElement.fitToContents?.width && element.childElement.needsFullSizeToCalcFit?.width) {
                    sizeForCalcProps.width = size.width;
                }
                if (element.childElement.fitToContents?.height && element.childElement.needsFullSizeToCalcFit?.height) {
                    sizeForCalcProps.height = size.height;
                }

                const elementProps = element.calcProps(sizeForCalcProps);

                if (element.childElement.fitToContents?.width) {
                    if (element.childElement.fitToContentsAnchor && element.childElement.fitToContentsAnchor.width === geom.AnchorType.RIGHT) {
                        const widthDiff = element.model.width - elementProps.size.width;
                        element.model.x += widthDiff;
                    }
                    if (element.childElement.fitToContentsAnchor && element.childElement.fitToContentsAnchor.width === geom.AnchorType.CENTER) {
                        const widthDiff = element.model.width - elementProps.size.width;
                        element.model.x += widthDiff / 2;
                    }

                    element.model.width = elementProps.size.width;
                }
                if (element.childElement.fitToContents?.height) {
                    if (element.childElement.fitToContentsAnchor && element.childElement.fitToContentsAnchor.height === geom.AnchorType.BOTTOM) {
                        const heightDiff = element.model.height - elementProps.size.height;
                        element.model.y += heightDiff;
                    }

                    if (element.childElement.fitToContentsAnchor && element.childElement.fitToContentsAnchor.height === geom.AnchorType.CENTER) {
                        const heightDiff = element.model.height - elementProps.size.height;
                        element.model.y += heightDiff / 2;
                    }

                    element.model.height = elementProps.size.height;
                }

                elementProps.bounds = new geom.Rect(element.model.x, element.model.y, elementProps.size);
                if (element.referencePoint) {
                    elementProps.bounds = elementProps.bounds.offset(-element.referencePoint.x, -element.referencePoint.y);
                }

                elementProps.rotate = element.model.rotation;
                elementProps.layer = elementIndex;
                elementProps.calculatedModel = _.cloneDeep(element.model);

                if (element.limitSize) {
                    if (element.minWidth > element.model.width) {
                        element.model.width = element.minWidth;
                    }
                    if (element.minHeight > element.model.height) {
                        element.model.height = element.minHeight;
                    }
                }
                if (this.restrictElementsToBounds) {
                    if (this.restrictElementsToBoundsByRegistrationPoint) {
                        const registrationPointBounds = new geom.Rect(element.model.x, element.model.y, 0, 0).fitInRect(new geom.Rect(0, 0, props.size.width, props.size.height));
                        element.model.x = registrationPointBounds.x;
                        element.model.y = registrationPointBounds.y;
                    } else {
                        elementProps.bounds = elementProps.bounds.fitInRect(new geom.Rect(0, 0, props.size.width, props.size.height));
                        element.model.x = elementProps.bounds.x + element.referencePoint?.x ?? 0;
                        element.model.y = elementProps.bounds.y + element.referencePoint?.y ?? 0;
                    }
                }

                if (element.model.isHidden) {
                    elementProps.opacity = 0;
                }
            }
        });

        return { size };
    }

    getAnimations() {
        const animations = this._getAnimations();
        animations.forEach(animation => animation.element = this);

        if (this.animateChildren) {
            Object.values(this.elements)
                // Will preserve the correct order of the children and ensure ConnectorGroup is last
                .sort((elementA, elementB) => {
                    if (elementA.isInstanceOf("ConnectorGroup")) return 1;
                    if (elementB.isInstanceOf("ConnectorGroup")) return -1;
                    return elementA.itemIndex - elementB.itemIndex;
                })
                .forEach(element => {
                    animations.push(...element.getAnimations());
                });
        }

        if (this.disableAnimationsByDefault) {
            animations.forEach(animation => animation.disabledByDefault = true);
        }

        // Combining animations of grouped elements
        const groupedAnimations = [];
        let groupIdx = 1;
        animations.forEach(animation => {
            const groupId = animation.element?.groupId;

            // Element not in a group
            if (!groupId) {
                groupedAnimations.push(animation);
                return;
            }

            // Already grouped
            if (groupedAnimations.some(groupedAnimation => groupedAnimation.element?.groupId === groupId)) {
                return;
            }

            const groupAnimations = animations.filter(animation => animation.element?.groupId === groupId);
            // Only one element in group
            if (groupAnimations.length === 1) {
                groupedAnimations.push(animation);
                return;
            }

            const groupAnimationElementIds = groupAnimations.map(({ element }) => element.uniquePath).sort();
            groupedAnimations.push({
                ...animation,
                id: createHash(groupAnimationElementIds.join("|")),
                element: new AuthoringElementsGroup({ id: groupId, parentElement: this, canvas: this.canvas, options: { groupId } }),
                elementId: groupId,
                elementName: `Group #${groupIdx}`,
                animatingElements: groupAnimations.map(({ element }) => element),
                name: "Animate in",
                prepare: () => groupAnimations.filter(({ prepare }) => !!prepare).forEach(({ prepare }) => prepare()),
                onBeforeAnimationFrame: progress => {
                    const elements = groupAnimations
                        .filter(({ onBeforeAnimationFrame }) => !!onBeforeAnimationFrame)
                        .map(({ onBeforeAnimationFrame }) => onBeforeAnimationFrame(progress));
                    if (elements.some(element => !!element)) {
                        return this;
                    }
                },
                finalize: () => groupAnimations.filter(({ finalize }) => !!finalize).forEach(({ finalize }) => finalize())
            });

            groupIdx++;
        });

        return groupedAnimations;
    }

    _exportToSharedModel() {
        const childElements = Object.values(this.elements).map(containerEl => Object.values(containerEl.elements)).flat();
        return childElements.reduce((model, el) => _.mergeWith(model, el.exportToSharedModel(), (a, b) => {
            if (_.isArray(a)) return a.concat(b);
        }), {});
    }

    _importFromSharedModel(model) {
        const childElements = Object.values(this.elements).map(containerEl => Object.values(containerEl.elements)).flat();
        return childElements.map(element => element.importFromSharedModel(model));
    }

    _migrate_10_02() {
        super._migrate_10_02();

        for (let element of this.itemCollection) {
            if (element.shadow?.color) {
                let shadowColor = tinycolor(element.shadow.color);
                element.shadow.opacity = shadowColor.getAlpha();
                element.shadow.color = shadowColor.setAlpha(1).toRgbString();
            }
        }
    }
}

export const elements = {
    AuthoringCanvas
};

