import React from "react";
import styled, { css } from "styled-components";
import { themeColors } from "../react/sharedStyles";
import { ForeColorType, PaletteColorType } from "../../common/constants";
import { Icon } from "./Icon";
import { tinycolor, _ } from "js/vendor";
import { FlexBox } from "../react/components/LayoutGrid";

const CircleColorChit = styled.div.attrs(({ color, size, selected }) => ({
    style: {
        backgroundColor: color,
        width: size,
        height: size,
        // borderColor: selected ? themeColors.ui_blue : "white",
        outline: selected ? `solid 1px ${themeColors.ui_blue}` : "none",
        fontFamily: "Arial",
        fontWeight: 600,
        fontSize: size / 2.5,
        color: tinycolor(color).isDark() ? "white" : "black",
    }
}))`
    height: 30px;
    width: 30px;
    border: solid 1px ${props => tinycolor(props.color).isNearWhite() ? "#aaa" : "white"};
    border-radius: 50%;
    overflow: hidden;
    position: relative;
    cursor: pointer;
    
    &:after {
        content: "${props => props.overlayLetter}";
        position: absolute;
        top: -1px;
        left: 0px;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        //font-size: 10px;
    }
    
    ${props => props.disabled && css`
        &:after {
            content: "/";
            position: absolute;
            top: 0px;
            left: 0px;
            width: 100%;
            height: 100%;
            font-size: 46px;
            transform: rotate(30deg);
            color: white;
        }     
    `};
`;

const CircleColorChitNone = styled(CircleColorChit).attrs(({ color, size, selected }) => ({
    style: {
        width: size,
        height: size,
    }
}))`
    background: white;
    //border: solid 1px #333;
    border: solid 1px white;

    div {
        margin: 0px;
        border: solid 1px #333;
        border-radius: 50%;
        width: 100%;
        height: 100%;
    }

    &:after {
        content: "";
        position: absolute;
        top: calc(50% - 3px);
        left: -9px;
        width: calc(100% + 20px);
        height: 4px;
        background: #333;
        transform: rotate(-45deg);
        transform-origin: center center;
    }
`;

const CircleColorChitAuto = styled(CircleColorChit).attrs(({ color, size, selected }) => ({
    style: {
        backgroundColor: color,
        width: size,
        height: size,
        borderColor: selected ? themeColors.ui_blue : "#bbb",
        outline: selected ? `solid 1px ${themeColors.ui_blue}` : "none",
        fontFamily: "Arial",
        fontWeight: 600,
        fontSize: size / 2.5,
        color: tinycolor(color).isDark() ? "white" : "black",
    }
}))`
    background: white;

    &:after {
        content: "${({ autoLetter = "A" }) => autoLetter}";
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        //font-size: 10px;
    }
`;

const CircleColorChitAccent = styled(CircleColorChit).attrs(({ color, size, selected }) => ({
    style: {
        backgroundColor: color,
        width: size,
        height: size,
        borderColor: selected ? themeColors.ui_blue : "#bbb",
        outline: selected ? `solid 1px ${themeColors.ui_blue}` : "none",
        fontSize: size / 2,
        color: tinycolor(color).isDark() ? "white" : "black"
    }
}))`
    background: white;

    &:after {
        content: "A";
        position: absolute;
        top: -1px;
        left: 0px;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        //font-size: 10px;
    }
`;

const CircleColorChitSlide = styled(CircleColorChit)`
    background: white;

    &:after {
        content: "SLIDE";
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 7px;
        color: black;
    }

    &.dark {
        &:after {
            color: white;
        }
    }
`;

const ColorfulChit = styled(CircleColorChit)`
    vertical-align: top;
    display: grid;
    grid-auto-flow: column;

    div {
        height: 100%;
        display: inline-block;
        vertical-align: top;
        flex-grow: 1;
    }
`;

const PrimaryChit = styled(CircleColorChit)`
    border: solid 1px #000;
    vertical-align: top;
    display: grid;
    grid-auto-flow: column;
    transform: rotate(45deg);

    div {
        height: 100%;
        display: inline-block;
        vertical-align: top;
        flex-grow: 1;
    }
`;

export const ColorChitWithIcon = styled(CircleColorChit)`
    display: flex;
    align-items: center;
    justify-content: center;

    .bai-icon {
            // color: ${props => tinycolor(props.color).isDark() ? "#fff !important" : "#000 !important"};
        color: ${props => props.color};
        opacity: 0.8 !important;
    }
`;

export function ColorChit({ color, type, onClick, selected, size = 30, overlayLetter, disabled }) {
    switch (type) { // none, auto, slide, colorful
        case "none":
            return (
                <CircleColorChitNone onClick={onClick} selected={selected} size={size} disabled={disabled}>
                    <div />
                </CircleColorChitNone>
            );
        case "accent":
            return (
                <CircleColorChitAccent color={color} onClick={onClick} selected={selected} size={size} disabled={disabled} overlayLetter={overlayLetter} />
            );
        case "auto":
            return (
                <CircleColorChitAuto color={color} onClick={onClick} selected={selected} size={size} disabled={disabled} overlayLetter={overlayLetter} />
            );
        case "slide":
            return (
                <CircleColorChitSlide color={color} onClick={onClick} selected={selected} size={size} disabled={disabled} overlayLetter={overlayLetter}
                    className={selected ? "dark" : ""} />
            );
        case "colorful":
            return (
                <ColorfulChit color={color} onClick={onClick} selected={selected} size={size} disabled={disabled} overlayLetter={overlayLetter}>
                    {color.map((color, index) => (<div key={index} style={{ background: color }} />))}
                </ColorfulChit>
            );
        case "primary":
            return (
                <PrimaryChit color={color} onClick={onClick} selected={selected} size={size} disabled={disabled} overlayLetter={overlayLetter}>
                    {color.map((color, index) => (<div key={index} style={{ background: color }} />))}
                </PrimaryChit>
            );
        case "picker":
            return (
                <ColorChitWithIcon color="white" onClick={onClick} selected={selected} size={size} disabled={disabled} overlayLetter="?">
                    <Icon>colorize</Icon>
                </ColorChitWithIcon>
            );
        default:
            return (
                <CircleColorChit color={color} onClick={onClick} selected={selected} size={size} disabled={disabled} overlayLetter={overlayLetter} />
            );
    }
}

export function ThemeColorChit({ color, type, theme, size = 30, selected, onClick, isAccentColor, overlayLetter, disabled }) {
    if (!color || !(typeof color === "string") || color === "none" || color === "transparent") {
        return (<ColorChit type="none" size={size} onClick={onClick} selected={selected} disabled={disabled} />);
    }

    if (color === ForeColorType.NEUTRAL || color === ForeColorType.PRIMARY || color === ForeColorType.AUTO) {
        const primaryDark = theme.palette.getColor(PaletteColorType.PRIMARY_DARK);
        const primaryLight = theme.palette.getColor(PaletteColorType.PRIMARY_LIGHT);
        return (<ColorChit
            color={[primaryDark, primaryLight]}
            type="primary"
            size={size}
            onClick={onClick}
            selected={selected}
            isAccentColor={isAccentColor}
            overlayLetter={overlayLetter}
            disabled={disabled}
        />);
    }

    if (color === ForeColorType.SECONDARY) {
        const secondaryDark = theme.palette.getColor(PaletteColorType.SECONDARY_DARK);
        const secondaryLight = theme.palette.getColor(PaletteColorType.SECONDARY_LIGHT);
        return (<ColorChit
            color={[secondaryDark, secondaryLight]}
            type="primary"
            size={size}
            onClick={onClick}
            selected={selected}
            isAccentColor={isAccentColor}
            overlayLetter={overlayLetter}
            disabled={disabled}
        />);
    }

    const themeColors = theme.palette.getSlideColors();
    if (color === ForeColorType.COLORFUL) {
        return (<ColorChit
            color={Object.values(themeColors)}
            type="colorful"
            size={size}
            onClick={onClick}
            selected={selected}
            isAccentColor={isAccentColor}
            overlayLetter={overlayLetter}
            disabled={disabled}
        />);
    }

    if (color === "theme" ||
        color?.startsWith("accent") ||
        color?.startsWith("chart") ||
        color?.equalsAnyOf(
            PaletteColorType.BACKGROUND_ACCENT,
            PaletteColorType.BACKGROUND_DARK,
            PaletteColorType.BACKGROUND_LIGHT,
            PaletteColorType.PRIMARY_DARK,
            PaletteColorType.PRIMARY_LIGHT,
            PaletteColorType.SECONDARY_DARK,
            PaletteColorType.SECONDARY_LIGHT,
            "white",
            "black",
            PaletteColorType.POSITIVE,
            PaletteColorType.NEGATIVE
        )
    ) {
        return (<ColorChit
            color={theme.palette.getColor(color)}
            size={size}
            type={type}
            onClick={onClick}
            selected={selected}
            isAccentColor={isAccentColor}
            overlayLetter={overlayLetter}
            disabled={disabled}
        />);
    }

    if (color.startsWith("rgb") || color.startsWith("#")) {
        return (<ColorChit
            color={color}
            size={size}
            type={type}
            onClick={onClick}
            selected={selected}
            overlayLetter={overlayLetter}
            disabled={disabled}
        />);
    }

    // its an image color
    return <ColorChitWithIcon color={themeColors.ui_blue} size={size} disabled={disabled}><Icon fill>photo_camera</Icon></ColorChitWithIcon>;
}

