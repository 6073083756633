import { Radio } from "@material-ui/core";
import React, { Component } from "react";
import styled from "styled-components";
import Spreadsheet, { Cell } from "x-data-spreadsheet";
import * as XLSX from "xlsx";

import { ActionPermissionsObject, PermissionActionType } from "common/interfaces";
import WorkspaceController from "js/controllers/WorkspaceController";
import AppController from "js/core/AppController";
import getLogger, { LogGroup } from "js/core/logger";
import * as xlsxUtils from "js/core/utilities/xlsx";
import { cellRangeObjToA1Notation } from "js/core/utilities/xlsx";
import { stox } from "js/core/utilities/xlsxspread";
import { ShowErrorDialog, ShowUpgradeDialog } from "js/react/components/Dialogs/BaseDialog";
import ProBadge from "js/react/components/ProBadge";
import { UpgradePlanDialogType } from "js/react/views/MarketingDialogs/UpgradePlanDialog";
import { $ } from "js/vendor";

const logger = getLogger(LogGroup.DATA_SOURCE);

const Container = styled.div`
    width: 100%;
    min-height: 60vh;
    display: flex;
    flex-direction: column;
`;

const SpreadsheetContainer = styled.div<{ radioButtonsPresent: boolean }>`
    width: 100%;
    height: ${props => `${props.radioButtonsPresent ? 80 : 100}%`};
`;

const RadioButtonsContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 30px 30px 0;
    gap: 15px;
`;

const RadioButtonSection = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    gap: 20px;
    cursor: pointer;
    .disabled {
        cursor: not-allowed;
        background-color: #999;
    }
`;

const RadioButtonLabelSection = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    & .label-header {
        font-size: 14px;
        font-weight: 600;
    }
    & .label-description {
        font-size: 12px;
    }
`;

interface Props {
    workbookData: XLSX.WorkBook,
    savingType?: "link" | "import",
    selectedSheetIndex: number,
    selectedCellRange: string,
    handleBack: Function,
    handleSavingTypeChange: Function,
    handleSheetIndexChange: Function,
    handleCellRangeSelection: Function,
}

export default class FormatDataPane extends Component<Props> {
    grid: Spreadsheet;

    componentDidMount() {
        const container = document.querySelector("#x-spreadsheet-container") as HTMLElement;
        this.grid = new Spreadsheet("#x-spreadsheet-container", {
            mode: "read",
            showToolbar: false,
            showContextmenu: false,
            view: {
                height: () => container.clientHeight,
                width: () => container.clientWidth
            },
            style: {
                font: { // @ts-ignore
                    name: "Source Sans Pro",
                },
            },
            col: {
                len: 26 * 10,
                width: 100,
                indexWidth: 60,
                minWidth: 60,
            },
            row: {
                len: 150,
                height: 25,
            },
            settings: {
                enableKeyboard: true
            }
        });

        try {
            this.grid.loadData(stox(this.props.workbookData));

            this.grid.on("cell-selected", this.handleCellSelected);
            this.grid.on("cells-selected", this.handleCellRangeSelected);

            container.addEventListener("keydown", this.handleKeyDown);
            container.setAttribute("tabindex", "0");
            container.focus();

            const $sheetTabs = $(".x-spreadsheet-menu").children();
            if ($sheetTabs?.length) {
                if (this.props.selectedSheetIndex) {
                    $($sheetTabs[this.props.selectedSheetIndex + 1]).click();
                }

                for (let i = 0; i < $sheetTabs.length; i++) {
                    if (i === 0) continue;
                    $($sheetTabs[i]).on("click", () => this.handleSheetIndexChange(i - 1));
                }
            }

            this.setSelectedCellRange();
        } catch (err) {
            logger.error(err, "[FormatDataPane] failed to load data to x-spreadsheet UI");
            ShowErrorDialog({
                title: "Oops, failed to parse imported file",
                message: "Added spreadsheet file is either invalid or contains unsupported cell formatting. Please choose a different file.",
            });

            this.props.handleBack();
        }
    }

    componentWillUnmount(): void {
        const $sheetTabs = $(".x-spreadsheet-menu").children();
        if ($sheetTabs?.length) {
            for (let i = 0; i < $sheetTabs.length; i++) {
                if (i === 0) continue;
                $($sheetTabs[i]).off("click", () => this.handleSheetIndexChange(i - 1));
            }
        }
        const container = document.querySelector("#x-spreadsheet-container");
        if (container) {
            container.removeEventListener("keydown", this.handleKeyDown);
        }
    }

    componentDidUpdate(prevProps: Props): void {
        if (prevProps.selectedSheetIndex !== this.props.selectedSheetIndex) {
            this.setSelectedCellRange();
        }
    }

    setSelectedCellRange = () => {
        const $sheet = (this.grid as any).sheet;

        // copied from x-spreadsheet lib source code
        const _selectorSet = (eri, eci) => {
            const { table, selector, toolbar, data, contextMenu } = $sheet;
            selector.setEnd(eri, eci, false);
            const cell = data.getCell(eri, eci);
            $sheet.trigger("cells-selected", cell, selector.range);
            contextMenu.setMode("range");
            toolbar.reset();
            table.render();
        };

        let selectedRange: XLSX.Range;
        if (this.props.selectedCellRange) {
            selectedRange = XLSX.utils.decode_range(this.props.selectedCellRange);
        } else {
            const ws = this.props.workbookData.Sheets[this.props.workbookData.SheetNames[this.props.selectedSheetIndex]];
            selectedRange = xlsxUtils.getDefaultCellRange(ws);
        }

        if (selectedRange) {
            (this.grid as any).sheet.selector.set(selectedRange.s.r, selectedRange.s.c);
            _selectorSet(selectedRange.e.r, selectedRange.e.c);

            this.handleCellRangeSelected(null, {
                sri: selectedRange.s.r, sci: selectedRange.s.c, eri: selectedRange.e.r, eci: selectedRange.e.c,
            });
        }
    }

    handleCellSelected = (_cell: Cell) => {
        this.props.handleCellRangeSelection(null);
    }

    handleCellRangeSelected = (_cell: Cell, params: { sri: number, sci: number, eri: number, eci: number }) => {
        const selectedCellRange = cellRangeObjToA1Notation(params);
        const selectedCellCount = (Math.abs(params.sri - params.eri) + 1) * (Math.abs(params.sci - params.eci) + 1);

        this.props.handleCellRangeSelection(selectedCellCount > 1 ? selectedCellRange : null);
    }

    handleSheetIndexChange = (selectedSheetIndex: number) => {
        this.props.handleCellRangeSelection(null);
        this.props.handleSheetIndexChange(selectedSheetIndex);
    }

    handleSavingTypeChange = (savingType: Props["savingType"]) => {
        if (!WorkspaceController.actionPermissions[ActionPermissionsObject.DATA_LINKING][PermissionActionType.USE] && savingType === "link") {
            if (WorkspaceController.canUpgradeToRemoveActionRestriction(ActionPermissionsObject.DATA_LINKING, PermissionActionType.USE)) {
                ShowUpgradeDialog({
                    type: UpgradePlanDialogType.UPGRADE_PLAN_TO_TEAM,
                    analytics: { cta: "LinkDataSource" },
                    workspaceId: AppController.workspaceId
                });
            }

            this.props.handleSavingTypeChange("import");
            return;
        }

        this.props.handleSavingTypeChange(savingType);
    }

    handleKeyDown = (e: KeyboardEvent) => {
        const sheet = (this.grid as any).sheet;
        const { selector } = sheet;
        const { range } = selector;

        if (!range) return;

        let { sri: startRow, sci: startCol, eri: endRow, eci: endCol } = range;
        const isShiftPressed = e.shiftKey;

        // Get current sheet dimensions
        const currentSheet = this.props.workbookData.Sheets[this.props.workbookData.SheetNames[this.props.selectedSheetIndex]];
        const sheetRange = XLSX.utils.decode_range(currentSheet["!ref"]);
        const maxRow = sheetRange.e.r;
        const maxCol = sheetRange.e.c;

        switch (e.key) {
            case "ArrowUp":
                e.preventDefault();
                if (isShiftPressed) {
                    endRow = Math.max(0, endRow - 1);
                } else {
                    startRow = Math.max(0, startRow - 1);
                    endRow = startRow;
                }
                break;
            case "ArrowDown":
                e.preventDefault();
                if (isShiftPressed) {
                    endRow = Math.min(maxRow, endRow + 1);
                } else {
                    startRow = Math.min(maxRow, startRow + 1);
                    endRow = startRow;
                }
                break;
            case "ArrowLeft":
                e.preventDefault();
                if (isShiftPressed) {
                    endCol = Math.max(0, endCol - 1);
                } else {
                    startCol = Math.max(0, startCol - 1);
                    endCol = startCol;
                }
                break;
            case "ArrowRight":
                e.preventDefault();
                if (isShiftPressed) {
                    endCol = Math.min(maxCol, endCol + 1);
                } else {
                    startCol = Math.min(maxCol, startCol + 1);
                    endCol = startCol;
                }
                break;
            default:
                return;
        }

        // Update selection
        selector.set(startRow, startCol);
        sheet.selector.setEnd(endRow, endCol, false);

        // Trigger the cell range selection handler
        this.handleCellRangeSelected(null, {
            sri: startRow,
            sci: startCol,
            eri: endRow,
            eci: endCol
        });
    }

    render() {
        const { savingType } = this.props;

        const canUseDataLinking = WorkspaceController.actionPermissions[ActionPermissionsObject.DATA_LINKING].use;
        const shouldShowTeamBadge = WorkspaceController.canUpgradeToRemoveActionRestriction(ActionPermissionsObject.DATA_LINKING, PermissionActionType.USE);

        return (
            <Container>
                <SpreadsheetContainer id="x-spreadsheet-container" radioButtonsPresent={!!savingType} />

                {savingType && (
                    <RadioButtonsContainer>
                        <RadioButtonSection onClick={() => this.handleSavingTypeChange("import")}>
                            <Radio color="primary" checked={savingType === "import"} />
                            <RadioButtonLabelSection>
                                <span className="label-header">Import Data</span>
                                <span className="label-description">Changes to the sheet will not be reflected on the slide unless you manually refresh. You can edit this data directly on the slide.</span>
                            </RadioButtonLabelSection>
                        </RadioButtonSection>
                        <RadioButtonSection className={!canUseDataLinking ? "disabled" : ""} onClick={() => this.handleSavingTypeChange("link")}>
                            <Radio color="primary" checked={savingType === "link"} />
                            <RadioButtonLabelSection>
                                <div style={{ display: "flex", alignItems: "center" }}>
                                    <span className="label-header">Link to Datasource</span>
                                    <ProBadge
                                        // @ts-ignore
                                        show={shouldShowTeamBadge}
                                        upgradeType={UpgradePlanDialogType.UPGRADE_PLAN_TO_TEAM}
                                        analytics={{ cta: "LinkDataSource" }}
                                        workspaceId={AppController.workspaceId}
                                    />
                                </div>
                                <span className="label-description">Your slide is automatically updated if the sheet is modified. Data can't be edited from the slide.</span>
                            </RadioButtonLabelSection>
                        </RadioButtonSection>
                    </RadioButtonsContainer>
                )}
            </Container>
        );
    }
}
