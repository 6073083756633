import React, { Component, useRef } from "react";
import { useDrag, useDrop } from "react-dnd";
import styled from "styled-components";

import { MenuItem } from "@material-ui/core";

import { Button } from "../../../../Components/Button";
import { Dropdown } from "../../../../Components/Dropdown";
import { WidgetButtonPosition } from "../../../../Components/WidgetButton";
import { WithLabel } from "../../../../Components/WithLabel";
import { sanitizeHtml } from "../../../../core/utilities/dompurify";
import { themeColors } from "../../../../react/sharedStyles";
import { Icon } from "../../../../Components/Icon";
import { NumericStepper } from "../../../../Components/NumericStepper";
import { ToggleIcon } from "../../../../Components/ToggleSwitch";
import { HorizontalPropertyList, PropertyPanelContainer, PropertySection } from "../../../../EditorComponents/PropertyPanel";
import { ControlBar, ControlBarGroup } from "../ElementControlBars/Components/ControlBar";
import DeleteElementButton from "../ElementSelections/Components/DeleteElementButton";
import { PropertyPanelHeader } from "../../../../editor/ElementEditor/PropertyPanelHeader";
import { GridLayoutPropertyPanel } from "./GridContainer/GridLayoutUI";
import { LayoutEngineType } from "../../elements/elements/LayoutContainers/GridLayoutContainer";
import { ToolTipBadge } from "../../../../Components/ToolTip";
import { FlexBox } from "../../../../react/components/LayoutGrid";

const CarouselList = styled.div`
    width: 100%;
    display: flex;
    flex-flow: column;
    gap: 10px;
`;

const CarouselItemContainer = styled.div.attrs(({ backgroundColor, height, canDrag }) => ({
    style: {
        backgroundColor,
        height: `${height}px`
    }
}))`
    width: 100%;
    position: relative;

    cursor: ${props => props.canDrag ? "pointer" : "default"};
    border: solid 1px ${props => props.selected ? themeColors.ui_blue : "#ccc"};

    display: flex;
    justify-content: center;
    align-items: center;

    .delete-button {
        opacity: 0;
        transition: opacity 0.2s ease-in-out;
    }

    &:hover {
        border-color: ${themeColors.ui_blue};

        .delete-button {
            opacity: 1;
        }
    }
`;

const CarouselItemElementContainer = styled.div.attrs(({ scale }) => ({
    style: {
        transform: `scale(${scale})`
    }
}))`
    > div {
        opacity: 1 !important;
        animation-name: none !important;

        * {
            pointer-events: none !important;
        }
    }
`;

const ItemIndex = styled.div`
    position: absolute;
    background: ${props => props.selected ? themeColors.ui_blue : "#bbb"};
    color: white;
    border-radius 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    font-weight: bold;
    width: 20px;
    height: 20px;
    top: -10px;
    left: -10px;
`;

function normalizeElementHtml(html) {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, "text/html");
    const element = doc.body.firstChild;
    element.style.removeProperty("left");
    element.style.removeProperty("top");
    element.style.setProperty("position", "relative");
    return element.outerHTML;
}

const DraggableCarouselItem = ({ index, selected, item, onClick, onMove, backgroundColor, onDelete, canDelete, canDrag }) => {
    const containerRef = useRef(null);

    const [isMouseDown, setIsMouseDown] = React.useState(false);

    const [{ isDropTarget }, drop] = useDrop({
        accept: "item",
        collect: monitor => ({
            handleId: monitor.getHandlerId(),
            isDropTarget: monitor.isOver()
        }),
        drop: item => {
            onMove(item.index, index);
            setIsMouseDown(false);
        }
    });

    const [, drag] = useDrag({
        type: "item",
        item: () => ({ item, index }),
        collect: monitor => ({
            isDragging: monitor.isDragging()
        }),
        end: () => {
            setIsMouseDown(false);
        }
    });

    if (canDrag) {
        drag(drop(containerRef));
    }

    const itemHTML = normalizeElementHtml(sanitizeHtml(item.DOMNode?.outerHTML ?? ""));
    const elementSize = item.calculatedProps.bounds.size;
    const scale = 220 / elementSize.width;
    const height = elementSize.height * scale + 30;

    return (
        <CarouselItemContainer
            ref={containerRef}
            id={item.id}
            height={height}
            backgroundColor={isDropTarget ? themeColors.ui_blue : backgroundColor}
            onClick={() => onClick(index)}
            selected={selected}
            canDrag={canDrag}
        >
            <CarouselItemElementContainer
                scale={scale}
                onMouseDown={() => setIsMouseDown(true)}
                onMouseUp={() => setIsMouseDown(false)}
                dangerouslySetInnerHTML={{ __html: itemHTML }}
            />
            {!isMouseDown && canDelete &&
                <DeleteElementButton onClick={onDelete} position={WidgetButtonPosition.CORNER} />
            }
            <ItemIndex selected={selected}>{index + 1}</ItemIndex>
        </CarouselItemContainer>
    );
};

export class ImageCarouselPropertyPanel extends Component {
    handleClickItem = index => {
        const { element, selectionLayerController } = this.props;
        element.goToItem(index, true);
        selectionLayerController.setSelectedElements([element.itemElements[index]]);
    }

    handleAddItem = () => {
        const { element } = this.props;

        element.addItem();

        element.saveModel();
    }

    handleReorderItem = (fromIndex, toIndex) => {
        const { element } = this.props;

        const items = element.itemCollection;

        const sourceItem = items[fromIndex];
        items.remove(sourceItem);
        items.insert(sourceItem, toIndex);

        element.saveModel();
    }

    handleDeleteItem = itemId => {
        const { element } = this.props;

        if (element.itemCollection.length > element.minItemCount) {
            element.deleteItem(itemId);
        }

        element.saveModel();
    }

    render() {
        const { element } = this.props;

        const bg = element.getBackgroundColor();
        const backgroundColor = bg.toRgbString();

        return (
            <PropertyPanelContainer>
                <PropertySection>
                    <WithLabel label="Transition">
                        <Dropdown value={element.transition} onChange={value => element.updateModel({ transition: value })}>
                            <MenuItem value="slide-left">Slide Left/Right</MenuItem>
                            <MenuItem value="slide-up">Slide Up/Down</MenuItem>
                            <MenuItem value="fade">Fade</MenuItem>
                        </Dropdown>
                    </WithLabel>
                </PropertySection>
                <PropertySection>
                    <HorizontalPropertyList distribute>
                        <WithLabel label="Auto Play" left tight small>
                            <ToggleIcon icon="play_arrow" value={element.autoPlay} onChange={value => element.updateModel({ autoPlay: value })} />
                        </WithLabel>
                        {element.autoPlay && (
                            <>
                                <WithLabel label="secs" left tight small>
                                    <NumericStepper value={element.autoPlayInterval / 1000} onChange={value => element.updateModel({ autoPlayInterval: value * 1000 })} />
                                </WithLabel>

                                <WithLabel label="Loop" left tight small>
                                    <ToggleIcon icon="replay" iconOff="arrow_right_alt" value={element.loop} onChange={value => element.updateModel({ loop: value })} />
                                </WithLabel>
                            </>
                        )}

                    </HorizontalPropertyList>
                </PropertySection>
                <PropertySection>
                    <CarouselList>
                        {element.itemElements
                            .map((item, index) => item?.calculatedProps && (
                                <DraggableCarouselItem
                                    key={index}
                                    index={index}
                                    item={item}
                                    selected={element.currentIndex === index}
                                    backgroundColor={backgroundColor}
                                    onClick={() => this.handleClickItem(index)}
                                    onMove={(fromIndex, toIndex) => this.handleReorderItem(fromIndex, toIndex)}
                                    onDelete={() => this.handleDeleteItem(item.id)}
                                    canDelete={element.itemCount > element.minItemCount}
                                    canDrag={element.itemCount > 1}
                                />))}
                    </CarouselList>
                    <Button onClick={this.handleAddItem}>Add Item</Button>
                </PropertySection>
                {element.model.wasMigratedFromV10 && (
                    <PropertySection>
                        <FlexBox left gap={5}>
                            Note: This slide is using legacy text styles.
                            <ToolTipBadge>
                                This slide was migrated from a previous version of Beautiful.ai and is using text styles to match the previous design. Newly created carousel slides in this version of Beautiful.ai may use a different set of text styles.
                            </ToolTipBadge>
                        </FlexBox>
                    </PropertySection>
                )}
            </PropertyPanelContainer>
        );
    }
}

export class ImageCarouselControlBar extends Component {
    render() {
        const { element, selectionLayerController } = this.props;

        return (
            <ControlBar>
                <ControlBarGroup>
                    <Button blue disabled={element?.currentIndex == 0} onClick={() => {
                        element.goPrevItem();
                        selectionLayerController.setSelectedElements([element.itemElements[element.currentIndex]]);
                    }}>
                        <Icon>arrow_back</Icon>
                        Prev
                    </Button>
                    <div style={{ padding: "0px 10px" }}>
                        <label>Item {element?.currentIndex + 1} of {element?.itemCount}</label>
                    </div>
                    <Button blue disabled={element?.currentIndex == element.itemCount - 1} onClick={() => {
                        element.goNextItem();
                        selectionLayerController.setSelectedElements([element.itemElements[element.currentIndex]]);
                    }}>
                        <Icon>arrow_forward</Icon>
                        Next
                    </Button>
                </ControlBarGroup>
            </ControlBar>
        );
    }
}

export class ImageCarouselGridContainerPropertyPanel extends Component {
    render() {
        const { element, canvas } = this.props;

        return (
            <PropertyPanelContainer>
                <PropertyPanelHeader>Carousel Grid</PropertyPanelHeader>
                {element && (
                    <GridLayoutPropertyPanel {...this.props} element={element}
                        allowFullBleed={false}
                        availableLayoutEngines={[LayoutEngineType.FLEX, LayoutEngineType.PRESERVE_ASPECT, LayoutEngineType.CUSTOM]}
                        showMoreGridOptions
                    />
                )}
            </PropertyPanelContainer>
        );
    }
}
